import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import axios from "axios";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import AddTaskModal from "./add-task/AddTask";
import { crmActivitiesContext } from "../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";

const API_URL = process.env.REACT_APP_API_URL;

const TaskContent = ({ componentType }) => {
    const { crmActivitiesData } = useContext(crmActivitiesContext);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [deals, setDeals] = useState([]);
    const [leads, setLeads] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [users, setUsers] = useState([{
        _id: "blfsf",
        name: "user1"
    }]);
    const { id } = useParams();

    useEffect(() => {
        fetchTasks();
        fetchCompanies();
        fetchDeals();
        fetchLeads();
        fetchContacts();
    }, [id, crmActivitiesData.tempFetch]);

    const fetchContacts = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-contacts`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setContacts(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);

        }
    };

    const fetchTasks = async () => {
        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;
            const response = await axios.post(`${API_URL}/crm/get-activities`, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                crm_type: componentType,
                owner_id,
                type: "TASK",
            });
            if (response.data.success) {
                setTasks(response.data.data);
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to fetch tasks", delay: 3000 });
        } finally {
            setLoading(false);
        }
    };

    const fetchCompanies = async () => {
        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;
            const response = await axios.post(`${API_URL}/crm/get-companies`, {
                "owner_id": owner_id
            });
            if (response.data.success) {
                setCompanies(response.data.data);
            }
        } catch (error) {
            // PNotify.error({ title: "Error", text: "Failed to fetch companies", delay: 3000 });
        }
    };

    const fetchDeals = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-deals`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setDeals(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching deals:", error);
        }
    };

    const fetchLeads = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-leads`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setLeads(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching leads:", error);

        }

    };

    const handleTaskCreated = (newTask) => {
        setTasks(prev => ([newTask, ...prev]));
    };

    const getPriorityBadgeColor = (priority) => {
        switch (priority.toLowerCase()) {
            case 'high':
                return 'bg-danger';
            case 'medium':
                return 'bg-warning';
            case 'low':
                return 'bg-info';
            default:
                return 'bg-secondary';
        }
    };

    const getStatusBadgeColor = (status) => {
        switch (status.toLowerCase()) {
            case 'todo':
                return 'bg-secondary';
            case 'in progress':
                return 'bg-primary';
            case 'completed':
                return 'bg-success';
            default:
                return 'bg-secondary';
        }
    };

    if (loading) return <div className="text-center text-light">Loading tasks...</div>;

    return (
        <div className="p-4 rounded">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>Tasks</h5>
                <Button onClick={() => setShowModal(true)}>Create Task</Button>
            </div>

            {tasks.length > 0 ? (
                tasks.map((task) => (
                    <div key={task._id} className="bg-dark text-white p-3 mb-2 rounded">
                        <span className="text-info">Task added by {task?.owner_id?.fname} {task?.owner_id?.lname}</span>

                        <div className="d-flex justify-content-between align-items-center">
                            <h6>{task.title}</h6>
                            <div>
                                <span className={`badge me-2 ${getPriorityBadgeColor(task.priority)}`}>
                                    {task.priority}
                                </span>
                                <span className={`badge ${getStatusBadgeColor(task.status)}`}>
                                    {task.status}
                                </span>
                            </div>
                        </div>
                        <p className="mt-2 mb-2">{task.description}</p>
                        <div className="d-flex justify-content-between text-muted">
                            <small>Assigned to: {task.assigned_to}</small>
                            <small>Due: {new Date(task.due_datetime).toLocaleString()}</small>
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-center text-muted">No tasks created yet.</p>
            )}

            <AddTaskModal
                showModal={showModal}
                setShowModal={setShowModal}
                componentType={componentType}
                id={id}
                onTaskCreated={handleTaskCreated}
                companies={companies}
                deals={deals}
                leads={leads}
                contacts={contacts}
                users={users}
            />
        </div>
    );
};

export default TaskContent;