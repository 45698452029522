import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

const TagCards = ({ tags, handleDeleteTag }) => {
    return (
        <div className="container-fluid p-0 mt-3">
            {tags.map(tag => (
                <div key={tag._id} className="card mb-4 shadow-sm border-0" >
                    <div className="card-body p-0">
                        <div className="row g-0" style={{borderRadius: '10px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}>
                            {/* Tag Header Section */}
                            <div className="col-12 p-3 d-flex justify-content-between align-items-center ">
                                <div className="d-flex align-items-center">
                                    <div
                                        className="tag-color-preview"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '50%',
                                            backgroundColor: tag.tag_color,
                                            marginRight: '12px'
                                        }}
                                    />
                                    <h6 className="mb-0 fw-bold">{tag.tag_name}</h6>
                                    <span className="badge bg-light text-secondary ms-2">{tag.type}</span>
                                </div>
                                <div className="tag-actions">
                                    <button
                                        className="btn btn-sm btn-light me-2"
                                        onClick={() => {
                                            // handleEditTag(tag._id)
                                        }}
                                    >
                                        <FaEdit className="text-primary" />
                                    </button>
                                    <button
                                        className="btn btn-sm btn-light"
                                        onClick={() => {
                                            if (window.confirm('Are you sure you want to delete this tag?')) {
                                                handleDeleteTag(tag._id);
                                            }
                                        }}
                                    >
                                        <FaTrash className="text-danger" />
                                    </button>
                                </div>
                            </div>

                            {/* Metrics Section - Inline Style */}
                            <div className="col-12">
                                <div className="d-flex flex-wrap justify-content-around p-3">
                                    <div className="text-center px-3">
                                        <div className="fs-4 fw-bold">{tag.flexlinkCount}</div>
                                        <div className="text-secondary small">Flexlinks</div>
                                    </div>

                                    <div className="text-center px-3">
                                        <div className="fs-4 fw-bold">{tag.flexpageCount}</div>
                                        <div className="text-secondary small">Flexpages</div>
                                    </div>

                                    <div className="text-center px-3">
                                        <div className="fs-4 fw-bold">{tag.eventCount}</div>
                                        <div className="text-secondary small">Events</div>
                                    </div>

                                    <div className="text-center px-3">
                                        <div className="fs-4 fw-bold">{tag.smartlinksCount}</div>
                                        <div className="text-secondary small">Smartlinks</div>
                                    </div>

                                    <div className="text-center px-3">
                                        <div className="fs-4 fw-bold">{tag.socialPostCount}</div>
                                        <div className="text-secondary small">Social Posts</div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TagCards;