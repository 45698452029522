import React, { useEffect } from 'react';

const OAuthCallback = () => {
  useEffect(() => {
    // Get the code from URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    
    if (code) {
      // Send message to parent window
      window.opener.postMessage(
        { 
          type: 'GMAIL_AUTH_CALLBACK',
          code: code
        }, 
        window.location.origin
      );
    } else {
      console.error('No authorization code received');
    }
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p className="mt-3">Completing Gmail authentication...</p>
        <p className="text-muted">You can close this window after completion.</p>
      </div>
    </div>
  );
};

export default OAuthCallback;