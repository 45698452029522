import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import { crmActivitiesContext } from "../../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";

const API_URL = process.env.REACT_APP_API_URL;

const LogMeetingModal = ({
    show,
    onHide,
    onMeetingLogged,
    id,
    componentType
}) => {
    const {crmActivitiesDispatch} = useContext(crmActivitiesContext);
    const [meetingData, setMeetingData] = useState({
        outcome: "",
        attendees: [],
        notes: "",
        start_time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        end_time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        date: new Date().toISOString().split('T')[0]
    });

    const handleLogMeeting = async () => {
        if (!meetingData.outcome || !meetingData.notes || meetingData.attendees.length === 0) {
            PNotify.error({ title: "Error", text: "Please fill all required fields", delay: 3000 });
            return;
        }

        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.post(`${API_URL}/crm/log-meeting`, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                crm_type: componentType,
                owner_id: user._id,
                outcome: meetingData.outcome,
                attendees: meetingData.attendees,
                notes: meetingData.notes,
                start_time: meetingData.start_time,
                end_time: meetingData.end_time,
                date: meetingData.date,
                author: user.fname || "User",
            });

            if (response.data.success) {
                // Reset form
                setMeetingData({
                    outcome: "",
                    attendees: [],
                    notes: "",
                    start_time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    end_time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    date: new Date().toISOString().split('T')[0]
                });

                // Notify parent component and hide modal
                // onMeetingLogged(response.data.data);
                crmActivitiesDispatch({ type: "SET_TEMP_FETCH", payload: false });
                onHide();

                PNotify.success({ title: "Success", text: "Meeting logged successfully", delay: 2000 });
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to log meeting", delay: 3000 });
        }
    };

    const handleAttendeeChange = (value) => {
        setMeetingData(prev => ({
            ...prev,
            attendees: value.split(',').map(attendee => attendee.trim())
        }));
    };

    return (
        <Modal show={show} onHide={onHide} animation={true} className="Sidebar-Modal-end" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Log Meeting</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Outcome</Form.Label>
                        <Form.Select
                            value={meetingData.outcome}
                            onChange={(e) => setMeetingData({ ...meetingData, outcome: e.target.value })}
                        >
                            <option value="">Select outcome</option>
                            <option value="Successful">Successful</option>
                            <option value="Rescheduled">Rescheduled</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="No Show">No Show</option>
                            <option value="Follow-up Required">Follow-up Required</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Attendees (comma-separated)</Form.Label>
                        <Form.Control
                            type="text"
                            value={meetingData.attendees.join(', ')}
                            onChange={(e) => handleAttendeeChange(e.target.value)}
                            placeholder="John Doe, Jane Smith"
                        />
                    </Form.Group>

                    <div className="row mb-3">
                        <div className="col">
                            <Form.Group>
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={meetingData.date}
                                    onChange={(e) => setMeetingData({ ...meetingData, date: e.target.value })}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <Form.Group>
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    value={meetingData.start_time}
                                    onChange={(e) => setMeetingData({ ...meetingData, start_time: e.target.value })}
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group>
                                <Form.Label>End Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    value={meetingData.end_time}
                                    onChange={(e) => setMeetingData({ ...meetingData, end_time: e.target.value })}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={meetingData.notes}
                            onChange={(e) => setMeetingData({ ...meetingData, notes: e.target.value })}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={handleLogMeeting}>Save Meeting</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LogMeetingModal;