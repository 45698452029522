import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import LogMeetingModal from "./log-meeting/LogMeeting"; // Import the new component
import { crmActivitiesContext } from "../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";

const API_URL = process.env.REACT_APP_API_URL;

const MeetingContent = ({ componentType }) => {
    const {crmActivitiesData} = useContext(crmActivitiesContext);
    const [meetings, setMeetings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        fetchMeetings();
    }, [id, crmActivitiesData.tempFetch]);

    const fetchMeetings = async () => {
        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;
            const response = await axios.post(`${API_URL}/crm/get-activities`, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                owner_id,
                type: "MEETING",
                crm_type: componentType
            });
            if (response.data.success) {
                setMeetings(response.data.data);
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to fetch meetings", delay: 3000 });
        } finally {
            setLoading(false);
        }
    };

    const handleMeetingLogged = (newMeeting) => {
        setMeetings(prev => ([newMeeting, ...prev]));
    };

    if (loading) return <div className="text-center text-light">Loading meetings...</div>;

    return (
        <div className="p-4 rounded">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>Meetings</h5>
                <Button onClick={() => setShowModal(true)}>Log Meeting</Button>
            </div>

            {meetings.length > 0 ? (
                meetings.map((meeting) => (
                    <div key={meeting._id} className="bg-dark text-white p-3 mb-2 rounded">
                        <div className="d-flex justify-content-between">
                            <span className="text-info">Meeting logged by {meeting?.author}</span>
                            <small className="text-muted">
                                {new Date(meeting.date).toLocaleDateString()} {meeting.start_time} - {meeting.end_time}
                            </small>
                        </div>
                        <div className="mt-2">
                            <span className="badge bg-primary me-2">{meeting.outcome}</span>
                            <span className="badge bg-secondary">
                                {meeting.attendees.join(', ')}
                            </span>
                        </div>
                        <div className="mt-2">{meeting.notes}</div>
                    </div>
                ))
            ) : (
                <p className="text-center text-muted">No meetings logged yet.</p>
            )}

            {/* Using the new LogMeetingModal component */}
            <LogMeetingModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onMeetingLogged={handleMeetingLogged}
                id={id}
                componentType={componentType}
            />
        </div>
    );
};

export default MeetingContent;