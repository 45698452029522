import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { useHistory } from "react-router-dom";
import { X } from 'lucide-react';
import AddContactModal from "./add-contact/AddConact";



const API_URL = process.env.REACT_APP_API_URL;
const ContactPage = () => {
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [audienceTags, setAudienceTags] = useState([]);

    const history = useHistory();

    const formatContacts = (rawContacts) => {
        return rawContacts.map((contact, index) => ({
            id: contact._id,
            email: contact.email || 'N/A',
            name: `${contact.firstName} ${contact.lastName}`,
            phoneNumber: contact.phoneNumber || 'N/A',
            linkedinUrl: contact.linkedinUrl || 'N/A',
            currentRole: contact.currentRole || 'N/A',
            company: contact?.company?.name || 'N/A',
            contactStatus: contact.contactStatus || 'Pending',
            contactOwner: contact.contactOwner || 'N/A',
            tags: contact.tags.map(tag => tag.tag_name).join(', ') || 'N/A',
            description: contact.description || 'N/A'
        }));
    };

    const fetchContacts = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-contacts`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                const formattedContacts = formatContacts(response.data.data);
                setContacts(formattedContacts);
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);
            PNotify.error({
                title: 'Error',
                text: 'Failed to fetch contacts',
                delay: 3000
            });
        }
    };


    const fetchCompanies = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-companies`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setCompanies(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching companies:", error);
            // PNotify.error({
            //     title: 'Error',
            //     text: 'Failed to fetch companies',
            //     delay: 3000
            // });
        }
    };

    const fetchAudienceTags = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/tags/get-audience-tags`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setAudienceTags(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching audience tags:", error);
            // PNotify.error({
            //     title: 'Error',
            //     text: 'Failed to fetch audience tags',
            //     delay: 3000
            // });
        }
    };

    useEffect(() => {
        fetchContacts();
        fetchCompanies();
        fetchAudienceTags();

    }, []);

    const getContactsAfterAddition = async () => {
        fetchContacts();
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ row }) => (
                    <span
                        style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                        onClick={() => history.push(`/crm-contact/${row.original.id}`)}
                    >
                        {row.original.name}
                    </span>
                )
            },

            {
                Header: "Email",
                accessor: "email",
            },

            {
                Header: "Phone Number",
                accessor: "phoneNumber",
            },
            {
                Header: "LinkedIn",
                accessor: "linkedinUrl",
                Cell: ({ value }) => value !== 'N/A' ? (
                    <a href={value} target="_blank" rel="noopener noreferrer" className="text-primary">
                        View Profile
                    </a>
                ) : (
                    <span>N/A</span>
                )
            },
            {
                Header: "Current Role",
                accessor: "currentRole",
            },
            {
                Header: "Company",
                accessor: "company",
            },
            {
                Header: "Status",
                accessor: "contactStatus",
                Cell: ({ value }) => (
                    <span className={`badge ${value === 'active' ? 'bg-success' :
                        value === 'pending' ? 'bg-warning' :
                            value === 'inactive' ? 'bg-danger' :
                                'bg-secondary'
                        }`}>
                        {value}
                    </span>
                )
            },
            // {
            //     Header: "Owner",
            //     accessor: "contactOwner",
            // },
            {
                Header: "Tags",
                accessor: "tags",
                Cell: ({ value }) => (
                    <div className="d-flex flex-wrap gap-1">
                        {typeof value === 'string' ?
                            value.split(',').map((tag, index) => (
                                <span key={index} className="badge bg-info">
                                    {tag.trim()}
                                </span>
                            )) :
                            <span>N/A</span>
                        }
                    </div>
                )
            },
            {
                Header: "Description",
                accessor: "description",
                Cell: ({ value }) => (
                    <div className="text-truncate" style={{ maxWidth: '200px' }} title={value}>
                        {value}
                    </div>
                )
            },
            {
                Header: "Action",
                Cell: ({ row }) => (
                    <div className="d-flex gap-2">
                        {/* <button
                            className="btn btn-sm btn-primary"
                            onClick={() => handleEditContact(row.original)}
                        >
                            Edit
                        </button> */}
                        <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleDeleteContact(row.original.id)}
                        >
                            Delete
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const handleEditContact = (contact) => {
        // Implement edit functionality
        console.log('Edit contact:', contact);
    };

    const handleDeleteContact = async (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this contact?');
        if (!isConfirmed) return; // Stop execution if user cancels

        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;

            const res = await axios.post(`${API_URL}/crm/delete-contact`, {
                "contact_id": id,
                "owner_id": owner_id
            });

            if (res.data.success) {
                fetchContacts();
            }
        } catch (error) {
            console.error("Error deleting lead:", error);
            PNotify.error({
                title: 'Error',
                text: 'Failed to delete lead',
                delay: 3000
            });
        }
    };

    return (
        // <div className="main-container">
        //     <div className="main-page-body-content">
        //         <div className="main-page-body-content-body">
        //             <div className="main-page-body-content-body-container">
        //                 <div className="tab-content-body mobile-view-hide">
                            <DataTable columns={columns} data={contacts} companies={companies} getContactsAfterAddition={getContactsAfterAddition} audienceTags={audienceTags} />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};



const DataTable = ({ columns, data, companies, getContactsAfterAddition, audienceTags }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        usePagination
    );

    const [searchInput, setSearchInput] = useState("");

    const [showAddModal, setShowAddModal] = useState(false);
    // ... rest of your existing state ...

    const handleAddContact = (newContact) => {
        // Handle adding the new contact here
        console.log('New contact:', newContact);
        setShowAddModal(false);
    };

    const handleSearch = (value) => {
        setGlobalFilter(value || undefined);
        setSearchInput(value);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    return (
        <div className="data-table">
            <div className="table-controls">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchInput}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <button onClick={exportToExcel}>Export to Excel</button>
                <button
                    onClick={() => setShowAddModal(true)}
                >New Contact</button>
            </div>

            <table {...getTableProps()} className="table w-100">
                <thead>
                    {headerGroups.map((headerGroup, headerGroupIndex) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={`header-group-${headerGroupIndex}`}>
                            {headerGroup.headers.map((column, columnIndex) => (
                                <th {...column.getHeaderProps()} key={`header-${headerGroupIndex}-${columnIndex}`}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
                                {row.cells.map((cell, cellIndex) => (
                                    <td
                                        {...cell.getCellProps()}
                                        key={`cell-${rowIndex}-${cellIndex}`}
                                        className={cell.column.id === "status" ? "status-pending" : ""}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="pagination">
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageOptions.length}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={({ selected }) => {
                        if (selected > pageIndex) nextPage();
                        if (selected < pageIndex) previousPage();
                    }}
                    containerClassName={"pagination-buttons"}
                    activeClassName={"active"}
                    pageClassName={"pagination-page"}
                    pageLinkClassName={"pagination-link"}
                    previousClassName={"pagination-previous"}
                    nextClassName={"pagination-next"}
                    breakClassName={"pagination-break"}
                    key="pagination"
                />
            </div>

            <div className="navigation">
                <span>
                    Page {pageIndex + 1} of {pageOptions.length}
                </span>
            </div>
            <style>
                {`
                .table-controls input {
  width: 50%;
  padding: 5px;
  font-size: 14px;
}

.table-controls button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}
                .table-controls input {
  box-shadow: none;
  padding: 10px 15px;
  font-size: 14px;
  min-height: 45px;
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
}

.table-controls button {
  min-width: 131px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
  padding: 10px 15px;
  border-color: transparent;
  background-color: #2E37A4;
}
.table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

`}
            </style>


            <AddContactModal
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                onSubmit={handleAddContact}
                companies={companies}
                getContactsAfterAddition={getContactsAfterAddition}
                audienceTags={audienceTags}
            />

        </div>
    );
};


export default ContactPage;




