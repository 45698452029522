import React from "react";
import { SiInstagram } from "react-icons/si";
import { BsWhatsapp } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsTiktok } from "react-icons/bs";
import { BsSnapchat } from "react-icons/bs";
import { BsPaypal } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { BsPinterest } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";


import { FaYoutube, FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function BuilderIcon(props) {


    const socials = Object.values(props.data.items);
    console.log('MY PROPS icons=========', socials);
    return (
        <>
            <div className="social_item_list" id={props.unique_id}>
               {socials.map((item, index) => (
                <>
                {
                    item.type== "FACEBOOK" ?<a href={item.url} target="blank" > <BsFacebook /></a> :

                    item.type== "INSTAGRAM" ? <a href={item.url} target="blank"><SiInstagram /></a> :

                    item.type== "WHATSAPP" ? <a href={item.url} target="blank"><BsWhatsapp /></a> :

                    item.type== "TWITTER" ? <a href={item.url} target="blank"><FaXTwitter /></a> :

                    item.type== "LINKEDIN" ? <a href={item.url} target="blank"><BsLinkedin /></a> :

                    item.type== "YOUTUBE" ? <a href={item.url} target="blank"><FaYoutube /></a> :

                    item.type== "TIKTOK" ? <a href={item.url} target="blank"><BsTiktok /></a> :

                    item.type== "SNAPCHAT" ? <a href={item.url} target="blank"><BsSnapchat /></a> :

                    item.type== "PAYPAL" ? <a href={item.url} target="blank"><BsPaypal /></a> :

                    item.type== "TELEGRAM" ? <a href={item.url} target="blank"><BsTelegram /></a> :

                    item.type== "BLUESKY" ? <a href={item.url} target="blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="currentColor">
                                    <path d="M111.8 62.2C170.2 105.9 233 194.7 256 242.4c23-47.6 85.8-136.4 144.2-180.2c42.1-31.6 110.3-56 110.3 21.8c0 15.5-8.9 130.5-14.1 149.2C478.2 298 412 314.6 353.1 304.5c102.9 17.5 129.1 75.5 72.5 133.5c-107.4 110.2-154.3-27.6-166.3-62.9l0 0c-1.7-4.9-2.6-7.8-3.3-7.8s-1.6 3-3.3 7.8l0 0c-12 35.3-59 173.1-166.3 62.9c-56.5-58-30.4-116 72.5-133.5C100 314.6 33.8 298 15.7 233.1C10.4 214.4 1.5 99.4 1.5 83.9c0-77.8 68.2-53.4 110.3-21.8z"/>
                                    </svg></a> :

                    item.type== "PINTEREST" ? <a href={item.url} target="blank"><BsPinterest /></a> :""

               }
               </>
               ))}
            </div>
        </>
    );
}

export default BuilderIcon;