import React from 'react'
import { useState, useEffect, useRef } from "react";
import { MdLocationOn } from "react-icons/md";
import { InputGroup, Form } from 'react-bootstrap';
import GoogleMaps from "../EventBuilder/EventsTab/GoogleMap2";

const VenueComponent = ({ handleVenueName, handleVenueAddress }) => {
    const locationInputRef = useRef(null);

    const [eventProperties, setEventProperties] = useState({
        eventType: 'Public',
        location: '',
        Zipcode: "",
        hostName: '',
        City: "",
        country: "",
        lat: "",
        lng: "",
        venue_name: ""
    });

    useEffect(() => {
        // Check if Google Maps Places API is loaded
        if (window.google && window.google.maps && window.google.maps.places && locationInputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(
                locationInputRef.current,
                { types: ['geocode', 'establishment'] }
            );

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();

                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested
                    alert("No details available for this location");
                    return;
                }

                // Extract location details
                const locationDetails = {
                    location: place.formatted_address || place.name,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    City: '',
                    country: '',
                    Zipcode: ''
                };

                // Extract additional address components
                if (place.address_components) {
                    place.address_components.forEach((component) => {
                        const componentType = component.types[0];

                        switch (componentType) {
                            case "locality":
                                locationDetails.City = component.long_name;
                                break;
                            case "country":
                                locationDetails.country = component.long_name;
                                break;
                            case "postal_code":
                                locationDetails.Zipcode = component.long_name;
                                break;
                        }
                    });
                }

                // Update event properties with location details
                setEventProperties(prevState => ({
                    ...prevState,
                    ...locationDetails
                }));

                handleVenueAddress(locationDetails);
            });

            return () => {
                // Clean up listener
                window.google.maps.event.clearInstanceListeners(autocomplete);
            };
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEventProperties(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'venue_name') {
            handleVenueName(value);
        }
    };

    return (
        <div className="form_block_group">
            <Form.Label>Venue Name</Form.Label>
            <InputGroup className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Venue Name"
                    aria-label="Venue Name"
                    aria-describedby="basic-addon1"
                    name="venue_name"
                    value={eventProperties.venue_name}
                    onChange={handleInputChange}
                />
            </InputGroup>

            <Form.Label>Venue Address</Form.Label>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><MdLocationOn /></InputGroup.Text>
                <Form.Control
                    ref={locationInputRef}
                    type="text"
                    placeholder="Location"
                    aria-label="Location"
                    aria-describedby="basic-addon1"
                    name="location"
                    value={eventProperties.location}
                    onChange={handleInputChange}
                />
            </InputGroup>
            {
                eventProperties.lat &&
                eventProperties.lng &&
                typeof eventProperties.lat === 'number' &&
                typeof eventProperties.lng === 'number' &&
                (
                    <div className="mt-3" style={{ width: '100%', height: '250px' }}>
                        <GoogleMaps
                            center={{
                                lat: parseFloat(eventProperties.lat),
                                lng: parseFloat(eventProperties.lng)
                            }}
                            zoom={15}
                            markers={[{
                                lat: parseFloat(eventProperties.lat),
                                lng: parseFloat(eventProperties.lng),
                                title: eventProperties.location
                            }]}
                        />
                        <div className="mt-2 text-muted">
                            {eventProperties.location}
                            {eventProperties.City && `, ${eventProperties.City}`}
                            {eventProperties.country && `, ${eventProperties.country}`}
                        </div>
                    </div>
                )}
        </div>
    )
}

export default VenueComponent