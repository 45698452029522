import React, { useState, useRef, useEffect } from "react";
import { BsFillGridFill, BsStars } from "react-icons/bs";
import { FaChevronRight, FaLongArrowAltLeft, FaTrash } from "react-icons/fa";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { CiGlobe } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import TimezoneSelect from "react-timezone-select";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { MdLocationOn } from "react-icons/md";
import { useBuilderContext } from "../../builderContext";
import { uploadSingleImageApi } from "../../BackendApi/Api/flowpageApi";
import "./EventInfo.css";
import { API_URL } from "../../BackendApi/Api/config";
import GoogleMaps from "./GoogleMap2";
import { getMembersByEventId } from "../../BackendApi/Api/Event";
import { FaX } from "react-icons/fa6";
import axios from "axios";
import { Modal } from "react-bootstrap";
import AddVenue from "../../Components/Venue/AddVenue";

export function EventGridList1() {
  return (
    <>
      <div className="add_block_list_item">
        <button className="add-block-button-wrapper">
          <div className="d-flex">
            <div className="add-block-btn-icon">
              <BsFillGridFill />
            </div>
            <div className="add-block-btn-text">
              <h2 className="add-block-btn-text-title">Event</h2>
              <p className="add-block-btn-text-desc">
                Add a Event Grid to your post
              </p>
            </div>
          </div>
          <div className="right_arrow">
            <FaChevronRight />
          </div>
        </button>
      </div>
    </>
  );
}

export function EventInfo() {
  const locationInputRef = useRef(null);

  const { addComponent, editComponent, myComponents } = useBuilderContext();
  const { id } = useParams();

  const [venuesList, setVenuesList] = useState([]);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [updateData, setUpdateData] = useState(false);
  const [eventProperties, setEventProperties] = useState({
    selectedTimezone: "",
    eventType: "Public",
    description: "",
    hostedImage: "",
    eventTitle: "",
    startDate: "",
    hostName: "",
    location: "",
    Zipcode: "",
    endDate: "",
    email: [],
    City: "",
    locationType: "",
    country: "",
    lat: "",
    lng: "",
    selectedVenueId: "", // Add this new field
  });

  const [showAddVenueModal, setShowAddVenueModal] = useState(false);

  const handleShowAddVenueModal = () => {
    setShowAddVenueModal(true);
  };

  const handleHideAddVenueModal = () => {
    setShowAddVenueModal(false);
  };

  const fetchVenues = async () => {
    try {
      const response = await axios.post(
        `${API_URL}event/get-venues-with-event-id`,
        { event_id: id }
      );
      if (response.data.success) {
        setVenuesList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching venues:", error);
    }
  };

  useEffect(() => {
    fetchVenues();
  }, [id]);

  useEffect(() => {
    if (isInitialRender) {
      const myComponent = myComponents.filter((item) => item.type === "EVENT");
      let newMyComponent = myComponent[0]?.properties;

      if (newMyComponent) {
        setEventProperties({
          selectedTimezone: newMyComponent?.selectedTimezone || "",
          eventType: newMyComponent?.eventType || "Public",
          description: newMyComponent?.description || "",
          hostedImage: newMyComponent?.hostedImage || "",
          eventTitle: newMyComponent?.eventTitle || "",
          startDate: newMyComponent?.startDate || "",
          location: newMyComponent?.location || "",
          hostName: newMyComponent?.hostName || "",
          endDate: newMyComponent?.endDate || "",
          email: newMyComponent?.email || [],
          City: newMyComponent?.City || "",
          locationType: newMyComponent?.locationType || "",
          country: newMyComponent?.country || "",
          lat: newMyComponent?.lat || "",
          lng: newMyComponent?.lng || "",
          selectedVenueId: newMyComponent?.selectedVenueId || "",
        });

        setIsInitialRender(false);
      }
    }
  }, [myComponents, isInitialRender]);

  const detectLocationType = (location) => {
    // Regular expression to detect virtual meeting links
    const virtualRegex =
      /\b(zoom|meet\.google|webex|teams\.microsoft|skype|goto(meeting)?)\b/i;

    // List of known virtual meeting domain keywords
    const virtualDomains = [
      "zoom.us",
      "meet.google.com",
      "webex.com",
      "teams.microsoft.com",
      "skype.com",
      "gotomeeting.com",
    ];

    // Check if location contains virtual meeting keywords or links
    const isVirtual =
      virtualRegex.test(location) ||
      virtualDomains.some((domain) => location.includes(domain));

    if (isVirtual) {
      // Generate random virtual meeting details
      return {
        locationType: "Virtual",
        lat: "",
        lng: "",
        City: "",
        country: "",
        location: location,
      };
    }
  };

  //   useEffect(() => {
  //     // Check if Google Maps Places API is loaded
  //     if (
  //       window.google &&
  //       window.google.maps &&
  //       window.google.maps.places &&
  //       locationInputRef.current
  //     ) {
  //       const autocomplete = new window.google.maps.places.Autocomplete(
  //         locationInputRef.current,
  //         { types: ["geocode", "establishment"] }
  //       );

  //       autocomplete.addListener("place_changed", () => {
  //         const place = autocomplete.getPlace();

  //         if (!place.geometry) {
  //           // User entered the name of a Place that was not suggested
  //           alert("No details available for this location");
  //           return;
  //         }

  //         // Extract location details
  //         const locationDetails = {
  //           location: place.formatted_address || place.name,
  //           locationType: "Physical",
  //           lat: place.geometry.location.lat(),
  //           lng: place.geometry.location.lng(),
  //           City: "",
  //           country: "",
  //           Zipcode: "",
  //         };

  //         // Extract additional address components
  //         if (place.address_components) {
  //           place.address_components.forEach((component) => {
  //             const componentType = component.types[0];

  //             switch (componentType) {
  //               case "locality":
  //                 locationDetails.City = component.long_name;
  //                 break;
  //               case "country":
  //                 locationDetails.country = component.long_name;
  //                 break;
  //               case "postal_code":
  //                 locationDetails.Zipcode = component.long_name;
  //                 break;
  //             }
  //           });
  //         }

  //         // Update event properties with location details
  //         setEventProperties((prevState) => ({
  //           ...prevState,
  //           ...locationDetails,
  //         }));
  //       });

  //       return () => {
  //         // Clean up listener
  //         window.google.maps.event.clearInstanceListeners(autocomplete);
  //       };
  //     }
  //   }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If it's not a location input, proceed with normal input change
    if (name !== "location") {
      setEventProperties((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      return;
    }

    // For location input, keep existing detection logic
    const locationDetails = detectLocationType(value);

    setEventProperties((prevState) => ({
      ...prevState,
      [name]: value,
      ...locationDetails,
    }));
  };

  const handleSelection = () => {
    setEventProperties((prevProperties) => ({
      ...prevProperties,
      eventType: prevProperties.eventType === "Public" ? "Private" : "Public",
    }));
  };

  const handleUpdateEvent = () => {
    editComponent(1, "EVENT", eventProperties, true);
  };

  const [brandsMembers, setBrandsMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const handleMemberSelect = (member) => {
    setSelectedMembers((prevSelectedMembers) => {
      // Add the member to the selected members if not already selected
      const updatedMembers = prevSelectedMembers.some(
        (selected) => selected.user_id === member.user_id
      )
        ? prevSelectedMembers
        : [...prevSelectedMembers, member];

      // Update eventProperties with the updated list of selected members
      setEventProperties((prevProperties) => ({
        ...prevProperties,
        hostedImage: updatedMembers.map((member) => member.profile_image), // Array of profile images
        hostName: updatedMembers.map((member) => member.user_name), // Array of user names
        email: updatedMembers.map((member) => member.email), // Array of emails
      }));

      if (updatedMembers.length > 0) {
        setUpdateData(true);
      }
      return updatedMembers; // Return the updated selected members
    });
  };

  const handleRemoveMember = (userId) => {
    setSelectedMembers((prevSelectedMembers) =>
      prevSelectedMembers.filter((member) => member.user_id !== userId)
    );

    // Reset dropdown if the removed member is currently selected
    if (selectedMemberId === userId) {
      setSelectedMemberId(""); // Reset selected member ID
    }
  };

  const handleDropdownChange = (e) => {
    const memberId = e.target.value;
    setSelectedMemberId(memberId); // Update selected member ID
    const selectedMember = brandsMembers.find(
      (member) => member.user_id === memberId
    );
    handleMemberSelect(selectedMember);
  };

  const handleRemove = (userId) => {
    handleRemoveMember(userId); // Remove member from the list
    if (selectedMemberId === userId) {
      setSelectedMemberId(""); // Reset the dropdown if the removed member was selected
    }
  };

  const getBrandsMembers = async (event_id) => {
    try {
      const result = await getMembersByEventId({ event_id });

      if (result.data.status) {
        let validMembers = result.data.data.filter(
          (member) =>
            member.user_id !== null &&
            member.user_name !== null &&
            member.email !== null &&
            member.profile_image !== null
        );
        let hostnames = eventProperties.hostName;
        const matchingMembers = validMembers.filter((member) => {
          const isMatch = Array.isArray(hostnames)
            ? hostnames.includes(member.user_name)
            : member.user_name === hostnames;

          return isMatch;
        });
        setBrandsMembers(validMembers);

        setSelectedMembers(matchingMembers);
        setSelectedMemberId(matchingMembers.map((member) => member.user_id));
      } else {
        console.log("Error fetching brands members:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching brands members:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getBrandsMembers(id);
    }
  }, [id, eventProperties.hostName]);

  console.log("brandsMembers", brandsMembers);

  const {
    eventType,
    eventTitle,
    startDate,
    endDate,
    selectedTimezone,
    location,
    description,
    hostName,
    email,
    hostedImage,
    Zipcode,
    City,
  } = eventProperties;

  return (
    <>
      <Modal
        show={showAddVenueModal}
        onHide={handleHideAddVenueModal}
        size="lg"
        backdrop="static"
        keyboard={false}
        //    className="Sidebar-Modal-end"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Venue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddVenue
            handleClose={handleHideAddVenueModal}
            eventId={id}
            componentType="Event"
            onVenueAdded={() => fetchVenues()}
          />
        </Modal.Body>
      </Modal>

      {/* <AddVenue
        show={showAddVenueModal}
        handleClose={handleHideAddVenueModal}
      /> */}
      <div className="eventinfoblock add_block">
        <div className="styles__Header-sc-82jpo5-0 cDIdp">
          <div>
            {/* <Link to="/">
                        <button type="button" aria-label="Go back to layout view">
                            <FaLongArrowAltLeft />
                        </button>
                    </Link> */}
            <label
              htmlFor="grayDarker"
              className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM"
            >
              Update Event Grid
            </label>
          </div>
        </div>

        <div className="icons_list_item cvtPmc">
          <div className="event-top d-flex align-items-center justify-content-end">
            <div className="event-type">
              <button
                type="button"
                aria-label="Toggle event type"
                className="event-type-btn"
                onClick={handleSelection}
              >
                {eventType === "Public" ? <CiGlobe /> : <BsStars />} {eventType}{" "}
                <IoIosArrowDown />
              </button>
            </div>
          </div>
          <div className="position-relative">
            <div className="icons_list_item_text">
              <div className="icons_list_item_text_title">Even Title</div>
            </div>
            <div className="form_block">
              <input
                type="text"
                className="form-control"
                name="eventTitle"
                value={eventTitle}
                onChange={handleInputChange}
                placeholder="Event title"
              />
            </div>
          </div>
          <div className="position-relative">
            <div className="icons_list_item_text">
              <div className="icons_list_item_text_title">
                Start Date & Time
              </div>
            </div>
            <div className="form_block">
              <input
                type="datetime-local"
                className="form-control"
                name="startDate"
                value={startDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="position-relative">
            <div className="icons_list_item_text">
              <div className="icons_list_item_text_title">End Date & Time</div>
            </div>
            <div className="form_block">
              <input
                type="datetime-local"
                className="form-control"
                name="endDate"
                value={endDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="position-relative">
            <div className="icons_list_item_text">
              <div className="icons_list_item_text_title">Time Zone</div>
            </div>
            <div className="select-wrapper form_block_group">
              <TimezoneSelect
                value={selectedTimezone}
                onChange={(timezone) =>
                  setEventProperties((prevProperties) => ({
                    ...prevProperties,
                    selectedTimezone: timezone.value,
                  }))
                }
              />
            </div>
          </div>

          <div className="position-relative">
            {/* <div className="icons_list_item_text">
              <div className="icons_list_item_text_title">Location</div>
            </div> */}

            {/* <div>
            <select
                className="form-control"
                value={selectedMemberId} // Bind dropdown to state
                onChange={handleDropdownChange}
              >
                <option value="" disabled>
                  Select a team member
                </option>
                {venuesList.map((member) => (
                  <option key={member.user_id} value={member.user_id}>
                    {member.user_name ? member.user_name : member.email}
                  </option>
                ))}
              </select>
            </div> */}
            {/* <div className="form_block_group">
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><MdLocationOn /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Location"
                                aria-label="Location"
                                aria-describedby="basic-addon1"
                                name="location"
                                value={location}
                                onChange={handleInputChange}
                            />
                        </InputGroup>
                    </div> */}

            {/* <div className="form_block_group">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <MdLocationOn />
                </InputGroup.Text>
                <Form.Control
                  ref={locationInputRef}
                  type="text"
                  placeholder="Location"
                  aria-label="Location"
                  aria-describedby="basic-addon1"
                  name="location"
                  value={eventProperties.location}
                  onChange={handleInputChange}
                />
              </InputGroup>
              {eventProperties.locationType === "Physical" &&
                eventProperties.lat &&
                eventProperties.lng &&
                typeof eventProperties.lat === "number" &&
                typeof eventProperties.lng === "number" && (
                  <div
                    className="mt-3"
                    style={{ width: "100%", height: "250px" }}
                  >
                    <GoogleMaps
                      center={{
                        lat: parseFloat(eventProperties.lat),
                        lng: parseFloat(eventProperties.lng),
                      }}
                      zoom={15}
                      markers={[
                        {
                          lat: parseFloat(eventProperties.lat),
                          lng: parseFloat(eventProperties.lng),
                          title: eventProperties.location,
                        },
                      ]}
                    />
                    <div className="mt-2 text-muted">
                      {eventProperties.location}
                      {eventProperties.City && `, ${eventProperties.City}`}
                      {eventProperties.country &&
                        `, ${eventProperties.country}`}
                    </div>
                  </div>
                )}
            </div> */}
          </div>

          <div className="position-relative">
            <div className="icons_list_item_text">
              <div className="icons_list_item_text_title">Location</div>
            </div>

            {/* <div className="form_block mb-3">
              <select
                className="form-control"
                value={eventProperties.locationType || ""}
                onChange={(e) =>
                  setEventProperties((prev) => ({
                    ...prev,
                    locationType: e.target.value,
                    // Reset location-related fields when switching types
                    location: "",
                    lat: "",
                    lng: "",
                    City: "",
                    country: "",
                    Zipcode: "",
                  }))
                }
              >
                <option value="" disabled>
                  Select location type
                </option>
                <option value="Virtual">Virtual</option>
                <option value="Physical">Physical</option>
              </select>
            </div> */}

            <div className="form_block mb-3">
              <div className="d-flex gap-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="locationType"
                    id="locationTypeVirtual"
                    value="Virtual"
                    checked={eventProperties.locationType === "Virtual"}
                    onChange={(e) =>
                      setEventProperties((prev) => ({
                        ...prev,
                        locationType: e.target.value,
                        // Reset location-related fields when switching types
                        location: "",
                        lat: "",
                        lng: "",
                        City: "",
                        country: "",
                        Zipcode: "",
                        selectedVenueId: "",
                      }))
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="locationTypeVirtual"
                  >
                    Virtual
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="locationType"
                    id="locationTypePhysical"
                    value="Physical"
                    checked={eventProperties.locationType === "Physical"}
                    onChange={(e) =>
                      setEventProperties((prev) => ({
                        ...prev,
                        locationType: e.target.value,
                        // Reset location-related fields when switching types
                        location: "",
                        lat: "",
                        lng: "",
                        City: "",
                        country: "",
                        Zipcode: "",
                        selectedVenueId: "",
                      }))
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="locationTypePhysical"
                  >
                    Physical
                  </label>
                </div>
              </div>
            </div>

            {eventProperties.locationType === "Virtual" && (
              <div className="form_block_group">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <MdLocationOn />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter virtual meeting link"
                    aria-label="Virtual meeting link"
                    aria-describedby="basic-addon1"
                    name="location"
                    value={eventProperties.location || ""}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </div>
            )}

            {eventProperties.locationType === "Physical" && (
              <>
                <div className="form_block_group">
                  <select
                    className="form-control mb-3"
                    value={eventProperties.selectedVenueId || ""}
                    onChange={(e) => {
                      const selectedVenue = venuesList.find(
                        (venue) => venue._id === e.target.value
                      );
                      if (selectedVenue) {
                        setEventProperties((prev) => ({
                          ...prev,
                          selectedVenueId: selectedVenue._id,
                          location: selectedVenue.event_venue_address,
                          City: selectedVenue.event_venue_city,
                          country: selectedVenue.event_venue_country,
                          Zipcode: selectedVenue.event_venue_zip,
                          lat:
                            selectedVenue.event_venue_location
                              ?.coordinates[1] || "",
                          lng:
                            selectedVenue.event_venue_location
                              ?.coordinates[0] || "",
                        }));
                      }
                    }}
                  >
                    <option value="" disabled>
                      Select a venue
                    </option>
                    {venuesList.map((venue) => (
                      <option key={venue._id} value={venue._id}>
                        {venue.event_venue_name}
                      </option>
                    ))}
                  </select>

                  {eventProperties.location && (
                    <div className="selected-venue-details p-2 border rounded mb-3 bg-light">
                      <p className="mb-1">
                        <strong>Address:</strong> {eventProperties.location}
                      </p>
                      {eventProperties.City && (
                        <p className="mb-1">
                          <strong>City:</strong> {eventProperties.City}
                        </p>
                      )}
                      {eventProperties.country && (
                        <p className="mb-1">
                          <strong>Country:</strong> {eventProperties.country}
                        </p>
                      )}
                      {eventProperties.Zipcode && (
                        <p className="mb-0">
                          <strong>Zip:</strong> {eventProperties.Zipcode}
                        </p>
                      )}
                    </div>
                  )}

                  {eventProperties.lat && eventProperties.lng && (
                    <div
                      className="mt-3"
                      style={{ width: "100%", height: "250px" }}
                    >
                      <GoogleMaps
                        center={{
                          lat: parseFloat(eventProperties.lat),
                          lng: parseFloat(eventProperties.lng),
                        }}
                        zoom={15}
                        markers={[
                          {
                            lat: parseFloat(eventProperties.lat),
                            lng: parseFloat(eventProperties.lng),
                            title: eventProperties.location,
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn bg-green-dark text-white "
                    onClick={handleShowAddVenueModal}
                  >
                    Add Venue
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="position-relative mt-3">
            <div className="icons_list_item_text">
              <div className="icons_list_item_text_title">Event Host Names</div>
            </div>
          </div>

          <div className="member-dropdown mt-3">
            {brandsMembers.length === 0 || brandsMembers === null ? (
              // Message if there are no members
              <p className="text-danger">Please add a member</p>
            ) : (
              // Dropdown if members exist
              <select
                className="form-control"
                value={selectedMemberId} // Bind dropdown to state
                onChange={handleDropdownChange}
              >
                <option value="" disabled>
                  Select a team member
                </option>
                {brandsMembers.map((member) => (
                  <option key={member.user_id} value={member.user_id}>
                    {member.user_name ? member.user_name : member.email}
                  </option>
                ))}
              </select>
            )}
          </div>

          {/* Display Selected Members */}
          <div className="selected-members mt-3">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              {selectedMembers.map((member) => (
                <div
                  key={member.user_id}
                  style={{
                    background: "#76d9a2cf",
                    borderRadius: "5px",
                    padding: "8px 13px",
                    color: "white",
                  }}
                >
                  <span>{member.user_name}</span>
                  <FaX
                    onClick={() => handleRemove(member.user_id)}
                    size={15}
                    style={{
                      border: "1px solid #33af6acf",
                      borderRadius: "50%",
                      color: "#33af6acf",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="position-relative mt-3">
            <div className="icons_list_item_text">
              <div className="icons_list_item_text_title">Add description</div>
            </div>
            <div className="form_block mt-2">
              <textarea
                className="form-control"
                placeholder="Add description"
                rows="5"
                name="description"
                value={description}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>

          <div className="create-btn-wrapper row">
            <button
              className="create-b bg-green-dark col-12"
              onClick={handleUpdateEvent}
            >
              Update Event
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
