// import React, { useState } from "react";
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
// import "bootstrap/dist/css/bootstrap.min.css";

// // Sidebar Component (Draggable Items) - Opens from the left
// const Sidebar = ({ isOpen, toggleSidebar, items, onDragEnd }) => {
//   return (
//     <div
//       className="position-fixed top-0 start-0 h-100 bg-light border-end p-3"
//       style={{
//         width: "250px",
//         transform: isOpen ? "translateX(0)" : "translateX(-100%)",
//         transition: "transform 0.3s ease-in-out",
//         boxShadow: "2px 0px 5px rgba(0,0,0,0.2)",
//       }}
//     >
//       <button className="btn btn-danger mb-3" onClick={toggleSidebar}>
//         Close
//       </button>
//       <h4 className="mb-3">Sidebar</h4>
//       <Droppable droppableId="sidebar" isDropDisabled>
//         {(provided) => (
//           <div ref={provided.innerRef} {...provided.droppableProps}>
//             {items.map((item, index) => (
//               <Draggable key={item} draggableId={item} index={index}>
//                 {(provided, snapshot) => (
//                   <div
//                     ref={provided.innerRef}
//                     {...provided.draggableProps}
//                     {...provided.dragHandleProps}
//                     className={`p-2 mb-2 text-white text-center bg-primary rounded ${
//                       snapshot.isDragging ? "opacity-50" : ""
//                     }`}
//                     style={{ cursor: "grab" }}
//                   >
//                     {item}
//                   </div>
//                 )}
//               </Draggable>
//             ))}
//             {provided.placeholder}
//           </div>
//         )}
//       </Droppable>
//     </div>
//   );
// };

// // List Component (Drop Target) - Displayed in a row
// const List = ({ title, listId, items, setItems }) => {
//   return (
//     <Droppable droppableId={listId}>
//       {(provided) => (
//         <div
//           ref={provided.innerRef}
//           {...provided.droppableProps}
//           className="p-3 border rounded bg-light w-100 my-2"
//           style={{ minHeight: "80px" }}
//         >
//           <h5>{title}</h5>
//           {items.length === 0 && <div className="text-muted text-center">Drop items here</div>}
//           {items.map((item, index) => (
//             <Draggable key={item} draggableId={item} index={index}>
//               {(provided, snapshot) => (
//                 <div
//                   ref={provided.innerRef}
//                   {...provided.draggableProps}
//                   {...provided.dragHandleProps}
//                   className={`p-2 bg-secondary text-white rounded my-1 ${
//                     snapshot.isDragging ? "opacity-50" : ""
//                   }`}
//                   style={{ cursor: "grab" }}
//                 >
//                   {item}
//                 </div>
//               )}
//             </Draggable>
//           ))}
//           {provided.placeholder}
//         </div>
//       )}
//     </Droppable>
//   );
// };

// // Main App Component
// function App() {
//   const [isSidebarOpen, setSidebarOpen] = useState(true);
//   const [sidebarItems] = useState(["Item 1", "Item 2", "Item 3"]);
//   const [listData, setListData] = useState({ row1: [], row2: [], row3: [] });

//   const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const { source, destination, draggableId } = result;
//     if (source.droppableId === destination.droppableId) return;

//     setListData((prev) => {
//       const newSourceList = prev[source.droppableId]?.filter((item) => item !== draggableId) || [];
//       const newDestinationList = [...(prev[destination.droppableId] || []), draggableId];

//       return {
//         ...prev,
//         [source.droppableId]: newSourceList,
//         [destination.droppableId]: newDestinationList,
//       };
//     });
//   };

//   return (
//     <DragDropContext onDragEnd={onDragEnd}>
//       <div className="container-fluid mt-4">
//         {!isSidebarOpen && (
//           <button
//             className="btn btn-primary position-fixed"
//             onClick={toggleSidebar}
//             style={{ top: "10px", left: "10px", zIndex: 1000 }}
//           >
//             Open Sidebar
//           </button>
//         )}

//         <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} items={sidebarItems} />

//         <div className="d-flex flex-column align-items-center ms-5">
//           <List title="Row 1" listId="row1" items={listData.row1} setItems={setListData} />
//           <List title="Row 2" listId="row2" items={listData.row2} setItems={setListData} />
//           <List title="Row 3" listId="row3" items={listData.row3} setItems={setListData} />
//         </div>
//       </div>
//     </DragDropContext>
//   );
// }

// export default App;

import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemTypes = { COMPONENT: "component", CARD: "card", CARD_GROUP: "card_group" };

const SidebarItem = ({ component, type }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: type || ItemTypes.COMPONENT,
    item: { component, type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        padding: "8px",
        margin: "4px",
        backgroundColor: "lightgray",
        opacity: isDragging ? 0.5 : 1,
        cursor: "grab",
      }}
    >
      {component}
    </div>
  );
};

const DraggableComponent = ({ component, index, components, setComponents }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COMPONENT,
    item: { component, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        padding: "8px",
        margin: "4px",
        backgroundColor: "lightblue",
        opacity: isDragging ? 0.5 : 1,
        cursor: "grab",
      }}
    >
      {component}
    </div>
  );
};

const DroppableSection = ({ components, setComponents }) => {
  const [, drop] = useDrop(() => ({
    accept: [ItemTypes.COMPONENT, ItemTypes.CARD, ItemTypes.CARD_GROUP],
    drop: (item) => addComponent(item),
  }));

  const addComponent = (item) => {
    if (item.type === ItemTypes.CARD_GROUP) {
      setComponents((prev) => [...prev, { type: ItemTypes.CARD_GROUP, cards: ["Card 1"] }]);
    } else {
      setComponents((prev) => [...prev, item.component]);
    }
  };

  return (
    <div
      ref={drop}
      style={{
        minHeight: "100px",
        padding: "10px",
        border: "2px dashed black",
        margin: "10px",
        backgroundColor: "white",
      }}
    >
      {components.map((component, index) => (
        component.type === ItemTypes.CARD_GROUP ? (
          <CardGroup key={index} group={component} components={components} setComponents={setComponents} index={index} />
        ) : (
          <DraggableComponent
            key={index}
            index={index}
            component={component}
            components={components}
            setComponents={setComponents}
          />
        )
      ))}
    </div>
  );
};

const CardGroup = ({ group, components, setComponents, index }) => {
  const addCard = () => {
    if (group.cards.length < 3) {
      const updatedComponents = [...components];
      updatedComponents[index].cards.push(`Card ${group.cards.length + 1}`);
      setComponents(updatedComponents);
    }
  };

  return (
    <div style={{ padding: "10px", margin: "10px", border: "1px solid black", backgroundColor: "lightgreen" }}>
      <h4>Card Group</h4>
      <div style={{ display: "flex", gap: "10px" }}>
        {group.cards.map((card, cardIndex) => (
          <div key={cardIndex} style={{ padding: "10px", border: "1px solid gray", backgroundColor: "lightblue" }}>{card}</div>
        ))}
      </div>
      {group.cards.length < 3 && <button onClick={addCard}>Add Card</button>}
    </div>
  );
};

const App = () => {
  const [sections, setSections] = useState([{ id: 1, components: [] }]);
  const addSection = () => {   setSections([...sections, { id: sections.length + 1, components: [] }]);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ display: "flex", gap: "20px" }}>
        <div style={{ width: "200px", padding: "10px", border: "1px solid black" }}>
          <h3>Sidebar</h3>
          {["Header", "Image", "Text", "Button"].map((comp) => (
            <SidebarItem key={comp} component={comp} />
          ))}
          <SidebarItem component="Card Group" type={ItemTypes.CARD_GROUP} />
          <button onClick={addSection} style={{ marginTop: "10px" }}>Add Section</button>
        </div>
        <div style={{ flex: 1 }}>
          {sections.map((section) => (
            <div key={section.id}>
              <h3>Section {section.id}</h3>
              <DroppableSection
                components={section.components}
                setComponents={(newComponents) => {
                  setSections((prev) =>
                    prev.map((s) =>
                      s.id === section.id ? { ...s, components: newComponents } : s
                    )
                  );
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </DndProvider>
  );
};

export default App;
