// MainRouter.js
import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

// Import components
import Dashboard from "../dashboard/Dashboard";
import Login from "../Components/Login/Login";
import SmartLinks from "../SmartLinks/SmartLinks";

import Setting from "../Setting/Setting";
import Metrics from "../Metrics/Metrics";
import Forgotpassword from "../Components/Login/Forgotpassword";
import CreateAccount from "../Components/Login/Createaccount";
import CustomBrand from "../CustomBrand/CustomBrand";
import Campaigns from "../Campaigns/Campaigns";
import ViewCampaign from "../Campaigns/ViewCampaign";
import Home from "../pages/Home/Home";
import Contact from "../pages/Contact/Contact";
import Portifolio from "../pages/PublicFlexpages/flexpages";
import ResetPassword from "../Components/Login/reset";
import Page404 from "../pages/Page404/Page404";
import FlexpageTemplate from "../FlexpageTemplate/PageBuilder";
import CreateBrand from "../CustomBrand/CreateBrand";
import SocialPage from "../SocialDesign/SocialPage";
import Createpost from "../SocialContent/Createpost";
import AcceptInvitation from "../pages/Login/AcceptInvitation";
import Verify from "../Components/Login/Verify";
import EditBrand from "../CustomBrand/EditBrand";
import Flowpages from "../Flowpages/Flowpages";
import PageBuilder from "../PageBuilder/PageBuilder";
import Bugs from "../Components/feedback/Bugs";
import Feedback from "../Components/feedback/feedback";
import SmarteditMain from "../Components/CreateSmartlink/SmartLinkeditMain";
import PrivateRouteNoSidebar from "./PrivateRouteNoSidebar";
import SocialPage2 from "../SocialDesign/SocialPage2";

import Canva from "../canva/canva";

import CanBan from "../SocialContent/postideas/KanbanBoard"
import CanvaOAuthPage from "../SocialContent/Twitter";
import AdminDashboard from "../Admin/Dashboard/AdminDashboard";
import Brands from "../Admin/Dashboard/Brands";
import Vanity from "../Admin/Dashboard/Vanity";
import Sampleshare from "../Components/share/sample"
import EventBuilder from "../EventBuilder/EventBuilder";
import EventPage from "../Events/EventPage";

import CohereChat from "../Components/AstralAi/CohereChatbot";

// import VoiceInputComponent from "../tempnestedroutes/VoiceInput";
// import GoogleSpeechTest from "../tempnestedroutes/googlespeech";

// import VoiceWidget from "../Components/SpeechRecognition/PicoVoice";

import VoiceToTextComponent from "../Components/SpeechRecognition/ReactSpeakup";
import AiPreamble from "../Admin/Dashboard/AiPreamble";
import BrandEvents from "../Events/brandEvents/BrandEvents";
import PublicSocialCalendr from "../SocialDesign/PublicCalender"
import CityEvents from "../Events/CityEvents/CityEvents";

import DiscoverEvents from "../Events/DiscoverEvents/DiscoverEvents";
import EventCategoriesPage from "../Admin/Dashboard/EventCategories";
import CategoricalEvents from "../Events/CategoricalEvents/CategoricalEvents";
import EventsNearbyMap from "../Events/EventsNearbyMap/EventsNearbyMap";

import MapView from "../tempnestedroutes/googlemaps";
import EventCities from "../Admin/Dashboard/EventCities";

import TheMasterSections from '../tempnestedroutes/BuilderComponents'

import LiveEdition from "../tempnestedroutes/LiveEditor"
import WebBuilder from "../views/web-builder/MainView"
import BuilderBlock from '../views/web-builder/MainBuilder'
import WebPagesList from "../views/web-builder/WebPagesList";
import MainView2 from "../views/web-builder/MainView2"

import DragAndDropComponent from "../tempnestedroutes/DragDrop"

import Crm from "../views/crm/Crm";
import CrmContacts from "../views/crm/contacts/Contacts";
import CrmContactDetails from "../views/crm/contacts/contact-details/ContactDetails";

import CrmCompanies from "../views/crm/companies/Companies";
import CrmCompanyDetails from "../views/crm/companies/company-details/CompanyDetails";

import CrmLeads from "../views/crm/leads/Leads";
import CrmLeadDetails from "../views/crm/leads/lead-details/LeadDetails";

import CrmDeals from "../views/crm/deals/Deals";
import CrmDealDetails from "../views/crm/deals/deal-details/DealDetails";
import OAuthCallback from "../views/crm/components/gmail-auth/OAuthCallback";
import GoogleLocationSearch from "../tempnestedroutes/GoogleLocationSearch";
import EventsVenue from "../Events/events-venue/EventsVenue";
import GuestResponse from "../EventBuilder/Components/GuestResponse/GuestResponse";



const MainRouter = () => {
  return (
    <Switch>
      {/* Public Routes */}
      <PublicRoute exact path="/" component={Home} />
      <PublicRoute exact path="/home" component={Home} />
      <PublicRoute exact path="/contact" component={Contact} />
      <PublicRoute exact path="/login" component={Login} restricted={true} />
      <PublicRoute exact path="/createaccount" component={CreateAccount} restricted={true} />
      <PublicRoute exact path="/forgotpassword" component={Forgotpassword} />
      <PublicRoute exact path="/reset" component={ResetPassword} />
      <PublicRoute exact path="/acceptinvitation/:id" component={AcceptInvitation} />
      <PublicRoute exact path="/verifyaccount" component={Verify} />
      <PublicRoute exact path="/canva" component={Canva} />
      {/* <PublicRoute exact path="/canban" component={CanBan} /> */}
      <PublicRoute exact path="/share" component={Sampleshare} />
      <PublicRoute exact path="/brand-events/:brandname" component={BrandEvents} />
      <PublicRoute exact path="/events-venue/:slug" component={EventsVenue} />
      <PublicRoute exact path="/events" component={DiscoverEvents} />

      <PublicRoute exact path="/city-events/:city_slug" component={CityEvents} />
      <PublicRoute exact path="/categorical-events/:category_slug" component={CategoricalEvents} />
      <PublicRoute exact path="/events-nearby" component={EventsNearbyMap} />

      {/* <PublicRoute exact path="/voiceinput" component={VoiceInputComponent} /> */}
      {/* <PublicRoute exact path="/googlespeech" component={GoogleSpeechTest} /> */}

      {/* <PublicRoute exact path="/picovoice" component={VoiceWidget} /> */}

      <PublicRoute exact path="/reactspeakup" component={VoiceToTextComponent} />

      <PublicRoute exact path="/coherechat" component={CohereChat} />

      <PublicRoute exact path="/social-calendar" component={PublicSocialCalendr} />

      <PublicRoute exact path="/tempmap" component={MapView} />

      <PublicRoute exact path="/thecomps" component={TheMasterSections} />

      <PublicRoute exact path="/live-editor" component={LiveEdition} />

      <PublicRoute exact path="/web-builder-public" component={BuilderBlock} />

      <PublicRoute exact path="/dragdrop" component={DragAndDropComponent} />

      <PublicRoute exact path="/gmail-auth/callback" component={OAuthCallback} />

      <PublicRoute exact path="/event-email-response" component={GuestResponse} />


      <PrivateRouteNoSidebar exact path="/webpage/:backhalf" component={BuilderBlock} accessallowedroles={["0", "2"]} />



      <PrivateRouteNoSidebar exact path="/createbrand" component={CreateBrand} />
      <PrivateRouteNoSidebar exact path="/google-location-search" component={GoogleLocationSearch} />





      {/* Private Routes */}
      <PrivateRoute exact path="/dashboard" component={Dashboard} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/flexlinks" component={SmartLinks} accessallowedroles={["0", "2"]} />

      <PrivateRoute exact path="/editflexlink/:id" component={SmarteditMain} accessallowedroles={["0", "2"]} />

      <PrivateRoute path="/setting" component={Setting} accessallowedroles={["0", "2"]} />
      <PrivateRoute path="/:type/metrics/:id" component={Metrics} accessallowedroles={["0", "2"]} />

      <PrivateRoute exact path="/custombrand" component={CustomBrand} accessallowedroles={["0"]} />
      <PrivateRoute exact path="/campaigns" component={Campaigns} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/viewcampaign/:id" component={ViewCampaign} accessallowedroles={["0", "2"]} />

      <PrivateRoute exact path="/flexpagetemplate" component={FlexpageTemplate} accessallowedroles={["0"]} />

      <PrivateRoute path="/social_design" component={SocialPage2} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/createpost" component={Createpost} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/brandedit/:id" component={EditBrand} accessallowedroles={["0"]} />
      <PrivateRoute exact path="/flexpage" component={Flowpages} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/events-creation" component={EventPage} accessallowedroles={["0", "2"]} />
      <PrivateRoute path="/editflexpage/:id" component={PageBuilder} accessallowedroles={["0", "2"]} />
      <PrivateRoute path="/editEvent/:id" component={EventBuilder} accessallowedroles={["0", "2"]} />

      <PrivateRoute exact path="/feature-request" component={Feedback} accessallowedroles={["0", "2"]} />

      <PrivateRoute exact path="/bugs-report" component={Bugs} accessallowedroles={["0", "2"]} />

      <PrivateRoute exact path="/admindashboard" component={AdminDashboard} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/brands" component={Brands} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/Vanity" component={Vanity} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/AiPreamble" component={AiPreamble} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/event-categories" component={EventCategoriesPage} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/event-cities" component={EventCities} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/web-builder" component={WebBuilder} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/WebPageList" component={WebPagesList} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/webpages/" component={WebPagesList} accessallowedroles={["0", "2"]} />

      <PrivateRoute path="/crm" component={Crm} accessallowedroles={["0", "2"]} />

      {/* <PrivateRoute exact path="/crm/contacts" component={CrmContacts} accessallowedroles={["0", "2"]} /> */}
      <PrivateRoute exact path="/crm-contact/:id" component={CrmContactDetails} accessallowedroles={["0", "2"]} />

      {/* <PrivateRoute exact path="/crm/companies" component={CrmCompanies} accessallowedroles={["0", "2"]} /> */}
      <PrivateRoute exact path="/crm-company/:id" component={CrmCompanyDetails} accessallowedroles={["0", "2"]} />

      {/* <PrivateRoute exact path="/crm/leads" component={CrmLeads} accessallowedroles={["0", "2"]} /> */}
      {/* <PrivateRoute exact path="/crm/lead/:id" component={CrmLeadDetails} accessallowedroles={["0", "2"]} /> */}

      {/* <PrivateRoute exact path="/crm/deals" component={CrmDeals} accessallowedroles={["0", "2"]} /> */}
      <PrivateRoute exact path="/crm-deal/:id" component={CrmDealDetails} accessallowedroles={["0", "2"]} />


      {/* Catch-all route for Portifolio or 404 */}
      <Route path="/:username?">
        {({ match }) => {
          if (match && match.params.username) {

            console.log("match.params.username", match.params.username);
            return <Portifolio />;
          }
          return <Page404 />;
        }}
      </Route>
    </Switch>
  );
};

export default MainRouter;