import React, { useEffect, useState, useContext } from "react";
import "./PageBuilder.css";
import "../Components/Flowinner/Flowedit.css";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { BiColorFill, BiLayout } from "react-icons/bi";
import { RiAddFill } from "react-icons/ri";
import { Tab, Nav } from "react-bootstrap";
import { FiCode } from "react-icons/fi";
import Tab3 from "./Components/BuilderTap/Tab3";
import { Route, useHistory } from 'react-router-dom';
import { Tab2 } from "./Components/BuilderTap/Tab2";
import Icon02 from "../assets/images/indent-decrease.svg";
import Icon01 from "../assets/images/indent-increase.svg";
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { TbWebhook, TbSocial, TbUser } from "react-icons/tb";
import { Form } from "react-bootstrap";
import { addWebhooks, getwebhooks } from "../BackendApi/Api/webhooks";
import { useParams } from "react-router-dom";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { updateSocialPreview, getsocialpreview } from "../BackendApi/Api/flowpageApi";
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import { API_URL } from "../BackendApi/Api/config";
import { SmartLinkContext } from "../Components/CreateSmartlink/SmartLinkcontext";
import { useBuilderContext } from "../builderContext";
// import Event from "./Components/EditBlockList/EditEvent";
import { EventGridAdd } from "./Components/AddBlockList/EventGridList";
import { EditEvent } from "./Components/EditBlockList/EditEvent";
import { MdQrCode } from "react-icons/md";
import Qrtemplates from "../Components/SmartLinkTabs/Qrtemplates";
import { AiFillInfoCircle, AiFillSetting, AiOutlineBlock } from "react-icons/ai";
import { EventInfo } from "../EventBuilder/EventsTab/EventInfo";
import GeneralBlocks from "../Events/EventsTab/GeneralBlocks";
import { HeaderAdd } from "./Components/AddBlockList/Header";
import { ImagesAdd } from "./Components/AddBlockList/images";
import { GalleryAdd } from "./Components/AddBlockList/Gallery";
import Settings from "./EventsTab/EventsSettings";
import Apperance from "./EventsTab/Apperance";
import SocialPreviewForm from "./EventsTab/socialPrew";
import AnalyticsForm from "./EventsTab/Tracking";
import WebhookForm from "./EventsTab/WebHooks";
import { AgentaAdd } from "./Components/AddBlockList/Agenta";
import { ParkingAdd } from "./Components/AddBlockList/Parking";
import ContactList from "./Components/contact-list/ContactList";
import HostDetails from "./EventsTab/HostDetails";
function EventBuilderRight({ changeColor, changeButtonColor, changeFontFamily, changeBackgroundColor2, changeBackgroundColor, changeColorPosition, changeBackgroundType }) {

    const { backgroundType, setBackgroundType, BackgroundColor, setBackgroundColor, backgroundColor2, setBackgroundColor2, colorPosition, setColorPosition, title, setTitle, setDescription, description } = useBuilderContext();

    const [smartlinkeditdata, setSmartlinkEditData,] = useContext(SmartLinkContext);
    const [addClass, setAddClass] = React.useState(false);
    const [webhook_url, setWebhook_url] = useState('');
    const [webhooksdata, setWebhooksData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [collapse, setCollapse] = useState(false);
    // const [backgroundType, setBackgroundType] = useState(1);
    // const [BackgroundColor, setBackgroundColor] = useState('#3713ed');
    // const [backgroundColor2, setBackgroundColor2] = useState('#f01a45');
    // const [colorPosition, setColorPosition] = useState('leftright');
    const [headerPosition, setHeaderPosition] = useState('left');

    const [favicon, setFavicon] = useState(null);
    const [openGraphImage, setOpenGraphImage] = useState(null);
    const [imageUrls, setImageUrls] = useState({ favicon: '', openGraphImage: '' });
    const [socialPreviewData, setSocialPreviewData] = useState({})
    const params = useParams();
    const classToggle = () => {
        document.querySelector('.tab_content').classList.toggle('xEfrZ');

        setAddClass((prev) => !prev);
    }

    const navigate = useHistory();


    useEffect(() => {
        if (params.id !== undefined) {
            getwebhookurl();
        }
    }, [params.id])

    const handleSelectFavicon = (e) => {
        setFavicon(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    favicon: reader.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle file selection for Open Graph Image
    const handleSelectOG = (e) => {
        setOpenGraphImage(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    openGraphImage: reader.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };


    const handleAddWebhooks = (e) => {
        e.preventDefault();
        setLoading(true);
        addWebhooks({ webhook_url: webhook_url, smartlink_id: params.id }).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                PNotify?.success({
                    title: 'Success',
                    text: res.data.message,
                });
                getwebhookurl();
            }
        }).catch((err) => {
            setLoading(false);
            PNotify?.error({
                title: 'Error',
                text: err,
            })
        }).finally(() => {
            setLoading(false);
        });
    }

    const getwebhookurl = () => {
        setLoading(true);
        getwebhooks({ smartlink_id: params.id }).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                setWebhooksData(res.data.data);
                setWebhook_url(res.data.data.webhook_url)
            }
        }).catch((err) => {
            setLoading(false);
            // PNotify?.error({
            //     title: 'Error',
            //     text: err,
            // })
        }).finally(() => {
            setLoading(false);
        });
    }

    let webhooks = webhooksdata?.webhook_url

    const addClassCheck = () => {
        setCollapse(true);
        setBackgroundType(2);
    }

    const removeClass = () => {
        setCollapse(false);
        setBackgroundType(1);
    }


    const handleValueChange = (e) => {
        const selectedValue = e.target.value;
        setColorPosition(selectedValue);
    };

    const handleUpdateSocialPreview = (e) => {
        e.preventDefault();
        setLoading(true);

        const formdata = new FormData();
        formdata.append("smartlinkId", params.id);
        formdata.append("description", description);
        formdata.append("title", title);
        formdata.append("favicon", favicon);
        formdata.append("og_image", openGraphImage);

        updateSocialPreview(formdata)
            .then((res) => {
                setLoading(false);

                if (res.code === 200) {
                    PNotify.success({
                        title: 'Success',
                        text: "Social Preview Updated Successfully",
                    });
                } else {
                    PNotify?.error({
                        title: 'Error',
                        text: "Something Went Wrong",
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                PNotify?.error({
                    title: 'Error',
                    text: err,
                });
            });
    };


    useEffect(() => {
        if (params.id !== undefined) {
            getsocialpreviewData(params.id);
        }
    }, [params.id])

    const getsocialpreviewData = (id) => {
        const data = {
            "smartlink_id": id
        }
        getsocialpreview(data).then((res) => {
            if (res.code === 200) {
                let previewData = res.data.data
                setSocialPreviewData(previewData);
                setTitle(previewData?.title);
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    openGraphImage: previewData?.favicon,
                }));
                setDescription(previewData?.description);
                setImageUrls({
                    openGraphImage: API_URL + 'ogimages/' + previewData?.og_image,
                    favicon: previewData?.favicon,
                });
            } else {
                PNotify?.error({
                    title: 'Error',
                    text: "Something went wrong",
                });
            }
        }).catch((err) => {
            PNotify?.error({
                title: 'Error',
                text: err,
            });
        })
    }

    return (
        <>

            <div className="mobile-hide">
                <div className="d-flex" style={{ height: "100vh" }}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                        <Nav variant="pills" className="flex-column">
                            <div class="RightSide_fixed_bar">
                                <ul class="RightSide_fixed_bar_list">
                                    <Nav.Item
                                        className="RightSide_fixed_bar_list_item"
                                        onClick={() => navigate.push(`/editEvent/${params.id}/appreance`)}

                                    >
                                        <Nav.Link className="RightSide_fixed_bar_list_item_link oALEn">
                                            <BiColorFill />
                                            Appearance
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/layout`)}>
                                        <Nav.Link eventKey="second" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <BiLayout />
                                            Layout
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/addblock`)}>
                                        <Nav.Link eventKey="third" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <RiAddFill />
                                            Add Block
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/eventinfo`)}>
                                        <Nav.Link eventKey="nineth" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <AiFillInfoCircle />
                                            Event Info
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className="RightSide_fixed_bar_list_item">
                                        <Nav.Link eventKey="tenth" className="RightSide_fixed_bar_list_item_link oALEn ">
                                            <AiOutlineBlock />
                                            General Block
                                        </Nav.Link>
                                    </Nav.Item> */}

                                    <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/qrtemplates/flex`)}>
                                        <Nav.Link eventKey="forth" className="RightSide_fixed_bar_list_item_link oALEn qr-template">
                                            <MdQrCode />
                                            QR Code
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/webhook`)}>
                                        <Nav.Link eventKey="fifth" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <TbWebhook />
                                            Webhook
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/tracking`)}>
                                        <Nav.Link eventKey="sixth" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <FiCode />
                                            Tracking
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/socialpreview`)}>
                                        <Nav.Link eventKey="seventh" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <TbSocial />
                                            Social Preview
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/settings`)}>
                                        <Nav.Link eventKey="eighth" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <AiFillSetting />
                                            Settings
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/contacts`)}>
                                        <Nav.Link eventKey="ninth" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <TbSocial />
                                            Guests
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className="RightSide_fixed_bar_list_item" onClick={() => navigate.push(`/editEvent/${params.id}/hostDetails`)}>
                                        <Nav.Link eventKey="tenth" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <TbUser />
                                            Host Details
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </ul>
                            </div>
                        </Nav>


                        <div className="cFOcQM tab_content">
                            <div className="d-flex align-items-center"><button className="Back-btn-icon" onClick={() => navigate.push("/events")}><IoArrowBackCircleOutline /> </button><div className="Back-btn-text"><h5>Back to Events Page</h5></div></div>
                            <div className="styles__TabContent themes_sec">
                                {/* Main Tabs routing */}
                                <Route path="/editEvent/:id/appreance" component={Apperance} exact />
                                <Route path="/editEvent/:id/settings" component={Settings} exact />
                                <Route path="/editEvent/:id/addblock" component={Tab3} exact />
                                <Route path="/editEvent/:id/qrtemplates/:pageType2" component={Qrtemplates} exact />
                                <Route path="/editEvent/:id/eventinfo" component={EventInfo} exact />
                                <Route path="/editEvent/:id/layout" component={Tab2} exact />
                                <Route path="/editEvent/:id/tracking" component={AnalyticsForm} exact />
                                <Route path="/editEvent/:id/socialpreview" component={SocialPreviewForm} exact />
                                <Route path="/editEvent/:id/webhook" component={WebhookForm} exact />
                                <Route path="/editEvent/:id/hostDetails" component={HostDetails} exact />

                                {/* //Tabs routing */}
                                <Route path="/editEvent/:id/Header" component={HeaderAdd} exact />
                                <Route path="/editEvent/:id/gallery" component={GalleryAdd} exact />
                                <Route path="/editEvent/:id/images" component={ImagesAdd} exact />
                                <Route path="/editEvent/:id/agenta" component={AgentaAdd} exact />
                                <Route path="/editEvent/:id/parking" component={ParkingAdd} exact />
                                <Route path="/editEvent/:id/eventgrid" component={EventGridAdd} exact />
                                <Route path="/editEvent/:id/contacts" component={ContactList} exact />

                            </div>
                        </div>


                        <div class="styles__CloseTab-sc-isevmn-1 jKiTgm">
                            <button type="button" onClick={classToggle}>
                                {addClass ? <img src={Icon01} alt="icn" /> : <img src={Icon02} alt="icn" />}
                            </button>
                        </div>
                    </Tab.Container>
                </div>
            </div>


            <div className="mobile-show">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="builder_mobile_navigation">
                        <div class="RightSide_fixed_bar">
                            <ul class="RightSide_fixed_bar_list">
                                <Nav.Item className="RightSide_fixed_bar_list_item">
                                    <Nav.Link eventKey="first" className="RightSide_fixed_bar_list_item_link oALEn">
                                        <BiColorFill />
                                        Appearance
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="RightSide_fixed_bar_list_item">
                                    <Nav.Link eventKey="second" className="RightSide_fixed_bar_list_item_link oALEn">
                                        <BiLayout />
                                        Layout
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="RightSide_fixed_bar_list_item">
                                    <Nav.Link eventKey="third" className="RightSide_fixed_bar_list_item_link oALEn">
                                        <RiAddFill />
                                        Add Block
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="RightSide_fixed_bar_list_item">
                                    <Nav.Link eventKey="forth" className="RightSide_fixed_bar_list_item_link oALEn qr-template">
                                        <RiAddFill />
                                        Qr Code
                                    </Nav.Link>
                                </Nav.Item>
                            </ul>
                        </div>
                    </Nav>

                    <Tab.Content className="cFOcQM tab_content">
                        <div className="d-flex align-items-center"><button className="Back-btn-icon" onClick={() => navigate.push('/events')}><IoArrowBackCircleOutline /> </button><div className="Back-btn-text"><h5>Back to Events Page</h5></div></div>
                        <Tab.Pane eventKey="first">
                            <div class="styles__TabContent themes_sec">
                                <div class="styles__TabContent__Header themes_sec_header">
                                    <h3>Themes</h3>
                                </div>
                                <div className="templates_list">
                                    <div className="theme-gallery">
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_white');
                                            }
                                        }  >
                                            <div className="ThumbnailContainer">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box"></div>
                                                <div className="video-box change_color"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_pink');
                                            }
                                        }>
                                            <div className="ThumbnailContainer dohZLT">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box JqLIB"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_green');
                                            }
                                        }>
                                            <div className="ThumbnailContainer htmJFC">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box eHEwqA"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('goWHMr');
                                            }
                                        }>
                                            <div className="ThumbnailContainer gqRGFY">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box kQamYq"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_beige');
                                            }
                                        }>
                                            <div className="ThumbnailContainer bnqqfO">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box eXJCbl"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_yellow');
                                            }
                                        }>
                                            <div className="ThumbnailContainer bWgPiP">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box fqVEao"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_black');
                                            }
                                        }>
                                            <div className="ThumbnailContainer gKQzFr">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box iCFlgu"></div>
                                                <div className="video-box change_color"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_blue');
                                            }
                                        }>
                                            <div className="ThumbnailContainer kQbHFv">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box eqpOAJ"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ZWEFTR"></div>
                                <div className="set_btn_colored">
                                    <div className="set_btn_colored_inner">
                                        <label for="foo" color="gray" class="style__Label-bufferapp-ui__sc-153wil8-5 dPYltt">Button Color</label>
                                        <div className="default-color-picker-list">
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#7367f6" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#7367f6');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#28c76f" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#28c76f');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ea5455" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#ea5455');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ff9f43" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#ff9f43');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#1e1e1e" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#1e1e1e');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#E6379a" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#E6379a');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#11cdef" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#11cdef');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#f73164" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#f73164');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#2dce89" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#2dce89');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#5f27cd" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#5f27cd');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#feca57" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#feca57');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ZWEFTR"></div>
                                <div className="font_family_content">
                                    <div class="section-inner-title">
                                        <h3>Font Packs</h3>
                                    </div>
                                    <div className="select-font">
                                        <div className="select-font-list">
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Roboto, sans-serif' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Roboto, sans-serif');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Roboto</p>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Playfair Display, serif' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Playfair Display, serif');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Playfair Display</p>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Oswald, sans-serif' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Oswald, sans-serif');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Oswald</p>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Libre Baskerville, serif' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Libre Baskerville, serif');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Libre Baskerville</p>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Lugrasimo, cursive' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Lugrasimo, cursive');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Lugrasimo</p>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="second">
                            <div className="styles__TabContent themes_sec">
                                <HashRouter>
                                    <Switch>
                                        <Route path="/" component={Tab2} exact></Route>
                                        <Route path="/header" component={EditHeader} exact></Route>
                                        <Route path="/editsocial" component={SocialLinkAdd} exact></Route>
                                        <Route path="/editsocial" component={EditSocialLink} exact></Route>
                                        <Route path="/editbutton" component={EditButtonLink} exact></Route>
                                        <Route path="/edityoutube" component={EditYoutube} exact></Route>
                                        <Route path="/editimage" component={EditImage} exact></Route>
                                        <Route path="/edittext" component={EditText} exact></Route>
                                        <Route path="/editimagegrid" component={ImageGridAdd} exact></Route>
                                        <Route path="/editsubheading" component={EditSubHeading} exact></Route>
                                        <Route path="/editspotify" component={EditSpotify} exact></Route>
                                        <Route path="/testimoials" component={TestimonialsAdd} exact></Route>

                                    </Switch>
                                </HashRouter>
                            </div>
                        </Tab.Pane> */}
                        {/* <Tab.Pane eventKey="third">
                            <div className="styles__TabContent themes_sec">
                                <HashRouter>
                                    <Switch>
                                        <Route path="/" component={Tab3} exact></Route>
                                    </Switch>
                                </HashRouter>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="forth">
                            <div className="styles__TabContent themes_sec" style={{ margin: '20px 0px' }}>
                                <Qrtemplates pageType="flex" />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="eighth">
                            <div className="styles__TabContent themes_sec" style={{ margin: '20px 0px' }}>
                                <Qrtemplates pageType="flex" />
                            </div>
                        </Tab.Pane> */}
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    );
}

export default EventBuilderRight;