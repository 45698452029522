import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import LogCallModal from "./log-call/LogCall";
import { crmActivitiesContext } from "../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";

const API_URL = process.env.REACT_APP_API_URL;

const CallContent = ({ componentType }) => {
    const { crmActivitiesData } = useContext(crmActivitiesContext);
    const [calls, setCalls] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const { id } = useParams();
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        fetchContacts();
        fetchCalls();
    }, [id, crmActivitiesData.tempFetch]);

    const fetchCalls = async () => {
        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;
            const response = await axios.post(`${API_URL}/crm/get-activities`, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                crm_type: componentType,
                owner_id,
                type: "CALL",
            });
            if (response.data.success) {
                setCalls(response.data.data);
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to fetch calls", delay: 3000 });
        } finally {
            setLoading(false);
        }
    };


    const fetchContacts = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-contacts`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setContacts(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);

        }
    };

    const handleCallLogged = (newCall) => {
        setCalls(prev => ([newCall, ...prev]));
    };

    if (loading) return <div className="text-center text-light">Loading calls...</div>;

    return (
        <div className="p-4 rounded">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>Calls</h5>
                <Button onClick={() => setShowModal(true)}>Log Call</Button>
            </div>

            {calls.length > 0 ? (
                calls.map((call) => (
                    <div key={call._id} className="bg-dark text-white p-3 mb-2 rounded">
                        <div className="d-flex justify-content-between">
                            <span className="text-info">Call logged by {call?.owner_id?.fname} {call?.owner_id?.lname}</span>
                            <small className="text-muted">
                                {new Date(call.call_date).toLocaleDateString()} at {call.call_time}
                            </small>
                        </div>
                        <div className="mt-2">
                            <span className="badge bg-primary me-2">{call.direction}</span>
                            <span className="badge bg-secondary">{call.outcome}</span>
                        </div>
                        <div className="mt-2">{call.call_notes}</div>
                        <div className="mt-2">
                            {call.contacted.map((contact) => (
                               //filter contacts by contact, contact is the id
                                contacts.filter((c) => c._id === contact).map((filteredContact) => (
                                    <span className="badge bg-success me-2" key={filteredContact._id}>
                                        {filteredContact.firstName} {filteredContact.lastName}
                                    </span>
                                ))
                            ))}
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-center text-muted">No calls logged yet.</p>
            )}

            <LogCallModal
                showModal={showModal}
                setShowModal={setShowModal}
                componentType={componentType}
                id={id}
                onCallLogged={handleCallLogged}
                contacts={contacts}
            />
        </div>
    );
};

export default CallContent;