import React from "react";
import { FaTrash, FaExternalLinkAlt } from "react-icons/fa";
import { BsFacebook, BsList } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsTiktok } from "react-icons/bs";
import { BsSnapchat } from "react-icons/bs";
import { BsPaypal } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { BsPinterest } from "react-icons/bs";


function ListItem({ item, index, handleUrlChange, deleteHandler }) {



    // FACEBOOK
    // INSTAGRAM
    // WHATSAPP
    // TWITTER

    // LINKEDIN
    // YOUTUBE
    // TIKTOK
    // SNAPCHAT
    // PAYPAL
    // TELEGRAM
    // PINTEREST
    const [Url, setUrl] = React.useState(item.url);



    const changeSocialUrl = (id, Url) => {
        handleUrlChange(id, Url);
    };

    const onChangeHabndler = (value) => {
        setUrl(value);
        changeSocialUrl(item.id, value);
    }




    return (
        <>
            <div className="icons_list_item cvtPmc">
                <div className="icons_list_item_icon">
                    <div className="icons_list_left">
                        <BsList />
                        {item.type == "FACEBOOK" ? <BsFacebook /> :
                            item.type == "INSTAGRAM" ? <BsInstagram /> :
                                item.type == "WHATSAPP" ? <BsWhatsapp /> :
                                    item.type == "TWITTER" ? <BsTwitterX /> :
                                        item.type == "LINKEDIN" ? <BsLinkedin /> :
                                            item.type == "YOUTUBE" ? <BsYoutube /> :
                                                item.type == "TIKTOK" ? <BsTiktok /> :
                                                    item.type == "SNAPCHAT" ? <BsSnapchat /> :
                                                        item.type == "PAYPAL" ? <BsPaypal /> :
                                                            item.type == "TELEGRAM" ? <BsTelegram /> :
                                                            item.type == "BLUESKY" ? 
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="currentColor">
                                                                <path d="M111.8 62.2C170.2 105.9 233 194.7 256 242.4c23-47.6 85.8-136.4 144.2-180.2c42.1-31.6 110.3-56 110.3 21.8c0 15.5-8.9 130.5-14.1 149.2C478.2 298 412 314.6 353.1 304.5c102.9 17.5 129.1 75.5 72.5 133.5c-107.4 110.2-154.3-27.6-166.3-62.9l0 0c-1.7-4.9-2.6-7.8-3.3-7.8s-1.6 3-3.3 7.8l0 0c-12 35.3-59 173.1-166.3 62.9c-56.5-58-30.4-116 72.5-133.5C100 314.6 33.8 298 15.7 233.1C10.4 214.4 1.5 99.4 1.5 83.9c0-77.8 68.2-53.4 110.3-21.8z"/>
                                                            </svg> :
                                                            item.type == "PINTEREST" ? <BsPinterest /> : ""
                        }
                    </div>
                    <div className="icons_list_right">
                        <button type="button" className="delete_btn" onClick={() => { deleteHandler(item.id) }}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">
                            {item.type == "FACEBOOK" ? "Facebook" :
                                item.type == "INSTAGRAM" ? "Instagram" :
                                    item.type == "WHATSAPP" ? "Whatsapp" :
                                        item.type == "TWITTER" ? "X (Formerly Twitter)" :
                                            item.type == "LINKEDIN" ? "Linkedin" :
                                                item.type == "YOUTUBE" ? "Youtube" :
                                                    item.type == "TIKTOK" ? "Tiktok" :
                                                        item.type == "SNAPCHAT" ? "Snapchat" :
                                                            item.type == "PAYPAL" ? "Paypal" :
                                                                item.type == "TELEGRAM" ? "Telegram" :
                                                                    item.type == "BLUESKY" ? "Bluesky" :
                                                                    item.type == "PINTEREST" ? "Pinterest" : ""
                            }
                        </div>
                    </div>
                    <div className="form_block">
                        <input type="text" className="form-control" placeholder={
                            item.type == "FACEBOOK" ? "https://www.facebook.com/" :
                                item.type == "INSTAGRAM" ? "https://www.instagram.com/" :
                                    item.type == "WHATSAPP" ? "https://www.whatsapp.com/" :
                                        item.type == "TWITTER" ? "https://www.twitter.com/" :
                                            item.type == "LINKEDIN" ? "https://www.linkedin.com/" :
                                                item.type == "YOUTUBE" ? "https://www.youtube.com/" :
                                                    item.type == "TIKTOK" ? "https://www.tiktok.com/" :
                                                        item.type == "SNAPCHAT" ? "https://www.snapchat.com/" :
                                                            item.type == "PAYPAL" ? "https://www.paypal.com/" :
                                                                item.type == "TELEGRAM" ? "https://www.telegram.com/" :
                                                                    item.type == "PINTEREST" ? "https://www.pinterest.com/" : ""
                        }
                            onChange={(e) => {
                                onChangeHabndler(e.target.value)
                            }}
                            value={Url}
                        />
                    </div>
                    <div className="link_target">
                        <button type="button" className="btn btn-sm" >
                            <FaExternalLinkAlt />
                        </button>
                    </div>
                </div>
            </div>


        </>
    );
}

export default ListItem;