import React, { useState, useContext } from "react";
import { Button, Modal, Form, Badge } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import { crmActivitiesContext } from "../../../../../../contexts/context/crmActivitiesContext";

const API_URL = process.env.REACT_APP_API_URL;

const SendEmailModal = ({ show, onHide, id, componentType, gmailConnected }) => {
    const { crmActivitiesDispatch } = useContext(crmActivitiesContext);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [recipients, setRecipients] = useState([]);
    const [emailInput, setEmailInput] = useState("");
    const [subject, setSubject] = useState("");

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const addRecipient = (e) => {
        // Prevent the default form submission behavior
        if (e) e.preventDefault();
        
        if (isValidEmail(emailInput) && !recipients.includes(emailInput)) {
            setRecipients([...recipients, emailInput]);
            setEmailInput("");
        } else if (emailInput.trim() !== "") {
            PNotify.error({
                title: "Invalid Email",
                text: "Please enter a valid email address.",
                delay: 2000
            });
        }
    };

    const removeRecipient = (email) => {
        setRecipients(recipients.filter((rec) => rec !== email));
    };

    const resetForm = () => {
        setEditorState(EditorState.createEmpty());
        setRecipients([]);
        setEmailInput("");
        setSubject("");
    };

    const handleSendEmail = async () => {
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const emailBody = draftToHtml(rawContent);
        if (recipients.length === 0 || !subject.trim() || !emailBody.trim()) return;

        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const endpoint = `${API_URL}/crm/send-email`;

            const response = await axios.post(endpoint, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                crm_type: componentType,
                owner_id: user._id,
                recipient: recipients,
                subject,
                body: emailBody,
                sender: user.name || "User",
            });

            if (response.data.success) {
                resetForm();
                crmActivitiesDispatch({ type: "SET_TEMP_FETCH", payload: false });
                onHide();
                PNotify.success({ title: "Success", text: "Email sent successfully", delay: 2000 });
            }
        } catch (error) {
            PNotify.error({
                title: "Error",
                text: error.response?.data?.message || "Failed to send email",
                delay: 3000
            });
        }
    };

    return (
        <Modal show={show} onHide={onHide} animation={true} className="Sidebar-Modal-end" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Send Email
                    {gmailConnected && <Badge bg="success" className="ms-2">via Gmail</Badge>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>To</Form.Label>
                        <Form.Control
                            type="email"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            onKeyPress={(e) => e.key === "Enter" && addRecipient(e)}
                            placeholder="Type email and press Enter to add"
                        />
                        <div className="mt-2">
                            {recipients.map((email, index) => (
                                <Badge key={index} bg="info" className="me-2 p-2">
                                    {email} <span style={{ cursor: "pointer" }} onClick={() => removeRecipient(email)}>✖</span>
                                </Badge>
                            ))}
                        </div>
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                            type="text"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label>Message</Form.Label>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="wysiwyg-wrapper"
                            editorClassName="wysiwyg-editor"
                            onEditorStateChange={setEditorState}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button
                    variant="primary"
                    onClick={handleSendEmail}
                    disabled={recipients.length === 0 || !subject.trim() || !editorState.getCurrentContent().hasText()}
                >
                    Send Email
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SendEmailModal;