import React, { useState, useEffect } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { useHistory } from "react-router-dom";
import { X } from 'lucide-react';
import axios from "axios";
import AddDealModal from "./add-deal/AddDeal";
import { FaTable, FaColumns } from 'react-icons/fa'; // Import icons for toggle

const API_URL = process.env.REACT_APP_API_URL;

const DealsPage = () => {
    const [deals, setDeals] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [audienceTags, setAudienceTags] = useState([]);

    
    const history = useHistory();

    const formatDeals = (rawDeals) => {
        return rawDeals.map((deal) => ({
            id: deal._id,
            title: deal.title || 'N/A',
            companies: deal.companies || [],
            contacts: deal.contacts || [],
            amount: deal.amount || 0,
            expectedCloseDate: deal.expectedCloseDate || 'N/A',
            dealType: deal.dealType || 'New',
            dealPriority: deal.dealPriority || 'Medium',
            dealOwner: deal?.dealOwner?.fname + " " + deal?.dealOwner?.lname || 'N/A',
            pipeline: deal.pipeline || 'Sales Pipeline',
            dealStage: deal.dealStage || 'Qualification',
            tags: deal.tags || [],
            description: deal.description || 'N/A'
        }));
    };

    const fetchDeals = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-deals`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                const formattedDeals = formatDeals(response.data.data);
                setDeals(formattedDeals);
            }
        } catch (error) {
            console.error("Error fetching deals:", error);
            PNotify.error({
                title: 'Error',
                text: 'Failed to fetch deals',
                delay: 3000
            });
        }
    };

    const fetchCompanies = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-companies`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setCompanies(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching companies:", error);
        
        }
    };

    const fetchContacts = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-contacts`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setContacts(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);
          
        }
    };
    const fetchAudienceTags = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/tags/get-audience-tags`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setAudienceTags(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching audience tags:", error);
            // PNotify.error({
            //     title: 'Error',
            //     text: 'Failed to fetch audience tags',
            //     delay: 3000
            // });
        }
    };

    useEffect(() => {
        fetchDeals();
        fetchCompanies();
        fetchContacts();
        fetchAudienceTags();
    }, []);

    const getDealsAfterAddition = async () => {
        fetchDeals();
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "Title",
                accessor: "title",
                Cell: ({ row }) => (
                    <span
                        style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                        onClick={() => history.push(`/crm-deal/${row.original.id}`)}
                    >
                        {row.original.title}
                    </span>
                )
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: ({ value }) => (
                    <div>${value.toLocaleString()}</div>
                )
            },
            {
                Header: "Expected Close",
                accessor: "expectedCloseDate",
            },
            {
                Header: "Stage",
                accessor: "dealStage",
                Cell: ({ value }) => (
                    <span className="badge bg-info">
                        {value}
                    </span>
                )
            },
            {
                Header: "Owner",
                accessor: "dealOwner",
            },
            {
                Header: "Actions",
                Cell: ({ row }) => (
                    <div className="d-flex gap-2">
                        {/* <button
                            className="btn btn-sm btn-primary"
                            onClick={() => handleEditDeal(row.original)}
                        >
                            Edit
                        </button> */}
                        <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleDeleteDeal(row.original.id)}
                        >
                            Delete
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const handleEditDeal = (deal) => {
        console.log('Edit deal:', deal);
    };

    const handleDeleteDeal = async (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this deal?');
        if (!isConfirmed) return; // Stop execution if user cancels

        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;

            const res = await axios.post(`${API_URL}/crm/delete-deal`, {
                "deal_id": id,
                "owner_id": owner_id
            });

            if (res.data.success) {
                fetchDeals();
            }
        } catch (error) {
            console.error("Error deleting lead:", error);
            PNotify.error({
                title: 'Error',
                text: 'Failed to delete lead',
                delay: 3000
            });
        }
    };

    return (
        // <div className="main-container">
        //     <div className="main-page-body-content">
        //         <div className="main-page-body-content-body">
        //             <div className="main-page-body-content-body-container">
        //                 <div className="tab-content-body mobile-view-hide">
                            <DataTable columns={columns} data={deals} companies={companies} contacts={contacts} audienceTags={audienceTags} getDealsAfterAddition={getDealsAfterAddition}/>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

const DataTable = ({ columns, data, companies, contacts, audienceTags, getDealsAfterAddition }) => {
    const history = useHistory();
    const [showAddModal, setShowAddModal] = useState(false);
    const [searchInput, setSearchInput] = useState("");


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        usePagination
    );

    const handleSearch = (value) => {
        setGlobalFilter(value || undefined);
        setSearchInput(value);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Deals");
        XLSX.writeFile(workbook, "deals.xlsx");
    };

    return (
        <div className="data-table">
            <div className="view-toggle">
                <button
                    className={`view-toggle-btn active`}
                    onClick={() => history.push('/crm/deals/kanban')}
                    title="Kanban View"
                >
                    <FaColumns />
                </button>
                <button
                    className={`view-toggle-btn`}
                    onClick={() => history.push('/crm/deals/table')}
                    title="Table View"
                >
                    <FaTable />
                </button>
            </div>
            <div className="table-controls">
                <input
                    type="text"
                    placeholder="Search deals..."
                    value={searchInput}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <button onClick={exportToExcel}>Export to Excel</button>
                <button onClick={() => setShowAddModal(true)}>New Deal</button>
            </div>

            <table {...getTableProps()} className="table">
                <thead>
                    {headerGroups.map((headerGroup, headerGroupIndex) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={`header-group-${headerGroupIndex}`}>
                            {headerGroup.headers.map((column, columnIndex) => (
                                <th {...column.getHeaderProps()} key={`header-${headerGroupIndex}-${columnIndex}`}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
                                {row.cells.map((cell, cellIndex) => (
                                    <td {...cell.getCellProps()} key={`cell-${rowIndex}-${cellIndex}`}>
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="pagination">
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageOptions.length}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={({ selected }) => {
                        if (selected > pageIndex) nextPage();
                        if (selected < pageIndex) previousPage();
                    }}
                    containerClassName={"pagination-buttons"}
                    activeClassName={"active"}
                    pageClassName={"pagination-page"}
                    pageLinkClassName={"pagination-link"}
                    previousClassName={"pagination-previous"}
                    nextClassName={"pagination-next"}
                    breakClassName={"pagination-break"}
                />
            </div>

            <AddDealModal
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                companies={companies}
                contacts={contacts}
                audienceTags={audienceTags}
                getDealsAfterAddition={getDealsAfterAddition}
            />
        </div>
    );
};



export default DealsPage;