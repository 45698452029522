import React from 'react';
import { FaGlobeAmericas, FaPhoneAlt, FaEnvelope, FaDatabase } from 'react-icons/fa';
import { RiPagesLine } from 'react-icons/ri';

const ResolveDestinationTitle = (smartlinkdata) => {
    if (!smartlinkdata) return null;

    const destinationTypes = {
        'url': {
            icon: <FaGlobeAmericas />,
            label: 'Dynamic URL',
            value: smartlinkdata.long_url || smartlinkdata.destinationData
        },
        'flexpage': {
            icon: <RiPagesLine />,
            label: 'Flexpage',
            value: smartlinkdata?.destinationFlexpage?.full_short_url
        },
        'phone': {
            icon: <FaPhoneAlt />,
            label: 'Phone',
            value: smartlinkdata.destinationPhone
        },
        'email': {
            icon: <FaEnvelope />,
            label: 'Email',
            value: smartlinkdata.destinationEmail
        },
        'data': {
            icon: <FaDatabase />,
            label: 'Data',
            value: smartlinkdata.destinationData
        }
    };

    const destinationType = smartlinkdata.destinationType?.toLowerCase();
    const destination = destinationTypes[destinationType] || destinationTypes['url'];

    // Truncate long values
    const truncateValue = (value, maxLength = 50) =>
        value && value.length > maxLength
            ? `${value.substring(0, maxLength)}...`
            : value;

    return (
        <>
            {/* <div
                className="destination-container d-flex align-items-center"
            >
                <div className="destination-icon me-2">
                    {destination.icon}
                </div>
                <div className="destination-details">
                    <div className="destination-type">{destination.label}</div>
                    <div className="destination-value text-muted">
                        {truncateValue(destination.value)}
                    </div>
                </div>
            </div> */}

            {destination.icon}{destination.label}
            
        </>

    );
};

export default ResolveDestinationTitle;