import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { IoIosArrowBack } from "react-icons/io";
import { RiMore2Fill } from "react-icons/ri";
import { FaBolt } from "react-icons/fa";
import { Tab, Nav } from 'react-bootstrap';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { RiEditLine, RiDeleteBin6Line, RiAddLine, RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { Modal, Button } from "react-bootstrap";

import CrmActivities from '../../components/crm-activities/CrmActivities';

import AddNote from '../../components/crm-activities/notes/add-note/AddNote';
import SendEmailModal from '../../components/crm-activities/emails/send-email/SendEmail';
import AssociationsPanel from '../../components/association-panel/AssociationPanel'
import Select from 'react-select';
import { Badge } from 'react-bootstrap';
import { X } from 'lucide-react';





const API_URL = process.env.REACT_APP_API_URL;

const ContactDetails = () => {
  const history = useHistory();
  const { id } = useParams();
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [associations, setAssociations] = useState({
    companies: [],
    deals: [],
    lists: []
  });
  const [comments, setComments] = useState([]);

  const [gmailConnected, setGmailConnected] = useState(false);

  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const [triggerActivity, setTriggerActivity] = useState(false);

  useEffect(() => {
    fetchContactDetails();
    checkGmailAuthStatus();
    fetchCompanies();
  }, [id]);


  const checkGmailAuthStatus = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await axios.post(`${API_URL}/gmail/check-auth-status`, {
        user_id: user._id
      });
      setGmailConnected(response.data.isConnected);
    } catch (error) {
      console.error("Failed to check Gmail auth status:", error);
    } finally {

    }
  };

  const fetchContactDetails = async () => {


    try {

      const owner_id = JSON.parse(localStorage.getItem("user"))._id;

      const response = await axios.post(`${API_URL}/crm/contact-details`, {
        contact_id: id,
        owner_id
      });

      if (response.data.success) {
        setContactData(response.data.data);
        // Assuming the API returns associations and comments
        setAssociations(response.data.associations || {
          companies: [],
          deals: [],
          lists: []
        });
        setComments(response.data.comments || []);
      }
    } catch (error) {
      PNotify.error({
        title: 'Error',
        text: 'Failed to fetch contact details',
        delay: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanies = async () => {
    const owner_id = JSON.parse(localStorage.getItem("user"))._id;

    try {
      const response = await axios.post(`${API_URL}/crm/get-companies`, {
        "owner_id": owner_id,
      });

      if (response.data.success) {
        setCompanies(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
      // PNotify.error({
      //     title: 'Error',
      //     text: 'Failed to fetch companies',
      //     delay: 3000
      // });
    }
  };

  const handleNoteAdded = () => {
    fetchContactDetails();
  };



  const LeftPanel = () => (
    <div className="col-4 bg-light p-4" >
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div onClick={() => history.push('/crm/contacts')} className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
          <IoIosArrowBack />
          <span className="ms-2">Back</span>
        </div>
        {/* <div className="dropdown">
          <RiMore2Fill style={{ cursor: 'pointer' }} />
        </div> */}
      </div>

      {/* Profile Card */}
      <div className="mb-4" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
        <div className="d-flex gap-3">
          <div className="bg-primary rounded-circle d-flex justify-content-center align-items-center text-white"
            style={{ width: '48px', height: '48px' }}>
            <span className="fs-4">AV</span>
          </div>
          <div>
            <h5 className="mb-1">{contactData?.firstName} {contactData?.lastName}</h5>
            <div className="text-muted small">{contactData?.currentRole}</div>
            <div className="text-muted small">{contactData?.email}</div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="d-flex flex-wrap gap-2 mt-3">
          {/* <button className="btn btn-light border">
            <span className="d-flex align-items-center">
              Add to Sequence <span className="ms-1">▼</span>
            </span>
          </button> */}
          <button className="btn btn-light border" onClick={() => setShowEmailModal(true)}>Email</button>
          <button className="btn btn-light border" onClick={() => setShowNoteModal(true)}>Note</button>
          {/* <button className="btn btn-light border">Call</button> */}
          {/* <button className="btn btn-light border">More</button> */}
        </div>
      </div>

      {/* Run Agents Section */}
      {/* <div className="mb-4" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
        <h6 className="text-muted mb-3">Run Agents</h6>
        <div className="d-flex flex-wrap gap-2">
          <button className="btn btn-light border">
            <FaBolt className="text-primary me-2" />
            Market Research
          </button>
          <button className="btn btn-light border">
            <FaBolt className="text-primary me-2" />
            Enrich Email
          </button>
          <button className="btn btn-light border">
            <FaBolt className="text-primary me-2" />
            Enrich Phone
          </button>
        </div>
      </div> */}

      <EditableContactDetails
        companies={companies}
        contactData={contactData}
        setContactData={fetchContactDetails}
      />

      <div className="mb-4" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
        <h6 className="text-muted mb-3">Last Updated</h6>
        <p>{new Date(contactData.updatedAt).toLocaleString()}</p>
        <h6 className="text-muted mb-3">Created</h6>
        <p>{new Date(contactData.createdAt).toLocaleString()}</p>
      </div>

    </div>
  )

  if (loading) {
    return <div className="text-center p-5">Loading...</div>;
  }

  return (
    <>
      <AddNote
        show={showNoteModal}
        onHide={() => setShowNoteModal(false)}
        componentType="CONTACT"
        id={id}
      />
      <SendEmailModal
        show={showEmailModal}
        onHide={() => setShowEmailModal(false)}
        id={id}
        componentType="CONTACT"
        gmailConnected={gmailConnected}
      />
      <div className="main-container">
        <div className="container-fluid p-0">
          <div className="row g-0">
            {/* Left Panel */}
            <LeftPanel />

            {/* Center Panel - Activity Tabs */}
            <div className="col-5 p-4">

              <CrmActivities componentType="CONTACT" />

            </div>

            {/* Right Panel - Associations */}
            <div className="col-3">
              <AssociationsPanel componentType="CONTACT" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};






const EditableContactDetails = ({ companies, contactData, setContactData }) => {
  const [editing, setEditing] = useState({
    field: null,
    value: null,
    arrayIndex: null
  });
  const [newArrayItem, setNewArrayItem] = useState('');
  const [hoveredItem, setHoveredItem] = useState({ field: null, index: null });
  const [audienceTags, setAudienceTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isEditingTags, setIsEditingTags] = useState(false);

  const companyOptions = companies.map(company => ({
    value: company._id,
    label: company.name
  }));

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "pending", label: "Pending" }
  ];

  useEffect(() => {
    fetchAudienceTags();
    // Initialize selected tags if contact already has tags
    if (contactData?.tags && Array.isArray(contactData.tags)) {
      // Handle potential nested array
      const tagArray = Array.isArray(contactData.tags[0]) ? contactData.tags[0] : contactData.tags;
      const initialTags = tagArray.map(tag => {
        if (typeof tag === 'object' && tag._id) {
          return { value: tag._id, label: tag.tag_name };
        } else {
          return { value: tag, label: tag };
        }
      });
      setSelectedTags(initialTags);
    }
  }, [contactData]);


  const fetchAudienceTags = async () => {
    const owner_id = JSON.parse(localStorage.getItem("user"))._id;

    try {
      const response = await axios.post(`${API_URL}/tags/get-audience-tags`, {
        "owner_id": owner_id,
      });

      if (response.data.success) {
        setAudienceTags(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching audience tags:", error);
    }
  };

  const handleUpdate = async (field, value, arrayIndex = null) => {
    try {
      const owner_id = JSON.parse(localStorage.getItem("user"))._id;
      const updateData = {
        contact_id: contactData._id,
        owner_id,
        field,
        value
      };

      if (arrayIndex !== null) {
        updateData.arrayIndex = arrayIndex;
      }

      const response = await axios.post(`${API_URL}/crm/update-contact-fields`, updateData);

      if (response.data.success) {
        setContactData(response.data.data);
        PNotify.success({
          title: 'Success',
          text: 'Contact updated successfully',
          delay: 2000
        });
      }
    } catch (error) {
      PNotify.error({
        title: 'Error',
        text: 'Failed to update contact',
        delay: 2000
      });
    }
    setEditing({ field: null, value: null, arrayIndex: null });
    setNewArrayItem('');
  };

  const handleTagChange = (selected) => {
    setSelectedTags(selected || []);
  };

  const handleTagsUpdate = async () => {
    // Extract tag IDs for the update
    const tagValues = selectedTags.map(tag => tag.value);
    await handleUpdate('tags', tagValues);
    setIsEditingTags(false);
  };

  const handleArrayAdd = async (field) => {
    if (!newArrayItem) return;

    const currentArray = contactData[field] || [];
    const updatedArray = [...currentArray, newArrayItem];
    await handleUpdate(field, updatedArray);
  };

  const handleArrayDelete = async (field, index) => {
    const updatedArray = contactData[field].filter((_, i) => i !== index);
    await handleUpdate(field, updatedArray);
  };

  const renderArrayField = (field, label, items = []) => (
    <div className="mb-4">
      <div
        className="d-flex justify-content-between align-items-center mb-2"
        onMouseEnter={() => setHoveredItem({ field, index: 'header' })}
        onMouseLeave={() => setHoveredItem({ field: null, index: null })}
      >
        <h6 className="text-muted mb-0">{label}</h6>
        {(hoveredItem.field === field && hoveredItem.index === 'header') && (
          <button
            className="btn btn-sm btn-outline-primary opacity-100"
            onClick={() => setEditing({ field, value: '', arrayIndex: 'new' })}
          >
            <RiAddLine /> Add
          </button>
        )}
      </div>

      {editing.field === field && editing.arrayIndex === 'new' && (
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control"
            value={newArrayItem}
            onChange={(e) => setNewArrayItem(e.target.value)}
            placeholder={`Add new ${label.toLowerCase()}`}
          />
          <button
            className="btn btn-success"
            onClick={() => handleArrayAdd(field)}
          >
            <RiCheckLine />
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setEditing({ field: null, value: null, arrayIndex: null })}
          >
            <RiCloseLine />
          </button>
        </div>
      )}

      {items.map((item, index) => (
        <div
          key={index}
          className="d-flex align-items-center gap-2 mb-2"
          onMouseEnter={() => setHoveredItem({ field, index })}
          onMouseLeave={() => setHoveredItem({ field: null, index: null })}
        >
          {editing.field === field && editing.arrayIndex === index ? (
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={editing.value}
                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
              />
              <button
                className="btn btn-success"
                onClick={() => handleUpdate(field, contactData[field].map((i, idx) =>
                  idx === index ? editing.value : i
                ))}
              >
                <RiCheckLine />
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setEditing({ field: null, value: null, arrayIndex: null })}
              >
                <RiCloseLine />
              </button>
            </div>
          ) : (
            <>
              <span>{item}</span>
              {hoveredItem.field === field && hoveredItem.index === index && (
                <div className="btn-group">
                  <button
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => setEditing({ field, value: item, arrayIndex: index })}
                  >
                    <RiEditLine />
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => handleArrayDelete(field, index)}
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );

  const renderTagsField = (tagsOptions) => {

    return (
      <div className="mb-4">
        <div
          className="d-flex justify-content-between align-items-center mb-2"
          onMouseEnter={() => setHoveredItem({ field: 'tags', index: 'header' })}
          onMouseLeave={() => setHoveredItem({ field: null, index: null })}
        >
          <h6 className="text-muted mb-0">Tags</h6>
          {!isEditingTags && hoveredItem.field === 'tags' && hoveredItem.index === 'header' && (
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => setIsEditingTags(true)}
            >
              <RiEditLine />
            </button>
          )}
        </div>

        {isEditingTags ? (
          <div className="mb-3">
            <Select
              options={tagsOptions}
              isMulti
              value={selectedTags}
              onChange={handleTagChange}
              placeholder="Select tags..."
            />
            <div className="d-flex flex-wrap gap-2 mt-2">
              {selectedTags.map((tag) => (
                <Badge key={tag.value} className="p-2">
                  {tag.label}
                  <X
                    size={14}
                    className="ms-2 cursor-pointer"
                    onClick={() =>
                      handleTagChange(
                        selectedTags.filter((c) => c.value !== tag.value)
                      )
                    }
                  />
                </Badge>
              ))}
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                className="btn btn-sm btn-success me-2"
                onClick={handleTagsUpdate}
              >
                <RiCheckLine /> Save
              </button>
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => setIsEditingTags(false)}
              >
                <RiCloseLine /> Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-wrap gap-2">
            {contactData.tags && contactData.tags.length > 0 ? (
              contactData.tags.map((tag, index) => (
                <Badge key={index} className="p-2 bg-info">
                  {typeof tag === 'object' ? tag.tag_name : tag}
                </Badge>
              ))
            ) : (
              <span className="text-muted">No tags</span>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderDropdownField = (field, label, value, options) => (
    <div
      className="mb-4"
      onMouseEnter={() => setHoveredItem({ field, index: 'simple' })}
      onMouseLeave={() => setHoveredItem({ field: null, index: null })}
    >
      <h6 className="text-muted mb-2">{label}</h6>
      {editing.field === field ? (
        <div className="input-group">
          <select
            className="form-select"
            value={editing.value}
            onChange={(e) => setEditing({ ...editing, value: e.target.value })}
          >
            <option value="">Select {label}</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <button
            className="btn btn-success"
            onClick={() => handleUpdate(field, editing.value)}
          >
            <RiCheckLine />
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setEditing({ field: null, value: null })}
          >
            <RiCloseLine />
          </button>
        </div>
      ) : (
        <div className="d-flex align-items-center gap-2">
          <p className="text-muted mb-0">
            {options.find(opt => opt.value === value)?.label || 'Not set'}
          </p>
          {hoveredItem.field === field && hoveredItem.index === 'simple' && (
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => setEditing({ field, value: value || '' })}
            >
              <RiEditLine />
            </button>
          )}
        </div>
      )}
    </div>
  );

  const renderSimpleField = (field, label, value) => {
    if (field === 'company') {
      return renderDropdownField(field, label, value, companyOptions);
    }
    if (field === 'contactStatus') {
      return renderDropdownField(field, label, value, statusOptions);
    }

    return (
      <div
        className="mb-4"
        onMouseEnter={() => setHoveredItem({ field, index: 'simple' })}
        onMouseLeave={() => setHoveredItem({ field: null, index: null })}
      >
        <h6 className="text-muted mb-2">{label}</h6>
        {editing.field === field ? (
          <div className="input-group">
            {field === 'description' ? (
              <textarea
                className="form-control"
                value={editing.value}
                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
                rows={4}
              />
            ) : (
              <input
                type="text"
                className="form-control"
                value={editing.value}
                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
              />
            )}
            <button
              className="btn btn-success"
              onClick={() => handleUpdate(field, editing.value)}
            >
              <RiCheckLine />
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setEditing({ field: null, value: null })}
            >
              <RiCloseLine />
            </button>
          </div>
        ) : (
          <div className="d-flex align-items-center gap-2">
            <p className="text-muted mb-0">{value || 'Not set'}</p>
            {hoveredItem.field === field && hoveredItem.index === 'simple' && (
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setEditing({ field, value: value || '' })}
              >
                <RiEditLine />
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  const tagsOptions = audienceTags && audienceTags.map((c) => ({
    value: c._id,
    label: `${c.tag_name}`,
  }));

  return (
    <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
      {/* {renderSimpleField('contactOwner', 'Contact Owner', contactData?.contactOwner)} */}
      {renderArrayField('workEmail', 'Work Email', contactData?.workEmail)}
      {renderArrayField('personalEmail', 'Personal Email', contactData?.personalEmail)}
      {renderArrayField('companyPhoneNumber', 'Company Phone Number', contactData?.companyPhoneNumber)}
      {renderArrayField('contactPhoneNumber', 'Contact Phone Number', contactData?.contactPhoneNumber)}
      {renderSimpleField('company', 'Current Company', contactData?.company)}
      {renderSimpleField('currentRole', 'Current Role', contactData?.currentRole)}
      {renderSimpleField('contactStatus', 'Contact Status', contactData?.contactStatus)}
      {renderTagsField(tagsOptions)}
      {renderSimpleField('description', 'Description', contactData?.description)}
    </div>
  );
};


export default ContactDetails;




