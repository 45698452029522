import React from "react";
import { Link } from "react-router-dom";
import { HeadingList } from "../AddBlockList/HeadingList";
import { SocialLinkList } from "../AddBlockList/SocialLinkList";
import { ButtonLinkList } from "../AddBlockList/ButtonLinkList";
import { TextList } from "../AddBlockList/TextList";
import { ImageList } from "../AddBlockList/ImageList";
import { YoutubeList } from "../AddBlockList/YoutubeList";
import { SpotifyList } from "../AddBlockList/SpotifyList";
import { ImageGridList } from "../AddBlockList/ImageGridList";
import { YoutubeIframe } from "../AddBlockList/YoutubeIframe";
import { TestimonialsList } from "../AddBlockList/TestimonialsList";
import { CountactList } from "../AddBlockList/ContactUs";
import { EventGridList } from "../AddBlockList/EventGridList";
import { ContactForm } from "../AddBlockList/ContactForm";


function Tab3() {
    return (
        <>
            <div class="styles__TabContent__Header themes_sec_header">
                <h3>Add Blocks</h3>
            </div>
            <div className="templates_list">
                <label for="foo" color="gray" class="style__Label-bufferapp-ui__sc-153wil8-5 dPYltt">Essentials</label>
                <div className="add_block_list">
                    <Link to="/headding">
                        <HeadingList />
                    </Link>
                    <Link to="/sociallink">
                        <SocialLinkList />
                    </Link>
                    <Link to="/buttonlink">
                        <ButtonLinkList />
                    </Link>
                    <Link to="/text">
                        <TextList />
                    </Link>
                    <Link to="/image">
                        <ImageList />
                    </Link>
                    <Link to="/testimoials">
                        <TestimonialsList />
                    </Link>
                    <Link to="/contact">
                        <CountactList />
                    </Link>
                    <Link to="/contactform">
                        <ContactForm />
                    </Link>
                </div>
            </div>
            {/* <div className="templates_list">
                <label for="foo" color="gray" class="style__Label-bufferapp-ui__sc-153wil8-5 dPYltt">Events</label>
                <div className="add_block_list">
                    <Link to="/event">
                        <EventGridList />
                    </Link>
                </div>
            </div> */}
            <div className="templates_list">
                <label for="foo" color="gray" class="style__Label-bufferapp-ui__sc-153wil8-5 dPYltt">Media</label>
                <div className="add_block_list">
                    <Link to="/youtube">
                        <YoutubeList />
                    </Link>
                    <Link to="/youtubeaddiframe">
                        <YoutubeIframe />
                    </Link>
                    <Link to="/spotify">
                        <SpotifyList />
                    </Link>
                </div>
            </div>
            <div className="templates_list">
                <label for="foo" color="gray" class="style__Label-bufferapp-ui__sc-153wil8-5 dPYltt">Media</label>
                <div className="add_block_list">
                    <Link to="/imagegrid">
                        <ImageGridList />
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Tab3;