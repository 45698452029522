import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; import axios from "axios";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { Button } from "react-bootstrap";
import AddNote from "./add-note/AddNote";
import { crmActivitiesContext } from "../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";

const API_URL = process.env.REACT_APP_API_URL;

const NotesContent = ({ componentType }) => {
  const {crmActivitiesData} = useContext(crmActivitiesContext);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    fetchNotes();
  }, [id, crmActivitiesData.tempFetch]);
  const fetchNotes = async () => {
    try {
      const owner_id = JSON.parse(localStorage.getItem("user"))._id;
      const response = await axios.post(`${API_URL}/crm/get-activities`, {
        contact_id: id,
        company_id: id,
        lead_id: id,
        deal_id: id,
        owner_id,
        type: "NOTE",
        crm_type: componentType
      });
      if (response.data.success) {
        setNotes(response.data.data);
      }
    } catch (error) {
      PNotify.error({ title: "Error", text: "Failed to fetch notes", delay: 3000 });
    } finally {
      setLoading(false);
    }
  };

  const handleNoteAdded = (newNote) => {
    setNotes(prev => ([newNote, ...prev]));
  };

  if (loading) return <div className="text-center text-light">Loading notes...</div>;

  return (
    <div className="p-4 rounded">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Notes</h5>
        <Button onClick={() => setShowModal(true)}>Add Note</Button>
      </div>
      {notes.length > 0 ? (
        notes.map((note) => (
          <div key={note.id} className="bg-secondary p-3 mb-2 rounded">
            <div className="d-flex justify-content-between">
              <span className="text-info">Note added by {note?.owner_id?.fname} {note?.owner_id?.lname}</span>
              <small className="text-muted">{new Date(note.activity_created_at).toLocaleTimeString()}</small>
            </div>
            <div className="text-white mt-2" dangerouslySetInnerHTML={{ __html: note.note }} />
          </div>
        ))
      ) : (
        <p className="text-center text-muted">No notes yet.</p>
      )}

      <AddNote
        show={showModal}
        onHide={() => setShowModal(false)}
        onNoteAdded={handleNoteAdded}
        componentType={componentType}
        id={id}
      />

    </div>
  );
};

export default NotesContent;