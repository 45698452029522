import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { IoIosArrowBack } from "react-icons/io";
import { RiMore2Fill } from "react-icons/ri";
import { FaBolt } from "react-icons/fa";
import { Tab, Nav } from 'react-bootstrap';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { RiEditLine, RiDeleteBin6Line, RiAddLine, RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { Modal, Button } from "react-bootstrap";

import CrmActivities from '../../components/crm-activities/CrmActivities';
import AssociationsPanel from '../../components/association-panel/AssociationPanel';
import AddNote from '../../components/crm-activities/notes/add-note/AddNote';
import SendEmailModal from '../../components/crm-activities/emails/send-email/SendEmail';
import Select from 'react-select';
import { Badge } from 'react-bootstrap';
import { X } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const CompanyDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [associations, setAssociations] = useState({
    contacts: [],
    deals: [],
    lists: []
  });
  const [comments, setComments] = useState([]);
  const [gmailConnected, setGmailConnected] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);


  useEffect(() => {
    fetchCompanyDetails();
    checkGmailAuthStatus();
  }, [id]);



  const checkGmailAuthStatus = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await axios.post(`${API_URL}/gmail/check-auth-status`, {
        user_id: user._id
      });
      setGmailConnected(response.data.isConnected);
    } catch (error) {
      console.error("Failed to check Gmail auth status:", error);
    } finally {

    }
  };

  const fetchCompanyDetails = async () => {
    try {
      const owner_id = JSON.parse(localStorage.getItem("user"))._id;

      const response = await axios.post(`${API_URL}/crm/company-details`, {
        company_id: id,
        owner_id
      });

      if (response.data.success) {
        setCompanyData(response.data.data);
        // Assuming the API returns associations and comments
        setAssociations(response.data.associations || {
          contacts: [],
          deals: [],
          lists: []
        });
        setComments(response.data.comments || []);
      }
    } catch (error) {
      PNotify.error({
        title: 'Error',
        text: 'Failed to fetch company details',
        delay: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const LeftPanel = () => (
    <div className="col-4 bg-light p-4" >
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div onClick={() => history.push('/crm/companies')} className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
          <IoIosArrowBack />
          <span className="ms-2">Back</span>
        </div>
        {/* <div className="dropdown">
          <RiMore2Fill className="cursor-pointer" />
        </div> */}
      </div>

      {/* Profile Card */}
      <div className="mb-4" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
        <div className="d-flex gap-3">
          <div className="bg-primary rounded-circle d-flex justify-content-center align-items-center text-white"
            style={{ width: '48px', height: '48px' }}>
            <span className="fs-4">{companyData?.name ? companyData.name.substring(0, 2).toUpperCase() : 'CO'}</span>
          </div>
          <div>
            <h5 className="mb-1">{companyData?.name}</h5>
            <div className="text-muted small">{companyData?.industry}</div>
            <div className="text-muted small">{companyData?.website}</div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="d-flex flex-wrap gap-2 mt-3">
          {/* <button className="btn btn-light border">
            <span className="d-flex align-items-center">
              Add to Campaign <span className="ms-1">▼</span>
            </span>
          </button>
          <button className="btn btn-light border">Email</button>
          <button className="btn btn-light border">Note</button>
          <button className="btn btn-light border">Task</button>
          <button className="btn btn-light border">More</button> */}
          <button className="btn btn-light border" onClick={() => setShowEmailModal(true)}>Email</button>
          <button className="btn btn-light border" onClick={() => setShowNoteModal(true)}>Note</button>
        </div>
      </div>

      {/* Run Agents Section */}
      {/* <div className="mb-4" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
        <h6 className="text-muted mb-3">Run Agents</h6>
        <div className="d-flex flex-wrap gap-2">
          <button className="btn btn-light border">
            <FaBolt className="text-primary me-2" />
            Company Research
          </button>
          <button className="btn btn-light border">
            <FaBolt className="text-primary me-2" />
            Find Contacts
          </button>
          <button className="btn btn-light border">
            <FaBolt className="text-primary me-2" />
            News Updates
          </button>
        </div>
      </div> */}

      <EditableCompanyDetails
        companyData={companyData}
        setCompanyData={setCompanyData}
      />

      <div className="mb-4" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
        <h6 className="text-muted mb-3">Last Updated</h6>
        <p>{new Date(companyData.updatedAt).toLocaleString()}</p>
        <h6 className="text-muted mb-3">Created</h6>
        <p>{new Date(companyData.createdAt).toLocaleString()}</p>
      </div>
    </div>
  )

  if (loading) {
    return <div className="text-center p-5">Loading...</div>;
  }

  return (
    <>
      <AddNote
        show={showNoteModal}
        onHide={() => setShowNoteModal(false)}
        componentType="COMPANY"
        id={id}
      />
      <SendEmailModal
        show={showEmailModal}
        onHide={() => setShowEmailModal(false)}
        id={id}
        componentType="COMPANY"
        gmailConnected={gmailConnected}
      />
      <div className="main-container">
        <div className="container-fluid p-0">
          <div className="row g-0">
            {/* Left Panel */}
            <LeftPanel />

            {/* Center Panel - Activity Tabs */}
            <div className="col-5 p-4">
              <CrmActivities componentType="COMPANY" />
            </div>

            {/* Right Panel - Associations */}
            <div className="col-3">
              <AssociationsPanel componentType="COMPANY" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EditableCompanyDetails = ({ companyData, setCompanyData }) => {
  const [editing, setEditing] = useState({
    field: null,
    value: null,
    arrayIndex: null
  });
  const [newArrayItem, setNewArrayItem] = useState('');
  const [hoveredItem, setHoveredItem] = useState({ field: null, index: null });
  const [audienceTags, setAudienceTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isEditingTags, setIsEditingTags] = useState(false);

  const industryOptions = [
    { value: "Technology", label: "Technology" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Finance", label: "Finance" },
    { value: "Education", label: "Education" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Retail", label: "Retail" },
    { value: "Construction", label: "Construction" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Food & Beverage", label: "Food & Beverage" },
    { value: "Transportation", label: "Transportation" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Agriculture", label: "Agriculture" },
    { value: "Energy", label: "Energy" },
    { value: "Telecommunications", label: "Telecommunications" },
    { value: "Consulting", label: "Consulting" },
    { value: "Other", label: "Other" }
  ];

  const sizeOptions = [
    { value: "1-10 employees", label: "1-10 employees" },
    { value: "11-50 employees", label: "11-50 employees" },
    { value: "51-200 employees", label: "51-200 employees" },
    { value: "201-500 employees", label: "201-500 employees" },
    { value: "501-1000 employees", label: "501-1000 employees" },
    { value: "1001-5000 employees", label: "1001-5000 employees" },
    { value: "5001-10000 employees", label: "5001-10000 employees" },
    { value: "10000+ employees", label: "10000+ employees" }
  ];

  useEffect(() => {
    fetchAudienceTags();
    // Initialize selected tags if company already has tags
    if (companyData?.tags && Array.isArray(companyData.tags)) {
      // Handle potential nested array
      const tagArray = Array.isArray(companyData.tags[0]) ? companyData.tags[0] : companyData.tags;
      const initialTags = tagArray.map(tag => {
        if (typeof tag === 'object' && tag._id) {
          return { value: tag._id, label: tag.tag_name };
        } else {
          return { value: tag, label: tag };
        }
      });
      setSelectedTags(initialTags);
    }
  }, [companyData]);

  const fetchAudienceTags = async () => {
    const owner_id = JSON.parse(localStorage.getItem("user"))._id;

    try {
      const response = await axios.post(`${API_URL}/tags/get-audience-tags`, {
        "owner_id": owner_id,
      });

      if (response.data.success) {
        setAudienceTags(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching audience tags:", error);
    }
  };

  const handleUpdate = async (field, value, arrayIndex = null) => {
    try {
      const owner_id = JSON.parse(localStorage.getItem("user"))._id;
      const updateData = {
        company_id: companyData._id,
        owner_id,
        field,
        value
      };

      if (arrayIndex !== null) {
        updateData.arrayIndex = arrayIndex;
      }

      const response = await axios.post(`${API_URL}/crm/update-company-fields`, updateData);

      if (response.data.success) {
        setCompanyData(response.data.data);
        PNotify.success({
          title: 'Success',
          text: 'Company updated successfully',
          delay: 2000
        });
      }
    } catch (error) {
      PNotify.error({
        title: 'Error',
        text: 'Failed to update company',
        delay: 2000
      });
    }
    setEditing({ field: null, value: null, arrayIndex: null });
    setNewArrayItem('');
  };

  const handleArrayAdd = async (field) => {
    if (!newArrayItem) return;

    const currentArray = companyData[field] || [];
    const updatedArray = [...currentArray, newArrayItem];
    await handleUpdate(field, updatedArray);
  };

  const handleArrayDelete = async (field, index) => {
    const updatedArray = companyData[field].filter((_, i) => i !== index);
    await handleUpdate(field, updatedArray);
  };

  const handleTagChange = (selected) => {
    setSelectedTags(selected || []);
  };

  const handleTagsUpdate = async () => {
    // Extract tag IDs for the update
    const tagValues = selectedTags.map(tag => tag.value);
    await handleUpdate('tags', tagValues);
    setIsEditingTags(false);
  };

  const renderArrayField = (field, label, items = []) => (
    <div className="mb-4">
      <div
        className="d-flex justify-content-between align-items-center mb-2"
        onMouseEnter={() => setHoveredItem({ field, index: 'header' })}
        onMouseLeave={() => setHoveredItem({ field: null, index: null })}
      >
        <h6 className="text-muted mb-0">{label}</h6>
        {(hoveredItem.field === field && hoveredItem.index === 'header') && (
          <button
            className="btn btn-sm btn-outline-primary opacity-100"
            onClick={() => setEditing({ field, value: '', arrayIndex: 'new' })}
          >
            <RiAddLine /> Add
          </button>
        )}
      </div>

      {editing.field === field && editing.arrayIndex === 'new' && (
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control"
            value={newArrayItem}
            onChange={(e) => setNewArrayItem(e.target.value)}
            placeholder={`Add new ${label.toLowerCase()}`}
          />
          <button
            className="btn btn-success"
            onClick={() => handleArrayAdd(field)}
          >
            <RiCheckLine />
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setEditing({ field: null, value: null, arrayIndex: null })}
          >
            <RiCloseLine />
          </button>
        </div>
      )}

      {items.map((item, index) => (
        <div
          key={index}
          className="d-flex align-items-center gap-2 mb-2"
          onMouseEnter={() => setHoveredItem({ field, index })}
          onMouseLeave={() => setHoveredItem({ field: null, index: null })}
        >
          {editing.field === field && editing.arrayIndex === index ? (
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={editing.value}
                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
              />
              <button
                className="btn btn-success"
                onClick={() => handleUpdate(field, companyData[field].map((i, idx) =>
                  idx === index ? editing.value : i
                ))}
              >
                <RiCheckLine />
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setEditing({ field: null, value: null, arrayIndex: null })}
              >
                <RiCloseLine />
              </button>
            </div>
          ) : (
            <>
              <span>{item}</span>
              {hoveredItem.field === field && hoveredItem.index === index && (
                <div className="btn-group">
                  <button
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => setEditing({ field, value: item, arrayIndex: index })}
                  >
                    <RiEditLine />
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => handleArrayDelete(field, index)}
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );

  const renderTagsField = (tagsOptions) => {

    return (
      <div className="mb-4">
        <div
          className="d-flex justify-content-between align-items-center mb-2"
          onMouseEnter={() => setHoveredItem({ field: 'tags', index: 'header' })}
          onMouseLeave={() => setHoveredItem({ field: null, index: null })}
        >
          <h6 className="text-muted mb-0">Tags</h6>
          {!isEditingTags && hoveredItem.field === 'tags' && hoveredItem.index === 'header' && (
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => setIsEditingTags(true)}
            >
              <RiEditLine />
            </button>
          )}
        </div>

        {isEditingTags ? (
          <div className="mb-3">
            <Select
              options={tagsOptions}
              isMulti
              value={selectedTags}
              onChange={handleTagChange}
              placeholder="Select tags..."
            />
            <div className="d-flex flex-wrap gap-2 mt-2">
              {selectedTags.map((tag) => (
                <Badge key={tag.value} className="p-2">
                  {tag.label}
                  <X
                    size={14}
                    className="ms-2 cursor-pointer"
                    onClick={() =>
                      handleTagChange(
                        selectedTags.filter((c) => c.value !== tag.value)
                      )
                    }
                  />
                </Badge>
              ))}
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                className="btn btn-sm btn-success me-2"
                onClick={handleTagsUpdate}
              >
                <RiCheckLine /> Save
              </button>
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => setIsEditingTags(false)}
              >
                <RiCloseLine /> Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-wrap gap-2">
            {companyData.tags && companyData.tags.length > 0 ? (
              companyData.tags.map((tag, index) => (
                <Badge key={index} className="p-2 bg-info">
                  {typeof tag === 'object' ? tag.tag_name : tag}
                </Badge>
              ))
            ) : (
              <span className="text-muted">No tags</span>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderDropdownField = (field, label, value, options) => (
    <div
      className="mb-4"
      onMouseEnter={() => setHoveredItem({ field, index: 'simple' })}
      onMouseLeave={() => setHoveredItem({ field: null, index: null })}
    >
      <h6 className="text-muted mb-2">{label}</h6>
      {editing.field === field ? (
        <div className="input-group">
          <select
            className="form-select"
            value={editing.value}
            onChange={(e) => setEditing({ ...editing, value: e.target.value })}
          >
            <option value="">Select {label}</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <button
            className="btn btn-success"
            onClick={() => handleUpdate(field, editing.value)}
          >
            <RiCheckLine />
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setEditing({ field: null, value: null })}
          >
            <RiCloseLine />
          </button>
        </div>
      ) : (
        <div className="d-flex align-items-center gap-2">
          <p className="text-muted mb-0">
            {options.find(opt => opt.value === value)?.label || 'Not set'}
          </p>
          {hoveredItem.field === field && hoveredItem.index === 'simple' && (
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => setEditing({ field, value: value || '' })}
            >
              <RiEditLine />
            </button>
          )}
        </div>
      )}
    </div>
  );

  const renderSimpleField = (field, label, value) => {
    if (field === 'industry') {
      return renderDropdownField(field, label, value, industryOptions);
    }
    if (field === 'size') {
      return renderDropdownField(field, label, value, sizeOptions);
    }

    return (
      <div
        className="mb-4"
        onMouseEnter={() => setHoveredItem({ field, index: 'simple' })}
        onMouseLeave={() => setHoveredItem({ field: null, index: null })}
      >
        <h6 className="text-muted mb-2">{label}</h6>
        {editing.field === field ? (
          <div className="input-group">
            {field === 'description' ? (
              <textarea
                className="form-control"
                value={editing.value}
                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
                rows={4}
              />
            ) : (
              <input
                type="text"
                className="form-control"
                value={editing.value}
                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
              />
            )}
            <button
              className="btn btn-success"
              onClick={() => handleUpdate(field, editing.value)}
            >
              <RiCheckLine />
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setEditing({ field: null, value: null })}
            >
              <RiCloseLine />
            </button>
          </div>
        ) : (
          <div className="d-flex align-items-center gap-2">
            <p className="text-muted mb-0">{value || 'Not set'}</p>
            {hoveredItem.field === field && hoveredItem.index === 'simple' && (
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setEditing({ field, value: value || '' })}
              >
                <RiEditLine />
              </button>
            )}
          </div>
        )}
      </div>
    )
  }

  const tagsOptions = audienceTags && audienceTags.map((c) => ({
    value: c._id,
    label: `${c.tag_name}`,
  }));


  return (
    <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
      {renderSimpleField('name', 'Company Name', companyData?.name)}
      {renderSimpleField('industry', 'Industry', companyData?.industry)}
      {renderSimpleField('website', 'Website', companyData?.website)}
      {renderSimpleField('linkedinUrl', 'LinkedIn URL', companyData?.linkedinUrl)}
      {renderSimpleField('size', 'Company Size', companyData?.size)}
      {renderSimpleField('revenue', 'Annual Revenue', companyData?.revenue)}

      <h6 className="text-muted mb-2">Location</h6>
      <div className="ps-2 mb-3">
        {renderSimpleField('city', 'City', companyData?.city)}
        {renderSimpleField('state', 'State/Region', companyData?.state)}
        {renderSimpleField('country', 'Country', companyData?.country)}
        {renderSimpleField('postalCode', 'Postal Code', companyData?.postalCode)}
      </div>

      {/* {renderArrayField('tags', 'Tags', companyData?.tags)} */}
      {renderTagsField(tagsOptions)}

      {renderSimpleField('description', 'Description', companyData?.description)}
    </div>
  );
};

export default CompanyDetails;