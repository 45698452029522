import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Badge, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { X } from 'lucide-react'; // Assuming you're using react-feather icons
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import Select from "react-select";
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const AddDealModal = ({ show, handleClose, companies, contacts, deals, associatedDeals, onAddDeal, componentType, audienceTags }) => {

    const { id } = useParams();

    const [activeTab, setActiveTab] = useState('new');

    const [formData, setFormData] = useState({
        title: '',
        companies: [],
        contacts: [],
        amount: '',
        expectedCloseDate: '',
        dealType: 'New',
        dealPriority: 'Medium',
        dealOwner: JSON.parse(localStorage.getItem("user")).fname + " " + JSON.parse(localStorage.getItem("user")).lname,
        pipeline: 'Sales Pipeline',
        dealStage: 'Qualification',
        tags: [],
        description: ''
    });

    const [loading, setLoading] = useState(false);
    const [tagInput, setTagInput] = useState('');
    const [dealsList, setDealsList] = useState([]);
    const [selectedDeals, setSelectedDeals] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        const filteredDeals = deals.filter(deal => !associatedDeals.some(associated => associated.associated_deal_id._id === deal._id));
        setDealsList(filteredDeals);
    }, [deals, associatedDeals]);

    const showError = (message) => {
        PNotify.error({
            title: 'Error',
            text: message,
            delay: 3000
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleTagInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = tagInput.trim();
            if (newTag && !formData.tags.includes(newTag)) {
                setFormData(prev => ({
                    ...prev,
                    tags: [...prev.tags, newTag]
                }));
                setTagInput('');
            }
        }
    };

    const removeTag = (tagToRemove) => {
        setFormData(prev => ({
            ...prev,
            tags: prev.tags.filter(tag => tag !== tagToRemove)
        }));
    };

    // Convert MongoDB documents to options for Select
    const companyOptions = companies.map((c) => ({
        value: c._id,
        label: `${c.name}`,
    }));

    const contactOptions = contacts.map((c) => ({
        value: c._id,
        label: `${c.firstName} ${c.lastName} (${c.email})`,
    }));

    // Handle Select change
    const handleCompanyChange = (selected) => {
        setFormData((prev) => ({
            ...prev,
            companies: selected || [],
        }));
    };

    const handleContactChange = (selected) => {
        setFormData((prev) => ({
            ...prev,
            contacts: selected || [],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/crm/add-deal`, {
                ...formData,
                owner_id,
                dealOwner: JSON.parse(localStorage.getItem("user"))._id,
                tags: selectedTags.map((t) => t.value),
                companies: formData.companies.map((c) => c.value),
                contacts: formData.contacts.map((c) => c.value),
                method: 'association',
                crm_type: componentType,
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id
            });

            if (response.data.success) {

                onAddDeal();
                handleClose();
            }
        } catch (error) {
            if (typeof PNotify !== 'undefined') {
                PNotify.error({
                    title: 'Error',
                    text: error.response?.data?.message || 'Error adding deal',
                    delay: 3000
                });
            } else {
                alert(error.response?.data?.message || 'Error adding deal');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDealSelect = (e, dealId) => {
        e.stopPropagation(); // Prevent row click when checkbox is clicked

        if (selectedDeals.includes(dealId)) {
            setSelectedDeals(selectedDeals.filter(id => id !== dealId));
        } else {
            setSelectedDeals([...selectedDeals, dealId]);
        }
    };

    // Handle row click
    const handleRowClick = (dealId) => {
        if (selectedDeals.includes(dealId)) {
            setSelectedDeals(selectedDeals.filter(id => id !== dealId));
        } else {
            setSelectedDeals([...selectedDeals, dealId]);
        }
    };

    const handleAddDeals = async () => {

        if (selectedDeals.length === 0) {
            showError('Please select at least one deal');
            return;
        }

        const owner_id = JSON.parse(localStorage.getItem("user"))._id;
        const data = {
            deals: selectedDeals,
            owner_id: owner_id,
            crm_type: componentType,
            contact_id: id,
            company_id: id,
            lead_id: id,
            deal_id: id
        };

        try {
            const response = await axios.post(`${API_URL}/crm/add-associated-deal`, data);
            if (response.data.success) {
                onAddDeal();
                handleClose();
            }
        } catch (error) {
            console.error("Error adding deals:", error);
        }
    };

    const getInitialStyle = () => {
        return {
            backgroundColor: '#777',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontWeight: 'bold',
            marginRight: '10px'
        };
    };

    const tagsOptions = audienceTags && audienceTags.map((c) => ({
        value: c._id,
        label: `${c.tag_name}`,
    }));

    const handleTagChange = (selected) => {
        setSelectedTags(selected || []);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            animation={true}
            className="Sidebar-Modal-end"
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add New Deal</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="tabs-header d-flex border-bottom">
                    <div
                        className={`tab-item py-2 px-3 flex-grow-1 text-center ${activeTab === 'new' ? 'fw-bold' : 'text-secondary'}`}
                        onClick={() => setActiveTab('new')}
                        style={{ cursor: 'pointer' }}
                    >
                        New
                    </div>
                    <div
                        className={`tab-item py-2 px-3 flex-grow-1 text-center ${activeTab === 'existing' ? 'fw-bold' : 'text-secondary'}`}
                        onClick={() => setActiveTab('existing')}
                        style={{ cursor: 'pointer' }}
                    >
                        Existing
                    </div>
                </div>

                {activeTab === 'new' && (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        {/* Companies Selection */}
                        <Form.Group className="mb-3">
                            <Form.Label>Companies</Form.Label>
                            <Select
                                options={companyOptions}
                                isMulti
                                value={formData.companies}
                                onChange={handleCompanyChange}
                                placeholder="Select companies..."
                            />
                            <div className="d-flex flex-wrap gap-2 mt-2">
                                {formData.companies.map((company) => (
                                    <Badge key={company.value} className="p-2">
                                        {company.label}
                                        <X
                                            size={14}
                                            className="ms-2 cursor-pointer"
                                            onClick={() =>
                                                handleCompanyChange(
                                                    formData.companies.filter((c) => c.value !== company.value)
                                                )
                                            }
                                        />
                                    </Badge>
                                ))}
                            </div>
                        </Form.Group>

                        {/* Contacts Selection */}
                        <Form.Group className="mb-3">
                            <Form.Label>Contacts</Form.Label>
                            <Select
                                options={contactOptions}
                                isMulti
                                value={formData.contacts}
                                onChange={handleContactChange}
                                placeholder="Select contacts..."
                            />
                            <div className="d-flex flex-wrap gap-2 mt-2">
                                {formData.contacts.map((contact) => (
                                    <Badge key={contact.value} className="p-2">
                                        {contact.label}
                                        <X
                                            size={14}
                                            className="ms-2 cursor-pointer"
                                            onClick={() =>
                                                handleContactChange(
                                                    formData.contacts.filter((c) => c.value !== contact.value)
                                                )
                                            }
                                        />
                                    </Badge>
                                ))}
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                name="amount"
                                value={formData.amount}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Expected Close Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="expectedCloseDate"
                                value={formData.expectedCloseDate}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Deal Type</Form.Label>
                            <Form.Select
                                name="dealType"
                                value={formData.dealType}
                                onChange={handleChange}
                            >
                                <option value="New">New</option>
                                <option value="Existing">Existing</option>
                                <option value="Renewal">Renewal</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Deal Priority</Form.Label>
                            <Form.Select
                                name="dealPriority"
                                value={formData.dealPriority}
                                onChange={handleChange}
                            >
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                                <option value="Low">Low</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Deal Owner</Form.Label>
                            <Form.Control
                                type="text"
                                name="dealOwner"
                                value={formData.dealOwner}
                                onChange={handleChange}
                                disabled
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Pipeline</Form.Label>
                            <Form.Select
                                name="pipeline"
                                value={formData.pipeline}
                                onChange={handleChange}
                            >
                                <option value="Sales Pipeline">Sales Pipeline</option>
                                <option value="Marketing Pipeline">Marketing Pipeline</option>
                                <option value="Partnership Pipeline">Partnership Pipeline</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Deal Stage</Form.Label>
                            <Form.Select
                                name="dealStage"
                                value={formData.dealStage}
                                onChange={handleChange}
                            >
                                <option value="Qualification">Qualification</option>
                                <option value="Discovery">Discovery</option>
                                <option value="Proposal">Proposal</option>
                                <option value="Negotiation">Negotiation</option>
                                <option value="Closed Won">Closed Won</option>
                                <option value="Closed Lost">Closed Lost</option>
                            </Form.Select>
                        </Form.Group>

                        {/* <Form.Group className="mb-3">
                        <Form.Label>Tags</Form.Label>
                        <div className="border rounded p-2">
                            <div className="d-flex flex-wrap gap-2 mb-2">
                                {formData.tags.map((tag, index) => (
                                    <Badge
                                        key={index}
                                        bg="primary"
                                        className="d-flex align-items-center me-1 mb-1"
                                    >
                                        {tag}
                                        <X
                                            className="ms-1 cursor-pointer"
                                            size={14}
                                            onClick={() => removeTag(tag)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </Badge>
                                ))}
                            </div>
                            <Form.Control
                                type="text"
                                className="border-0"
                                value={tagInput}
                                onChange={(e) => setTagInput(e.target.value)}
                                onKeyDown={handleTagInputKeyDown}
                                placeholder="Type a tag and press Enter or comma to add"
                            />
                        </div>
                    </Form.Group> */}

                        <Form.Group className="mb-3">
                            <Form.Label>Tags</Form.Label>
                            <Select
                                options={tagsOptions}
                                isMulti
                                value={selectedTags}
                                onChange={handleTagChange}
                                placeholder="Select tags..."
                            />
                            <div className="d-flex flex-wrap gap-2 mt-2">
                                {selectedTags.map((tag) => (
                                    <Badge key={tag.value} className="p-2">
                                        {tag.label}
                                        <X
                                            size={14}
                                            className="ms-2 cursor-pointer"
                                            onClick={() =>
                                                handleTagChange(
                                                    selectedTags.filter((c) => c.value !== tag.value)
                                                )
                                            }
                                        />
                                    </Badge>
                                ))}
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                rows={3}
                            />
                        </Form.Group>

                        <div className="text-end mt-4">


                            <Button variant="secondary"
                                className="me-2"

                                onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? 'Adding...' : 'Add Deal'}
                            </Button>
                        </div>
                    </Form>
                )}
                {activeTab === 'existing' && (
                    <>
                        <ListGroup className="mb-3" variant="flush">
                            {dealsList.map(deal => (
                                <ListGroup.Item
                                    key={deal._id}
                                    onClick={() => handleRowClick(deal._id)}
                                    className={`d-flex align-items-center ${selectedDeals.includes(deal._id) ? 'bg-primary' : 'bg-dark'} text-white border-secondary`}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        className="me-2"
                                        checked={selectedDeals.includes(deal._id)}
                                        onChange={(e) => handleDealSelect(e, deal._id)}
                                    />
                                    <div style={getInitialStyle()}>
                                        {deal.title.charAt(0).toUpperCase()}
                                    </div>
                                    <div>{deal.title}</div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>

                        <div className="d-flex justify-content-end">
                            <Button
                                variant="primary"
                                size="sm"
                                disabled={selectedDeals.length === 0}
                                onClick={handleAddDeals}
                            >
                                + Add
                            </Button>
                        </div>
                    </>
                )}
            </Modal.Body>
            {/* <Modal.Footer>
                
            </Modal.Footer> */}
        </Modal>
    );
};

export default AddDealModal;