import { useState, useEffect } from "react";

const useCurrentLocation = () => {
    const [location1, setLocation] = useState(null);
    const [address, setAddress] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!navigator.geolocation) {
            setError("Geolocation is not supported by your browser");
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setLocation({ latitude, longitude });
                fetchAddress(latitude, longitude); // Fetch address
            },
            (err) => {
                setError(err.message);
            }
        );
    }, []);

    // 🔹 Function to Fetch Address from OpenStreetMap (Free)
    const fetchAddress = async (latitude, longitude) => {
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            const data = await response.json();

            if (data.display_name) {
                setAddress(data.display_name);
            } else {
                setError("Address not found");
            }
        } catch (err) {
            setError("Failed to fetch address");
        }
    };

    return { location1, address, error };
};

export default useCurrentLocation;
