import React, { useContext } from 'react';
import { WebBuilderContext } from '../../contexts/context/webBuilderContext';
import ComponentsList from './ComponentsList';
import { ImageIcon } from 'lucide-react';
import NavbarEdit from './components/navbar/NavbarEdit';

const BLOCK_TYPES = {
  NONE: { id: 'NONE', icon: null },
  TOP: { id: 'TOP', icon: null },
  LEFT: { id: 'LEFT', icon: null },
  RIGHT: { id: 'RIGHT', icon: null },
  BACKGROUND: { id: 'BACKGROUND', icon: null }
};

const API_URL = process.env.REACT_APP_API_URL;

const BlockBuilder = () => {
  const { blockData } = useContext(WebBuilderContext);



  return (
    <>
      <NavbarEdit />
      <div className="mainbuilder container min-w-4xl">
        {blockData.blocks.map((block, index) => (
          <div
            key={block.id}
            className="block-wrapper border border-1 border-dashed rounded-0 block-container"
            style={{ backgroundColor: block.color || '#ffffff' }}
          >
            <div className="position-relative">
              {block.block_type === BLOCK_TYPES.TOP.id ? (
                <div className="container d-flex flex-column">
                  <div className="w-100 bg-light d-flex align-items-center justify-content-center mt-5" style={{ height: '15rem' }}>
                    {block.image ? (
                      <img
                        src={block.image.src.startsWith('http') || block.image.src.startsWith('https') ? block.image.src : `${API_URL}/web-builder/${block.image.src}`}
                        alt={block.image.alt || 'Block image'}
                        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                      />
                    ) : (
                      <ImageIcon className="text-secondary" size={32} />
                    )}
                  </div>
                  <div className="">
                    <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />
                  </div>
                </div>
              ) : block.block_type === BLOCK_TYPES.LEFT.id ? (
                <div className="container">
                  <div className="row g-0">
                    <div className="col-4 bg-light d-flex align-items-center justify-content-center my-5" style={{ height: '90vh', borderRadius: '30px' }}>
                      {block.image ? (
                        <img
                          src={block.image.src.startsWith('http') || block.image.src.startsWith('https') ? block.image.src : `${API_URL}/web-builder/${block.image.src}`}
                          alt={block.image.alt || 'Block image'}
                          style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '15px' }}
                        />
                      ) : (
                        <ImageIcon className="text-secondary" size={32} />
                      )}
                    </div>
                    <div className="col-8 text-secondary d-flex flex-column align-items-center justify-content-center mt-5">
                      <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />
                    </div>
                  </div>
                </div>
              ) : block.block_type === BLOCK_TYPES.RIGHT.id ? (
                <div className="container">
                  <div className="row g-0">
                    <div className="col-8 text-secondary d-flex flex-column align-items-center justify-content-center mt-5">
                      <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />
                    </div>
                    <div className="col-4 my-5 bg-light d-flex align-items-center justify-content-center" style={{ height: '80vh', borderRadius: '30px' }}>
                      {block.image ? (
                        <img
                          src={block.image.src.startsWith('http') || block.image.src.startsWith('https') ? block.image.src : `${API_URL}/web-builder/${block.image.src}`}
                          alt={block.image.alt || 'Block image'}
                          style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '15px' }}
                        />
                      ) : (
                        <ImageIcon className="text-secondary" size={32} />
                      )}
                    </div>
                  </div>
                </div>
              ) : block.block_type === BLOCK_TYPES.BACKGROUND.id ? (
                <div className="position-relative" style={{ height: 'fit-content' }}>
                  <div className="position-absolute top-0 start-0 end-0 bottom-0 bg-dark d-flex align-items-center justify-content-center">
                    {block.image ? (
                      <img
                        src={block.image.src.startsWith('http') || block.image.src.startsWith('https') ? block.image.src : `${API_URL}/web-builder/${block.image.src}`}
                        alt={block.image.alt || 'Block image'}
                        style={{ height: '100%', width: '100%', objectFit: 'cover', filter: 'blur(2px)', opacity: '0.4' }}
                      />
                    ) : (
                      <ImageIcon className="text-secondary" size={32} />
                    )}
                  </div>
                  <div className="p-3 text-white text-center position-relative">
                    <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />
                  </div>
                </div>
              ) : (
                <div className="p-3 text-secondary text-center">
                  <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />
                </div>
              )}
            </div>
          </div>
        ))}

        {/* {blockData.blocks.length === 0 && (
        <div className="empty-state">
          <div className="border border-2 border-dashed rounded p-4 text-secondary text-center" style={{ minHeight: '100px' }}>
            No blocks yet
          </div>
        </div>
      )} */}

        <style>
          {`
          .mainbuilder {
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          }
            .container{
                max-width: 1150px !important;
            }
          .block-container {
            transition: border-color 0.2s;
          }
          .block-container:hover {
            // border-color: var(--bs-primary) !important;
          }
          .action-buttons {
            opacity: 0;
            transition: opacity 0.2s;
          }
          .block-wrapper:hover .action-buttons {
            opacity: 1;
          }
          .add-button {
            opacity: 0;
            transition: opacity 0.2s;
            border: none;
            padding: 0;
            margin-top: -12px;
          }
          .block-wrapper:hover .add-button,
          .add-button:hover {
            opacity: 1;
          }
          .hover-primary:hover {
            color: var(--bs-primary) !important;
          }
          .btn-link {
            text-decoration: none;
            padding: 0;
          }
          .btn-link:hover {
            color: var(--bs-primary) !important;
          }
        `}
        </style>
      </div>
    </>
  );
};

export default BlockBuilder;