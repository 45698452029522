import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

const API_URL = process.env.REACT_APP_API_URL;

const ContactForms = ({ data, unique_id, smartlink_id }) => {
    const { username } = useParams();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        title: '',
        company: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/crm/add-contact-from-event-flexpage`,
                {
                    name: formData.name,
                    email: formData.email,
                    phone: formData.phone,
                    title: formData.title,
                    company: formData.company,
                    type: "flexpage",
                    backhalf: username
                });
                if(response.data.success){
                    PNotify.success({
                        text: "Contact Form submitted successfully",
                        delay: 3000
                    });
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        title: '',
                        company: ''
                    });
                    setLoading(false);
                }
        } catch (error) {
            console.error(error);
            PNotify.error({
                text: "Failed to submit Contact Form",
                delay: 3000
            });
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 border rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">{data.title}</h2>

            {/* Name */}
            <div className="mb-3">
                <label htmlFor="name" className="block text-sm font-medium mb-1">
                    Name
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>

            {/* Email */}
            <div className="mb-3">
                <label htmlFor="email" className="block text-sm font-medium mb-1">
                    Email
                </label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>

            {/* Phone */}
            <div className="mb-3">
                <label htmlFor="phone" className="block text-sm font-medium mb-1">
                    Phone
                </label>
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>

            {/* Title */}
            <div className="mb-3">
                <label htmlFor="title" className="block text-sm font-medium mb-1">
                    Title
                </label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>

            {/* Company */}
            <div className="mb-3">
                <label htmlFor="company" className="block text-sm font-medium mb-1">
                    Company
                </label>
                <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>

            {/* Submit Button */}
            <button
                type="submit"
                className="w-full bg-blue-500 py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                disabled={loading}
            >
                {loading ? (
                    "Submitting..."
                ) : (
                    "Submit"
                )}
            </button>
        </form>
    );
};

export default ContactForms;
