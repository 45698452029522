// import React from "react";
// import { Link } from "react-scroll";
// import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";

// function BuilderButton(props) {
//     console.log("my button props", props);

// const saveClicks = (smartlink_id, unique_id) => {
//     saveComponentsClicks({
//         unique_id: unique_id,
//         smartlink_id: smartlink_id,

//     });
// }

//     return (
//         <>
//             <div className="button_content_box">
//                 <div className="small_favicon">
//                     <img src={props.data.icon} />
//                 </div>
//                 <a href={props.data.link} target="_blank">
//                     <button onClick={() => { saveClicks(props.smartlink_id, props.data.unique_id) }} className="btn link-btn-view bg_aqw" style={{ backgroundColor: props.buttonColor }}>{props.data.lable}</button>
//                 </a>
//                 <p>{props.data.url}</p>
//             </div>
//         </>
//     );
// }

// export default BuilderButton;
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../BackendApi/Api/config";
import "./../../../pages/PublicFlexpages/flexComponents/ButtonCustomizer.css";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";

function BuilderButton(props) {
  const {
    buttonShape,
    buttonStyle,
    backgroundColor,
    size,
    thumbnailPosition,
    thumbnail,
    label,
    jumpToAnchor,
    link,
    emoji,emojis,
    hoverAnimation,
    animation,
    subtext,
  } = props.data;

  const saveClicks = (smartlink_id, unique_id) => {
    saveComponentsClicks({
      unique_id: unique_id,
      smartlink_id: smartlink_id,
    });
  };

  const [isShaking, setIsShaking] = useState(false);

  // Shake every 5 seconds if 'shake' is selected
  useEffect(() => {
    let interval;
    if (animation === "shake") {
      interval = setInterval(() => {
        setIsShaking(true);
        setTimeout(() => setIsShaking(false), 500);
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [animation]);

  const hoverClass =
    hoverAnimation === "fill-empty"
      ? "animation-fill-empty"
      : hoverAnimation === "grow-shrink"
      ? "animation-grow-shrink"
      : hoverAnimation === "shake"
      ? "animation-shaking"
      : "";

  const animationClass =
    animation === "pulsing"
      ? "animation-pulsing"
      : animation === "shake" && isShaking
      ? "animation-shaking"
      : "";

  const handleAnchorClick = (e, targetId) => {
    e.preventDefault();
    if (!targetId) return;

    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleButtonClick = (e) => {
    // If it's an anchor jump, handle that
    if (jumpToAnchor?.type) {
      const targetId =
        jumpToAnchor.type === "TESTIMONIAL"
          ? jumpToAnchor.properties[0].unique_id
          : jumpToAnchor.properties.unique_id;

      handleAnchorClick(e, targetId);
      e.preventDefault();
    }

    // Save click analytics in either case
    saveClicks(props.smartlink_id, props.data.unique_id);
  };
  return (
    <div className="button_content_box">
      <a
        href={jumpToAnchor?.type ? "#" : link}
        target={jumpToAnchor?.type ? "_self" : "_blank"}
        style={{ textDecoration: "none", color: "black" }}
        onClick={handleButtonClick}
      >
        <div
          className={`button-preview ${hoverClass} ${animationClass}`}
          style={{
            display: "flex",
            alignItems: "center",
            borderRadius:
              buttonShape === "rounded"
                ? "30px"
                : buttonShape === "curvedRectangle"
                ? "20px"
                : "10px",
            flexDirection:
              thumbnailPosition === "right" ? "row" : "row-reverse",
            backgroundColor: backgroundColor,
            padding: "10px 10px",
            width: "100%",
            height: "100%",
            border: buttonStyle === "outline" ? "1px solid #ddd" : "none",
            boxShadow:
              buttonStyle === "shadow"
                ? "0px 4px 58px rgba(0,0,0,0.1)"
                : "none",
          }}
        >
          <div
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <div>
              <p
                className="btn-text"
                style={{ textAlign: "center", fontSize: 20 }}
              >
                {label}
              </p>
              {subtext && (
                <p
                  className="btn-text"
                  style={{
                    textAlign: "center",
                    fontSize: size === "standard" ? "14px" : "18px",
                    marginTop: size === "standard" ? "-10px" : "5px",
                    fontWeight: size === "standard" ? "normal" : "bold",
                    lineHeight: size === "standard" ? "1.2" : "1.5",
                  }}
                >
                  {subtext}
                </p>
              )}
            </div>
            {jumpToAnchor?.type && (
              <p
                className="btn-text"
                style={{ textAlign: "center", marginTop: 16, marginLeft: 10 }}
                onClick={(e) =>
                  handleAnchorClick(
                    e,
                    jumpToAnchor.type === "TESTIMONIAL"
                      ? jumpToAnchor.properties[0].unique_id
                      : jumpToAnchor.properties.unique_id
                  )
                }
              >
                {jumpToAnchor.type}
              </p>
            )}
          </div>

          {emojis  ? (
            <div
              style={{
                borderRadius:
                  buttonShape === "rounded"
                    ? "50%"
                    : buttonShape === "curvedRectangle"
                    ? "20px"
                    : "10px",
                marginRight: thumbnailPosition === "left" ? "8px" : "0px",
                marginLeft: thumbnailPosition === "right" ? "8px" : "0px",
                border: buttonStyle === "outline" ? "1px solid #ddd" : "none",
                backgroundColor: "transparent",
                padding: "0px 0px",
              }}
            >
              <span
                style={{
                  fontSize: size === "large" ? "40px" : "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {emojis ? emojis : emoji}
              </span>
            </div>
          ) : thumbnail ? (
            <img
              src={
                thumbnail.startsWith("http://") ||
                thumbnail.startsWith("https://")
                  ? thumbnail
                  : `${API_URL}/flowpage/${thumbnail}`
              }
              alt={label || "Button image"}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://via.placeholder.com/40x40?text=Error";
              }}
              style={{
                width: size === "large" ? "60px" : "40px",
                height: size === "large" ? "60px" : "40px",
                borderRadius:
                  buttonShape === "rounded"
                    ? "50%"
                    : buttonShape === "curvedRectangle"
                    ? "20px"
                    : "10px",
                objectFit: "cover",
                marginRight: thumbnailPosition === "left" ? "8px" : "0px",
                marginLeft: thumbnailPosition === "right" ? "8px" : "0px",
              }}
            />
          ) : null}
        </div>
      </a>
    </div>
  );
}

export default BuilderButton;
