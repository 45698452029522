import React from "react";
import { BsStars } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Event(props) {

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    return (
        <>
            <div className="event-section" id={props.data.unique_id}>
                <div className="event-section-inner">
                    <div className="event-tper-sec">
                        <div className="event-type-box">
                            <div className="event-type-icon">
                                <BsStars />
                            </div>
                            <div className="event-type-text">
                            {props.data.eventType} Event
                            </div>
                        </div>
                    </div>
                    <div className="event-title">
                        <h2 className="m-0">{props.data.eventTitle}</h2>
                    </div>
                    <div className="event-date-sec">
                        <div className="event-date-inner">
                            <div className="date-icon">
                                <div className="month-name">
                                    MAR
                                </div>
                                <div className="day-name">
                                    20
                                </div>
                            </div>
                            <div className="event-date-text">
                                <div className="event-date">
                                    Wednesday, March 20
                                </div>
                                <div className="event-time">
                                    10:00 AM - 11:00 AM
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="event-location-sec mt-2">
                        <div className="event-date-inner">
                            <div className="date-icon d-flex align-items-center justify-content-center">
                                <MdLocationOn />
                            </div>
                            <div className="event-date-text">
                                {/* <div className="event-date">
                                    Mel's Drive-In
                                </div>
                                <div className="event-time">
                                    San Francisco, California
                                </div> */}
                                <div className="event-date">
                                    {props.data.location}
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="Registration-sec">
                        <div className="registration-inner">
                            <div className="registration-header">
                                Registration
                            </div>
                            <div className="inner">
                                <div className="jsx-3830652295">
                                    <div className="jsx-3830652295 flex-column content">
                                        <div className="jsx-1768947162 info-rows flex-column one">
                                            <div className="jsx-1768947162 d-flex align-items-center flex-align one">
                                                <div className="icon-box">
                                                    <BsStars />
                                                </div>
                                                <div className="event-date-text">
                                                    <div className="event-date">
                                                        Approval Required
                                                    </div>
                                                    <div className="event-time">
                                                        Your registration is subject to approval by the host.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="jsx-3363824362 message">Welcome! To join the event, please register below. </div>
                                        <div className="jsx-1768947162 d-flex align-items-center flex-align one">
                                            <div className="icon-box-img">
                                                <div className="bg-avtar"></div>
                                            </div>
                                            <div className="event-date-text">
                                                <div className="event-date">
                                                    Your Name
                                                </div>
                                                <div className="event-time">
                                                    emp2022opti@gmail.com
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn one-click">One Click Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="event-description-sec mt-3">
                        <div className="event-description-title event-xyt">
                            About Event
                        </div>
                        <div className="event-description-text">
                         {props.data.description}
                        </div>
                    </div>
                    <div className="event-description-sec location mt-3">
                        <div className="event-description-title event-xyt">
                            Location
                        </div>
                        <div className="event-description-text">

                            <p>{props.data.location}</p>
                            {/* <h5>Mel's Drive-In</h5>
                            <p className="">801 Mission St, San Francisco, CA 94103, USA
                                We will try and meet Mel's Diner on Mission St. Look for Bobby Torres or Spencer Kersch. Text this number if you are lost (954) 558-4754</p> */}
                        </div>
                        <div style={{ height: '200px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "" }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                className="map-container"
                            >
                                <AnyReactComponent
                                    lat={59.955413}
                                    lng={30.337844}
                                    text="My Marker"
                                />
                            </GoogleMapReact>
                        </div>
                    </div>
                    <div className="event-description-sec mt-3">
                        <div className="event-description-title event-xyt">
                            Hosted By
                        </div>
                        <div className="hosted-by-sec">
                            <div className="hosted-inner">
                                <div className="host-left">
                                    <div className="hosted-img">
                                        <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                    </div>
                                    <div className="hosted-text">
                                        <div className="hosted-name">
                                            Mark Smith
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="event-description-sec mt-4">
                        <div className="event-description-title event-xyt">
                            14 Going
                        </div>
                        <div className="total-people-sec">
                            <div className="total-people-inner pb-2">
                                <div className="total-people-img">
                                    <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                </div>
                                <div className="total-people-img">
                                    <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                </div>
                                <div className="total-people-img">
                                    <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                </div>
                                <div className="total-people-img">
                                    <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                </div>
                                <div className="total-people-img">
                                    <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                </div>
                                <div className="total-people-img">
                                    <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                </div>
                            </div>
                            <div class="jsx-2911588165 text-tinted fs-sm guests-string animated">Phoebe Elefante, Sean Pinnock and 12 others</div>
                            <br />
                            <div className="contact-host">
                                <button className="btn contact-host-btn btn-event-page">Contact the Host</button>
                            </div>
                            <div className="report-host">
                                <button className="btn report-host-btn btn-event-page">Report the Host</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Event;