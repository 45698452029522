import React, { useEffect, useState } from "react";
import "./Brand.css";
import { IoIosArrowBack } from "react-icons/io";
import { MdSend } from "react-icons/md";
import { MdFacebook } from "react-icons/md";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import { FaPinterest, FaTiktok } from "react-icons/fa";
import { HiLink } from "react-icons/hi";
import { Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import InputGroup from "react-bootstrap/InputGroup";
import qrOptiions from "../Components/Login/defaultQr.json";
import { createBrandApi } from "../BackendApi/Api/brandApi";
import { AiFillCheckCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import VenueComponent from "./VenueComponent";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";

const api_url = process.env.REACT_APP_API_URL;

const brand_categories = [
  "Technology Company",
  "Healthcare Company",
  "Financial Services Company",
  "Retail Company",
  "Manufacturing Company",
  "Energy Company",
  "Telecommunications Company",
  "Transportation and Logistics Company",
  "Media and Entertainment Company",
  "Hospitality and Leisure Company",
  "Real Estate Company",
  "Agriculture Company",
  "Construction Company",
  "Education and Training Company",
  "Non-Profit Organization",
  "Government Entity",
  "Consulting Firm",
  "Legal Services Firm",
  "Marketing and Advertising Agency",
  "Food and Beverage Company",
  "Pharmaceutical Company",
  "Biotechnology Company",
  "Automotive Company",
  "Aerospace and Defense Company",
  "Environmental Services Company",
  "Fashion and Apparel Company",
  "Consumer Goods Company",
  "Publishing Company",
  "Sports and Recreation Company",
  "Travel and Tourism Company",
  "Art and Design Company",
  "Event Planning Company",
  "Research and Development Company",
  "Software Development Company",
  "Hardware Manufacturing Company",
  "E-commerce Company",
  "Start-up",
  "Family-Owned Business",
  "Multinational Corporation",
  "Small and Medium-sized Enterprise (SME)",
  "Franchise",
  "Cooperative",
  "Sole Proprietorship",
  "Partnership",
];

const leftUserSelect = [
  "Company_name",
  "brand_select",
  "contact_details",
  "brand_description",
  "socials_details",
  "logo_details",
  "final_step",
];

function CreateBrand() {
  const history = useHistory();

  const userdata = JSON.parse(localStorage.getItem("user"));

  const [venueName, setVenueName] = useState("");

  const [isVenue, setIsVenue] = useState(false);

  const [venueAddress, setVenueAddress] = useState({
    selectedTimezone: "",
    eventType: "Public",
    description: "",
    hostedImage: "",
    eventTitle: "",
    startDate: "",
    hostName: "",
    location: "",
    Zipcode: "",
    endDate: "",
    email: [],
    City: "",
    locationType: "",
    country: "",
    lat: "",
    lng: "",
    venue_name: "",
  });

  const handleVenueName = (name) => {
    setVenueName(name);
  };

  const handleVenueAddress = (address) => {
    setVenueAddress((prev) => ({
      ...prev,
      ...address,
    }));
  };

  const [loaderStatus, setLoaderStatus] = useState();

  const [mainINputDisabled, setMainInputDisabled] = useState(false);

  const [companyList, setCompanyList] = useState([]);

  const [userResponseSection, setUserResponseSection] = useState(false);

  const [logoSuggestions, setLogosuggestions] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedContact, setSelectedContact] = useState(0);
  const [logoLogo, setLogoLogo] = useState(0);

  const [activeJhingalala, setActiveJhingalala] = useState("");

  const [userInput, setUserInput] = useState({
    company_name: "",
  });

  const [userSelectedLogo, setUserSelectedLogo] = useState(null);

  const [rightSideSection, setRightSideSection] = useState({
    company_name: false,
    category: false,
    email: false,
    phone: false,
    address: false,
    description: false,
    facebook: false,
    twitter: false,
    instagram: false,
    website: false,
    pinterest: false,
    tiktok: false,
    other: false,
    venue: false,
  });

  const [companyData, setCompanyData] = useState({
    company_name: "",
    category: [],
    email: "",
    phone: "",
    address: "",
    description: "",
    facebook: "",
    twitter: "",
    instagram: "",
    website: "",
    pinterest: "",
    tiktok: "",
    other: "N/A",
    logo_light: "",
    logo_dark: "",
    logo_full: "",
    logo_symbolic: "",
    venue_name: "",
    venue_address: "",
  });

  const [extraBooleans, setExtraBooleans] = useState({
    company_select: false,
    company_not_selected: false,
    show_user_image: true,
    show_input: true,
    category_select: false,
    category_not_selected: false,
    logo_not_selected: false,
    contact_select: false,
    description_select: false,
    socials_select: false,
    logo_select: false,
    ask_email: false,
    ask_phone: false,
    ask_address: false,
    ask_description: false,
    enter_email: false,
    enter_phone: false,
    enter_address: false,
    enter_description: false,
    ask_website: false,
    ask_facebook: false,
    ask_twitter: false,
    ask_instagram: false,
    ask_pinterest: false,
    ask_tiktok: false,
    ask_other: false,
    enter_website: false,
    enter_facebook: false,
    enter_twitter: false,
    enter_instagram: false,
    enter_pinterest: false,
    enter_tiktok: false,
    enter_other: false,
    ask_logo: false,
    enter_logo: false,
    venue: false,
    ask_venue: false,
    enter_venue: false,
    final_step: false,
    final_submit: false,
    final_message: false,
  });

  const [questionType, setQuestionType] = useState("Company_name");
  // const [questionType, setQuestionType] = useState("venue");

  const [loading, setLoading] = useState(false);

  const continueVenue = () => {
    if (venueName == "") {
      PNotify.error({
        text: "Please enter venue name",
        delay: 2000,
        shadow: true,
        styling: "material",
        icon: "material",
        modules: {
          Desktop: {
            desktop: true,
          },
        },
      });
      return false;
    }

    if (venueAddress.location == "") {
      PNotify.error({
        text: "Please enter venue address",
        delay: 2000,
        shadow: true,
        styling: "material",
        icon: "material",
        modules: {
          Desktop: {
            desktop: true,
          },
        },
      });
      return false;
    }

    setExtraBooleans({
      ...extraBooleans,
      venue: false,
      enter_venue: false,
      ask_venue: false,
      final_step: true,
      final_submit: true,
    });

    setQuestionType("final_step");
    setIsVenue(true);
  };

  const getcompanynames = async () => {
    const response = await fetch(`${api_url}/branding/getcompanylist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ company_name: userInput.company_name }),
    });

    const result = await response.json();

    if (result.status === true) {
      setLoading(false);
      setCompanyList(result.data.companies);
      setExtraBooleans({
        ...extraBooleans,
        company_select: true,
      });
      setCompanyData({ ...companyData, company_name: "" });
      setUserResponseSection(true);
    } else {
      setCompanyList([userInput.company_name]);
      setUserResponseSection(true);
    }
  };

  const getcontactdetails = async (company) => {
    setLoading(true);
    const response = await fetch(`${api_url}/branding/getcontactdetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ company_name: company }),
    });

    const result = await response.json();

    if (result.status === true) {
      setCompanyData({
        ...companyData,
        email: result.data.contacts.email || "xyz@gmail.com",
        phone: result.data.contacts.phone || "1234567890",
        address: result.data.contacts.address || "xyz",
      });

      setExtraBooleans({
        ...extraBooleans,
        category_select: false,
        contact_select: true,
        ask_email: true,
      });

      setLoading(false);
    } else {
      setCompanyData({
        ...companyData,
        email: "xyz@gmail.com",
        phone: "1234567890",
        address: "xyz",
      });

      setExtraBooleans({
        ...extraBooleans,
        category_select: false,
        contact_select: true,
        ask_email: true,
      });

      setLoading(false);
    }
  };

  const getbrandsocials = async (company) => {
    setLoading(true);

    setExtraBooleans({
      ...extraBooleans,
      getting_contact_details: true,
    });
    const response = await fetch(`${api_url}/branding/getbrandsocials`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ company_name: company }),
    });

    const result = await response.json();

    if (result.status === true) {
      setCompanyData({
        ...companyData,
        facebook: result.data.socials.facebook,
        twitter: result.data.socials.twitter,
        instagram: result.data.socials.instagram,
        pinterest: result.data.socials.pinterest,
        tiktok: result.data.socials.tiktok,
        website: result.data.socials.website,
      });

      setExtraBooleans({
        ...extraBooleans,
        ask_website: true,
        socials_select: true,
      });

      setLoading(false);
    } else {
      const modified_company = companyData.company_name.replace(
        /[^a-zA-Z0-9]/g,
        ""
      );

      setCompanyData({
        ...companyData,
        facebook: `https://www.facebook.com/${modified_company}`,
        twitter: `https://twitter.com/${modified_company}`,
        instagram: `https://www.instagram.com/${modified_company}`,
        pinterest: `https://www.pinterest.com/${modified_company}`,
        tiktok: `https://www.tiktok.com/@${modified_company}`,
        website: `https://${modified_company}.com`,
      });

      setExtraBooleans({
        ...extraBooleans,
        ask_website: true,
        socials_select: true,
      });

      setLoading(false);
    }
  };

  const getbrandDescription = async (company, category) => {
    setLoading(true);
    const response = await fetch(`${api_url}/branding/getbranddescription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_name: company,
        company_category: category,
      }),
    });

    const result = await response.json();

    if (result.status === true) {
      setCompanyData({
        ...companyData,
        description: result.data.description || "company description",
      });
      setLoading(false);
    } else {
      setCompanyData({
        ...companyData,
        description: "coompany description",
      });
      setLoading(false);
    }
  };

  const getbrandlogo = async (company) => {
    setLoading(true);
    const response = await fetch(`${api_url}/branding/getbrandlogosuggestons`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ company_name: company }),
    });

    const result = await response.json();

    if (result.status === true) {
      setLogosuggestions(result.data);

      setExtraBooleans({
        ...extraBooleans,
        // enter_logo: true
        ask_logo: true,
      });

      setLoading(false);
    } else {
      setExtraBooleans({
        ...extraBooleans,
        enter_logo: true,
      });

      setLoading(false);
    }

    console.log("image suggestion resutl", result);
  };

  const handleLogoUpload = async () => {
    setLoading(true);

    const formData = new FormData();

    formData.append("logo", userSelectedLogo);

    const response = await fetch(`${api_url}/branding/uploadsingle`, {
      method: "POST",
      body: formData,
    });

    const result = await response.json();

    if (result.status === true) {
      setCompanyData({
        ...companyData,
        logo_light: result.data.filename,
        logo_dark: result.data.filename,
        logo_full: result.data.filename,
        logo_symbolic: result.data.filename,
      });

      setExtraBooleans({
        ...extraBooleans,
        logo_select: false,
        ask_logo: false,
        enter_logo: false,
        venue: true,
        ask_venue: true,
        // final_step: true,
        // final_submit: true
      });

      setQuestionType("final_step");

      setLoading(false);
    } else {
      setCompanyData({
        ...companyData,
        logo_light: logoSuggestions[0],
        logo_dark: logoSuggestions[0],
        logo_full: logoSuggestions[0],
        logo_symbolic: logoSuggestions[0],
      });

      setExtraBooleans({
        ...extraBooleans,
        logo_select: false,
        ask_logo: false,
        enter_logo: false,
      });

      setQuestionType("final_step");

      setLoading(false);
    }
  };

  const handleCreateBrand = async () => {
    try {
      setLoading(true);

      const brandata = {
        company_name: companyData.company_name,
        company_type: companyData.category,
        description: companyData.description,
        address: companyData.address,
        email: companyData.email,
        phone: companyData.phone,
        logo_light: companyData.logo_light,
        logo_dark: companyData.logo_dark,
        logo_full: companyData.logo_full,
        logo_symbolic: companyData.logo_symbolic,
        primary_color: "#000000",
        secondary_color: "#000000",
        additional_color_1: "#000000",
        additional_color_2: "#000000",
        facebook_url: companyData.facebook,
        twitter_url: companyData.twitter,
        instagram_url: companyData.instagram,
        website_url: companyData.website,
        pinterest_url: companyData.pinterest,
        tiktok_url: companyData.tiktok,
        other_url: companyData.other,
        owner_id: userdata.owner_id,
        creator_name: userdata.fname + "" + userdata.lname,
        creator_image: userdata.profile_image,
        created_by: userdata._id,
        qr_options: JSON.stringify(qrOptiions),
      };
      if (isVenue) {
        brandata.venue_details = {
          name: venueName,
          address: venueAddress,
        };
      }

      createBrandApi(brandata).then((response) => {
        if (response.data.status === true) {
          setExtraBooleans({
            ...extraBooleans,

            final_submit: false,
            final_message: true,
          });

          setLoading(false);
          handleRedirect();

          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } catch (e) {
      PNotify.error({
        text: e.message,
        delay: 2000,
        shadow: true,
        styling: "material",
        icon: "material",
        modules: {
          Desktop: {
            desktop: true,
          },
        },
      });
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/custombrand");
    }, 2000);
  };

  const handleBrandCategory = (selected) => {
    setCompanyData({ ...companyData, category: selected });
  };

  useEffect(() => {
    if (selectedCompany != 0) {
      getcontactdetails(selectedCompany.company_name);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCategory != 0) {
      getbrandDescription(
        selectedCategory.company_name,
        selectedCategory.category
      );
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedContact != 0) {
      getbrandsocials(selectedContact.company_name);
    }
  }, [selectedContact]);

  useEffect(() => {
    if (logoLogo != 0) {
      getbrandlogo(logoLogo.company_name);
    }
  }, [logoLogo]);

  const checkbrandexist = async () => {
    try {
      setLoading(true);

      const response = await fetch(`${api_url}/branding/checkbrandexist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company_name: companyData.company_name,
        }),
      });

      const result = await response.json();

      if (result.status) {
        setLoading(false);
        alert("Brand already exist");
      } else {
        setExtraBooleans((extraBooleans) => {
          return {
            ...extraBooleans,
            show_input: false,
            company_select: false,
            category_select: true,
          };
        });
        console.log("extraBooleans", extraBooleans);
        setQuestionType("brand_select");
        setRightSideSection({
          ...rightSideSection,
          company_name: true,
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="auto_brand_container container_grid sidebar_box">
        <div className="auto_brand_card">
          <div className="auto_brand_card_body">
            <div className="auto_brand_header">
              <div className="header_left">
                <div className="back-btn-logo">
                  <div
                    className="back_icon"
                    onClick={() => history.push("/custombrand")}
                  >
                    <IoIosArrowBack />
                  </div>
                  <div className="back_logo">
                    <img
                      src={require("../assets/images/logo/Astral.png")}
                      alt="qr"
                    />
                  </div>
                  {/* <Button
                                        onClick={() => getbrandlogo(companyData.company_name)}
                                    >get logo</Button> */}
                </div>
              </div>
            </div>

            <div className="chat_body auto_brand_body">
              <div className="chat__chatWidget app__base">
                <div className="ai_chat">
                  <div className="ai_chat_box">
                    <div class="D1N9DI"></div>
                    <div className="chat__messages">
                      {questionType === "Company_name" ? (
                        <>
                          <div className="chat__message__light">Hi!</div>
                          <div className="chat__message__dark">
                            Let’s get started with your brand!
                          </div>
                          <div className="chat__message__dark">
                            First things first, what’s your brand name?
                          </div>
                        </>
                      ) : questionType === "brand_select" ? (
                        <div className="chat__message__dark">
                          Please select brand Category.
                        </div>
                      ) : questionType === "contact_details" ? (
                        <>
                          <div className="chat__message__dark">
                            Let’s get your contact details organized.
                          </div>

                          {extraBooleans.ask_email && (
                            <div className="chat__message__dark">
                              Is Your Email below correct ?
                            </div>
                          )}

                          {extraBooleans.enter_email && (
                            <div className="chat__message__dark">
                              Please enter your email ?
                            </div>
                          )}

                          {extraBooleans.ask_phone && (
                            <div className="chat__message__dark">
                              Is your public phone number correct?
                            </div>
                          )}

                          {extraBooleans.enter_phone && (
                            <div className="chat__message__dark">
                              Please enter your public phone number ?
                            </div>
                          )}
                          {extraBooleans.ask_address && (
                            <div className="chat__message__dark">
                              Is Your address below correct ?
                            </div>
                          )}

                          {extraBooleans.enter_address && (
                            <div className="chat__message__dark">
                              Please enter your address ?
                            </div>
                          )}
                        </>
                      ) : questionType === "brand_description" ? (
                        <>
                          {extraBooleans.ask_description && (
                            <div className="chat__message__dark">
                              Is your brand description below correct ?
                            </div>
                          )}

                          {extraBooleans.enter_description && (
                            <div className="chat__message__dark">
                              Please enter your brand description ?
                            </div>
                          )}
                        </>
                      ) : questionType === "socials_details" ? (
                        <>
                          <div className="chat__message__dark">
                            Let's explore your presence on social media.
                          </div>

                          {extraBooleans.ask_website && (
                            <div className="chat__message__dark">
                              Is Your website below correct ?
                            </div>
                          )}

                          {extraBooleans.enter_website && (
                            <div className="chat__message__dark">
                              Please enter your website ?
                            </div>
                          )}

                          {extraBooleans.ask_facebook && (
                            <div className="chat__message__dark">
                              Is Your facebook below correct ?
                            </div>
                          )}

                          {extraBooleans.enter_facebook && (
                            <div className="chat__message__dark">
                              Please enter your facebook ?
                            </div>
                          )}

                          {extraBooleans.ask_twitter && (
                            <div className="chat__message__dark">
                              Is Your X (formerly Twitter) below correct ?
                            </div>
                          )}

                          {extraBooleans.enter_twitter && (
                            <div className="chat__message__dark">
                              Please enter your X (formerly Twitter) ?
                            </div>
                          )}

                          {extraBooleans.ask_instagram && (
                            <div className="chat__message__dark">
                              Is Your instagram below correct ?
                            </div>
                          )}

                          {extraBooleans.enter_instagram && (
                            <div className="chat__message__dark">
                              Please enter your instagram ?
                            </div>
                          )}

                          {extraBooleans.ask_pinterest && (
                            <div className="chat__message__dark">
                              Is Your pinterest below correct ?
                            </div>
                          )}

                          {extraBooleans.enter_pinterest && (
                            <div className="chat__message__dark">
                              Please enter your pinterest ?
                            </div>
                          )}

                          {extraBooleans.ask_tiktok && (
                            <div className="chat__message__dark">
                              Is Your tiktok below correct ?
                            </div>
                          )}

                          {extraBooleans.enter_tiktok && (
                            <div className="chat__message__dark">
                              Please enter your tiktok ?
                            </div>
                          )}

                          {extraBooleans.enter_other && (
                            <div className="chat__message__dark">
                              Please any other url yout to share with us.
                            </div>
                          )}
                        </>
                      ) : questionType === "logo_details" ? (
                        <>
                          {extraBooleans.ask_logo && (
                            <div className="chat__message__dark">
                              Select a logo among the folllowing options.
                            </div>
                          )}

                          {extraBooleans.enter_logo && (
                            <div className="chat__message__dark">
                              Please enter your brand logo.
                            </div>
                          )}
                        </>
                      ) : questionType === "venue" ? (
                        <>
                          {extraBooleans.ask_venue && (
                            <div className="chat__message__dark">
                              Do you have a venue ?
                            </div>
                          )}
                          {extraBooleans.enter_venue && (
                            <div className="chat__message__dark">
                              Please enter your venue details.
                            </div>
                          )}
                        </>
                      ) : questionType === "final_step" ? (
                        <>
                          <div className="chat__message__dark">
                            Hang tight, we’re almost done!
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>

                  {loading ? (
                    <div className="ai_chat_box mt-3">
                      <div class="D1N9DI"></div>
                      <div className="chat__messages">
                        <div className="loader_gif">
                          <img
                            src={require("../assets/images/loader.gif")}
                            alt="qr"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {userResponseSection && (
                        <div className="user_chat_box">
                          {extraBooleans.show_user_image && (
                            <div class="user-profile">
                              <img
                                src={`${api_url}/profile/${userdata.profile_image}`}
                                alt="qr"
                              />
                            </div>
                          )}

                          <div className="chat__messages w-100">
                            {extraBooleans.company_select && (
                              <>
                                {companyList &&
                                  companyList.map((company, index) => (
                                    <Form.Check
                                      type="radio"
                                      label={company}
                                      name="companyRadio"
                                      id={`companyRadio-${index}`}
                                      key={index}
                                      value={company}
                                      onChange={(e) => {
                                        setCompanyData({
                                          ...companyData,
                                          company_name: e.target.value,
                                        });
                                      }}
                                    />
                                  ))}
                                {companyData.company_name == "" &&
                                  extraBooleans.company_not_selected && (
                                    <p style={{ color: "red" }}>
                                      Please select your company.
                                    </p>
                                  )}

                                <Button
                                  className="btn btn-sm float-right add-user-btn btn btn-primary mt-3"
                                  onClick={() => {
                                    if (companyData.company_name == "") {
                                      setExtraBooleans({
                                        ...extraBooleans,
                                        company_not_selected: true,
                                      });
                                      return;
                                    }

                                    checkbrandexist();
                                  }}
                                >
                                  Next
                                </Button>
                              </>
                            )}

                            {extraBooleans.category_select && (
                              <>
                                <div className="form-group did-floating-label-content">
                                  <Typeahead
                                    id="brandTypeahead"
                                    labelKey="company_name" // Display label (company_name)
                                    multiple
                                    options={brand_categories} // Use the transformed data
                                    selected={companyData.category}
                                    onChange={handleBrandCategory}
                                    className="did-floating-select-custom w-100"
                                    placeholder="Select Brands"
                                  />
                                  {/* <label for="exampleFormControlInput1" className="form-label did-floating-label">Select Brands</label> */}
                                </div>
                                {companyData.category.length == 0 &&
                                  extraBooleans.category_not_selected && (
                                    <p style={{ color: "red" }}>
                                      Please select atleast one category.
                                    </p>
                                  )}

                                <Button
                                  className="btn btn-sm float-right add-user-btn btn btn-primary"
                                  onClick={() => {
                                    if (companyData.category.length == 0) {
                                      setExtraBooleans({
                                        ...extraBooleans,
                                        category_not_selected: true,
                                      });
                                      return;
                                    }
                                    setExtraBooleans({
                                      ...extraBooleans,
                                      category_select: false,
                                    });

                                    setRightSideSection({
                                      ...rightSideSection,
                                      category: true,
                                    });

                                    setQuestionType("contact_details");
                                    setSelectedCompany(companyData);
                                  }}
                                >
                                  Next
                                </Button>
                              </>
                            )}

                            {extraBooleans.contact_select && (
                              <>
                                {extraBooleans.ask_email && (
                                  <>
                                    <div className="chat__message__light mb-2">
                                      {companyData.email}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_email: false,
                                            ask_phone: true,
                                          });
                                          setRightSideSection({
                                            ...rightSideSection,
                                            email: true,
                                          });
                                        }}
                                      >
                                        Yes
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            show_input: true,
                                            ask_email: false,
                                            enter_email: true,
                                            show_user_image: false,
                                          });
                                        }}
                                      >
                                        No
                                      </Button>
                                    </div>
                                  </>
                                )}

                                {extraBooleans.ask_phone && (
                                  <>
                                    {/* <div className="blank"></div> */}
                                    <div className="chat__message__light mb-2">
                                      {companyData.phone}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_phone: false,
                                            ask_address: true,
                                          });
                                          setRightSideSection({
                                            ...rightSideSection,
                                            phone: true,
                                          });
                                        }}
                                      >
                                        Yes
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            show_input: true,
                                            ask_phone: false,
                                            enter_phone: true,
                                            show_user_image: false,
                                          });
                                        }}
                                      >
                                        No
                                      </Button>
                                    </div>
                                  </>
                                )}

                                {extraBooleans.ask_address && (
                                  <>
                                    {/* <div className="blank"></div> */}
                                    <div className="chat__message__light mb-2">
                                      {companyData.address}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_address: false,
                                            ask_description: true,
                                            description_select: true,
                                          });

                                          setQuestionType("brand_description");

                                          setRightSideSection({
                                            ...rightSideSection,
                                            address: true,
                                          });

                                          setSelectedCategory(companyData);
                                        }}
                                      >
                                        Yes
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            show_input: true,
                                            ask_address: false,
                                            enter_address: true,
                                            show_user_image: false,
                                          });
                                        }}
                                      >
                                        No
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}

                            {extraBooleans.description_select && (
                              <>
                                {extraBooleans.ask_description && (
                                  <>
                                    {/* <div className="blank"></div> */}
                                    {/* <div className="chat__message__light mb-2">{companyData.description}</div>
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                <Button
                                                                                    className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                                                                    onClick={() => {
                                                                                        setExtraBooleans({ ...extraBooleans, ask_email: false, ask_phone: true })
                                                                                        setRightSideSection({
                                                                                            ...rightSideSection,
                                                                                            description: true
                                                                                        })
                                                                                    }}>Yes</Button>

                                                                                <Button
                                                                                    className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                                                                    onClick={() => {
                                                                                        setExtraBooleans({ ...extraBooleans, ask_email: false, enter_email: true })
                                                                                    }}
                                                                                >No</Button>
                                                                            </div>
                                                                            <div className="blank"></div> */}
                                    <div className="chat__message__light mb-2">
                                      {companyData.description}
                                    </div>
                                    <Button
                                      className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                      onClick={() => {
                                        setExtraBooleans({
                                          ...extraBooleans,
                                          ask_description: false,
                                          description_select: false,
                                          socials_select: true,
                                        });
                                        setQuestionType("socials_details");

                                        setRightSideSection({
                                          ...rightSideSection,
                                          description: true,
                                        });

                                        setSelectedContact(companyData);
                                      }}
                                    >
                                      continue
                                    </Button>

                                    {/* <Button
                                                                                onClick={() => {
                                                                                    setExtraBooleans({ ...extraBooleans, ask_email: false, enter_email: true })
                                                                                }}
                                                                            >no</Button> */}
                                  </>
                                )}
                              </>
                            )}

                            {extraBooleans.socials_select && (
                              <>
                                {extraBooleans.ask_website && (
                                  <>
                                    <div className="chat__message__light mb-2">
                                      {companyData.website}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_website: false,
                                            ask_facebook: true,
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            website: true,
                                          });
                                        }}
                                      >
                                        yes
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_website: false,
                                            enter_website: true,
                                            show_user_image: false,
                                            show_input: true,
                                          });
                                        }}
                                      >
                                        no
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_website: false,
                                            ask_facebook: true,
                                          });

                                          setCompanyData({
                                            ...companyData,
                                            website: "N/A",
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            website: true,
                                          });
                                        }}
                                      >
                                        dont have
                                      </Button>
                                    </div>
                                  </>
                                )}

                                {extraBooleans.ask_facebook && (
                                  <>
                                    <div className="chat__message__light mb-2">
                                      {companyData.facebook}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_twitter: true,
                                            ask_facebook: false,
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            facebook: true,
                                          });
                                        }}
                                      >
                                        yes
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_facebook: false,
                                            enter_facebook: true,
                                            show_user_image: false,
                                            show_input: true,
                                          });
                                        }}
                                      >
                                        no
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_twitter: true,
                                            ask_facebook: false,
                                          });

                                          setCompanyData({
                                            ...companyData,
                                            facebook: "N/A",
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            facebook: true,
                                          });
                                        }}
                                      >
                                        dont have
                                      </Button>
                                    </div>
                                  </>
                                )}

                                {extraBooleans.ask_twitter && (
                                  <>
                                    <div className="chat__message__light mb-2">
                                      {companyData.twitter}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_twitter: false,
                                            ask_instagram: true,
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            twitter: true,
                                          });
                                        }}
                                      >
                                        yes
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_twitter: false,
                                            enter_twitter: true,
                                            show_user_image: false,
                                            show_input: true,
                                          });
                                        }}
                                      >
                                        no
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_twitter: false,
                                            ask_instagram: true,
                                          });

                                          setCompanyData({
                                            ...companyData,
                                            twitter: "N/A",
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            twitter: true,
                                          });
                                        }}
                                      >
                                        dont have
                                      </Button>
                                    </div>
                                  </>
                                )}

                                {extraBooleans.ask_instagram && (
                                  <>
                                    <div className="chat__message__light mb-2">
                                      {companyData.instagram}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_pinterest: true,
                                            ask_instagram: false,
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            instagram: true,
                                          });
                                        }}
                                      >
                                        yes
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_instagram: false,
                                            enter_instagram: true,
                                            show_user_image: false,
                                            show_input: true,
                                          });
                                        }}
                                      >
                                        no
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_pinterest: true,
                                            ask_instagram: false,
                                          });

                                          setCompanyData({
                                            ...companyData,
                                            instagram: "N/A",
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            instagram: true,
                                          });
                                        }}
                                      >
                                        dont have
                                      </Button>
                                    </div>
                                  </>
                                )}

                                {extraBooleans.ask_pinterest && (
                                  <>
                                    <div className="chat__message__light mb-2">
                                      {companyData.pinterest}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_pinterest: false,
                                            ask_tiktok: true,
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            pinterest: true,
                                          });
                                        }}
                                      >
                                        yes
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_pinterest: false,
                                            enter_pinterest: true,
                                            show_user_image: false,
                                            show_input: true,
                                          });
                                        }}
                                      >
                                        no
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_pinterest: false,
                                            ask_tiktok: true,
                                          });

                                          setCompanyData({
                                            ...companyData,
                                            pinterest: "N/A",
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            pinterest: true,
                                          });
                                        }}
                                      >
                                        dont have
                                      </Button>
                                    </div>
                                  </>
                                )}

                                {extraBooleans.ask_tiktok && (
                                  <>
                                    <div className="chat__message__light mb-2">
                                      {companyData.tiktok}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            enter_other: true,
                                            ask_tiktok: false,
                                            show_input: true,
                                            show_user_image: false,
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            tiktok: true,
                                          });
                                        }}
                                      >
                                        yes
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_tiktok: false,
                                            enter_tiktok: true,
                                            show_user_image: false,
                                            show_input: true,
                                          });
                                        }}
                                      >
                                        no
                                      </Button>

                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            enter_other: true,
                                            ask_tiktok: false,
                                            show_input: true,
                                            show_user_image: false,
                                          });

                                          setCompanyData({
                                            ...companyData,
                                            tiktok: "N/A",
                                          });

                                          setRightSideSection({
                                            ...rightSideSection,
                                            tiktok: true,
                                          });
                                        }}
                                      >
                                        dont have
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}

                            {extraBooleans.logo_select && (
                              <>
                                {extraBooleans.ask_logo && (
                                  <>
                                    <div className="row scrollable-section">
                                      {logoSuggestions &&
                                        logoSuggestions.map((item, index) => {
                                          return (
                                            <>
                                              <div
                                                class="col-lg-4 col-md-12 mb-4 mb-lg-0 "
                                                onClick={() => {
                                                  setCompanyData({
                                                    ...companyData,
                                                    logo_light: item,
                                                    logo_dark: item,
                                                    logo_full: item,
                                                    logo_symbolic: item,
                                                  });
                                                  setActiveJhingalala(index);
                                                }}
                                              >
                                                <div className="image_list_logos ">
                                                  <img
                                                    className={
                                                      activeJhingalala === index
                                                        ? "blah_blah"
                                                        : ""
                                                    }
                                                    src={item}
                                                    alt="="
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </div>
                                    {extraBooleans.logo_not_selected &&
                                      (companyData.logo_light == "" ||
                                        companyData.logo_dark == "" ||
                                        companyData.logo_full == "" ||
                                        companyData.logo_symbolic == "") && (
                                        <p style={{ color: "red" }}>
                                          Please select logo before you
                                          countinue
                                        </p>
                                      )}

                                    <Button
                                      className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                      onClick={() => {
                                        if (
                                          companyData.logo_light == "" ||
                                          companyData.logo_dark == "" ||
                                          companyData.logo_full == "" ||
                                          companyData.logo_symbolic == ""
                                        ) {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            logo_not_selected: true,
                                          });
                                          return;
                                        }
                                        setExtraBooleans({
                                          ...companyData,
                                          logo_select: false,
                                          ask_logo: false,
                                          enter_logo: false,
                                          venue: true,
                                          ask_venue: true,
                                          // final_step: true,
                                          // final_submit: true
                                        });
                                        // setQuestionType("final_step");
                                        setQuestionType("venue");
                                      }}
                                    >
                                      {" "}
                                      Continue{" "}
                                    </Button>
                                    <div className="chat__message__light mb-2">
                                      <p>
                                        If your logo is not among these, then
                                        lets upload manually.
                                      </p>
                                      <Button
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,

                                            ask_logo: false,
                                            enter_logo: true,
                                          });
                                        }}
                                      >
                                        Upload Logo
                                      </Button>
                                    </div>
                                  </>
                                )}

                                {extraBooleans.enter_logo && (
                                  <>
                                    <div className="form-group did-floating-label-content">
                                      <input
                                        type="file"
                                        accept="image/*"
                                        className="form-control"
                                        onChange={(e) => {
                                          setUserSelectedLogo(
                                            e.target.files[0]
                                          );
                                        }}
                                      />
                                    </div>
                                    <Button
                                      onClick={() => {
                                        handleLogoUpload();
                                      }}
                                    >
                                      continue
                                    </Button>
                                  </>
                                )}
                              </>
                            )}

                            {extraBooleans.venue && (
                              <>
                                {extraBooleans.venue &&
                                  extraBooleans.ask_venue && (
                                    <>
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            ask_venue: false,
                                            enter_venue: true,
                                          });
                                        }}
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        className="btn btn-sm float-right add-user-btn btn btn-primary w-100"
                                        onClick={() => {
                                          setExtraBooleans({
                                            ...extraBooleans,
                                            venue: false,
                                            enter_venue: false,
                                            ask_venue: false,
                                            final_step: true,
                                            final_submit: true,
                                          });

                                          setQuestionType("final_step");
                                          setIsVenue(false);
                                        }}
                                      >
                                        No
                                      </Button>
                                    </>
                                  )}

                                {extraBooleans.enter_venue && (
                                  <>
                                    <VenueComponent
                                      handleVenueName={handleVenueName}
                                      handleVenueAddress={handleVenueAddress}
                                    />

                                    <Button
                                      onClick={() => {
                                        continueVenue();
                                      }}
                                    >
                                      continue
                                    </Button>
                                  </>
                                )}
                              </>
                            )}

                            {extraBooleans.final_step && (
                              <>
                                {extraBooleans.final_submit && (
                                  <>
                                    <p>Ready to finalize your brand ?</p>
                                    <p>
                                      Look to the right and check everything
                                      over before we save. You can update this
                                      in the BrandKit section later on too.
                                    </p>
                                    <Button
                                      onClick={() => {
                                        handleCreateBrand();
                                      }}
                                    >
                                      confirm
                                    </Button>
                                  </>
                                )}

                                {extraBooleans.final_message && (
                                  <>
                                    <div className="success-msg-box">
                                      <div className="success-msg-inner">
                                        <div className="success-msg-icon">
                                          <AiFillCheckCircle />
                                        </div>
                                        <div>
                                          <p className="success-msg-bold">
                                            Successfully
                                          </p>
                                          <p className="success-msg-text">
                                            Your brand has been created
                                            successfully !!
                                          </p>
                                          <p>redirecting you to brands page</p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="user-chat"></div>
                {extraBooleans.show_input && (
                  <div className="chat__input">
                    <div className="chat__input__container">
                      <div className="chat__input__input">
                        <input
                          type="text"
                          className="chat-input"
                          disabled={mainINputDisabled}
                          placeholder={
                            questionType === "Company_name"
                              ? "Enter Your Company Name"
                              : questionType == "contact_details" &&
                                extraBooleans.enter_email
                              ? "email"
                              : questionType == "contact_details" &&
                                extraBooleans.enter_phone
                              ? "phone"
                              : questionType == "contact_details" &&
                                extraBooleans.enter_address
                              ? "address"
                              : questionType == "socials_details" &&
                                extraBooleans.enter_website
                              ? "enter web website address"
                              : questionType == "socials_details" &&
                                extraBooleans.enter_facebook
                              ? "enter facebook address"
                              : questionType == "socials_details" &&
                                extraBooleans.enter_instagram
                              ? "enter instagram address"
                              : questionType == "socials_details" &&
                                extraBooleans.enter_twitter
                              ? "enter twitter address"
                              : questionType == "socials_details" &&
                                extraBooleans.enter_pinterest
                              ? "enter pinterest address"
                              : questionType == "socials_details" &&
                                extraBooleans.enter_tiktok
                              ? "enter tiktok address"
                              : ""
                          }
                          value={
                            questionType == "Company_name"
                              ? userInput.company_name
                              : questionType == "contact_details" &&
                                extraBooleans.enter_email
                              ? companyData.email
                              : questionType == "contact_details" &&
                                extraBooleans.enter_phone
                              ? companyData.phone
                              : questionType == "contact_details" &&
                                extraBooleans.enter_address
                              ? companyData.address
                              : questionType == "brand_description" &&
                                extraBooleans.enter_description
                              ? companyData.description
                              : questionType == "socials_details" &&
                                extraBooleans.enter_website
                              ? companyData.website
                              : questionType == "socials_details" &&
                                extraBooleans.enter_facebook
                              ? companyData.facebook
                              : questionType == "socials_details" &&
                                extraBooleans.enter_twitter
                              ? companyData.twitter
                              : questionType == "socials_details" &&
                                extraBooleans.enter_instagram
                              ? companyData.instagram
                              : questionType == "socials_details" &&
                                extraBooleans.enter_pinterest
                              ? companyData.pinterest
                              : questionType == "socials_details" &&
                                extraBooleans.enter_tiktok
                              ? companyData.tiktok
                              : questionType == "socials_details" &&
                                extraBooleans.enter_other
                              ? companyData.other
                              : ""
                          }
                          onChange={(e) => {
                            if (questionType === "Company_name") {
                              setUserInput({
                                ...userInput,
                                company_name: e.target.value,
                              });
                            }

                            if (
                              questionType === "contact_details" &&
                              extraBooleans.enter_email
                            ) {
                              setCompanyData({
                                ...companyData,
                                email: e.target.value,
                              });
                            }

                            if (
                              questionType === "contact_details" &&
                              extraBooleans.enter_phone
                            ) {
                              setCompanyData({
                                ...companyData,
                                phone: e.target.value,
                              });
                            }

                            if (
                              questionType === "contact_details" &&
                              extraBooleans.enter_address
                            ) {
                              setCompanyData({
                                ...companyData,
                                address: e.target.value,
                              });
                            }

                            if (
                              questionType === "brand_description" &&
                              extraBooleans.enter_description
                            ) {
                              setCompanyData({
                                ...companyData,
                                description: e.target.value,
                              });
                            }

                            if (
                              questionType === "socials_details" &&
                              extraBooleans.enter_website
                            ) {
                              setCompanyData({
                                ...companyData,
                                website: e.target.value,
                              });
                            }

                            if (
                              questionType === "socials_details" &&
                              extraBooleans.enter_facebook
                            ) {
                              setCompanyData({
                                ...companyData,
                                facebook: e.target.value,
                              });
                            }

                            if (
                              questionType === "socials_details" &&
                              extraBooleans.enter_twitter
                            ) {
                              setCompanyData({
                                ...companyData,
                                twitter: e.target.value,
                              });
                            }

                            if (
                              questionType === "socials_details" &&
                              extraBooleans.enter_instagram
                            ) {
                              setCompanyData({
                                ...companyData,
                                instagram: e.target.value,
                              });
                            }

                            if (
                              questionType === "socials_details" &&
                              extraBooleans.enter_pinterest
                            ) {
                              setCompanyData({
                                ...companyData,
                                pinterest: e.target.value,
                              });
                            }

                            if (
                              questionType === "socials_details" &&
                              extraBooleans.enter_tiktok
                            ) {
                              setCompanyData({
                                ...companyData,
                                tiktok: e.target.value,
                              });
                            }

                            if (
                              questionType === "socials_details" &&
                              extraBooleans.enter_other
                            ) {
                              setCompanyData({
                                ...companyData,
                                other: e.target.value,
                              });
                            }
                          }}
                        />
                        <div className="chat__input__button">
                          <button
                            className="chat__input__button__send"
                            disabled={mainINputDisabled}
                            onClick={() => {
                              if (questionType === "Company_name") {
                                setLoading(true);
                                getcompanynames();
                              }

                              if (
                                questionType === "contact_details" &&
                                extraBooleans.enter_email
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_email: false,
                                  ask_phone: true,
                                  show_user_image: true,
                                  show_input: false,
                                });

                                setRightSideSection({
                                  ...rightSideSection,
                                  email: true,
                                });
                              }

                              if (
                                questionType === "contact_details" &&
                                extraBooleans.enter_phone
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_phone: false,
                                  ask_address: true,
                                  show_user_image: true,
                                  show_input: false,
                                });
                                setRightSideSection({
                                  ...rightSideSection,
                                  phone: true,
                                });
                              }

                              if (
                                questionType === "contact_details" &&
                                extraBooleans.enter_address
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_address: false,
                                  ask_description: true,
                                  description_select: true,
                                  show_user_image: true,
                                  show_input: false,
                                });
                                setRightSideSection({
                                  ...rightSideSection,
                                  address: true,
                                });
                                setQuestionType("brand_description");
                                setSelectedCategory(companyData);
                              }

                              if (
                                questionType === "brand_description" &&
                                extraBooleans.enter_description
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_description: false,
                                });
                                setRightSideSection({
                                  ...rightSideSection,
                                  description: true,
                                });
                              }

                              if (
                                questionType === "socials_details" &&
                                extraBooleans.enter_website
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_website: false,
                                  ask_facebook: true,
                                  show_user_image: true,
                                  show_input: false,
                                });
                                setRightSideSection({
                                  ...rightSideSection,
                                  website: true,
                                });
                              }

                              if (
                                questionType === "socials_details" &&
                                extraBooleans.enter_facebook
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_facebook: false,
                                  ask_twitter: true,
                                  show_user_image: true,
                                  show_input: false,
                                });
                                setRightSideSection({
                                  ...rightSideSection,
                                  facebook: true,
                                });
                              }

                              if (
                                questionType === "socials_details" &&
                                extraBooleans.enter_twitter
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_twitter: false,
                                  ask_instagram: true,
                                  show_user_image: true,
                                  show_input: false,
                                });
                                setRightSideSection({
                                  ...rightSideSection,
                                  twitter: true,
                                });
                              }

                              if (
                                questionType === "socials_details" &&
                                extraBooleans.enter_instagram
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_instagram: false,
                                  ask_pinterest: true,
                                  show_user_image: true,
                                  show_input: false,
                                });
                                setRightSideSection({
                                  ...rightSideSection,
                                  instagram: true,
                                });
                              }

                              if (
                                questionType === "socials_details" &&
                                extraBooleans.enter_pinterest
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_pinterest: false,
                                  ask_tiktok: true,
                                  show_user_image: true,
                                  show_input: false,
                                });
                                setRightSideSection({
                                  ...rightSideSection,
                                  pinterest: true,
                                });
                              }

                              if (
                                questionType === "socials_details" &&
                                extraBooleans.enter_tiktok
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_tiktok: false,
                                  enter_other: true,
                                  show_user_image: false,
                                  show_input: true,
                                });
                                setRightSideSection({
                                  ...rightSideSection,
                                  tiktok: true,
                                });
                              }

                              if (
                                questionType === "socials_details" &&
                                extraBooleans.enter_other
                              ) {
                                setExtraBooleans({
                                  ...extraBooleans,
                                  enter_other: false,
                                  socials_select: false,
                                  logo_select: true,
                                  show_input: false,
                                  ask_logo: true,
                                });
                                setQuestionType("logo_details");
                                setRightSideSection({
                                  ...rightSideSection,
                                  other: true,
                                });
                                setLogoLogo(companyData);
                              }
                            }}
                          >
                            <MdSend />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w0Ne1v">
          <div className="chat_sidebar-inner">
            <div className="detail_content_box">
              <span className="mini_title">YOUR BRAND DETAILS</span>
              <div className="blank"></div>

              {rightSideSection.company_name && (
                <>
                  <div className="input-effect" controlId="formBasicName">
                    <input
                      className="effect-16"
                      type="text"
                      placeholder=""
                      value={companyData.company_name}
                      onChange={(e) => {
                        setCompanyData({
                          ...companyData,
                          company_name: e.target.value,
                        });
                      }}
                    />
                    <Form.Label>Company Name</Form.Label>
                    <span className="focus-border"></span>
                  </div>
                  <div className="blank"></div>
                </>
              )}
              {rightSideSection.category && (
                <>
                  <div className="form-group input-effect mb-0">
                    <Typeahead
                      id="brandTypeahead"
                      labelKey="company_name" // Display label (company_name)
                      multiple
                      options={brand_categories} // Use the transformed data
                      selected={companyData.category}
                      onChange={handleBrandCategory}
                      className="effect-16"
                      placeholder="Select Brands"
                    />
                    <label className="form-label">Select Brands</label>
                  </div>
                  <div className="blank"></div>
                </>
              )}

              {rightSideSection.email && (
                <>
                  <div className="input-effect" controlId="formBasicEmail">
                    <input
                      className="effect-16"
                      type="email"
                      placeholder=""
                      value={companyData.email}
                      // onChange={(e) => {
                      //     setCompanyData({
                      //         ...companyData,
                      //         email: e.target.value
                      //     })
                      // }}
                    />
                    <Form.Label>Brand Email</Form.Label>
                    <span className="focus-border"></span>
                  </div>
                  <div className="blank"></div>
                </>
              )}

              {rightSideSection.phone && (
                <>
                  <div className="input-effect" controlId="formBasicPhone">
                    <input
                      className="effect-16"
                      type="text"
                      placeholder=""
                      value={companyData.phone}
                      // onChange={(e) => {
                      //     setCompanyData({
                      //         ...companyData,
                      //         phone: e.target.value
                      //     })
                      // }}
                    />
                    <Form.Label>Brand Phone</Form.Label>
                    <span className="focus-border"></span>
                  </div>
                  <div className="blank"></div>
                </>
              )}

              {rightSideSection.address && (
                <>
                  <div className="input-effect" controlId="formBasicAddress">
                    <textarea
                      className="effect-16"
                      type="text"
                      placeholder=""
                      value={companyData.address}
                      onChange={(e) => {
                        setCompanyData({
                          ...companyData,
                          address: e.target.value,
                        });
                      }}
                    />
                    <Form.Label>Brand Address</Form.Label>
                    <span className="focus-border-textarea"></span>
                  </div>
                  <div className="blank"></div>
                </>
              )}

              {rightSideSection.description && (
                <>
                  <div
                    className="input-effect"
                    controlId="formBasicDescription"
                  >
                    <textarea
                      className="effect-16"
                      type="text"
                      rows="3"
                      placeholder="Brand Description"
                      value={companyData.description}
                      onChange={(e) => {
                        setCompanyData({
                          ...companyData,
                          description: e.target.value,
                        });
                      }}
                    />
                    <Form.Label>Brand Description</Form.Label>
                    <span className="focus-border-textarea"></span>
                  </div>
                  <div className="blank"></div>
                </>
              )}

              {rightSideSection.website && (
                <InputGroup controlId="formBasicEmail" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-lg">
                    <TbWorld />
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Website URL"
                    value={companyData.website}
                    onChange={(e) =>
                      setCompanyData({
                        ...companyData,
                        website: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              )}

              {rightSideSection.facebook && (
                <InputGroup controlId="formBasicEmail" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-lg">
                    <MdFacebook />
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Facebook URL"
                    value={companyData.facebook}
                    onChange={(e) =>
                      setCompanyData({
                        ...companyData,
                        facebook: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              )}

              {rightSideSection.twitter && (
                <InputGroup controlId="formBasicEmail" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-lg">
                    <AiFillTwitterCircle />
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Twitter URL"
                    value={companyData.twitter}
                    onChange={(e) =>
                      setCompanyData({
                        ...companyData,
                        twitter: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              )}

              {rightSideSection.instagram && (
                <InputGroup controlId="formBasicEmail" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-lg">
                    <AiFillInstagram />
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Instagram URL"
                    value={companyData.instagram}
                    onChange={(e) =>
                      setCompanyData({
                        ...companyData,
                        instagram: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              )}

              {rightSideSection.pinterest && (
                <InputGroup controlId="formBasicEmail" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-lg">
                    <FaPinterest />
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Pinterest URL"
                    value={companyData.pinterest}
                    onChange={(e) =>
                      setCompanyData({
                        ...companyData,
                        pinterest: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              )}

              {rightSideSection.tiktok && (
                <InputGroup controlId="formBasicEmail" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-lg">
                    <FaTiktok />
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="TikTok URL"
                    value={companyData.tiktok}
                    onChange={(e) =>
                      setCompanyData({ ...companyData, tiktok: e.target.value })
                    }
                  />
                </InputGroup>
              )}

              {rightSideSection.other && (
                <InputGroup controlId="formBasicEmail" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-lg">
                    <HiLink />
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Other URL"
                    value={companyData.other}
                    onChange={(e) =>
                      setCompanyData({ ...companyData, other: e.target.value })
                    }
                  />
                </InputGroup>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBrand;
