import React, { useState, useEffect, useContext } from "react";
import { BsStars, BsSubscript } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import GoogleMapReact from "google-map-react";
import { API_URL } from "../../../BackendApi/Api/config";
import UserInputModal from "../UserInputModal";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import MapBlock from "../../../Helpers/MapBlock";
import { EventContext } from "../../EventsContext";
import LocationDisplay from "../../../Components/locationsType/LocationType";
import useCurrentLocation from "../../../Helpers/useCurrentLocation";
import GoogleMaps from "../../../EventBuilder/EventsTab/GoogleMap2";
import CopyCat from "../../../Components/CopyCat/CopyCat";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
function Event(props) {
  let {
    eventTitle,
    description,
    email,
    startDate,
    endDate,
    location,
    hostName,
    hostedImage,
    selectedTimezone,
    locationType,
    lat,
    lng,
  } = props.data;
  const { formData } = useContext(EventContext);
  const { location1, address, error } = useCurrentLocation();
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const start = new Date(startDate);
  const end = new Date(endDate);

  const monthName = format(start, "MMM", { locale: enUS }).toUpperCase();
  const dayName = format(start, "d");
  const weekdayName = format(start, "EEEE, MMMM d", { locale: enUS });
  const startTime = format(start, "hh:mm a");
  const endTime = format(end, "hh:mm a");
  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    location
  )}`;
  if (locationType == "Virtual") {
    location = location;
  }
  const tagsData = () => {
    switch (formData.statusTag) {
      case 0:
        return "Upcoming";
      case 2:
        return "Limited Seats";
      case 1:
        return "Sold Out";
      default:
        return false;
    }
  };
  const [locate, setLocate] = useState({
    lat: 10.99835602,
    lng: 77.01502627,
  });

  useEffect(() => {
    if (locationType === "Physical") {
      if (lat && lng) {
        setLocate({
          lat: lat,
          lng: lng,
        });
      }
    }
  }, [lat, lng]);
  return (
    <>
      <div id={props.data.unique_id} style={{ color: "white" }}>
        <div className="event-section-inner">
          <div className="event-tper-sec">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="event-type-box">
                <div className="event-type-icon">
                  <BsStars />
                </div>
                <div className="event-type-text">
                  {props.data.eventType} Event
                </div>
              </div>
              <div className="event-type-box">
                <div className="event-type-text">
                  {formData.eventType == 1 ? "Paid" : "Free"}
                </div>
              </div>
            </div>
          </div>
          {tagsData() && (
            <div className="event-title" style={{ marginLeft: "5px" }}>
              <div className="day-name"> {tagsData()}</div>
            </div>
          )}
          <div className="event-title">
            <h2 className="m-0">{props.data.eventTitle}</h2>
          </div>

          {startDate && endDate && (
            <div className="event-date-sec">
              <div className="event-date-inner">
                <div className="date-icon">
                  <div className="month-name">{monthName}</div>
                  <div className="day-name">{dayName}</div>
                </div>
                <div className="event-date-text">
                  <div className="event-date">{weekdayName}</div>
                  <div className="event-time">
                    {`${startTime} - ${endTime}`}
                  </div>
                </div>
              </div>
            </div>
          )}

          {location && (
            <>
              {/* <div className="event-location-sec mt-2">
                        <div className="event-date-inner">
                            <div className="date-icon d-flex align-items-center justify-content-center">
                                <MdLocationOn />
                            </div>
                            <div className="event-date-text">
                                <div className="event-date">
                                    <a
                                        href={googleMapsLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: 'inherit', textDecoration: 'none' }} // Ensure link doesn't turn blue or underlined
                                    >
                                        {location}
                                    </a>
                                </div>

                            </div>
                        </div>
                        </div> */}
              <LocationDisplay type="showUrls" eventData={props.data} />
            </>
          )}

          <div className="Registration-sec">
            <div className="registration-inner">
              <div className="registration-header">Registration</div>
              <div className="inner">
                <div className="jsx-3830652295">
                  <div className="jsx-3830652295 flex-column content">
                    <div className="jsx-1768947162 info-rows flex-column one">
                      <div className="jsx-1768947162 d-flex align-items-center flex-align one">
                        <div className="icon-box">
                          <BsStars />
                        </div>
                        <div className="event-date-text">
                          <div className="event-date">Approval Required</div>
                          <div className="event-time">
                            Your registration is subject to approval by the
                            host.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="jsx-3363824362 message">
                      Welcome! To join the event, please register below.{" "}
                    </div>
                    {/* <div className="jsx-1768947162 d-flex align-items-center flex-align one">
                                            <div className="icon-box-img">
                                                <div className="bg-avtar"></div>
                                            </div>
                                            <div className="event-date-text">
                                                <div className="event-date">
                                                    Your Name
                                                </div>
                                                <div className="event-time">
                                                    emp2022opti@gmail.com
                                                </div>
                                            </div>
                                        </div> */}
                    {/* {formData.capacityType == 1 && formData.maxAttendees > 0 ? (
                                            <>
                                                <div className="jsx-3363824362 message">Max Attendees
                                                    {formData.maxAttendees}</div>
                                                <div className="jsx-3363824362 message">{formData.showGuestCount == true ? 4 : '0'} Going</div></>) : null}

                                        {formData.showTicketsLeft ? (
                                            <div className="jsx-3363824362 message">Tickets Left
                                                {formData.ticketsLeft}</div>

                                        ) : null} */}
                    <button className="btn one-click">One Click Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {description && (
            <div className="event-description-sec mt-3">
              <div className="event-description-title event-xyt">
                About Event
              </div>
              <div className="event-description-text">
                {props.data.description}
              </div>
            </div>
          )}
          {locationType !== "Virtual" ? (
            <div className="event-description-sec location mt-3">
              <div className="event-description-title event-xyt">Location</div>
              <div className="event-description-text d-flex align-items-center gap-2">
                <p>{props.data.location}</p>
                <CopyCat text={props.data.location} />
              </div>
              <div style={{ height: "200px", width: "100%" }}>
                {/* <GoogleMaps
                  center={{
                    lat: parseFloat(location1?.latitude),
                    lng: parseFloat(location1?.longitude),
                  }}
                  zoom={15}
                  markers={[
                    {
                      lat: parseFloat(location1?.latitude),
                      lng: parseFloat(location1?.longitude),
                      title: location1?.name,
                    },
                  ]}
                /> */}
                <GoogleMaps
                  center={{
                    lat: parseFloat(locate.lat),
                    lng: parseFloat(locate.lng),
                  }}
                  zoom={15}
                  markers={[
                    {
                      lat: parseFloat(locate.lat),
                      lng: parseFloat(locate.lng),
                      title: "",
                    },
                  ]}
                />
              </div>
              {/* <MapBlock address="801 Mission St, San Francisco, CA 94103, USA" height="300px" /> */}
            </div>
          ) : (
            <div className="event-description-sec location mt-5">
              <LocationDisplay type="showUrls" eventData={props.data} />
            </div>
          )}
          {formData.showHosts &&
            (hostName || hostedImage) &&
            hostedImage.length > 0 && (
              <div className="event-description-sec mt-3">
                <div className="event-description-title event-xyt">
                  Hosted By
                </div>
                <div className="hosted-by-sec">
                  <div className="hosted-inner">
                    <div className="host-left">
                      <div>
                        <div className="total-people-inner pb-2">
                          {Array.isArray(hostedImage) &&
                            hostedImage.length > 0 &&
                            hostedImage.map((image, index) => (
                              <div className="total-people-img" key={index}>
                                {/* <img src={API_URL + "profile/" + image ?? "https://static.wikia.nocookie.net/naruto/images/b/bb/Itachi.png/revision/latest?cb=20220214112531"} alt="" /> */}
                                <img
                                  src={require("../../../assets/images/Avatar.webp")}
                                  alt=""
                                />
                              </div>
                            ))}
                        </div>

                        <div className="hosted-text">
                          <div className="hosted-name">
                            {hostName.map((name) => name).join(", ")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="event-description-sec mt-4">
            {formData.showGuestCount && (
              <div className="event-description-title event-xyt">0 Going</div>
            )}
            {formData.showGuestList && (
              <div className="total-people-sec">
                <div className="total-people-inner pb-2">
                  <div className="total-people-img">
                    <img
                      src={require("../../../assets/images/Avatar.webp")}
                      alt=""
                    />
                  </div>
                  <div className="total-people-img">
                    <img
                      src={require("../../../assets/images/Avatar.webp")}
                      alt=""
                    />
                  </div>
                  {/* <div className="total-people-img">
                                        <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                    </div>
                                    <div className="total-people-img">
                                        <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                    </div>
                                    <div className="total-people-img">
                                        <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                    </div>
                                    <div className="total-people-img">
                                        <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                    </div> */}
                </div>
                {/* <div class="jsx-2911588165 text-tinted fs-sm guests-string animated">Phoebe Elefante, Sean Pinnock and 12 others</div>
                                <br />
                                <div className="contact-host">
                                    <button className="btn contact-host-btn btn-event-page">Contact the Host</button>
                                </div>
                                <div className="report-host">
                                    <button className="btn report-host-btn btn-event-page">Report the Host</button>
                                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Event;
