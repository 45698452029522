import React, { useState, useEffect } from 'react';
import {
    Container, Row, Col, Card, Badge, Form, Dropdown,
    Button, Modal, OverlayTrigger, Tooltip
} from 'react-bootstrap';
import { FiSearch, FiFilter, FiCalendar, FiMoreVertical, FiDownload, FiShare } from 'react-icons/fi';
import { MdOutlineModeEdit } from 'react-icons/md';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './GuestList.css';

const API_URL = process.env.REACT_APP_API_URL;

const GuestList = () => {
    const { id } = useParams();
    const [guests, setGuests] = useState([]);
    const [filteredGuests, setFilteredGuests] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState('All Guests');
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [currentGuest, setCurrentGuest] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [notifyGuest, setNotifyGuest] = useState(false);
    const [customMessage, setCustomMessage] = useState('');
    const [sortBy, setSortBy] = useState('Register Time');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Status badge styling
    const statusStyles = {
        'Going': { bg: 'success', text: 'white' },
        'Invited': { bg: 'primary', text: 'white' },
        'Not Going': { bg: 'danger', text: 'white' },
        'Maybe': { bg: 'warning', text: 'dark' },
        'Pending Approval': { bg: 'orange', text: 'white' },
        'Waitlist': { bg: 'purple', text: 'white' },
        'Checked In': { bg: 'info', text: 'white' }
    };

    // Fetch guest data from API
    useEffect(() => {
        const fetchGuests = async () => {
            try {
                setLoading(true);
                const response = await axios.post(`${API_URL}/event/get-event-guests`, {
                    event_id: id
                });

                if (response.data.success) {
                    // Transform the data to match the component's expected format
                    const formattedGuests = response.data.data.map(guest => ({
                        id: guest._id,
                        name: guest.name || 'Guest', // Fallback if name is not provided
                        email: guest.email,
                        phone: guest.phone,
                        title: guest.title,
                        status: guest.status,
                        avatar: guest.name ? guest.name.charAt(0).toUpperCase() : 'G',
                        statusUpdatedAt: guest.updatedAt,
                        invitedAt: guest.createdAt
                    }));

                    setGuests(formattedGuests);
                    setFilteredGuests(formattedGuests);
                } else {
                    setError('Failed to fetch guests');
                }
            } catch (error) {
                console.error('Error fetching guests:', error);
                setError('An error occurred while fetching guests');
            } finally {
                setLoading(false);
            }
        };


        fetchGuests();

    }, [id]);

    // Filter guests based on search query and status
    useEffect(() => {
        let result = guests;

        // Apply search filter
        if (searchQuery) {
            result = result.filter(guest =>
                (guest.name && guest.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (guest.email && guest.email.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        }

        // Apply status filter
        if (statusFilter !== 'All Guests') {
            result = result.filter(guest => guest.status === statusFilter);
        }

        // Apply sorting
        if (sortBy === 'Register Time') {
            result = [...result].sort((a, b) => new Date(b.invitedAt) - new Date(a.invitedAt));
        } else if (sortBy === 'Name') {
            result = [...result].sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        } else if (sortBy === 'Status Change') {
            result = [...result].sort((a, b) => new Date(b.statusUpdatedAt) - new Date(a.statusUpdatedAt));
        }

        setFilteredGuests(result);
    }, [guests, searchQuery, statusFilter, sortBy]);

    // Open status update modal
    const handleStatusUpdate = (guest) => {
        setCurrentGuest(guest);
        setNewStatus(guest.status);
        setShowStatusModal(true);
    };

    // Update guest status
    const updateGuestStatus = async () => {
        if (!currentGuest || !newStatus) return;

        try {
            // Send update to API
            await axios.post(`${API_URL}/event/update-guest-status`, {
                guest_id: currentGuest.id,
                status: newStatus,
                notify: notifyGuest,
                message: notifyGuest ? customMessage : ''
            });

            // Update local state
            const updatedGuests = guests.map(guest => {
                if (guest.id === currentGuest.id) {
                    return {
                        ...guest,
                        status: newStatus,
                        statusUpdatedAt: new Date().toISOString()
                    };
                }
                return guest;
            });

            setGuests(updatedGuests);
            setShowStatusModal(false);
            setNotifyGuest(false);
            setCustomMessage('');

        } catch (error) {
            console.error('Error updating guest status:', error);
            // Handle error - show a toast notification or alert
        }
    };

    // Format date for display
    const formatDate = (dateString) => {
        if (!dateString) return '';

        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        if (date.toDateString() === today.toDateString()) {
            return 'Today';
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        } else {
            return `${date.toLocaleDateString('en-US', { month: 'short' })} ${date.getDate()}`;
        }
    };

    // Create avatar with first letter of name and random color
    const Avatar = ({ name }) => {
        const firstLetter = name ? name.charAt(0).toUpperCase() : 'G';
        return (
            <div className="guest-avatar">
                {firstLetter}
            </div>
        );
    };

    if (loading) {
        return (
            <Container fluid className="guest-list-container p-0">
                <Card className="border-0 shadow-sm">
                    <Card.Body className="text-center py-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-3">Loading guest list...</p>
                    </Card.Body>
                </Card>
            </Container>
        );
    }

    if (error) {
        return (
            <Container fluid className="guest-list-container p-0">
                <Card className="border-0 shadow-sm">
                    <Card.Body className="text-center py-5">
                        <div className="alert alert-danger">
                            {error}
                        </div>
                        <Button variant="primary" onClick={() => window.location.reload()}>
                            Retry
                        </Button>
                    </Card.Body>
                </Card>
            </Container>
        );
    }

    return (
        <>
            <Container fluid className="guest-list-container p-0">
                <Card className="border-0 shadow-sm">
                    <Card.Header className="bg-dark text-white d-flex justify-content-between align-items-center py-3">
                        <h4 className="mb-0">Guest List</h4>
                        <div className="d-flex">
                            <Button variant="outline-light" size="sm" className="me-2">
                                <FiDownload className="me-1" /> Export
                            </Button>
                            <Button variant="outline-light" size="sm">
                                <FiShare className="me-1" /> Share
                            </Button>
                        </div>
                    </Card.Header>

                    <Card.Body className="p-0">
                        {/* Search and Filter Bar */}
                        <div className="search-filter-container p-3 border-bottom">
                            <Row className="align-items-center">
                                <Col md={4} className="mb-2 mb-md-0">
                                    <div className="search-container">
                                        <FiSearch className="search-icon" />
                                        <Form.Control
                                            type="text"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            className="border-0 bg-light ps-5"
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className="mb-2 mb-md-0">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" className="w-100 text-start d-flex align-items-center">
                                            <FiFilter className="me-2" /> {statusFilter}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setStatusFilter('All Guests')}>All Guests</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Going')}>Going</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Invited')}>Invited</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Not Going')}>Not Going</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Maybe')}>Maybe</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Pending Approval')}>Pending Approval</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Waitlist')}>Waitlist</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setStatusFilter('Checked In')}>Checked In</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col md={4}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" className="w-100 text-start d-flex align-items-center">
                                            <FiCalendar className="me-2" /> {sortBy}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setSortBy('Register Time')}>Register Time</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSortBy('Name')}>Name</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSortBy('Status Change')}>Status Change</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>

                        {/* Guest List */}
                        <div className="guest-list">
                            {filteredGuests.length > 0 ? (
                                filteredGuests.map((guest) => (
                                    <div key={guest.id} className="guest-item">
                                        <div className="guest-info">
                                            <Avatar name={guest.name} />
                                            <div className="guest-details">
                                                <h6 className="mb-0">{guest.name || 'Guest'}</h6>
                                                <small className="text-muted">{guest.email}</small>
                                            </div>
                                        </div>
                                        <div className="guest-status">
                                            <Badge
                                                bg={statusStyles[guest.status]?.bg || 'secondary'}
                                                text={statusStyles[guest.status]?.text || 'white'}
                                                className="status-badge"
                                            >
                                                {guest.status}
                                            </Badge>
                                        </div>
                                        <div className="guest-date">
                                            {formatDate(guest.status === 'Invited' ? guest.invitedAt : guest.statusUpdatedAt)}
                                        </div>
                                        <div >
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                // className="action-btn"
                                                onClick={() => handleStatusUpdate(guest)}
                                            >
<MdOutlineModeEdit />
</Button>
                                        </div>
                                        {/* <div className="guest-actions">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Update Status</Tooltip>}
                                            >
                                                <Button
                                                    variant="light"
                                                    size="sm"
                                                    className="action-btn"
                                                    onClick={() => handleStatusUpdate(guest)}
                                                >
                                                    <FiMoreVertical />
                                                </Button>
                                            </OverlayTrigger>
                                        </div> */}

                                    </div>
                                ))
                            ) : (
                                <div className="text-center py-5">
                                    <p className="text-muted">No guests found matching your criteria.</p>
                                </div>
                            )}
                        </div>
                    </Card.Body>
                </Card>

                {/* Status Update Modal */}
                <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {currentGuest && (
                            <>
                                <p><strong>Guest:</strong> {currentGuest.name || 'Guest'}</p>
                                <p><strong>Current Status:</strong> {currentGuest.status}</p>

                                <Form.Group className="mb-3">
                                    <Form.Label>New Status</Form.Label>
                                    <Form.Select
                                        value={newStatus}
                                        onChange={(e) => setNewStatus(e.target.value)}
                                    >
                                        <option value="Going">Going</option>
                                        <option value="Invited">Invited</option>
                                        <option value="Not Going">Not Going</option>
                                        <option value="Maybe">Maybe</option>
                                        <option value="Pending Approval">Pending Approval</option>
                                        <option value="Waitlist">Waitlist</option>
                                        <option value="Checked In">Checked In</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        label="Notify Guest"
                                        checked={notifyGuest}
                                        onChange={() => setNotifyGuest(!notifyGuest)}
                                    />
                                </Form.Group>

                                {notifyGuest && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>Custom Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Add a custom message to the notification email..."
                                            value={customMessage}
                                            onChange={(e) => setCustomMessage(e.target.value)}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={updateGuestStatus}>
                            Update Status
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    );
};

export default GuestList;