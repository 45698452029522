import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { TbHelp } from 'react-icons/tb'
import { Button } from 'react-bootstrap'
import { FaTags, FaEdit, FaTrash } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import CreateTag from './CreateTag'
import axios from 'axios'
import * as PNotify from '@pnotify/core'
import '@pnotify/core/dist/PNotify.css'
import '@pnotify/core/dist/BrightTheme.css'
import { Tabs, Tab } from 'react-bootstrap'
import AudienceTags from './AudienceTags'
import GeneralTags from './GeneralTags'

const API_URL = process.env.REACT_APP_API_URL

const Tags = () => {
   

    return (
        <>          
            <div className="tab-content-header add-btn d-flex Add-margin">
                <h5 className="tab-content-title">
                    <div className="tab-content-icon"><FaTags /></div>
                    Tags
                </h5>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Tabs
                        defaultActiveKey="profile"
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                    >
                        <Tab eventKey="profile" title="General">
                            <GeneralTags />
                        </Tab>
                        <Tab eventKey="longer-tab" title="Audience">
                            <AudienceTags />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default Tags