const EventLoadingSkeleton = ({ count = 6 }) => {
    return (
      <div className="mini-page-list">
        <div className="mini-page-list-inner row">
          {Array(count).fill(0).map((_, index) => (
            <div key={index} className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6'>
              <div className="mini-page-list-inner-item">
                <div className="mini-page-list-inner-item-img">
                  {/* Image skeleton */}
                  <div 
                    className="skeleton-image"
                    style={{
                      height: "180px",
                      backgroundColor: "#e0e0e0",
                      width: "100%",
                      borderRadius: "8px",
                      animation: "pulse 1.5s infinite ease-in-out"
                    }}
                  />
                </div>
                <div className="mini-page-list-inner-item-content">
                  <div className="mini-page-list-inner-item-content-title">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      {/* Title skeleton */}
                      <div 
                        className="skeleton-title"
                        style={{
                          height: "24px",
                          backgroundColor: "#e0e0e0",
                          width: "60%",
                          borderRadius: "4px",
                          animation: "pulse 1.5s infinite ease-in-out"
                        }}
                      />
                      {/* Icon skeleton */}
                      <div 
                        className="skeleton-icon"
                        style={{
                          height: "24px",
                          width: "24px",
                          backgroundColor: "#e0e0e0",
                          borderRadius: "50%",
                          animation: "pulse 1.5s infinite ease-in-out"
                        }}
                      />
                    </div>
                    <div className='d-flex align-items-center gap-2 mt-2'>
                      {/* URL skeleton */}
                      <div 
                        className="skeleton-url"
                        style={{
                          height: "16px",
                          backgroundColor: "#e0e0e0",
                          width: "70%",
                          borderRadius: "4px",
                          animation: "pulse 1.5s infinite ease-in-out"
                        }}
                      />
                      {/* Button skeletons */}
                      <div 
                        className="skeleton-button"
                        style={{
                          height: "20px",
                          width: "20px",
                          backgroundColor: "#e0e0e0",
                          borderRadius: "4px",
                          animation: "pulse 1.5s infinite ease-in-out"
                        }}
                      />
                      <div 
                        className="skeleton-button"
                        style={{
                          height: "20px",
                          width: "20px",
                          backgroundColor: "#e0e0e0",
                          borderRadius: "4px",
                          animation: "pulse 1.5s infinite ease-in-out"
                        }}
                      />
                    </div>
                  </div>
  
                  <div className='inline-box mt-3'>
                    <div className="SmartLinks-table-view">
                      <div className="views04 d-flex gap-3">
                        {/* Stats skeletons */}
                        {Array(4).fill(0).map((_, i) => (
                          <div 
                            key={i}
                            className="skeleton-stat"
                            style={{
                              height: "16px",
                              backgroundColor: "#e0e0e0",
                              width: "60px",
                              borderRadius: "4px",
                              animation: "pulse 1.5s infinite ease-in-out"
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    <div className='mini-page-list-inner-item-content-btn'>
                      <div className="d-flex new-M">
                        {/* Action button skeletons */}
                        {Array(5).fill(0).map((_, i) => (
                          <div 
                            key={i}
                            className="skeleton-action-btn me-3"
                            style={{
                              height: "36px",
                              width: "36px",
                              backgroundColor: "#e0e0e0",
                              borderRadius: "4px",
                              animation: "pulse 1.5s infinite ease-in-out"
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
  
        {/* Keyframe animation for the pulse effect */}
        <style jsx>{`
          @keyframes pulse {
            0% {
              opacity: 0.6;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0.6;
            }
          }
        `}</style>
      </div>
    );
  };
  
  export default EventLoadingSkeleton;