import React, { useEffect, useState } from "react";
import { Button, Modal, Card, Row, Col, Badge } from "react-bootstrap";
import { CgUserList } from "react-icons/cg";
import { RiAddFill } from "react-icons/ri";
import { FaTrash, FaMapMarkerAlt, FaCity, FaGlobe } from "react-icons/fa";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import { API_URL } from "../../BackendApi/Api/config";
import { BsFillImageFill } from "react-icons/bs";
import axios from "axios";
import AddVenue from "../../Components/Venue/AddVenue";

const BrandVenues = ({ brand_id }) => {
  const [venuesList, setVenuesList] = useState([]);
  const [showAddVenueModal, setShowAddVenueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);

  const handleShowAddVenueModal = () => {
    setShowAddVenueModal(true);
  };

  const handleHideAddVenueModal = () => {
    setShowAddVenueModal(false);
  };

  const handleShowDeleteModal = (venue) => {
    setSelectedVenue(venue);
    setShowDeleteModal(true);
  };

  const handleHideDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedVenue(null);
  };

  const fetchVenues = async () => {
    try {
      const response = await axios.post(
        `${API_URL}event/get-venues-with-brand-id`,
        { brand_id: brand_id }
      );
      if (response.data.success) {
        setVenuesList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching venues:", error);
    }
  };

  const handleDeleteVenue = async () => {
    try {
      const response = await axios.post(
        `${API_URL}event/delete-venue`,
        { venue_id: selectedVenue._id }
      );
      
      if (response.data.success) {
        PNotify.success({
          title: "Success",
          text: "Venue deleted successfully",
        });
        fetchVenues();
      } else {
        PNotify.error({
          title: "Error",
          text: response.data.message || "Failed to delete venue",
        });
      }
    } catch (error) {
      console.error("Error deleting venue:", error);
      PNotify.error({
        title: "Error",
        text: "Something went wrong while deleting the venue",
      });
    } finally {
      handleHideDeleteModal();
    }
  };

  useEffect(() => {
    fetchVenues();
  }, [brand_id]);

  return (
    <>
      {/* Add Venue Modal */}
      <Modal
        show={showAddVenueModal}
        onHide={handleHideAddVenueModal}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Venue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddVenue
            handleClose={handleHideAddVenueModal}
            brandId={brand_id}
            componentType="Brand"
            onVenueAdded={() => fetchVenues()}
          />
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleHideDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Venue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete venue "{selectedVenue?.event_venue_name}"?
          This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteVenue}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <div className="tab-content-header add-btn d-flex ms-0">
          <h5 className="tab-content-title">
            <div className="tab-content-icon">
              <BsFillImageFill />
            </div>
            Venues
          </h5>

          <div className="tab-content-header-right">
            <Button
              className="btn btn-sm float-right add-user-btn"
              onClick={handleShowAddVenueModal}
            >
              {" "}
              <RiAddFill /> Add Venue
            </Button>
          </div>
        </div>

        {/* Venues Full-Width Card View */}
        <div className="mt-4">
          {venuesList.length > 0 ? (
            <div className="venue-list">
              {venuesList.map((venue) => (
                <Card key={venue._id} className="mb-3 shadow-sm">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="flex-grow-1">
                        <Card.Title className="mb-3 d-flex align-items-center">
                          <span className="me-2">{venue.event_venue_name}</span>
                          <Badge bg="primary">{venue.event_venue_zip}</Badge>
                        </Card.Title>
                        <Row>
                          <Col md={4}>
                            <div className="d-flex mb-2">
                              <FaCity className="me-2 text-muted" />
                              <span>{venue.event_venue_city}</span>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="d-flex mb-2">
                              <FaGlobe className="me-2 text-muted" />
                              <span>{venue.event_venue_country}</span>
                            </div>
                          </Col>
                        </Row>
                        <div className="d-flex mt-2">
                          <FaMapMarkerAlt className="me-2 text-muted" />
                          <span>{venue.event_venue_address}</span>
                        </div>
                        {venue.event_venue_description && (
                          <div className="mt-3 text-muted">
                            <small>{venue.event_venue_description}</small>
                          </div>
                        )}
                      </div>
                      <div className="ms-3">
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => handleShowDeleteModal(venue)}
                        >
                          <FaTrash className="me-1" /> Delete
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          ) : (
            <div className="text-center p-5 bg-light rounded">
              <BsFillImageFill size={40} className="text-muted mb-3" />
              <h5>No Venues Found</h5>
              <p className="text-muted">Add your first venue to get started.</p>
              <Button variant="primary" onClick={handleShowAddVenueModal}>
                <RiAddFill /> Add Venue
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BrandVenues;