import React from "react";
import { MdEdit } from "react-icons/md";


const HostDetails = () => {
  return (
    <div>
      <h3 className="ms-3">Host Details</h3>
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <h5>Hosts</h5>
          <button className="btn btn-primary yggy-btn">+ Add Host</button>
        </div>
        <p className="text-muted my-2">
          Add Hosts, special guests and event managers.
        </p>

        <div className="d-flex align-items-center justify-content-between mb-2" style={{backgroundColor: "#ffffff"}}>
          <div className="d-flex align-items-center gap-2">
            <img
              src="https://images.pexels.com/photos/30637582/pexels-photo-30637582/free-photo-of-siberian-husky-outdoors-in-san-francisco-landscape.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
              height={20}
              width={20}
              style={{ borderRadius: "50%" }}
            />
            <div className="yaay-txt">Indienomicon</div>
            <p className="text-muted mb-0">hello@indie....</p>
            <button
              className="btn yggy-btn p-1"
              style={{
                borderRadius: "20px",
                color: "#ea4335",
                backgroundColor: "rgba(234, 67, 53, 0.1)",
              }}
            >
              Manager
            </button>
          </div>
          <button className="btn btn-primary yggy-btn"><MdEdit /></button>
        </div>
      </div>
      <style>
        {`
            .yggy-btn{
                {/* font-size: 24px;    */}
                padding: 4px 10px;
            }
            .yaay-txt{
                {/* font-size: 10px; */}
                font-weight: 700;
            }
            .text-muted{
                {/* font-size: 10px; */}
            }
        `}
      </style>
    </div>
  );
};

export default HostDetails;
