import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { CiCalendar } from "react-icons/ci";
import { BsGlobe2, BsList } from "react-icons/bs";
import { FiClock, FiX } from "react-icons/fi";
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest, FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import "./../brandEvents/EventBrands.css"
import { API_URL } from "../../BackendApi/Api/config";
import Calendar from 'react-calendar';
import "react-calendar/dist/Calendar.css";
import "./../brandEvents/calendar.css"
import Loader2 from '../../Components/Loader/Loader2';
import useLocationInfo from '../useLocationInfo';
import { getBrandsEvents } from '../../BackendApi/Api/Event';
import LocationDisplay from '../../Components/locationsType/LocationType';
import { useHistory } from 'react-router-dom';
import DiscoverEventsNav from '../../Components/DiscoverEventsNav/DiscoverEventsNav';
const BrandEvents = () => {
    const { brandname } = useParams();
    const [activeTab, setActiveTab] = useState('desktop');
    const [brandsData, setBrandsData] = useState({});
    const [currentCity, setCurrentCity] = useState("");
    const [pastEvents, setPastEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [activeTab2, setActiveTab2] = useState('upcoming');
    const [currentEvents, setCurrentEvents] = useState([]);
    const [eventDates, setEventDates] = useState('');
    const [loading, setLoading] = useState(false);
    const { city, state, error } = useLocationInfo();
    const [selectedDate, setSelectedDate] = useState(null);
    const [audienceTags, setAudienceTags] = useState([]);
    const [generalTags, setGeneralTags] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const owner_id = user?._id
    const history = useHistory();
    // const location
    const getEvents = async (brandname) => {
        setLoading(true); // Data fetch karna shuru karte waqt loading true set karo
        try {
            const response = await getBrandsEvents({ brand_name: brandname, owner_id });

            if (response.data.status) {
                console.log(response.data.newBrandsData);
                setBrandsData(response.data.newBrandsData);
                setAudienceTags(response.data.newBrandsData.tags.filter(tag => tag.type === 'audience'));
                setGeneralTags(response.data.newBrandsData.tags.filter(tag => tag.type === 'general'));
                const past = [];
                const upcoming = [];
                response.data.data.forEach(event => {
                    if (event.getPastEvents) {
                        past.push(event);
                    } else {
                        upcoming.push(event);
                    }
                });

                setPastEvents(past);
                setUpcomingEvents(upcoming);
            } else {
                console.error(response.data.message || "Kuch galat ho gaya");
            }
        } catch (error) {
            console.error("Error fetching events:", error);
        } finally {
            setLoading(false); // Data fetch hone ke baad loading ko false kar do
        }
    };
    useEffect(() => {
        if (brandname) {
            getEvents(brandname);
        }
        setCurrentCity(city);
    }, [brandname, activeTab2]);

    let getSocials = brandsData?.brandsocials
    const socials = [
        { platform: "facebook", icon: <FaFacebook size={24} />, link: getSocials?.facebookLink },
        { platform: "linkedin", icon: <FaLinkedin size={20} />, link: getSocials?.linkedinLink },
        { platform: "website", icon: <BsGlobe2 size={20} />, link: getSocials?.websiteLink },
        { platform: "pinterest", icon: <FaPinterest size={20} />, link: getSocials?.pinterestLink },
        { platform: "instagram", icon: <FaInstagram size={20} />, link: getSocials?.instagramLink },
        { platform: "twitter", icon: <FaTwitter size={20} />, link: getSocials?.twitterLink },
        { platform: "tiktok", icon: <FaTiktok size={20} />, link: getSocials?.tiktokLink },
    ];


    useEffect(() => {
        const allEvents = [...upcomingEvents];
        const dates = new Set(
            allEvents.map(event =>
                new Date(event?.events?.properties?.startDate).toDateString()
            )
        );
        setEventDates(dates);
    }, [pastEvents, upcomingEvents]);



    useEffect(() => {
        let filteredEvents;
        if (selectedDate) {
            const filteredUpcoming = upcomingEvents.filter(event => {
                const eventDate = new Date(event?.events?.properties?.startDate);
                return (
                    eventDate.getFullYear() === selectedDate.getFullYear() &&
                    eventDate.getMonth() === selectedDate.getMonth() &&
                    eventDate.getDate() === selectedDate.getDate()
                );
            });

            filteredEvents = filteredUpcoming;
        } else {
            filteredEvents = activeTab2 === 'upcoming' ? upcomingEvents : pastEvents;
        }

        setCurrentEvents(filteredEvents);
    }, [selectedDate, activeTab2, upcomingEvents, pastEvents]);
    const handleTabSwitch = (tab) => {
        setActiveTab2(tab);
        setSelectedDate(null);
    };

    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            const hasEvent = eventDates && eventDates.has(date.toDateString());
            return hasEvent ? <div
                style={{
                    height: "4px",
                    width: "4px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    margin: "auto",
                    marginTop: "1px",
                }}
            ></div> : null;
        }
    };

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            return eventDates && eventDates.has(date.toDateString()) ? 'has-event' : 'no-event';
        }
    };

    const handleDateSelect = (date) => {
        if (eventDates && eventDates.has(date.toDateString())) {
            setSelectedDate(date);
        }
    }
    const handleEventClick = (event) => {
        window.open(`${API_URL}${event}`, "_blank");
    }



    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState("Select an option");

    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleSelect = (option) => {
        // Determine the base events list based on the current tab (upcoming/past)
        const baseEvents = activeTab2 === 'upcoming' ? upcomingEvents : pastEvents;

        // Filter events based on three conditions:
        // 1. Tag match
        // 2. Date match (if a date is selected)
        // 3. Event type (upcoming/past)
        const filteredEvents = baseEvents.filter(event => {
            const eventPro = event?.events?.properties;
            const eventDate = new Date(eventPro?.startDate);

            // Check if the event has the selected tag
            const tagMatch = event.audience_tags.some(tag => tag._id === option._id);

            // Check date match if a date is selected
            const dateMatch = !selectedDate || (
                eventDate.getFullYear() === selectedDate.getFullYear() &&
                eventDate.getMonth() === selectedDate.getMonth() &&
                eventDate.getDate() === selectedDate.getDate()
            );

            return tagMatch && dateMatch;
        });

        // Update the current events with filtered results
        setCurrentEvents(filteredEvents);
        setIsOpen(false);
    }
    return (
        <div className="main">
            {loading ? <Loader2 /> :
                <>
                    <DiscoverEventsNav />
                    <div className="container">
                        {/* Navigation */}

                        {/* Cover Section */}
                        <div className="text-center">
                            <div className="cover-container">
                                <img
                                    src={require("./../../assets/images/dummy/EVENTSLOGO.jpg")}
                                    alt="hero-img"
                                    className="img-fluid"
                                />
                                <div className="brand-container d-flex align-items-center justify-content-between ">
                                    <div className="brand-logo">
                                        <img
                                            src={(brandsData?.brandLogo?.startsWith("http://") || brandsData?.brandLogo?.startsWith("https://"))
                                                ? brandsData.brandLogo
                                                : `API_URL/flowpage/${brandsData.brandLogo}`}
                                            alt="Brand Logo"
                                            className="img-fluid"
                                        />
                                    </div>
                                    <button className="btn btn-success">Subscribe</button>
                                </div>
                                <div className="brand-info text-start mt-3">
                                    <h2>{brandsData.brandname}</h2>
                                    <p className='light-txt me-2'><FiClock /> {currentCity} - {new Date().toLocaleTimeString()}</p>
                                    <p className='light-txt' style={{ fontSize: '18px' }}>{brandsData.brandsDescriptions}</p>
                                    <div className="d-flex light-txt mt-2">
                                        {socials.map(
                                            ({ platform, icon, link }, index) =>
                                                link && (
                                                    <div
                                                        key={platform}
                                                        onClick={() => window.open(link, "_blank")}
                                                        className="me-3"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {icon}
                                                    </div>
                                                )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="brand-container container px-0">
                            <div className="row justify-content-center">
                                <div className="event-info-details col-lg-8 col-12 mb-4" style={{ borderRight: '0.5px solid gray' }}>
                                    <div className="d-flex align-items-center justify-content-between my-4">
                                        <h3 className="">
                                            {activeTab2 === "upcoming" ? "Upcoming Events" : "Past Events"}
                                        </h3>
                                        <div className="d-flex align-items-center">

                                            <div className="me-3" style={{ position: "relative", display: "inline-block" }}>
                                                <button
                                                    onClick={toggleDropdown}
                                                    style={{
                                                        padding: "6px 8px",
                                                        fontSize: "12px",
                                                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                                                        color: "white",
                                                        border: "none",
                                                        cursor: "pointer",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    filter tags ⌵
                                                </button>
                                                {isOpen && (
                                                    <ul
                                                        style={{
                                                            listStyle: "none",
                                                            zIndex: 1000,
                                                            padding: "10px 0",
                                                            margin: 0,
                                                            border: "1px solid #ccc",
                                                            fontSize: "10px",
                                                            borderRadius: "5px",
                                                            position: "absolute",
                                                            top: "100%",
                                                            left: 0,
                                                            width: "100%",
                                                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                        }}
                                                    >
                                                        {audienceTags.map((option) => (
                                                            <li
                                                                key={option}
                                                                onClick={() => handleSelect(option)}
                                                                style={{
                                                                    padding: "10px",
                                                                    cursor: "pointer",
                                                                    backgroundColor: "rgba(255, 255, 255, 0.08)",
                                                                    color: "white",
                                                                    transition: "background 0.2s",
                                                                }}
                                                                onMouseEnter={(e) => (e.target.style.background = "rgba(255, 255, 255, 0.3)")}
                                                                onMouseLeave={(e) => (e.target.style.background = "rgba(255, 255, 255, 0.08)")}
                                                            >
                                                                {option.tag_name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>

                                            <div className="d-flex list-btns me-2" style={{ background: 'transparent' }}>
                                                <span onClick={() => setActiveTab('desktop')}
                                                    className={`tab-icon ${activeTab === 'desktop' ? 'active-tab' : ''} mb-0 `}
                                                >
                                                    <HiMiniComputerDesktop />
                                                </span>
                                                <span onClick={() => setActiveTab('list')}
                                                    className={`tab-icon ${activeTab === 'list' ? 'active-tab' : ''} mb-0 `}
                                                >
                                                    <BsList />
                                                </span>
                                            </div>
                                            <span className="mx-2 d-flex align-items-center list-btns search">
                                                <IoMdSearch />
                                            </span>
                                        </div>
                                    </div>

                                    {currentEvents.length === 0 && (
                                        <div className="card bg-dark text-white" style={{ color: "white" }}>
                                            <div className="card-body d-flex align-items-start">
                                                <CiCalendar size={32} className="me-3" />
                                                <div>
                                                    <h5 className="card-title">No Events</h5>
                                                    <p className="card-text text-muted">Subscribe to get notified when new events are posted.</p>
                                                    <a href="#" className="text-primary">Subscribe</a>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    <>
                                        {activeTab === "desktop" &&
                                            Array.isArray(currentEvents) &&
                                            (() => {
                                                const groupedEvents = currentEvents.reduce((acc, event) => {
                                                    const eventPro = event?.events?.properties;
                                                    const eventDate = new Date(eventPro?.startDate).toDateString(); // Get date string

                                                    acc[eventDate] = acc[eventDate] || [];
                                                    acc[eventDate].push(event);
                                                    return acc;
                                                }, {});

                                                return Object.entries(groupedEvents).map(([date, events], index) => {
                                                    // Sort events for each date by startDate    
                                                    const sortedEvents = events.sort((a, b) =>
                                                        new Date(a?.events?.properties?.startDate) - new Date(b?.events?.properties?.startDate));

                                                    return (

                                                        <div key={index} style={{ marginBottom: "24px" }}>
                                                            <div className="d-flex align-items-start mt-3">
                                                                <div className="line-brandsname col-md-1"></div>
                                                                <div className="dot-img me-3 mt-2"></div>
                                                                <h5 style={{ color: "#fff" }}>{date}</h5>
                                                            </div>

                                                            {sortedEvents.map((event, idx) => {
                                                                const eventPro = event?.events?.properties;
                                                                const eventTime = new Date(eventPro?.startDate).toLocaleTimeString([], {
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                });
                                                                let hostName1
                                                                if (Array.isArray(eventPro?.hostName)) {
                                                                    hostName1 = eventPro?.hostName.slice(0, 2).map((i) => i).join(", ");
                                                                } else {
                                                                    hostName1 = eventPro?.hostName;
                                                                }
                                                                let hostImages = eventPro?.hostedImage

                                                                let rightSideImage = event?.header?.backgroundImage ?? "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=180,height=180/gallery-images/dd/cc8f6a58-7731-4f34-8562-f91f7c3499e7"
                                                                return (
                                                                    <>

                                                                        <div className="card-container mb-5" key={idx} onClick={() => handleEventClick(event?.backHalf)}>
                                                                            <div
                                                                                className="card-wrapper rounded mb-3"
                                                                                style={{ backgroundColor: "rgba(255, 255, 255, 0.04)" }}>
                                                                                <div
                                                                                    role="button"
                                                                                    tabIndex="0"
                                                                                    className="content-card d-flex justify-content-between align-items-center" >
                                                                                    <div className="d-flex flex-column gap-2 flex-grow-1">
                                                                                        <div className="d-flex align-items-center gap-2">
                                                                                            <span className="light-txt">{eventTime}</span>
                                                                                        </div>
                                                                                        <h5 className="text-white mb-1">{eventPro?.eventTitle}</h5>
                                                                                        <div className="d-flex align-items-center gap-2">
                                                                                            <div className="followers-icon d-flex gap-1">
                                                                                                {Array.isArray(hostImages) ? (
                                                                                                    hostImages.slice(0, 2).map((image, index) => (
                                                                                                        <img
                                                                                                            key={index}
                                                                                                            src={API_URL + "profile/" + image}
                                                                                                            alt={`host-${index}`}
                                                                                                            style={{
                                                                                                                marginTop: '4px',
                                                                                                                width: '24px',
                                                                                                                height: '24px',
                                                                                                                borderRadius: '50%',
                                                                                                                marginRight: '8px'
                                                                                                            }}
                                                                                                        />
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <img
                                                                                                        src={API_URL + "profile/" + hostImages}
                                                                                                        alt="host"
                                                                                                        style={{
                                                                                                            marginTop: '4px',
                                                                                                            width: '24px',
                                                                                                            height: '24px',
                                                                                                            borderRadius: '50%',
                                                                                                            marginRight: '8px'
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            </div>
                                                                                            <span className="light-txt">
                                                                                                By {hostName1}
                                                                                                {Array.isArray(eventPro?.hostName) && eventPro?.hostName.length > 2 && (
                                                                                                    <span> and {eventPro?.hostName.length - 2} others</span>
                                                                                                )}
                                                                                            </span>
                                                                                        </div>

                                                                                        <LocationDisplay eventData={eventPro} />

                                                                                        <div
                                                                                            className="badge rounded-pill"
                                                                                            style={{
                                                                                                maxWidth: "70px",
                                                                                                backgroundColor: "#d9632622",
                                                                                                color: "#fba67a",
                                                                                                padding: "8px 6px",
                                                                                                fontSize: "12px",
                                                                                            }}
                                                                                        >
                                                                                            {eventPro?.eventType}
                                                                                        </div>
                                                                                        {(event.audience_tags && event.audience_tags.length > 0) && (
                                                                                            <div className="brand-Vanity-Tag mt-2">
                                                                                                <p className="d-flex align-items-center">
                                                                                                    {event.audience_tags.map((tag) => (
                                                                                                        <span style={{
                                                                                                            color: `${tag.tag_color}`,
                                                                                                            backgroundColor: `${tag.tag_color}40`, // Adds transparency to the background
                                                                                                            padding: '4px 8px',
                                                                                                            borderRadius: '25px',
                                                                                                            display: 'inline-block',
                                                                                                            marginRight: '4px',
                                                                                                        }} className="vanity-tag" key={tag}>
                                                                                                            {tag.tag_name}
                                                                                                        </span>
                                                                                                    ))}
                                                                                                </p>
                                                                                            </div>
                                                                                        )}

                                                                                    </div>
                                                                                    <div
                                                                                        className="cover-image"
                                                                                        style={{
                                                                                            width: "180px",
                                                                                            height: "180px",
                                                                                            overflow: "hidden",
                                                                                            borderRadius: "8px",
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={rightSideImage?.startsWith("http://") || rightSideImage?.startsWith("https://") ? rightSideImage : API_URL + "flowpage/" + rightSideImage}
                                                                                            alt={eventPro?.eventTitle}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                height: "100%",
                                                                                                objectFit: "cover",
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                    )
                                                });
                                            })()}

                                        {activeTab === 'list' && Array.isArray(currentEvents) && (() => {
                                            // Group events by date
                                            const groupedEvents = currentEvents.reduce((acc, event) => {
                                                const eventPro = event?.events?.properties;
                                                const eventDate = new Date(eventPro?.startDate).toDateString(); // Get date string

                                                acc[eventDate] = acc[eventDate] || [];
                                                acc[eventDate].push(event);
                                                return acc;
                                            }, {});

                                            return Object.entries(groupedEvents).map(([date, events], index) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: '24px' }}>
                                                        <div className="d-flex align-items-start mt-3">
                                                            <div className="dot-img me-3 mt-2"></div>
                                                            <h5 style={{ color: '#fff' }}>{date}</h5>
                                                        </div>


                                                        <div>
                                                            {events.map((event, idx) => {
                                                                const eventPro = event?.events?.properties;
                                                                const eventTime = new Date(eventPro?.startDate).toLocaleTimeString([], {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                });
                                                                let hostImages = eventPro?.hostedImage
                                                                let hostName1
                                                                if (Array.isArray(eventPro?.hostName)) {
                                                                    hostName1 = eventPro?.hostName.map((i) => i).join(", ");
                                                                } else {
                                                                    hostName1 = eventPro?.hostName
                                                                }
                                                                return (
                                                                    <div key={idx} className="row animated"
                                                                        style={{ display: 'flex', flexDirection: 'row', }}>
                                                                        <div className="d-flex align-items-center">

                                                                            {/* Event Details */}
                                                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: "baseline", backgroundColor: "rgba(255, 255, 255, 0.04)", borderRadius: "8px", padding: "10px", marginTop: "2px" }}>
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <div className='d-flex align-items-center '>

                                                                                        <div
                                                                                            className="time text-tertiary-alpha"
                                                                                            style={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '14px', padding: "0px 16px" }}
                                                                                        >
                                                                                            {eventTime}
                                                                                        </div>
                                                                                        <div style={{ flex: 1 }}>
                                                                                            <div
                                                                                                style={{
                                                                                                    fontWeight: '500',
                                                                                                    fontSize: '16px',
                                                                                                    color: '#fff',
                                                                                                    overflow: 'hidden',
                                                                                                }}>
                                                                                                {eventPro?.eventTitle}
                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    overflow: 'hidden',
                                                                                                    whiteSpace: 'nowrap',
                                                                                                    textOverflow: 'ellipsis',
                                                                                                    color: 'rgba(255, 255, 255, 0.7)',
                                                                                                    display: 'flex',
                                                                                                    flexDirection: 'row',
                                                                                                }}>

                                                                                                {Array.isArray(hostImages) ? (
                                                                                                    hostImages.map((image, index) => (
                                                                                                        <img
                                                                                                            key={index}
                                                                                                            src={API_URL + "profile/" + image}
                                                                                                            alt={`host-${index}`}
                                                                                                            style={{
                                                                                                                marginTop: '4px',
                                                                                                                width: '24px',
                                                                                                                height: '24px',
                                                                                                                borderRadius: '50%',
                                                                                                                marginRight: '8px'
                                                                                                            }}
                                                                                                        />
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <img
                                                                                                        src={API_URL + "profile/" + hostImages}
                                                                                                        alt="host"
                                                                                                        style={{
                                                                                                            marginTop: '4px',
                                                                                                            width: '24px',
                                                                                                            height: '24px',
                                                                                                            borderRadius: '50%',
                                                                                                            marginRight: '8px'
                                                                                                        }}
                                                                                                    />
                                                                                                )}

                                                                                                <p style={{ marginTop: 5 }}>By {hostName1}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* Price */}
                                                                                    <div
                                                                                        className="meta-tag"
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'flex-end',
                                                                                        }}>
                                                                                        <div
                                                                                            className="pill light variant-color-green tiny"
                                                                                            style={{
                                                                                                backgroundColor: '#28a745',
                                                                                                padding: '4px 8px',
                                                                                                borderRadius: '12px',
                                                                                                fontSize: '12px',
                                                                                                color: '#fff',
                                                                                                display: 'inline-flex',
                                                                                                alignItems: 'center',
                                                                                            }}>
                                                                                            <div className="pill-label" style={{ paddingLeft: '4px' }}>
                                                                                                {eventPro?.eventType ? eventPro?.eventType : 'Free'}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='d-flex align-items-center'>

                                                                                    {(event.audience_tags && event.audience_tags.length > 0) && (
                                                                                        <div className="brand-Vanity-Tag mt-2">
                                                                                            <p className="d-flex align-items-center">
                                                                                                {event.audience_tags.map((tag) => (
                                                                                                    <span style={{
                                                                                                        color: `${tag.tag_color}`,
                                                                                                        backgroundColor: `${tag.tag_color}40`, // Adds transparency to the background
                                                                                                        padding: '4px 8px',
                                                                                                        borderRadius: '25px',
                                                                                                        display: 'inline-block',
                                                                                                        marginRight: '4px',
                                                                                                    }} className="vanity-tag" key={tag}>
                                                                                                        {tag.tag_name}
                                                                                                    </span>
                                                                                                ))}
                                                                                            </p>
                                                                                        </div>
                                                                                    )}

                                                                                </div>


                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            });
                                        })()}
                                    </>

                                </div>

                                <div className="event-info-calendar col-lg-4 col-12">
                                    <h4 className="text-center">Calendar</h4>
                                    <div
                                        className="specific-area"
                                        style={{
                                            border: "1px solid #7e869b",
                                            borderRadius: "6px",
                                            padding: "5px",
                                        }}
                                    >
                                        {/* Calendar */}
                                        <div style={{ position: "relative" }}>
                                            <Calendar
                                                tileContent={tileContent}
                                                tileClassName={tileClassName}
                                                onChange={handleDateSelect}
                                                tileDisabled={({ date }) => eventDates && !eventDates.has(date.toDateString())} // Disable dates without events
                                                value={selectedDate}
                                            />
                                        </div>

                                        {/* Tabs or Selected Date Content */}
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                marginTop: "38px",
                                                minHeight: "47px", // Ensures a consistent height
                                                justifyContent: "center", // Center content vertically
                                            }}
                                        >
                                            {selectedDate ? (
                                                // Selected Date Content
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        backgroundColor: "#b9a4a436",
                                                        borderRadius: "8px",
                                                        padding: "10px",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {selectedDate.toLocaleDateString("en-US", {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "numeric",
                                                        })}
                                                    </div>
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "white",
                                                            border: "1px solid white",
                                                            borderRadius: "50%",
                                                            padding: "1px 3px",
                                                        }}
                                                        onClick={() => setSelectedDate(null)}
                                                    >
                                                        <FiX />
                                                    </div>
                                                </div>
                                            ) : (
                                                // Tabs
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-around",
                                                        gap: "10px",
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        padding: "4px 0px",
                                                        backgroundColor: "#b9a4a436",

                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            color: activeTab2 === "upcoming" ? "white" : "black",
                                                            background: activeTab2 === "upcoming" ? "#b9a4a436" : "transparent",
                                                            border: activeTab2 === "past" ? "1px solid #b9a4a436" : "none",
                                                            borderRadius: "8px",
                                                        }}
                                                        onClick={() => handleTabSwitch("upcoming")}
                                                    >
                                                        Upcoming
                                                    </button>
                                                    <button
                                                        style={{
                                                            background: activeTab2 === "past" ? "#b9a4a436" : "transparent",
                                                            border: activeTab2 === "upcoming" ? "1px solid #b9a4a436" : "none",
                                                            color: activeTab2 === "past" ? "white" : "black",
                                                            borderRadius: "8px",
                                                            padding: "0px 35px",
                                                        }}
                                                        onClick={() => handleTabSwitch("past")}
                                                    >
                                                        Past
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>


                                <style>
                                    {`

    `}
                                </style>



                            </div>
                        </div>
                    </div>

                </>
            }
        </div>

    )
}

export default BrandEvents

