import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { MdOutgoingMail, MdPages } from "react-icons/md";
import { API_URL } from "../../BackendApi/Api/config";
import { contactToHost } from "../../BackendApi/Api/Event";
import { useParams } from "react-router-dom";

const UserInputModal = ({
  totalCount,
  maxAttendees,
  waitlist,
  eventUserdata,
  typeData,
  type,
  show,
  handleClose,
  Event_id,
  data,
}) => {
  const { username } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    title: "",
    company: "",
    image: null,
    message: "",
    imagePreview: null,
    message: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFormData((prev) => ({
        ...prev,
        image: file,
        imagePreview: imageUrl,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let response;
    let userStatus;

    if (type == "apply") {
      if (waitlist) {
        const maxAttendeesCount = maxAttendees ?? 0;
        const registeredCount = eventUserdata?.length ?? 0;
        userStatus = registeredCount >= maxAttendeesCount ? 0 : 1; // 0 = waitlist, 1 = pending
      }
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("userName", formData.name);
      formDataToSubmit.append("email", formData.email);
      formDataToSubmit.append("event_image", formData.image);
      formDataToSubmit.append("Event_id", Event_id);
      formDataToSubmit.append("status", userStatus);
      formDataToSubmit.append("backhalf", username);
      formDataToSubmit.append("phone", formData.phone);
      formDataToSubmit.append("title", formData.title);
      formDataToSubmit.append("company", formData.company);
      try {
        response = await fetch(`${API_URL}event/applyEvent`, {
          method: "POST",
          body: formDataToSubmit,
        });
        const result = await response.json();
        if (result.status == true) {
          handleClose();
        } else {
          alert(result.message);
        }
      } catch (error) {
        alert("Error: " + error.message);
      }
    } else if (type == "hosted") {
      const dataResult = {
        user_name: formData.name,
        event_name: data.eventTitle,
        host_email: data.email,
        host_name: data.hostName,
        email: formData.email,
        message: formData.message,
        Event_id: Event_id,
        backhalf: username,
        phone: formData.phone,
        title: formData.title,
        company: formData.company,
      };
      try {
        response = await contactToHost(dataResult);
        handleClose();
      } catch (error) {
        alert("Error: " + error.message);
      }
    }
  };
  const handleAddContact = async () => {
    let status = "Pending Approval";

    if (maxAttendees < totalCount && waitlist) {
      status = "Waitlist";
    }
    const data = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      title: formData.title,
      company: formData.company,
      backhalf: username,
      status: status,
      message: formData.message,
    };

    try {
      const response = await fetch(`${API_URL}event/apply-to-event`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.success) {
        handleClose();
        window.location.reload();
      } else {
        alert(result.message);
      }
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="mini-page-modal Sidebar-Modal"
      >
        <Modal.Header
          closeButton
          style={{
            background: "linear-gradient(90deg, #0b10e7 0%, #2adf63 100%)",
            color: "#fff",
            padding: "14px 22px",
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
          }}
        >
          <Modal.Title className="delete-modal-title">
            {" "}
            {type == "apply" ? (
              <>
                {" "}
                <MdPages /> User Apply
              </>
            ) : (
              <>
                {" "}
                <MdOutgoingMail /> Contact Host
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="custum-modal-body"
          style={{ padding: 20, margin: 10 }}
        >
          <Form>
            <Form.Group
              className="col-lg-12 did-floating-label-content"
              controlId="formBasicName"
            >
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="did-floating-input form-control"
                  placeholder=" "
                  value={formData.name}
                  name="name"
                  onChange={handleInputChange}
                  required
                  style={{ paddingTop: "1.25rem" }}
                />
                <Form.Label
                  className="did-floating-label"
                  style={{
                    position: "absolute",
                    top: formData.name ? "-0.75rem" : "0",
                    left: "0.75rem",
                    fontSize: formData.name ? "0.75rem" : "1rem",
                    color: formData.name ? "#007bff" : "#6c757d",
                    transition: "all 0.2s ease",
                    pointerEvents: "none",
                  }}
                >
                  Name
                </Form.Label>
              </div>
            </Form.Group>
            <Form.Group
              className="col-lg-12 did-floating-label-content"
              controlId="formBasicEmail"
            >
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="did-floating-input form-control"
                  placeholder=" "
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  style={{ paddingTop: "1.25rem" }}
                />
                <Form.Label
                  className="did-floating-label"
                  style={{
                    position: "absolute",
                    top: formData.email ? "-0.75rem" : "0",
                    left: "0.75rem",
                    fontSize: formData.email ? "0.75rem" : "1rem",
                    color: formData.email ? "#007bff" : "#6c757d",
                    transition: "all 0.2s ease",
                    pointerEvents: "none",
                  }}
                >
                  Email
                </Form.Label>
              </div>
            </Form.Group>

            <Form.Group
              className="col-lg-12 did-floating-label-content"
              controlId="formBasicPhone"
            >
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="did-floating-input form-control"
                  placeholder=" "
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                  style={{ paddingTop: "1.25rem" }}
                />
                <Form.Label
                  className="did-floating-label"
                  style={{
                    position: "absolute",
                    top: formData.phone ? "-0.75rem" : "0",
                    left: "0.75rem",
                    fontSize: formData.phone ? "0.75rem" : "1rem",
                    color: formData.phone ? "#007bff" : "#6c757d",
                    transition: "all 0.2s ease",
                    pointerEvents: "none",
                  }}
                >
                  Phone
                </Form.Label>
              </div>
            </Form.Group>

            <Form.Group
              className="col-lg-12 did-floating-label-content"
              controlId="formBasicTitle"
            >
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="did-floating-input form-control"
                  placeholder=" "
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  required
                  style={{ paddingTop: "1.25rem" }}
                />
                <Form.Label
                  className="did-floating-label"
                  style={{
                    position: "absolute",
                    top: formData.title ? "-0.75rem" : "0",
                    left: "0.75rem",
                    fontSize: formData.title ? "0.75rem" : "1rem",
                    color: formData.title ? "#007bff" : "#6c757d",
                    transition: "all 0.2s ease",
                    pointerEvents: "none",
                  }}
                >
                  Title
                </Form.Label>
              </div>
            </Form.Group>

            <Form.Group
              className="col-lg-12 did-floating-label-content"
              controlId="formBasicCompany"
            >
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="did-floating-input form-control"
                  placeholder=" "
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  required
                  style={{ paddingTop: "1.25rem" }}
                />
                <Form.Label
                  className="did-floating-label"
                  style={{
                    position: "absolute",
                    top: formData.company ? "-0.75rem" : "0",
                    left: "0.75rem",
                    fontSize: formData.company ? "0.75rem" : "1rem",
                    color: formData.company ? "#007bff" : "#6c757d",
                    transition: "all 0.2s ease",
                    pointerEvents: "none",
                  }}
                >
                  Company
                </Form.Label>
              </div>
            </Form.Group>

            <Form.Group
              className="col-lg-12 did-floating-label-content"
              controlId="formBasicMessage"
            >
              <div style={{ position: "relative" }}>
                <textarea
                  className="did-floating-input form-control"
                  placeholder=" "
                  name="Notes"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  style={{ paddingTop: "1.25rem" }}
                />
                <Form.Label
                  className="did-floating-label"
                  style={{
                    position: "absolute",
                    top: formData.message ? "-0.75rem" : "0",
                    left: "0.75rem",
                    fontSize: formData.message ? "0.75rem" : "1rem",
                    color: formData.message ? "#007bff" : "#6c757d",
                    transition: "all 0.2s ease",
                    pointerEvents: "none",
                  }}
                >
                  Notes
                </Form.Label>
              </div>
            </Form.Group>

            {/* {type == "apply" && (
                        <>
                            <Form.Group controlId="formImage">
                                <Form.Label>Upload Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    required
                                />
                            </Form.Group>
                            {formData.imagePreview && (
                                <div className="mt-3">
                                    <img
                                        src={formData.imagePreview}
                                        alt="Preview"
                                        style={{ width: "200px", height: 'auto' }}
                                    />
                                </div>
                            )}</>)}

                    {type == "hosted" && (
                        <Form.Group className="col-lg-12 did-floating-label-content" controlId="formBasicName">
                            <div style={{ position: "relative" }}>

                                <textarea className="did-floating-input form-control" placeholder="enter your message " value={formData.message} onChange={handleInputChange} name="message" required style={{ paddingTop: "1.25rem" }} />
                                <Form.Label
                                    className="did-floating-label"
                                    style={{
                                        position: "absolute",
                                        top: formData.message ? "-0.75rem" : "0",
                                        left: "0.75rem",
                                        fontSize: formData.message ? "0.75rem" : "1rem",
                                        color: formData.message ? "#007bff" : "#6c757d",
                                        transition: "all 0.2s ease",
                                        pointerEvents: "none",
                                    }}
                                >
                                    message
                                </Form.Label>
                            </div>
                        </Form.Group>
                    )} */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="Active-bttn btn" onClick={handleAddContact}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserInputModal;
