import React, { useState } from "react";
import { Button, Modal, Form, Badge } from "react-bootstrap";
import axios from "axios";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import { crmActivitiesContext } from "../../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";
import Select from "react-select";
import { X } from 'lucide-react'; // Assuming you're using react-feather icons

const API_URL = process.env.REACT_APP_API_URL;

const CallModal = ({
    showModal,
    setShowModal,
    componentType,
    id,
    onCallLogged,
    contacts
}) => {

    const { crmActivitiesDispatch } = useContext(crmActivitiesContext);
    const [callData, setCallData] = useState({
        outcome: "",
        direction: "",
        call_notes: "",
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        date: new Date().toISOString().split('T')[0],
        contacted: []
    });

    const handleLogCall = async () => {
        if (!callData.outcome || !callData.direction || !callData.call_notes || callData.contacted.length === 0) {
            PNotify.error({ title: "Error", text: "Please fill all required fields", delay: 3000 });
            return;
        }

        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.post(`${API_URL}/crm/log-call`, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                crm_type: componentType,
                owner_id: user._id,
                outcome: callData.outcome,
                direction: callData.direction,
                call_notes: callData.call_notes,
                call_time: callData.time,
                call_date: callData.date,
                author: user.fname || "User",
                contacted: callData.contacted.map((c) => c.value)
            });

            if (response.data.success) {
                // onCallLogged(response.data.data);
                crmActivitiesDispatch({ type: "SET_TEMP_FETCH", payload: false });
                setShowModal(false);
                resetForm();
                PNotify.success({ title: "Success", text: "Call logged successfully", delay: 2000 });
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to log call", delay: 3000 });
        }
    };

    const resetForm = () => {
        setCallData({
            outcome: "",
            direction: "",
            call_notes: "",
            contacted: [],
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            date: new Date().toISOString().split('T')[0]
        });
    };

    const contactOptions = contacts.map((c) => ({
        value: c._id,
        label: `${c.firstName} ${c.lastName} (${c.email})`,
    }));

    const handleContactChange = (selected) => {
        setCallData((prev) => ({
            ...prev,
            contacted: selected || [],
        }));
    };

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} animation={true} className="Sidebar-Modal-end" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Log Call</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                    <Form.Group className="mb-3">
                        <Form.Label>Contacted</Form.Label>
                        <Select
                            options={contactOptions}
                            isMulti
                            value={callData.contacted}
                            onChange={handleContactChange}
                            placeholder="Select contacts..."
                        />
                        <div className="d-flex flex-wrap gap-2 mt-2">
                            {callData.contacted.map((contact) => (
                                <Badge key={contact.value} className="p-2">
                                    {contact.label}
                                    <X
                                        size={14}
                                        className="ms-2 cursor-pointer"
                                        onClick={() =>
                                            handleContactChange(
                                                callData.contacted.filter((c) => c.value !== contact.value)
                                            )
                                        }
                                    />
                                </Badge>
                            ))}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Outcome</Form.Label>
                        <Form.Select
                            value={callData.outcome}
                            onChange={(e) => setCallData({ ...callData, outcome: e.target.value })}
                        >
                            <option value="">Select outcome</option>
                            <option value="Successful">Successful</option>
                            <option value="No Answer">No Answer</option>
                            <option value="Left Voicemail">Left Voicemail</option>
                            <option value="Wrong Number">Wrong Number</option>
                            <option value="Busy">Busy</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Direction</Form.Label>
                        <Form.Select
                            value={callData.direction}
                            onChange={(e) => setCallData({ ...callData, direction: e.target.value })}
                        >
                            <option value="">Select direction</option>
                            <option value="Outbound">Outbound</option>
                            <option value="Inbound">Inbound</option>
                        </Form.Select>
                    </Form.Group>

                    <div className="row mb-3">
                        <div className="col">
                            <Form.Group>
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={callData.date}
                                    onChange={(e) => setCallData({ ...callData, date: e.target.value })}
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group>
                                <Form.Label>Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    value={callData.time}
                                    onChange={(e) => setCallData({ ...callData, time: e.target.value })}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={callData.call_notes}
                            onChange={(e) => setCallData({ ...callData, call_notes: e.target.value })}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                <Button variant="primary" onClick={handleLogCall}>Save Call</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CallModal;