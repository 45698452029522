// import React, { useEffect, useState, useContext } from "react";
// import { BsStars, BsVoicemail } from "react-icons/bs";
// import { MdLocationOn } from "react-icons/md";
// import GoogleMapReact from 'google-map-react';
// import UserInputModal from "../../../EventBuilder/Components/UserInputModal";
// import { API_URL, REACT_APP_WEBSITE } from "../../../BackendApi/Api/config";
// import { EventContext } from "../../../EventBuilder/EventsContext";
// import { format } from 'date-fns';
// import { enUS } from 'date-fns/locale';
// import { getSettings } from "../../../BackendApi/Api/Event";
// import { AiFillContacts, AiFillMail, AiOutlineMail } from "react-icons/ai";
// import Demo from "../../../EventBuilder/Components/demo";
// import LocationDisplay from "../../../Components/locationsType/LocationType";
// const AnyReactComponent = ({ text }) => <div>{text}</div>;

// function Event(props) {
//     const [showModal, setShowModal] = useState(false);
//     const [userEvents, setUserEvents] = useState([]);
//     const { startDate, endDate, location, hostName, hostedImage, locationType } = props.data;
//     const [type, setType] = useState('');
//     const [typeData, setTypeData] = useState({});
//     const [formData, setFormData] = useState({});
//     const handleShow = (data, type) => {
//         props.handleShowModel(type, data);
//     };
//     const handleClose = () => setShowModal(false);
//     const defaultProps = {
//         center: {
//             lat: 10.99835602,
//             lng: 77.01502627
//         },
//         zoom: 11
//     };
//     const getSettingData = async (event_id) => {
//         try {
//             const result = await getSettings({ event_id });
//             if (result.data.status) {
//                 setFormData(result.data.data);
//                 props.setFormData(result.data.data);
//             }
//         } catch (error) {
//             alert(error.message);
//         }
//     };

//     useEffect(() => {
//         if (props.smartlink_id) {
//             getSettingData(props.smartlink_id);
//         }
//     }, [props]);
//     const getEvents = async () => {
//         try {
//             const response = await fetch(`${API_URL}event/getEvent`, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify({ Event_id: props.smartlink_id }),
//             });
//             const data = await response.json();
//             if (data.status) {
//                 setUserEvents(data.data);
//             } else {
//                 alert(data.message);
//             }
//         } catch (error) {
//             alert(error);
//         }
//     };

//     useEffect(() => {
//         if (props.smartlink_id) {
//             getEvents(props.smartlink_id);
//         }
//     }, [props, showModal]);
//     let eventUserdata = userEvents?.userEvents

//     const renderUserNames = (userData) => {
//         if (!userData) return '';
//         const maxVisible = 2; // Number of names to display
//         const totalUsers = userData?.length;

//         if (totalUsers === 0) return ''; // No users to display
//         if (totalUsers <= maxVisible) {
//             return userData.join(', '); // Show all names
//         }

//         // Display the first two names and the count of remaining users
//         const displayedNames = userData.slice(0, maxVisible).join(', ');
//         const remainingCount = totalUsers - maxVisible;

//         return `${displayedNames} and ${remainingCount} others`;
//     };

//     const start = new Date(startDate);
//     const end = new Date(endDate);

//     const monthName = format(start, 'MMM', { locale: enUS }).toUpperCase();
//     const dayName = format(start, 'd');
//     const weekdayName = format(start, 'EEEE, MMMM d', { locale: enUS });
//     const startTime = format(start, 'hh:mm a');
//     const endTime = format(end, 'hh:mm a');
//     const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`;

//     return (
//         <>
//             <UserInputModal
//                 maxAttendees={formData.maxAttendees}
//                 waitlist={formData.isWaitlist}
//                 eventUserdata={eventUserdata}
//                 Event_id={props.smartlink_id}
//                 typeData={props.modeldata}
//                 handleClose={props.handleClose}
//                 show={props.showModal}
//                 type={props.type}
//                 data={props.data}
//             />

//             <div className={`${props.types ? "transparent" : "event-section"}`} id={props.data.unique_id}>
//                 <div className="event-section-inner">

//                     <div className="event-tper-sec">
//                         <div style={{ display: "flex", justifyContent: "space-between" }}>
//                             <div className="event-type-box">
//                                 <div className="event-type-icon">
//                                     <BsStars />
//                                 </div>
//                                 <div className="event-type-text">
//                                     {props.data.eventType} Event
//                                 </div>
//                             </div>
//                             <div className="event-type-box">

//                                 <div className="event-type-text">
//                                     {formData.eventType == 1 ? "Paid" : "Free"}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="event-title" style={{ marginLeft: "5px" }}>
//                         <div className="day-name">Event Tags:  {formData.statusTag === 1 ? "Upcoming" :
//                             formData.statusTag === 2 ? "Limited Seats" :
//                                 formData.statusTag === 0 ? "Sold Out" : ""}</div>
//                     </div>
//                     <div className="event-title">
//                         <h2 className="m-0">{props.data.eventTitle}</h2>
//                     </div>

//                     <div className="event-date-sec">
//                         <div className="event-date-inner">
//                             <div className="date-icon">
//                                 <div className="month-name">
//                                     {monthName}
//                                 </div>
//                                 <div className="day-name">
//                                     {dayName}
//                                 </div>
//                             </div>
//                             <div className="event-date-text">
//                                 <div className="event-date">
//                                     {weekdayName}
//                                 </div>
//                                 <div className="event-time">
//                                     {`${startTime} - ${endTime}`}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     {/* <div className="event-location-sec mt-2">
//                         <div className="event-date-inner">
//                             <div className="date-icon d-flex align-items-center justify-content-center">
//                                 <MdLocationOn />
//                             </div>
//                             <div className="event-date-text">

//                                 <div className="event-date">
//                                     {location}
//                                 </div>

//                             </div>
//                         </div>
//                     </div> */}
//                     {location && (
//                         <div className="mt-2">
//                             <LocationDisplay type="showUrls" eventData={props.data} />
//                         </div>
//                     )}

//                     <div className="Registration-sec">
//                         <div className="registration-inner">
//                             <div className="registration-header">
//                                 Registration
//                             </div>
//                             <div className="inner">
//                                 <div className="jsx-3830652295">
//                                     <div className="jsx-3830652295 flex-column content">
//                                         <div className="jsx-1768947162 info-rows flex-column one">
//                                             <div className="jsx-1768947162 d-flex align-items-center flex-align one">
//                                                 <div className="icon-box">
//                                                     <BsStars />
//                                                 </div>
//                                                 <div className="event-date-text">
//                                                     <div className="event-date">
//                                                         Approval Required
//                                                     </div>
//                                                     <div className="event-time">
//                                                         Your registration is subject to approval by the host.
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="jsx-3363824362 message">Welcome! To join the event, please register below. </div>

//                                         {formData.capacityType == 1 && formData.maxAttendees > 0 ? (
//                                             <>
//                                                 <div className="jsx-3363824362 message">
//                                                     {`Max Attendees ${formData.maxAttendees}`}</div>
//                                                 <div className="jsx-3363824362 message">{formData.showGuestCount == true ? userEvents?.totalUserLength : '0'} Going</div></>) : null}

//                                         {formData.showTicketsLeft && userEvents?.totalUserLength <= formData.maxAttendees ? (
//                                             <div className="jsx-3363824362 message">
//                                                 Tickets Left {formData.maxAttendees - userEvents?.totalUserLength}
//                                             </div>
//                                         ) : <p>Tickets Left 0</p>}

//                                         {formData.isWaitlist && userEvents?.totalUserLength > formData.maxAttendees ? (
//                                             <div className="jsx-3363824362 message">
//                                                 Waitlist {userEvents?.totalUserLength - formData.maxAttendees}
//                                             </div>
//                                         ) : null}

//                                         <button className="btn one-click" onClick={() => handleShow(props, "apply", formData)}>One Click Apply</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="event-description-sec mt-3">
//                         <div className="event-description-title event-xyt">
//                             About Event
//                         </div>
//                         <div className="event-description-text">
//                             {props.data.description}
//                         </div>
//                     </div>
//                     {locationType !== "Virtual" &&
//                         <div className="event-description-sec location mt-3">
//                             <div className="event-description-title event-xyt">
//                                 Location
//                             </div>
//                             <div className="event-description-text">

//                                 <p>{props.data.location}</p>
//                                 {/* <h5>Mel's Drive-In</h5>
//                             <p className="">801 Mission St, San Francisco, CA 94103, USA
//                                 We will try and meet Mel's Diner on Mission St. Look for Bobby Torres or Spencer Kersch. Text this number if you are lost (954) 558-4754</p> */}
//                             </div>
//                             <div style={{ height: '200px', width: '100%' }}>
//                                 <GoogleMapReact
//                                     bootstrapURLKeys={{ key: "" }}
//                                     defaultCenter={defaultProps.center}
//                                     defaultZoom={defaultProps.zoom}
//                                     className="map-container"
//                                 >
//                                     <AnyReactComponent
//                                         lat={59.955413}
//                                         lng={30.337844}
//                                         text="My Marker"
//                                     />
//                                 </GoogleMapReact>
//                             </div>
//                         </div>}

//                     <div className="event-description-sec mt-4">
//                         <div className="event-description-title event-xyt">
//                             {userEvents?.totalUserLength} Going
//                         </div>
//                         <div className="total-people-sec">
//                             <div className="total-people-inner pb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                                 <div className="total-people-list" style={{ display: 'flex', alignItems: 'center' }}>

//                                     {Array.isArray(eventUserdata) &&
//                                         eventUserdata.slice(0, 3).map((item, index) => ( // Show only 3 users
//                                             <div className="total-people-img" key={item._id || index}>
//                                                 {item.user_image ? (
//                                                     <img src={`${API_URL}events/${item.user_image}`} alt="" />
//                                                 ) : (
//                                                     <img src={require("../../../assets/images/Avatar.webp")} alt="" />
//                                                 )}
//                                             </div>
//                                         ))}
//                                     {eventUserdata?.length > 3 && ( // Show the remaining user count if more than 3
//                                         <div className="remaining-users-count">
//                                             +{eventUserdata.length - 3} more
//                                         </div>
//                                     )}
//                                 </div>

//                             </div>
//                             <div className="guests-string animated">
//                                 {renderUserNames(eventUserdata?.map(item => item.userName))}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default Event;

import React, { useEffect, useState } from "react";
import { BsStars } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import GoogleMapReact from "google-map-react";
import UserInputModal from "../../../EventBuilder/Components/UserInputModal";
import { API_URL } from "../../../BackendApi/Api/config";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { getSettings } from "../../../BackendApi/Api/Event";
import LocationDisplay from "../../../Components/locationsType/LocationType";
import { FaPlusCircle } from "react-icons/fa";
import { useParams, useHistory } from "react-router-dom";
import GoogleMaps from "../../../EventBuilder/EventsTab/GoogleMap2";
import axios from "axios";
import CopyCat from "../../../Components/CopyCat/CopyCat";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Event({
  data,
  smartlink_id,
  showModal,
  handleShowModel,
  handleSetFormData,
  handleClose,
  types,
  modeldata,
  type,
  venue,
}) {
  const history = useHistory();
  const [userEvents, setUserEvents] = useState([]);
  const [formData, setFormDataState] = useState({});
  const [eventUserdata, setEventUserdata] = useState([]);
  const[goingGuests, setGoingGuests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
const [allGuests, setAllGuests] = useState([]);
const[waitlistData, setWaitlistData] = useState([]);
  const {
    startDate,
    endDate,
    location,
    eventType,
    description,
    eventTitle,
    locationType,
  } = data;
  const defaultMapProps = {
    center: { lat: 10.99835602, lng: 77.01502627 },
    zoom: 11,
  };

  const [locate, setLocate] = useState({
    lat: 10.99835602,
    lng: 77.01502627,
  });

  useEffect(() => {
    if (locationType === "Physical") {
      if (venue?.event_venue_location?.coordinates?.length > 0) {
        setLocate({
          lat: venue?.event_venue_location?.coordinates[1],
          lng: venue?.event_venue_location?.coordinates[0],
        });
      }
    }
  }, [venue]);

  const fetchEventSettings = async (eventId) => {
    try {
      const result = await getSettings({ event_id: eventId });
      if (result.data.status) {
        setFormDataState(result.data.data);
        // handleSetFormData(result.data.data);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const fetchUserEvents = async (eventId) => {
    try {
      const response = await fetch(`${API_URL}event/getEvent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ Event_id: eventId }),
      });
      const data = await response.json();
      console.log("the event data", data);
      if (data.status) {
        setUserEvents(data.data);
        setEventUserdata(data.data.userEvents);
      } else {
        alert(data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

    const fetchGuests = async (smartlink_id) => {
        try {
            setLoading(true);
            const response = await axios.post(`${API_URL}event/get-event-guests`, {
                event_id: smartlink_id
            });

            if (response.data.success) {
                // Transform the data to match the component's expected format
                const formattedGuests = response.data.data.map(guest => ({
                    id: guest._id,
                    name: guest.name || 'Guest', // Fallback if name is not provided
                    email: guest.email,
                    phone: guest.phone,
                    title: guest.title,
                    status: guest.status,
                    avatar: guest.name ? guest.name.charAt(0).toUpperCase() : 'G',
                    statusUpdatedAt: guest.updatedAt,
                    invitedAt: guest.createdAt
                }));
// console.log("formattedGuests", formattedGuests);
                setAllGuests(formattedGuests.filter(guest => guest.status != 'Going'));
                setGoingGuests(formattedGuests.filter(guest => guest.status === 'Going'));
                setWaitlistData(formattedGuests.filter(guest => guest.status === 'Waitlist'));
            } else {
                setError('Failed to fetch guests');
            }
        } catch (error) {
            console.error('Error fetching guests:', error);
            setError('An error occurred while fetching guests');
        } finally {
            setLoading(false);
        }
    };



  useEffect(() => {
    if (smartlink_id) {
      fetchEventSettings(smartlink_id);
      // fetchUserEvents(smartlink_id);
      fetchGuests(smartlink_id)
    }
  }, [smartlink_id]);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return {
      month: format(date, "MMM", { locale: enUS }).toUpperCase(),
      day: format(date, "d"),
      fullDate: format(date, "EEEE, MMMM d", { locale: enUS }),
      time: format(date, "hh:mm a"),
    };
  };

  const startDetails = formatDateTime(startDate);
  const endDetails = formatDateTime(endDate);

  const renderUserNames = (userData = []) => {
    console.log("userData", userData);
    const maxVisible = 2;
    const totalUsers = userData.length;

    if (totalUsers === 0) return "";
    if (totalUsers <= maxVisible) return userData.join(", ");

    const displayedNames = userData.slice(0, maxVisible).join(", ");
    return `${displayedNames} and ${totalUsers - maxVisible} others`;
  };

  const handleModalShow = (data, type) => handleShowModel(type, data);
  let newUserEvents = Array.isArray(eventUserdata) ? eventUserdata : [];
  const Avatar = ({ name, index, title }) => {
    const firstLetter = name ? name.charAt(0).toUpperCase() : 'G';
    return (
      <>
        <div style={{ 
            width: '40px', 
            height: '40px', 
            borderRadius: '50%', 
            backgroundColor: 'gray', 
            color: 'white', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            fontSize: '18px',
            fontWeight: 'bold',
            position: 'relative',
            left: `${-index * 15}px`, // Overlapping effect
            border: '2px solid white' // Optional: White border for better visibility
        }}>
            {firstLetter} 
        </div>
      </>
    );
}
const AvatarRow = ({ names, title }) => {
  const maxVisible = 5; // Maximum 5 avatars
  const visibleAvatars = names.slice(0, maxVisible); // First 5
  const hiddenCount = names.length - maxVisible; // Remaining count

  return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
          {visibleAvatars.map((name, index) => (
              <Avatar key={index} name={name} index={index} title={title} />
          ))}
          {hiddenCount > 0 && (
              <div 
              style={{ 
                  width: '40px', 
                  height: '40px', 
                  borderRadius: '50%', 
                  backgroundColor: 'gray', 
                  color: 'white', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  fontSize: '14px',
                  fontWeight: 'bold',
                  position: 'relative',
                  left: `${-visibleAvatars.length * 15}px`, 
                  border: '2px solid white' 
              }}
              >
                  +{hiddenCount}
              </div>
          )}
      </div>
  );
};
let allguestsCount = allGuests?.length;
let goingguestsCount = goingGuests?.length;
let totalCount = allguestsCount + goingguestsCount;
console.log("allguestsCount", formData);

  return (
    <>
      <UserInputModal
        maxAttendees={formData.maxAttendees}
        waitlist={formData.isWaitlist}
        totalCount={totalCount}
        eventUserdata={newUserEvents}
        Event_id={smartlink_id}
        typeData={modeldata}
        handleClose={handleClose}
        show={showModal}
        type={type}
        data={data}
      />

      <div
        className={`${types ? "transparent" : "event-section"}`}
        id={data.unique_id}
      >
        <div className="event-section-inner">
          <div className="event-tper-sec">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="event-type-box">
                <div className="event-type-icon">
                  <BsStars />
                </div>
                <div className="event-type-text">{eventType} Event</div>
              </div>
              <div className="event-type-box">
                <div className="event-type-text">
                  {formData.eventType === 1 ? "Paid" : "Free"}
                </div>
              </div>
            </div>
          </div>

          <div className="event-title" style={{ marginLeft: "5px" }}>
            <div className="day-name">
              {formData.statusTag === 0
                ? "Upcoming"
                : formData.statusTag === 2
                ? "Limited Seats"
                : formData.statusTag === 1
                ? "Sold Out"
                : ""}
            </div>
          </div>

          <div className="event-title">
            <h2 className="m-0">{eventTitle}</h2>
          </div>

          <div className="event-date-sec">
            <div className="event-date-inner">
              <div className="date-icon">
                <div className="month-name">{startDetails.month}</div>
                <div className="day-name">{startDetails.day}</div>
              </div>
              <div className="event-date-text">
                <div className="event-date">{startDetails.fullDate}</div>
                <div className="event-time">{`${startDetails.time} - ${endDetails.time}`}</div>
              </div>
            </div>
          </div>

          {location && <LocationDisplay type="showUrls" eventData={data} />}

          <div className="Registration-sec mt-5">
            <div className="registration-inner">
              <div className="registration-header">Registration</div>
              <div className="inner">
                <div className="jsx-3830652295">
                  <div className="jsx-3830652295 flex-column content">
                    <div className="jsx-1768947162 info-rows flex-column one">
                      <div className="jsx-1768947162 d-flex align-items-center flex-align one">
                        <div className="icon-box">
                          <BsStars />
                        </div>
                        <div className="event-date-text">
                          <div className="event-date">Approval Required</div>
                          <div className="event-time">
                            Your registration is subject to approval by the
                            host.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="jsx-3363824362 message">
                      Welcome! To join the event, please register below.{" "}
                    </div>

                    {formData.capacityType == 1 && formData.maxAttendees > 0 ? (
                      <>
                        <div className="jsx-3363824362 message">
                          {`Max Attendees ${formData.maxAttendees}`}
                        </div>
                        <div className="jsx-3363824362 message">
                          {formData.showGuestCount == true
                            ? goingGuests?.length
                            : "0"}{" "}
                          Going
                        </div>
                      </>
                    ) : null}

                    {formData.showTicketsLeft &&
                    totalCount <= formData.maxAttendees ? (
                      <div className="jsx-3363824362 message">
                        Tickets Left{" "}
                        {formData.maxAttendees - totalCount}
                      </div>
                    ) : (
                      <p>Tickets Left 0</p>
                    )}

                    {formData.isWaitlist &&
                    totalCount > formData.maxAttendees && waitlistData?.length > 0 ? (
                      <div className="jsx-3363824362 message">
                        Waitlist{" "}
                        {waitlistData?.length}
                      </div>
                    ) : null}

                    <button
                      className="btn one-click"
                      disabled={!formData.isWaitlist}
                      onClick={() => handleModalShow(data, "apply", formData)}
                    >
                      One Click Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="event-description-sec mt-3">
            <div className="event-description-title event-xyt">About Event</div>
            <div className="event-description-text">{description}</div>
          </div>
          {locationType !== "Virtual" && (
            <div className="event-description-sec location mt-3">
              <div className="event-description-title">Location</div>              
              <div
                onClick={() => {
                  console.log("the ultimate data", venue);
                  if (venue?.event_venue_slug) {
                    history.push(`/events-venue/${venue?.event_venue_slug}`);
                  }
                }}
                style={{ cursor: "pointer" }}
                className="event-description-text"
              >
                <p>{location}</p>
                <CopyCat text={location} />
              </div>
              <div style={{ height: "200px", width: "100%" }}>
                {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: "" }}
                  defaultCenter={defaultMapProps.center}
                  defaultZoom={defaultMapProps.zoom}
                  className="map-container"
                >
                  <AnyReactComponent
                    lat={locate.lat}
                    lng={locate.lng}
                    text="My Marker"
                  />
                </GoogleMapReact> */}
                <GoogleMaps
                  center={{
                    lat: parseFloat(locate.lat),
                    lng: parseFloat(locate.lng),
                  }}
                  zoom={15}
                  markers={[
                    {
                      lat: parseFloat(locate.lat),
                      lng: parseFloat(locate.lng),
                      title: venue?.event_venue_name,
                    },
                  ]}
                />
              </div>
            </div>
          )}

          {locationType === "Virtual" && (
            <div className="event-description-sec location mt-3">
              <div className="event-description-title">Location</div>
              <div
                style={{ cursor: "pointer" }}
                className="event-description-text"
              >
                <a href={location} target="_blank" rel="noopener noreferrer">
                  {location}
                </a>
              </div>
            </div>
          )}
          <div className="event-description-sec mt-3 event-xyt">
            <div className="event-description-sec mt-4">
              <div className="event-description-title ">
              {goingGuests.length} Going
              </div>
             
            </div>
            
            {/* <div className="total-people-sec ">
              <div
                className="total-people-inner pb-2 "
                style={{ display: "flex" }}
              >
                <div
                  className="total-people-list me-2 "
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {Array.isArray(newUserEvents) &&
                    newUserEvents.slice(0, 2).map(
                      (
                        item,
                        index // Show only 3 users
                      ) => (
                        <div
                          className="total-people-img "
                          key={item._id || index}
                        >
                          {item.user_image ? (
                            <img
                              src={`${API_URL}events/${item.user_image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              src={require("../../../assets/images/Avatar.webp")}
                              alt=""
                            />
                          )}
                        </div>
                      )
                    )}
                </div>
                {Array.isArray(newUserEvents) && newUserEvents.length > 2 && (
                  // Show the first 2 users and remaining count if more than 2
                  <div
                    className="remaining-users-count "
                    style={{
                      marginRight: "15px",
                      display: "flex",
                      alignItems: "center",
                      gap: "0px",
                    }}
                  >
                    {newUserEvents.slice(0, 2).map((item, ind) => (
                      <div
                        key={item._id || ind}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <span>{item.userName + "," || `Guest${ind + 1}`}</span>
                      </div>
                    ))}
                    <span>{newUserEvents.length - 2}Others</span>
                  </div>
                )}
              </div>
            </div> */}
          </div>
          <div className="event-description-sec mt-3 event-xyt" >
              <div className="total-people-sec mt-2">
                <div
                  className="total-people-inner"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="people-names ">
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <AvatarRow names={allGuests.map(guest => guest.name) || []} />
                    </div>
                  </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </>
  );
}

export default Event;
