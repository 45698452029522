import React, { useState, createContext, useReducer, useMemo } from "react";


export const crmActivitiesContext = createContext();

const initialCrmActivitiesState = {
    isFetching: true,
    activities: [],
    notes: [],
    emails: [],
    calls: [],
    tasks: [],
    meetings: [],
    tempFetch: false
    
}

const crmActivitiesReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CRM_ACTIVITIES':
            return {
                ...state,
                activities: action.activities,
                notes: action.notes,
                emails: action.emails,
                calls: action.calls,
                tasks: action.tasks,
                meetings: action.meetings
            }
        case 'SET_TEMP_FETCH':
            return {
                ...state,
                tempFetch: !state.tempFetch
            }
        default:
            return state;
    }
}

export function CrmActivitiesProvider({ children }) {
    const [crmActivitiesState, crmActivitiesDispatch] = useReducer(crmActivitiesReducer, initialCrmActivitiesState);
    
    const crmActivitiesData = useMemo(() => crmActivitiesState, [crmActivitiesState]);
    
    return (
        <crmActivitiesContext.Provider value={{ crmActivitiesData, crmActivitiesDispatch }}>
            {children}
        </crmActivitiesContext.Provider>
    );
}