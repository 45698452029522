import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Badge, ListGroup } from 'react-bootstrap';
import { X } from 'lucide-react';
import axios from 'axios';
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const AddLeadModal = ({ show, handleClose, companies, leads, associatedLeads, componentType, onAddLead }) => {

    const { id } = useParams();
    const [activeTab, setActiveTab] = useState('new');

    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        linkedinUrl: '',
        currentRole: '',
        company: '',
        leadStatus: 'New',
        leadOwner: '',
        tags: [],
        description: ''
    });

    const [loading, setLoading] = useState(false);
    const [tagInput, setTagInput] = useState('');

    const [leadsList, setLeadsList] = useState([]);
    const [selectedLeads, setSelectedLeads] = useState([]);

    useEffect(() => {
        const filteredLeads = leads.filter(lead => !associatedLeads.some(associated => associated.associated_lead_id._id === lead._id));
        setLeadsList(filteredLeads);
    }, [leads, associatedLeads]);

    const showError = (message) => {
        PNotify.error({
            title: 'Error',
            text: message,
            delay: 3000
        });
    };

    const showSuccess = (message) => {
        PNotify.success({
            title: 'Success',
            text: message,
            delay: 3000
        });
    };

    const validateForm = () => {
        let isValid = true;

        // Email validation
        if (!formData.email) {
            showError('Email is required');
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            showError('Please enter a valid email address');
            isValid = false;
        }

        // First Name validation
        if (!formData.firstName.trim()) {
            showError('First name is required');
            isValid = false;
        }

        // Last Name validation
        if (!formData.lastName.trim()) {
            showError('Last name is required');
            isValid = false;
        }

        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        const formDataWithOwner = {
            ...formData,
            owner_id: owner_id,
            method: 'association',
            crm_type: componentType,
            contact_id: id,
            company_id: id,
            lead_id: id,
            deal_id: id
        };

        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/crm/add-lead`, formDataWithOwner);

            if (response.data.success) {
                // Clear form
                setFormData({
                    email: '',
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    linkedinUrl: '',
                    currentRole: '',
                    company: '',
                    leadStatus: 'New',
                    leadOwner: '',
                    tags: [],
                    description: ''
                });

                // showSuccess('Lead added successfully!');
                onAddLead();
                handleClose();
            }
        } catch (error) {
            if (error.response?.data?.message) {
                showError(error.response.data.message);
            } else {
                showError('Error adding lead. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleTagInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = tagInput.trim();
            if (newTag && !formData.tags.includes(newTag)) {
                setFormData(prev => ({
                    ...prev,
                    tags: [...prev.tags, newTag]
                }));
                setTagInput('');
            }
        }
    };

    const removeTag = (tagToRemove) => {
        setFormData(prev => ({
            ...prev,
            tags: prev.tags.filter(tag => tag !== tagToRemove)
        }));
    };

    const handleTagInputChange = (e) => {
        setTagInput(e.target.value);
    };

    const handleLeadSelect = (e, leadId) => {
        e.stopPropagation(); // Prevent row click when checkbox is clicked

        if (selectedLeads.includes(leadId)) {
            setSelectedLeads(selectedLeads.filter(id => id !== leadId));
        } else {
            setSelectedLeads([...selectedLeads, leadId]);
        }
    };

    // Handle row click
    const handleRowClick = (leadId) => {
        if (selectedLeads.includes(leadId)) {
            setSelectedLeads(selectedLeads.filter(id => id !== leadId));
        } else {
            setSelectedLeads([...selectedLeads, leadId]);
        }
    };

    const handleAddLeads = async () => {

        if (selectedLeads.length === 0) {
            showError('Please select at least one lead');
            return;
        }

        const owner_id = JSON.parse(localStorage.getItem("user"))._id;
        const data = {
            leads: selectedLeads,
            owner_id: owner_id,
            crm_type: componentType,
            contact_id: id,
            company_id: id,
            lead_id: id,
            deal_id: id
        };

        try {
            const response = await axios.post(`${API_URL}/crm/add-associated-lead`, data);
            if (response.data.success) {
                onAddLead();
                handleClose();
            }
        } catch (error) {
            console.error("Error adding leads:", error);
            showError('Failed to add leads');
        }
    };

    const getInitialStyle = () => {
        return {
            backgroundColor: '#777',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontWeight: 'bold',
            marginRight: '10px'
        };
    };

    return (
        <Modal show={show} onHide={handleClose}
            animation={true}
            className="Sidebar-Modal-end"
            size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add New Lead</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="tabs-header d-flex border-bottom">
                    <div
                        className={`tab-item py-2 px-3 flex-grow-1 text-center ${activeTab === 'new' ? 'fw-bold' : 'text-secondary'}`}
                        onClick={() => setActiveTab('new')}
                        style={{ cursor: 'pointer' }}
                    >
                        New
                    </div>
                    <div
                        className={`tab-item py-2 px-3 flex-grow-1 text-center ${activeTab === 'existing' ? 'fw-bold' : 'text-secondary'}`}
                        onClick={() => setActiveTab('existing')}
                        style={{ cursor: 'pointer' }}
                    >
                        Existing
                    </div>
                </div>

                {activeTab === 'new' && (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="tel"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>LinkedIn URL</Form.Label>
                            <Form.Control
                                name="linkedinUrl"
                                value={formData.linkedinUrl}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Current Role</Form.Label>
                            <Form.Control
                                type="text"
                                name="currentRole"
                                value={formData.currentRole}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Company</Form.Label>
                            <Form.Select
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                            >
                                <option value="">Select Company</option>
                                {companies.map(company => (
                                    <option key={company._id} value={company._id}>
                                        {company.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Lead Status</Form.Label>
                            <Form.Select
                                name="leadStatus"
                                value={formData.leadStatus}
                                onChange={handleChange}
                            >
                                <option value="New">New</option>
                                <option value="Qualified">Qualified</option>
                                <option value="Contacted">Contacted</option>
                                <option value="Negotiating">Negotiating</option>
                                <option value="Closed">Closed</option>
                                <option value="Lost">Lost</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Lead Owner</Form.Label>
                            <Form.Control
                                type="text"
                                name="leadOwner"
                                value={formData.leadOwner}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Tags</Form.Label>
                            <div className="border rounded p-2">
                                <div className="d-flex flex-wrap gap-2 mb-2">
                                    {formData.tags.map((tag, index) => (
                                        <Badge
                                            bg="primary"
                                            key={index}
                                            className="d-flex align-items-center me-1 mb-1"
                                        >
                                            {tag}
                                            <X
                                                className="ms-1 cursor-pointer"
                                                size={14}
                                                onClick={() => removeTag(tag)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </Badge>
                                    ))}
                                </div>
                                <Form.Control
                                    type="text"
                                    className="border-0"
                                    value={tagInput}
                                    onChange={handleTagInputChange}
                                    onKeyDown={handleTagInputKeyDown}
                                    placeholder="Type a tag and press Enter or comma to add"
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                )}

                {activeTab === 'existing' && (
                    <>
                        <ListGroup className="mb-3" variant="flush">
                            {leadsList.map((lead) => (
                                <ListGroup.Item
                                    key={lead._id}
                                    onClick={() => handleRowClick(lead._id)}
                                    className={`d-flex align-items-center ${selectedLeads.includes(lead._id) ? 'bg-primary' : 'bg-dark'} text-white border-secondary`}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        className="me-2"
                                        checked={selectedLeads.includes(lead._id)}
                                        onChange={(e) => handleLeadSelect(e, lead._id)}
                                    />
                                    <div style={getInitialStyle()}>
                                        {lead.firstName.charAt(0).toUpperCase()}{lead.lastName.charAt(0).toUpperCase()}
                                    </div>
                                    <div>{lead.firstName} {lead.lastName}</div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>

                        <div className="d-flex justify-content-end">
                            <Button
                                variant="primary"
                                size="sm"
                                disabled={selectedLeads.length === 0}
                                onClick={handleAddLeads}
                            >
                                + Add
                            </Button>
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    disabled={loading}
                    onClick={handleSubmit}
                >
                    {loading ? 'Adding...' : 'Add Lead'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddLeadModal;