import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { Spinner, Container, Card, Button } from 'react-bootstrap';
const API_URL = process.env.REACT_APP_API_URL;

const EventResponse = () => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [guestDetails, setGuestDetails] = useState(null);

  // Parse query params using URLSearchParams
  const searchParams = new URLSearchParams(location.search);
  const guestId = searchParams.get('guest_id');
  const status = searchParams.get('status');

  useEffect(() => {
    const updateStatus = async () => {
      if (!guestId || !status) {
        setError('Missing required parameters');
        setLoading(false);
        return;
      }

      try {
        // Call the API to update the status
        const response = await axios.post(`${API_URL}/event/event-email-response`, {
          guestId,
          status,
        });

        if (!response.data.success) {
          setError('Failed to update guest status');
          return;
        }

        setGuestDetails(response.data.guest);
        setEventDetails(response.data.event);
        setSuccess(true);
      } catch (err) {
        setError(err.response?.data?.message || 'An error occurred while updating your response');
      } finally {
        setLoading(false);
      }
    };

    updateStatus();
  }, [guestId, status]);

  if (loading) {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <Card className="text-center p-4 shadow">
          <Card.Body>
            <Spinner animation="border" variant="primary" className="mb-3" />
            <Card.Text className="text-muted">Updating your response...</Card.Text>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <Card className="text-center p-4 shadow" style={{ maxWidth: '500px' }}>
          <Card.Body>
            <div className="text-danger display-4 mb-3">⚠️</div>
            <Card.Title as="h2" className="text-danger mb-3">Error</Card.Title>
            <Card.Text className="mb-4">{error}</Card.Text>
            <Button variant="primary" onClick={() => history.push('/')}>
              Return Home
            </Button>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  if (success) {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <Card className="text-center p-4 shadow" style={{ maxWidth: '500px' }}>
          <Card.Body>
            <div className="text-success display-4 mb-3">✓</div>
            <Card.Title as="h2" className="text-success mb-3">Thank You!</Card.Title>
            <Card.Text>
              Your response has been updated to <strong>{status}</strong>.
            </Card.Text>

            {eventDetails && (
              <div className="mt-4 pt-4 border-top">
                <h3 className="mb-3">{eventDetails.title}</h3>
                {eventDetails.date && (
                  <p className="text-muted mb-1">
                    <strong>When:</strong> {new Date(eventDetails.date).toLocaleString()}
                  </p>
                )}
                {eventDetails.location && (
                  <p className="text-muted mb-1">
                    <strong>Where:</strong> {eventDetails.location}
                  </p>
                )}
                {eventDetails.description && <p className="text-muted mt-3">{eventDetails.description}</p>}
              </div>
            )}

            <div className="mt-4">
              <Button variant="primary" onClick={() => history.push('/')}>
                Return Home
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  return null;
};

export default EventResponse;
