import React from 'react';
import { Menu } from 'lucide-react';
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';
import useWebBuilderActions from '../../../../contexts/actions/useWebBuilderActions';

const EditableNavbar = () => {
  const { blockData } = React.useContext(WebBuilderContext);
  const { toggleMobileMenu } = useWebBuilderActions();

  const navbarStyle = {
    background: 'rgba(255, 255, 255, 0.95)',
    backdropFilter: 'blur(10px)',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.08)'
  };

  return (
    <nav className="navbar navbar-expand-lg sticky-top" style={navbarStyle}>
      <div className="container">
        {/* Brand Section */}
        <div className="d-flex align-items-center position-relative">
          <a className="navbar-brand fw-bold" href="#" style={{ color: '#2196F3' }}>
            {blockData.navbar.brand}
          </a>
        </div>

        {/* Mobile Toggle Button */}
        <button
          className="navbar-toggler border-0 shadow-none"
          type="button"
          onClick={toggleMobileMenu}
        >
          <Menu size={24} style={{ color: '#2196F3' }} />
        </button>

        {/* Navigation Items */}
        <div className={`collapse navbar-collapse ${blockData.navbar.showMobileMenu ? 'show' : ''}`}>
          <ul className="navbar-nav ms-auto align-items-lg-center">
            {blockData.navbar.links.map(item => (
              <li key={item.id} className="nav-item mx-lg-2 my-2 my-lg-0 position-relative">
                <div className="d-flex align-items-center">
                  <a className="nav-link" href={item.href} style={{ color: '#666' }}>
                    {item.text}
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <style>
        {`
          .navbar-collapse {
            background-color: rgba(255, 255, 255, 0.95);
            backdrop-filter: blur(10px);
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
            padding: 10px;
            border-radius: 8px;
          }

          .navbar-toggler:focus {
            outline: none;
          }

          .navbar-toggler {
            z-index: 1000;
          }

          .navbar-collapse.show {
            background: #ffffff !important;
          }

          @media (max-width: 990px) {
            .navbar-nav {
              margin-top: -25px;
            }
          }
        `}
      </style>
    </nav>
  );
};

export default EditableNavbar;