import React, { useEffect } from "react";
import BuilderTitle from "./Components/TemplateView/BuilderTitle";
import BuilderIcon from "./Components/TemplateView/BuilderIcon";
import BuilderButton from "./Components/TemplateView/BuilderButton";
import BuilderText from "./Components/TemplateView/BuilderText";
import ImageText from "./Components/TemplateView/ImageText";
import YoutubeLink from "./Components/TemplateView/YoutubeLink";
import Spotify from "./Components/TemplateView/Spotify";
import ImageGrid from "./Components/TemplateView/ImageGrid";
import { FiCopy } from "react-icons/fi";
import { useState, useRef } from "react";
import { BsArrowsFullscreen } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { MdOutlineDesktopMac } from "react-icons/md";
import { FaTabletAlt, FaMobileAlt } from "react-icons/fa";

import {
  ButtonLinkAdd,
  ButtonLinkList,
} from "./Components/AddBlockList/ButtonLinkList";
import { useBuilderContext } from "../builderContext";
import BuilderHeader from "./Components/TemplateView/BuilderHeader";

import { publishMinipage } from "../BackendApi/Api/flowpageApi";

import { toJpeg, toBlob, toPng } from "html-to-image";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { v4 as uuidv4 } from "uuid";

import html2pdf from "html2pdf.js";

import domToImage from "dom-to-image";

// import domtoimage from 'dom-to-image';

import html2canvas from "html2canvas";

import { saveAs } from "file-saver";

// import { useScreenshot } from 'use-react-screenshot'
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import CopyCat from "../Components/CopyCat/CopyCat";
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import "suneditor/dist/css/suneditor.min.css";
import { EdityoutubeIframe } from "./Components/EditBlockList/EdityoutubeIframe";
import YoutubeIframe from "./Components/TemplateView/YoutubeIframe";
import Testimonials from "./Components/TemplateView/Testimonials";
import ContactUs from "./Components/TemplateView/ContactUs";
import Event from "./Components/TemplateView/Event";
import ContactForms from "./Components/TemplateView/ContactForms";

import { useParams } from "react-router-dom";

function BuilderLeft({ color, buttonColor, fontFamily }) {
  const { id } = useParams();

  const {
    myComponents,
    smartlink_url,
    backgroundType,
    BackgroundColor,
    backgroundColor2,
    colorPosition,
    imagePosition,
    smartlinkeditdata,
    company_slug,
    setCompain_slug,
    showPoweredBy,
  } = useBuilderContext();

  const [deviceView, setDeviceView] = useState("view-desktop");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loding, setLoding] = useState(false);
  const imageRef = useRef(null);

  const [flexpageLink, setFlexpageLink] = useState("");

  const captureWebpageAsImage = () => {
    const elementToCapture = document.querySelector(".builder-box-design");
    const scrollY = window.scrollY;

    // Save the current style of the body's scrollbar
    const originalBodyOverflowStyle = document.body.style.overflow;

    // Hide the scrollbar by setting body overflow to 'hidden'

    elementToCapture.style.overflow = "hidden";
    // document.body.style.overflow = 'hidden';

    // Create a new div element to act as a wrapper for the content
    const wrapper = document.createElement("div");
    wrapper.style.overflow = "hidden";
    wrapper.style.position = "absolute";
    wrapper.style.top = `${-scrollY}px`;
    wrapper.appendChild(elementToCapture.cloneNode(true));

    // Append the wrapper to the body
    document.body.appendChild(wrapper);

    toPng(elementToCapture, { scrollY, quality: 2.0 })
      .then((dataUrl) => {
        // Restore the original body's overflow style
        document.body.style.overflow = originalBodyOverflowStyle;

        // Remove the wrapper element
        document.body.removeChild(wrapper);

        // Generate a unique filename for the screenshot
        const fileName = `screenshot_${new Date().toISOString()}.png`;

        // Create a link element to download the image
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = fileName;

        // Trigger the download
        link.click();
      })
      .catch((error) => {
        console.error("Error capturing screenshot:", error);
        // Make sure to restore the original body's overflow style even in case of an error
        document.body.style.overflow = originalBodyOverflowStyle;
        // Remove the wrapper element if there's an error
        document.body.removeChild(wrapper);
      });
  };

  // const publish = () => {
  //     var element = document.getElementById("capture");
  //     element.classList.remove("page_builder_design_body");
  //     setLoding(true);
  //     const elementToCapture = imageRef.current;
  //     const scrollY = window.scrollY;

  //     elementToCapture.style.overflow = 'hidden';

  //     // create image from toPng
  //     toJpeg(elementToCapture, { scrollY, quality: 3.0 })
  //         .then(dataUrl => {

  //             element.classList.add("page_builder_design_body");
  //             elementToCapture.style.overflow = 'scroll';

  //             const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');

  //             // // Convert base64 string to Blob object
  //             const byteCharacters = atob(base64Data);
  //             const byteNumbers = new Array(byteCharacters.length);
  //             for (let i = 0; i < byteCharacters.length; i++) {
  //                 byteNumbers[i] = byteCharacters.charCodeAt(i);
  //             }
  //             const byteArray = new Uint8Array(byteNumbers);
  //             var blob = new Blob([byteArray], { type: 'image/jpeg' });

  //             // saveAs(blob, 'screenshot.jpeg');

  //             console.log("blob", blob.type);

  //             const url = window.location.href;
  //             const urlSplit = url.split('/');

  //             console.log("id from url", urlSplit);

  //             const withhash = urlSplit[urlSplit.length - 2];

  //             const id = withhash.slice(0, -1);

  //             console.log("my json data", myComponents);

  //             const jsonData = myComponents;

  //             const componentsData = [];

  //             jsonData.forEach(element => {
  //                 if (element.type === "BUTTON" || element.type === "IMAGETEXT") {
  //                     if (element.properties.unique_id) {
  //                         if (element.properties.unique_id == "" || element.properties.unique_id == null || element.properties.unique_id == undefined) {

  //                             const uniquId = Date.now() + uuidv4();

  //                             element.properties.unique_id = uniquId;

  //                         }

  //                     } else {
  //                         const uniquId = Date.now() + uuidv4();

  //                         element.properties.unique_id = uniquId;
  //                     }

  //                     let componentData;

  //                     if (element.type === "BUTTON") {
  //                         componentData = {
  //                             type: "BUTTON",
  //                             smartlink_id: id,
  //                             link: element.properties.link,
  //                             unique_id: element.properties.unique_id,
  //                             lable: element.properties.lable
  //                         };
  //                     }

  //                     if (element.type === "IMAGETEXT") {
  //                         componentData = {
  //                             type: "IMAGETEXT",
  //                             smartlink_id: id,
  //                             unique_id: element.properties.unique_id,
  //                             image_url: element.properties.imageLink,
  //                             alt: element.properties.imageAltText,
  //                             image_caption: element.properties.imageCaption,
  //                             link: element.properties.link
  //                         };
  //                     }

  //                     componentsData.push(componentData);

  //                 }

  //                 if (element.type === "ICONS" || element.type === "IMAGEGRID") {
  //                     for (const key in element.properties.items) {
  //                         const item = element.properties.items[key];

  //                         if (item.unique_id) {
  //                             if (item.unique_id == "" || item.unique_id == null || item.unique_id == undefined) {
  //                                 const uniqueId = Date.now() + uuidv4();
  //                                 item.unique_id = uniqueId;
  //                             }
  //                         } else {
  //                             const uniqueId = Date.now() + uuidv4();
  //                             item.unique_id = uniqueId;
  //                         }

  //                         let componentData;

  //                         if (element.type === "ICONS") {
  //                             componentData = {
  //                                 type: "ICONS",
  //                                 smartlink_id: id,
  //                                 unique_id: item.unique_id,
  //                                 social_type: item.type,
  //                                 link: item.url
  //                             };
  //                         }

  //                         if (element.type === "IMAGEGRID") {
  //                             componentData = {
  //                                 type: "IMAGEGRID",
  //                                 smartlink_id: id,
  //                                 unique_id: item.unique_id,
  //                                 image_url: item.imageurl,
  //                                 link: item.link,
  //                                 alt: item.alt
  //                             };
  //                         }

  //                         componentsData.push(componentData);
  //                     }
  //                 }

  //             });

  //             console.log("components data", componentsData);

  //             const formData = new FormData();
  //             formData.append('id', id);
  //             formData.append('page_json', JSON.stringify(jsonData));
  //             formData.append('page_thumbnail', blob);
  //             formData.append('background_color', color);
  //             formData.append('button_color', buttonColor);
  //             formData.append('font_family', fontFamily);
  //             formData.append('components', JSON.stringify(componentsData));
  //             formData.append('background_type', backgroundType);
  //             formData.append('background_color2', backgroundColor2);
  //             formData.append('background_color1', BackgroundColor);
  //             formData.append("background_Position", colorPosition);

  //             publishMinipage(formData).then((res) => {
  //                 if (res.data.status == true) {
  //                     PNotify.success({
  //                         title: 'Success',
  //                         text: 'Page Published Successfully',
  //                     });
  //                     setLoding(false);
  //                 } else {
  //                     PNotify.error({
  //                         title: 'Error',
  //                         text: res.data.message
  //                     })
  //                     setLoding(false);
  //                 }
  //             }).catch((err) => {
  //                 PNotify.error({
  //                     title: 'Error',
  //                     text: err.message
  //                 })
  //                 setLoding(false);
  //             })

  //         })
  // }

  const publish = () => {
    var element = document.getElementById("capture");
    element.classList.remove("page_builder_design_body");
    setLoding(true);
    const elementToCapture = imageRef.current;

    // Check if elementToCapture exists
    if (!elementToCapture) {
      PNotify.error({
        title: "Error",
        text: "Unable to publish page. Double check if any images are broken",
      });
      setLoding(false);
      return;
    }

    const scrollY = window.scrollY;
    const originalOverflow = elementToCapture.style.overflow;

    elementToCapture.style.overflow = "hidden";

    // create image from toPng
    toJpeg(elementToCapture, { scrollY, quality: 3.0 })
      .then((dataUrl) => {
        if (!dataUrl) {
          throw new Error(
            "Unable to publish page. Double check if any images are broken"
          );
        }

        // Restore the UI state
        element.classList.add("page_builder_design_body");
        elementToCapture.style.overflow = originalOverflow;

        const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, "");

        // Convert base64 string to Blob object
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: "image/jpeg" });

        console.log("blob", blob.type);

        const jsonData = myComponents;
        const componentsData = [];

        jsonData.forEach((element) => {
          if (
            element.type === "BUTTON" ||
            element.type === "IMAGETEXT" ||
            element.type === "YOUTUBEIFRAME" ||
            element.type === "YOUTUBELINK"
          ) {
            if (element.properties.unique_id) {
              if (!element.properties.unique_id) {
                const uniquId = Date.now() + uuidv4();
                element.properties.unique_id = uniquId;
              }
            } else {
              const uniquId = Date.now() + uuidv4();
              element.properties.unique_id = uniquId;
            }

            let componentData;

            if (element.type === "BUTTON") {
              componentData = {
                type: "BUTTON",
                smartlink_id: id,
                link: element.properties.link,
                unique_id: element.properties.unique_id,
                lable: element.properties.lable,
              };
            }

            if (element.type === "IMAGETEXT") {
              componentData = {
                type: "IMAGETEXT",
                smartlink_id: id,
                unique_id: element.properties.unique_id,
                image_url: element.properties.imageLink,
                alt: element.properties.imageAltText,
                image_caption: element.properties.imageCaption,
                link: element.properties.link,
              };
            }
            if (element.type === "YOUTUBELINK") {
              componentData = {
                type: "YOUTUBELINK",
                smartlink_id: id,
                unique_id: element.properties.unique_id,
                link: element.properties.link,
              };
            }
            if (element.type === "YOUTUBEIFRAME") {
              const extractURL = (iframeString) => {
                if (!iframeString || typeof iframeString !== "string") {
                  console.error(
                    "iframeString is undefined, null, or not a string"
                  );
                  return null;
                }

                const regex = /src="([^"]+)"/;
                const match = iframeString.match(regex);
                return match ? match[1] : null;
              };

              const url = extractURL(element.properties?.Link); // optional chaining to safely access 'link'

              componentData = {
                type: "YOUTUBEIFRAME",
                smartlink_id: id,
                unique_id: element.properties.unique_id,
                link: url,
              };
            }

            componentsData.push(componentData);
          }

          if (element.type === "ICONS" || element.type === "IMAGEGRID") {
            for (const key in element.properties.items) {
              const item = element.properties.items[key];

              if (item.unique_id) {
                if (!item.unique_id) {
                  const uniqueId = Date.now() + uuidv4();
                  item.unique_id = uniqueId;
                }
              } else {
                const uniqueId = Date.now() + uuidv4();
                item.unique_id = uniqueId;
              }

              let componentData;

              if (element.type === "ICONS") {
                componentData = {
                  type: "ICONS",
                  smartlink_id: id,
                  unique_id: item.unique_id,
                  social_type: item.type,
                  link: item.url,
                };
              }

              if (element.type === "IMAGEGRID") {
                componentData = {
                  type: "IMAGEGRID",
                  smartlink_id: id,
                  unique_id: item.unique_id,
                  image_url: item.imageurl,
                  link: item.link,
                  alt: item.alt,
                };
              }

              componentsData.push(componentData);
            }
          }
        });

        console.log("components data", componentsData);

        const formData = new FormData();
        formData.append("id", id);
        formData.append("page_json", JSON.stringify(jsonData));
        formData.append("page_thumbnail", blob);
        formData.append("background_color", color);
        formData.append("button_color", buttonColor);
        formData.append("font_family", fontFamily);
        formData.append("components", JSON.stringify(componentsData));
        formData.append("background_type", backgroundType);
        formData.append("background_color2", backgroundColor2);
        formData.append("background_color1", BackgroundColor);
        formData.append("background_Position", colorPosition);
        formData.append("showPoweredBy", showPoweredBy);

        publishMinipage(formData)
          .then((res) => {
            if (res.data.status === true) {
              PNotify.success({
                title: "Success",
                text: "Page Published Successfully",
              });
            } else {
              PNotify.error({
                title: "Error",
                text: res.data.message,
              });
            }
            setLoding(false);
          })
          .catch((err) => {
            PNotify.error({
              title: "Error",
              text: err.message,
            });
            setLoding(false);
          });
      })
      .catch((error) => {
        console.error("Error capturing screenshot:", error);

        // Restore the UI state
        element.classList.add("page_builder_design_body");
        elementToCapture.style.overflow = originalOverflow;

        PNotify.error({
          title: "Error",
          text: "Unable to publish page. Double check if any images are broken",
        });
        setLoding(false);
      });
  };

  const gradientDirectionMap = {
    leftright: "to right",
    rightleft: "to left",
    topbottom: "to bottom",
    bottomtop: "to top",
  };

  let urlWithoutProtocol = company_slug.split("://")[1];

  useEffect(() => {
    const handleInitialAnchor = () => {
      const hash = window.location.hash;
      if (hash) {
        const targetId = hash.replace("#", "");
        const element = document.getElementById(targetId);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
      }
    };

    handleInitialAnchor();
  }, []);

  const renderComponent = (component, index) => {
    let unique_id;
    if(!component.showStatus) {
      return null;
    }
    console.log("component", component);
    if (component.type === "TESTIMONIAL") {
      unique_id = component.properties[0]?.unique_id;
    }
    {
      unique_id = component.properties?.unique_id;
    }
    const commonProps = {
      key: index,
      id: unique_id || `component-${index}`,
      className: "component-wrapper",
    };

    switch (component.type ) {
      case "HEADER":
        return <BuilderHeader {...commonProps} data={component.properties} />;

      case "TITLE":
        return <BuilderTitle {...commonProps} data={component.properties} />;

      case "ICONS":
        return (
          <BuilderIcon
            unique_id={unique_id}
            {...commonProps}
            data={component.properties}
          />
        );

      case "BUTTON":
        return (
          <BuilderButton
            smartlink_id={id}
            {...commonProps}
            data={component.properties}
            buttonColor={buttonColor}
          />
        );

      case "TEXT":
        return <BuilderText {...commonProps} data={component.properties} />;

      case "IMAGETEXT":
        return (
          <ImageText
            smartlink_id={id}
            {...commonProps}
            data={component.properties}
          />
        );

      case "YOUTUBELINK":
        return (
          <YoutubeLink
            smartlink_id={id}
            {...commonProps}
            selectedbtn={component.selectedValue}
            data={component.properties}
          />
        );

      case "SPOTIFY":
        return (
          <Spotify
            smartlink_id={id}
            {...commonProps}
            data={component.properties}
            unique_id={unique_id}
          />
        );

      case "IMAGEGRID":
        return (
          <ImageGrid
            smartlink_id={id}
            {...commonProps}
            data={component.properties}
            imagePosition={imagePosition}
          />
        );

      case "YOUTUBEIFRAME":
        return (
          <YoutubeIframe
            smartlink_id={id}
            {...commonProps}
            selectedbtn={component.selectedValue}
            data={component.properties}
          />
        );

      case "CONTACTUS":
        return <ContactUs {...commonProps} data={component.properties} />;

      case "TESTIMONIAL":
        return (
          <Testimonials
            unique_id={unique_id}
            {...commonProps}
            data={component.properties}
          />
        );

      case "CONTACTFORM":
        return (
          <ContactForms
            unique_id={unique_id}
            {...commonProps}
            data={component.properties}
          />
        );

      default:
        return null; // Return null for unknown types
    }
  };
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="modal-center-show"
      >
        <Modal.Body>
          <div className="show-preview-header">
            <div className="show-preview-item">
              <button
                className="btn full-screen-btn-1"
                onClick={() => {
                  setDeviceView("view-desktop");
                }}
              >
                <MdOutlineDesktopMac />
              </button>
            </div>
            <div className="show-preview-item">
              <button
                className="btn full-screen-btn-1"
                onClick={() => {
                  setDeviceView("view-tablet");
                }}
              >
                <FaTabletAlt />
              </button>
            </div>
            <div className="show-preview-item">
              <button
                className="btn full-screen-btn-1"
                onClick={() => {
                  setDeviceView("view-mobile");
                }}
              >
                <FaMobileAlt />
              </button>
            </div>
          </div>
          <div className="show-preview-body">
            <div className="show-preview-content-2">
              <div className={`preview-box-background ${deviceView}`}>
                <div class="oEUMCt"></div>
                <div className={`page_builder_design_body ${color}`}>
                  <div
                    className={`builder-box-design goWHMr ${color}`}
                    style={{ fontFamily: fontFamily }}
                    ref={imageRef}
                  >
                    <div className="builder_content">
                      {myComponents &&
                        myComponents.map((component, index) => (
                          <>
                            {component.type === "HEADER" ? (
                              <BuilderHeader data={component.properties} />
                            ) : component.type === "TITLE" ? (
                              <BuilderTitle data={component.properties} />
                            ) : component.type === "ICONS" ? (
                              <BuilderIcon data={component.properties} />
                            ) : component.type === "BUTTON" ? (
                              <BuilderButton
                                data={component.properties}
                                buttonColor={buttonColor}
                              />
                            ) : component.type === "TEXT" ? (
                              <BuilderText data={component.properties} />
                            ) : component.type === "IMAGETEXT" ? (
                              <ImageText data={component.properties} />
                            ) : component.type === "YOUTUBELINK" ? (
                              <YoutubeLink data={component.properties} />
                            ) : component.type === "SPOTIFY" ? (
                              <Spotify data={component.properties} />
                            ) : component.type === "IMAGEGRID" ? (
                              <ImageGrid data={component.properties} />
                            ) : null}
                          </>
                        ))}

                      {showPoweredBy && (
                        <div className="company_name">
                          <h5>Powered with Astral</h5>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="oEUMCterd"></div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="page_builder_design_header">
        <div className="page_builder_design_header_container">
          <div className="publish_btn_container">
            <div className="d-flex align-items-center justify-content-between ms-4">
              <p className="m-0">{urlWithoutProtocol}</p>
              <CopyCat text={company_slug} />
            </div>
            {loding ? (
              <SpinnerLoader />
            ) : (
              <button className="publish-btn" onClick={publish}>
                Publish Changes
              </button>
            )}
          </div>
        </div>
      </div>

      <div
        className="page_builder_design_body"
        id="capture"
        style={{
          ...(backgroundType == 1 ? { backgroundColor: BackgroundColor } : {}),
          ...(backgroundType != 1
            ? {
                backgroundImage: `linear-gradient(${gradientDirectionMap[colorPosition]}, ${BackgroundColor}, ${backgroundColor2})`,
              }
            : {}),
        }}
      >
        <div className="xmpr">
          <div className="fill-screen-btn form-btn-right mb-3">
            <button
              type="button"
              className="btn full-screen-btn bg-green-dark"
              onClick={handleShow}
            >
              <BsArrowsFullscreen /> Device Preview
            </button>
          </div>
          <div
            className={`builder-box-design goWHMr ${color}`}
            style={{ fontFamily: fontFamily }}
            ref={imageRef}
          >
            <div className="builder_content">
              {/* {myComponents && myComponents.map((component, index) => (
                                <>
                                    {
                                        component.type === "HEADER" ? <BuilderHeader data={component.properties} /> :
                                            component.type === "TITLE" && component?.showStatus == true ? <BuilderTitle data={component.properties} /> :
                                                component.type === "ICONS" && component?.showStatus == true ? <BuilderIcon data={component.properties} /> :
                                                    component.type === "BUTTON" && component?.showStatus === true ? <BuilderButton data={component.properties} buttonColor={buttonColor} /> :
                                                        component.type === "TEXT" && component?.showStatus === true ? <BuilderText data={component.properties} /> :
                                                            component.type === "IMAGETEXT" && component?.showStatus === true ? <ImageText data={component.properties} /> :
                                                                component.type === "YOUTUBELINK" && component?.showStatus === true ? <YoutubeLink data={component.properties} /> :
                                                                    component.type === "SPOTIFY" && component?.showStatus === true ? <Spotify data={component.properties} /> :
                                                                        component.type === "IMAGEGRID" && component?.showStatus === true ? <ImageGrid data={component.properties} imagePosition={imagePosition} /> :
                                                                            component.type === "YOUTUBEIFRAME" && component?.showStatus === true ? <YoutubeIframe data={component.properties} /> :
                                                                                component.type === "CONTACTUS" && component?.showStatus === true ? <ContactUs data={component.properties} /> :
                                                                                    component.type === "TESTIMONIAL" && component?.showStatus === true ? <Testimonials data={component.properties} /> :
                                                                                        
                                                                                        
                                                                                            null

                                    }
                                </>
                                // CONTACTUS
                            ))} */}
              {myComponents &&
                myComponents.map((component, index) => (
                  <React.Fragment key={index}>
                    {renderComponent(component, index)}
                  </React.Fragment>
                ))}
              {/* <Event /> */}
              {/* <Testimonials /> */}
              {/* <ContactUs /> */}
              {/* component.type === "EVENT" && component?.showStatus === true ? <Event data={component.properties} /> : */}

              {showPoweredBy && (
                <div className="company_name">
                  <h5>Powered with Astral</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuilderLeft;
