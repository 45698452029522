import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronRight, FaLongArrowAltLeft, FaExternalLinkAlt } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

export function ContactForm() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <FaLongArrowAltLeft />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Contact Form</h2>
                            <p className="add-block-btn-text-desc">Add a Contact Form to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}


export function AddContactForm() {

    const navigate = useHistory();
    const { addComponent } = useBuilderContext();
    const unique_id = Date.now() + uuidv4();
    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Contact Form</label>
                    </div>
                </div>
            </div>

            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={
                    () => {
                        const data = {
                            unique_id: unique_id,
                            title: "Contact Form",
                            addToCrm: false
                        };
                        addComponent("CONTACTFORM", data);
                        navigate.push("/");
                    }
                }>
                    Add Contact Form
                </button>
            </div>
        </>
    );
}
