import React from 'react';
import { Switch, Route, NavLink, useRouteMatch, Redirect } from 'react-router-dom';
import CrmContacts from './contacts/Contacts';
import CrmCompanies from './companies/Companies';
import CrmDeals from './deals/Deals';
import { Container, Card, Nav } from 'react-bootstrap';
import CrmDealsKanban from './deals/DealsKanban';

const Crm = () => {
    const { path, url } = useRouteMatch();


    return (
        <div className="main-container">
            <div className="main-page-body-content">
                <div className="main-page-body-content-body">
                    <div className="main-page-body-content-body-container">
                        <div className="tab-content-body mobile-view-hide">
                            <Container>
                                <Card className="shadow-sm border-0">
                                    <Card.Header className="bg-white">
                                        <Nav variant="tabs" className="border-bottom-0">
                                            <Nav.Item>
                                                <NavLink
                                                    to={`${url}/contacts`}
                                                    className="nav-link px-4 py-2"
                                                    activeClassName="active font-weight-bold text-primary"
                                                >
                                                    Contacts
                                                </NavLink>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <NavLink
                                                    to={`${url}/companies`}
                                                    className="nav-link px-4 py-2"
                                                    activeClassName="active font-weight-bold text-primary"
                                                >
                                                    Companies
                                                </NavLink>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <NavLink
                                                    to={`${url}/deals/kanban`}
                                                    className="nav-link px-4 py-2"
                                                    activeClassName="active font-weight-bold text-primary"
                                                >
                                                    Deals
                                                </NavLink>
                                            </Nav.Item>
                                        </Nav>
                                    </Card.Header>
                                    <Card.Body>
                                        <Switch>
                                            <Route exact path={`${path}/contacts`} component={CrmContacts} />
                                            <Route exact path={`${path}/companies`} component={CrmCompanies} />
                                            <Route exact path={`${path}/deals/table`} component={CrmDeals} />
                                            <Route exact path={`${path}/deals/kanban`} component={CrmDealsKanban} />
                                            <Redirect to={`${path}/contacts`} />
                                        </Switch>
                                    </Card.Body>
                                </Card>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Crm;
