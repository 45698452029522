import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const API_URL = process.env.REACT_APP_API_URL;

const ContactList = () => {
    const [contacts, setContacts] = useState([]);
    const { id } = useParams();

    const getContacts = async () => {
        try {
            const response = await fetch(`${API_URL}/flowpage/get-flexpage-contacts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ flexpage_id: id }),
            });
            const data = await response.json();

            console.log("the incoming contact data", data);
            if (data.success) {
                setContacts(data.data);
            }
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    useEffect(() => {
        getContacts();
    }, []);

    return (
        <div className="styles__TabContent__Header themes_sec_header">
            <h3 className="styles__TabContent__Header__Title">Contacts</h3>
            <div className="row">
                {contacts && contacts.map((contact) => (
                    <div key={contact._id} className="mb-3">
                        <div className="card shadow-sm border-0 h-100">
                            <div className="card-body">
                                <h5 className="card-title">{contact.firstName}</h5>
                                <p className="card-text">{contact.email}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ContactList

// import React, { useState, useEffect } from 'react';
// import {
//     Container, Row, Col, Card, Badge, Form, Dropdown,
//     Button, Modal, ButtonGroup, OverlayTrigger, Tooltip
// } from 'react-bootstrap';
// import { FiSearch, FiFilter, FiCalendar, FiClock, FiMoreVertical, FiDownload, FiShare } from 'react-icons/fi';
// import './GuestList.css';

// const GuestList = () => {
//     const [guests, setGuests] = useState([]);
//     const [filteredGuests, setFilteredGuests] = useState([]);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [statusFilter, setStatusFilter] = useState('All Guests');
//     const [showStatusModal, setShowStatusModal] = useState(false);
//     const [currentGuest, setCurrentGuest] = useState(null);
//     const [newStatus, setNewStatus] = useState('');
//     const [notifyGuest, setNotifyGuest] = useState(false);
//     const [customMessage, setCustomMessage] = useState('');
//     const [sortBy, setSortBy] = useState('Register Time');

//     // Status badge styling
//     const statusStyles = {
//         'Going': { bg: 'success', text: 'white' },
//         'Invited': { bg: 'primary', text: 'white' },
//         'Not Going': { bg: 'danger', text: 'white' },
//         'Maybe': { bg: 'warning', text: 'dark' },
//         'Pending Approval': { bg: 'orange', text: 'white' },
//         'Waitlist': { bg: 'purple', text: 'white' },
//         'Checked In': { bg: 'info', text: 'white' }
//     };

//     // Sample data for demonstration (replace with API call)
//     useEffect(() => {
//         // Simulating API response
//         const mockData = [
//             { id: 1, name: 'Will Gorman', email: 'wgorman@gmail.com', status: 'Going', avatar: 'W', statusUpdatedAt: '2025-03-19T10:30:00', invitedAt: '2025-03-15T08:00:00' },
//             { id: 2, name: 'Robert S. Katz', email: 'robert@katz.pro', status: 'Going', avatar: 'R', statusUpdatedAt: '2025-02-22T14:15:00', invitedAt: '2025-02-20T09:30:00' },
//             { id: 3, name: 'Elijah Knisley', email: 'wirewhiz@branereality.com', status: 'Going', avatar: 'E', statusUpdatedAt: '2025-02-22T09:45:00', invitedAt: '2025-02-21T11:20:00' },
//             { id: 4, name: 'Ross Goodman', email: 'rossgoodmandp@gmail.com', status: 'Going', avatar: 'R', statusUpdatedAt: '2025-02-21T16:25:00', invitedAt: '2025-02-19T13:10:00' },
//             { id: 5, name: 'Joey', email: 'joeyfisher@gmail.com', status: 'Going', avatar: 'J', statusUpdatedAt: '2025-02-21T11:05:00', invitedAt: '2025-02-18T15:45:00' },
//             { id: 6, name: 'Caris Baker', email: 'caris.frazier@gmail.com', status: 'Invited', avatar: 'C', statusUpdatedAt: '2025-02-21T08:30:00', invitedAt: '2025-02-21T08:30:00' },
//             { id: 7, name: 'Kendra Kennedy', email: 'kendrakennedy01@gmail.com', status: 'Invited', avatar: 'K', statusUpdatedAt: '2025-02-21T08:30:00', invitedAt: '2025-02-21T08:30:00' },
//             { id: 8, name: 'Andrew Chan', email: 'andrewtzlanchan@gmail.com', status: 'Invited', avatar: 'A', statusUpdatedAt: '2025-02-21T08:30:00', invitedAt: '2025-02-21T08:30:00' },
//             { id: 9, name: 'Cesar Escobar', email: 'cesar.escodesign@gmail.com', status: 'Invited', avatar: 'C', statusUpdatedAt: '2025-02-21T08:30:00', invitedAt: '2025-02-21T08:30:00' },
//             { id: 10, name: 'Juan Rivera', email: 'jrivera6000@gmail.com', status: 'Invited', avatar: 'J', statusUpdatedAt: '2025-02-21T08:30:00', invitedAt: '2025-02-21T08:30:00' }
//         ];

//         setGuests(mockData);
//         setFilteredGuests(mockData);
//     }, []);

//     // Filter guests based on search query and status
//     useEffect(() => {
//         let result = guests;

//         // Apply search filter
//         if (searchQuery) {
//             result = result.filter(guest =>
//                 guest.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//                 guest.email.toLowerCase().includes(searchQuery.toLowerCase())
//             );
//         }

//         // Apply status filter
//         if (statusFilter !== 'All Guests') {
//             result = result.filter(guest => guest.status === statusFilter);
//         }

//         // Apply sorting
//         if (sortBy === 'Register Time') {
//             result = [...result].sort((a, b) => new Date(b.invitedAt) - new Date(a.invitedAt));
//         } else if (sortBy === 'Name') {
//             result = [...result].sort((a, b) => a.name.localeCompare(b.name));
//         } else if (sortBy === 'Status Change') {
//             result = [...result].sort((a, b) => new Date(b.statusUpdatedAt) - new Date(a.statusUpdatedAt));
//         }

//         setFilteredGuests(result);
//     }, [guests, searchQuery, statusFilter, sortBy]);

//     // Open status update modal
//     const handleStatusUpdate = (guest) => {
//         setCurrentGuest(guest);
//         setNewStatus(guest.status);
//         setShowStatusModal(true);
//     };

//     // Update guest status
//     const updateGuestStatus = () => {
//         if (!currentGuest || !newStatus) return;

//         const updatedGuests = guests.map(guest => {
//             if (guest.id === currentGuest.id) {
//                 return {
//                     ...guest,
//                     status: newStatus,
//                     statusUpdatedAt: new Date().toISOString()
//                 };
//             }
//             return guest;
//         });

//         setGuests(updatedGuests);
//         setShowStatusModal(false);

//         // Here you would also send the update to your API
//         console.log(`Updated ${currentGuest.name} status to ${newStatus}`);
//         console.log(`Notify guest: ${notifyGuest}`);
//         if (notifyGuest) {
//             console.log(`Custom message: ${customMessage}`);
//         }
//     };

//     // Format date for display
//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         const today = new Date();
//         const yesterday = new Date(today);
//         yesterday.setDate(yesterday.getDate() - 1);

//         if (date.toDateString() === today.toDateString()) {
//             return 'Today';
//         } else if (date.toDateString() === yesterday.toDateString()) {
//             return 'Yesterday';
//         } else {
//             return `${date.toLocaleDateString('en-US', { month: 'short' })} ${date.getDate()}`;
//         }
//     };

//     // Create avatar with first letter of name and random color
//     const Avatar = ({ name }) => {
//         const firstLetter = name.charAt(0).toUpperCase();
//         return (
//             <div className="guest-avatar">
//                 {firstLetter}
//             </div>
//         );
//     };

//     return (
//         <Container fluid className="guest-list-container p-0">
//             <Card className="border-0 shadow-sm">
//                 <Card.Header className="bg-dark text-white d-flex justify-content-between align-items-center py-3">
//                     <h4 className="mb-0">Guest List</h4>
//                     <div className="d-flex">
//                         <Button variant="outline-light" size="sm" className="me-2">
//                             <FiDownload className="me-1" /> Export
//                         </Button>
//                         <Button variant="outline-light" size="sm">
//                             <FiShare className="me-1" /> Share
//                         </Button>
//                     </div>
//                 </Card.Header>

//                 <Card.Body className="p-0">
//                     {/* Search and Filter Bar */}
//                     <div className="search-filter-container p-3 border-bottom">
//                         <Row className="align-items-center">
//                             <Col md={4} className="mb-2 mb-md-0">
//                                 <div className="search-container">
//                                     <FiSearch className="search-icon" />
//                                     <Form.Control
//                                         type="text"
//                                         placeholder="Search"
//                                         value={searchQuery}
//                                         onChange={(e) => setSearchQuery(e.target.value)}
//                                         className="border-0 bg-light ps-5"
//                                     />
//                                 </div>
//                             </Col>
//                             <Col md={4} className="mb-2 mb-md-0">
//                                 <Dropdown>
//                                     <Dropdown.Toggle variant="light" className="w-100 text-start d-flex align-items-center">
//                                         <FiFilter className="me-2" /> {statusFilter}
//                                     </Dropdown.Toggle>
//                                     <Dropdown.Menu>
//                                         <Dropdown.Item onClick={() => setStatusFilter('All Guests')}>All Guests</Dropdown.Item>
//                                         <Dropdown.Item onClick={() => setStatusFilter('Going')}>Going</Dropdown.Item>
//                                         <Dropdown.Item onClick={() => setStatusFilter('Invited')}>Invited</Dropdown.Item>
//                                         <Dropdown.Item onClick={() => setStatusFilter('Not Going')}>Not Going</Dropdown.Item>
//                                         <Dropdown.Item onClick={() => setStatusFilter('Maybe')}>Maybe</Dropdown.Item>
//                                         <Dropdown.Item onClick={() => setStatusFilter('Pending Approval')}>Pending Approval</Dropdown.Item>
//                                         <Dropdown.Item onClick={() => setStatusFilter('Waitlist')}>Waitlist</Dropdown.Item>
//                                         <Dropdown.Item onClick={() => setStatusFilter('Checked In')}>Checked In</Dropdown.Item>
//                                     </Dropdown.Menu>
//                                 </Dropdown>
//                             </Col>
//                             <Col md={4}>
//                                 <Dropdown>
//                                     <Dropdown.Toggle variant="light" className="w-100 text-start d-flex align-items-center">
//                                         <FiCalendar className="me-2" /> {sortBy}
//                                     </Dropdown.Toggle>
//                                     <Dropdown.Menu>
//                                         <Dropdown.Item onClick={() => setSortBy('Register Time')}>Register Time</Dropdown.Item>
//                                         <Dropdown.Item onClick={() => setSortBy('Name')}>Name</Dropdown.Item>
//                                         <Dropdown.Item onClick={() => setSortBy('Status Change')}>Status Change</Dropdown.Item>
//                                     </Dropdown.Menu>
//                                 </Dropdown>
//                             </Col>
//                         </Row>
//                     </div>

//                     {/* Guest List */}
//                     <div className="guest-list">
//                         {filteredGuests.length > 0 ? (
//                             filteredGuests.map((guest) => (
//                                 <div key={guest.id} className="guest-item">
//                                     <div className="guest-info">
//                                         <Avatar name={guest.name} />
//                                         <div className="guest-details">
//                                             <h6 className="mb-0">{guest.name}</h6>
//                                             <small className="text-muted">{guest.email}</small>
//                                         </div>
//                                     </div>
//                                     <div className="guest-status">
//                                         <Badge
//                                             bg={statusStyles[guest.status].bg}
//                                             text={statusStyles[guest.status].text}
//                                             className="status-badge"
//                                         >
//                                             {guest.status}
//                                         </Badge>
//                                     </div>
//                                     <div className="guest-date">
//                                         {formatDate(guest.status === 'Invited' ? guest.invitedAt : guest.statusUpdatedAt)}
//                                     </div>
//                                     <div className="guest-actions">
//                                         <OverlayTrigger
//                                             placement="top"
//                                             overlay={<Tooltip>Update Status</Tooltip>}
//                                         >
//                                             <Button
//                                                 variant="light"
//                                                 size="sm"
//                                                 className="action-btn"
//                                                 onClick={() => handleStatusUpdate(guest)}
//                                             >
//                                                 <FiMoreVertical />
//                                             </Button>
//                                         </OverlayTrigger>
//                                     </div>
//                                 </div>
//                             ))
//                         ) : (
//                             <div className="text-center py-5">
//                                 <p className="text-muted">No guests found matching your criteria.</p>
//                             </div>
//                         )}
//                     </div>
//                 </Card.Body>
//             </Card>

//             {/* Status Update Modal */}
//             <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Update Status</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {currentGuest && (
//                         <>
//                             <p><strong>Guest:</strong> {currentGuest.name}</p>
//                             <p><strong>Current Status:</strong> {currentGuest.status}</p>

//                             <Form.Group className="mb-3">
//                                 <Form.Label>New Status</Form.Label>
//                                 <Form.Select
//                                     value={newStatus}
//                                     onChange={(e) => setNewStatus(e.target.value)}
//                                 >
//                                     <option value="Going">Going</option>
//                                     <option value="Invited">Invited</option>
//                                     <option value="Not Going">Not Going</option>
//                                     <option value="Maybe">Maybe</option>
//                                     <option value="Pending Approval">Pending Approval</option>
//                                     <option value="Waitlist">Waitlist</option>
//                                     <option value="Checked In">Checked In</option>
//                                 </Form.Select>
//                             </Form.Group>

//                             <Form.Group className="mb-3">
//                                 <Form.Check
//                                     type="checkbox"
//                                     label="Notify Guest"
//                                     checked={notifyGuest}
//                                     onChange={() => setNotifyGuest(!notifyGuest)}
//                                 />
//                             </Form.Group>

//                             {notifyGuest && (
//                                 <Form.Group className="mb-3">
//                                     <Form.Label>Custom Message</Form.Label>
//                                     <Form.Control
//                                         as="textarea"
//                                         rows={3}
//                                         placeholder="Add a custom message to the notification email..."
//                                         value={customMessage}
//                                         onChange={(e) => setCustomMessage(e.target.value)}
//                                     />
//                                 </Form.Group>
//                             )}
//                         </>
//                     )}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
//                         Cancel
//                     </Button>
//                     <Button variant="primary" onClick={updateGuestStatus}>
//                         Update Status
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </Container>
//     );
// };

// export default GuestList;
