import React, { useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { X } from 'lucide-react';
import axios from 'axios';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import  Select from 'react-select';
import { Badge } from 'react-bootstrap';


const API_URL = process.env.REACT_APP_API_URL;

const AddCompanyModal = ({ show, handleClose, audienceTags, getCompaniesAfterAddition }) => {
    const [formData, setFormData] = useState({
        name: '',
        industry: '',
        website: '',
        linkedinUrl: '',
        size: '',
        revenue: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        tags: [],
        description: ''
    });

    const [loading, setLoading] = useState(false);
    const [tagInput, setTagInput] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);


    // Industry options
    const industries = [
        "Technology", "Healthcare", "Finance", "Education", "Manufacturing",
        "Retail", "Construction", "Entertainment", "Food & Beverage",
        "Transportation", "Real Estate", "Agriculture", "Energy",
        "Telecommunications", "Consulting", "Other"
    ];

    // Company size options
    const companySizes = [
        "1-10 employees", "11-50 employees", "51-200 employees",
        "201-500 employees", "501-1000 employees", "1001-5000 employees",
        "5001-10000 employees", "10000+ employees"
    ];

    // Countries list
    const countries = [
        "United States", "Canada", "United Kingdom", "Australia", "Germany",
        "France", "Japan", "China", "India", "Brazil", "Mexico", "Spain",
        "Italy", "Netherlands", "Sweden", "Singapore", "South Korea", "Other"
    ];

    const showError = (message) => {
        PNotify.error({
            title: 'Error',
            text: message,
            delay: 3000
        });
    };

    const showSuccess = (message) => {
        PNotify.success({
            title: 'Success',
            text: message,
            delay: 3000
        });
    };

    const validateForm = () => {
        let isValid = true;

        if (!formData.name.trim()) {
            showError('Company name is required');
            isValid = false;
        }

        if (formData.website && !formData.website.includes('.')) {
            showError('Please enter a valid website URL');
            isValid = false;
        }

        if (formData.linkedinUrl && !formData.linkedinUrl.includes('linkedin.com')) {
            showError('Please enter a valid LinkedIn URL');
            isValid = false;
        }

        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const owner_id = JSON.parse(localStorage.getItem("user"))._id;
        const formDataWithOwner = {
            ...formData,
            owner_id: owner_id,
            tags: selectedTags.map(tag => tag.value)
        };

        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/crm/add-company`, formDataWithOwner);

            if (response.data.success) {
                setFormData({
                    name: '',
                    industry: '',
                    website: '',
                    linkedinUrl: '',
                    size: '',
                    revenue: '',
                    city: '',
                    state: '',
                    country: '',
                    postalCode: '',
                    tags: [],
                    description: ''
                });

                showSuccess('Company added successfully!');
                handleClose();
                getCompaniesAfterAddition();
            }
        } catch (error) {
            if (error.response?.data?.message) {
                showError(error.response.data.message);
            } else {
                showError('Error adding company. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleTagInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = tagInput.trim();
            if (newTag && !formData.tags.includes(newTag)) {
                setFormData(prev => ({
                    ...prev,
                    tags: [...prev.tags, newTag]
                }));
                setTagInput('');
            }
        }
    };

    const removeTag = (tagToRemove) => {
        setFormData(prev => ({
            ...prev,
            tags: prev.tags.filter(tag => tag !== tagToRemove)
        }));
    };

    const handleTagInputChange = (e) => {
        setTagInput(e.target.value);
    };

    const tagsOptions = audienceTags && audienceTags.map((c) => ({
        value: c._id,
        label: `${c.tag_name}`,
    }));

    const handleTagChange = (selected) => {
        setSelectedTags(selected || []);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            animation={true}
            className="Sidebar-Modal-end"

        >
            <Modal.Header closeButton>
                <Modal.Title>Add New Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Industry</Form.Label>
                        <Form.Select
                            name="industry"
                            value={formData.industry}
                            onChange={handleChange}
                        >
                            <option value="">Select an industry</option>
                            {industries.map((industry, index) => (
                                <option key={index} value={industry}>{industry}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                            type="url"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                            placeholder="https://"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>LinkedIn URL</Form.Label>
                        <Form.Control
                            type="url"
                            name="linkedinUrl"
                            value={formData.linkedinUrl}
                            onChange={handleChange}
                            placeholder="https://linkedin.com/company/..."
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Company Size</Form.Label>
                        <Form.Select
                            name="size"
                            value={formData.size}
                            onChange={handleChange}
                        >
                            <option value="">Select company size</option>
                            {companySizes.map((size, index) => (
                                <option key={index} value={size}>{size}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Annual Revenue</Form.Label>
                        <Form.Control
                            type="text"
                            name="revenue"
                            value={formData.revenue}
                            onChange={handleChange}
                            placeholder="e.g. $1M - $5M"
                        />
                    </Form.Group>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>State/Region</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Country</Form.Label>
                                <Form.Select
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                >
                                    <option value="">Select a country</option>
                                    {countries.map((country, index) => (
                                        <option key={index} value={country}>{country}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="postalCode"
                                    value={formData.postalCode}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* <Form.Group className="mb-3">
                        <Form.Label>Tags</Form.Label>
                        <div className="border rounded p-2">
                            <div className="d-flex flex-wrap gap-2 mb-2">
                                {formData.tags.map((tag, index) => (
                                    <span
                                        key={index}
                                        className="badge bg-primary d-flex align-items-center"
                                    >
                                        {tag}
                                        <X
                                            className="ms-1 cursor-pointer"
                                            size={14}
                                            onClick={() => removeTag(tag)}
                                        />
                                    </span>
                                ))}
                            </div>
                            <Form.Control
                                type="text"
                                className="border-0"
                                value={tagInput}
                                onChange={handleTagInputChange}
                                onKeyDown={handleTagInputKeyDown}
                                placeholder="Enter tags"
                            />
                        </div>
                    </Form.Group> */}

                    <Form.Group className="mb-3">
                        <Form.Label>Tags</Form.Label>
                        <Select
                            options={tagsOptions}
                            isMulti
                            value={selectedTags}
                            onChange={handleTagChange}
                            placeholder="Select tags..."
                        />
                        <div className="d-flex flex-wrap gap-2 mt-2">
                            {selectedTags.map((tag) => (
                                <Badge key={tag.value} className="p-2">
                                    {tag.label}
                                    <X
                                        size={14}
                                        className="ms-2 cursor-pointer"
                                        onClick={() =>
                                            handleTagChange(
                                                selectedTags.filter((c) => c.value !== tag.value)
                                            )
                                        }
                                    />
                                </Badge>
                            ))}
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <div className="text-end mt-4">
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            className="me-2"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? 'Adding...' : 'Add Company'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddCompanyModal;