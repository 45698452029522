import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Badge } from "react-bootstrap";
import axios from "axios";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import { crmActivitiesContext } from "../../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";
import Select from "react-select";
import { X } from 'lucide-react'; // Assuming you're using react-feather icons


const API_URL = process.env.REACT_APP_API_URL;

const TaskModal = ({
    showModal,
    setShowModal,
    componentType,
    id,
    onTaskCreated,
    companies = [],
    deals = [],
    leads = [],
    contacts = [],
    users = []
}) => {
    const { crmActivitiesData, crmActivitiesDispatch } = useContext(crmActivitiesContext);
    const [taskData, setTaskData] = useState({
        title: "",
        description: "",
        companies: [],
        deals: [],
        leads: [],
        contacts: [],
        contact_id: "",
        priority: "Medium",
        assigned_to: "",
        due_date: new Date().toISOString().split('T')[0],
        due_time: "17:15",
        status: "Todo"
    });

    const handleCreateTask = async () => {
        if (!taskData.title || !taskData.description) {
            PNotify.error({ title: "Error", text: "Please fill all required fields", delay: 3000 });
            return;
        }

        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.post(`${API_URL}/crm/create-task`, {
                ...taskData,
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                crm_type: componentType,
                owner_id: user._id,
                due_datetime: `${taskData.due_date}T${taskData.due_time}`,
                author: user.name || "User",
            });

            if (response.data.success) {
                // onTaskCreated(response.data.data);
                crmActivitiesDispatch({ type: "SET_TEMP_FETCH", payload: false });
                setShowModal(false);
                resetForm();
                PNotify.success({ title: "Success", text: "Task created successfully", delay: 2000 });
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to create task", delay: 3000 });
        }
    };

    const resetForm = () => {
        setTaskData({
            title: "",
            description: "",
            companies: [],
            deals: [],
            leads: [],
            contacts: [],
            contact_id: "",
            priority: "Medium",
            assigned_to: "",
            due_date: new Date().toISOString().split('T')[0],
            due_time: "17:15",
            status: "Todo"
        });
    };

    const companyOptions = companies.map((c) => ({
        value: c._id,
        label: `${c.name}`,
    }));

    const contactOptions = contacts.map((c) => ({
        value: c._id,
        label: `${c.firstName} ${c.lastName} (${c.email})`,
    }));

    const dealOptions = deals.map((d) => ({
        value: d._id,
        label: `${d.title}`,
    }));

    const leadOptions = leads.map((l) => ({
        value: l._id,
        label: `${l.firstName} ${l.lastName} (${l.email})`,
    }));

    const handleCompanyChange = (selected) => {
        setTaskData((prev) => ({
            ...prev,
            companies: selected || [],
        }));
    };

    const handleContactChange = (selected) => {
        setTaskData((prev) => ({
            ...prev,
            contacts: selected || [],
        }));
    };

    const handleDealChange = (selected) => {
        setTaskData((prev) => ({
            ...prev,
            deals: selected || [],
        }));
    };

    const handleLeadChange = (selected) => {
        setTaskData((prev) => ({
            ...prev,
            leads: selected || [],
        }));
    };


    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}
            animation={true}
            className="Sidebar-Modal-end"
            size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Create Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={taskData.title}
                            onChange={(e) => setTaskData({ ...taskData, title: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={taskData.description}
                            onChange={(e) => setTaskData({ ...taskData, description: e.target.value })}
                        />
                    </Form.Group>

                    <div className="row mb-3">
                        <div className="col">

                            {/* Companies Selection */}
                            <Form.Group className="mb-3">
                                <Form.Label>Companies</Form.Label>
                                <Select
                                    options={companyOptions}
                                    isMulti
                                    value={taskData.companies}
                                    onChange={handleCompanyChange}
                                    placeholder="Select companies..."
                                />
                                <div className="d-flex flex-wrap gap-2 mt-2">
                                    {taskData.companies.map((company) => (
                                        <Badge key={company.value} className="p-2">
                                            {company.label}
                                            <X
                                                size={14}
                                                className="ms-2 cursor-pointer"
                                                onClick={() =>
                                                    handleCompanyChange(
                                                        taskData.companies.filter((c) => c.value !== company.value)
                                                    )
                                                }
                                            />
                                        </Badge>
                                    ))}
                                </div>
                            </Form.Group>

                            {/* Contacts Selection */}
                            <Form.Group className="mb-3">
                                <Form.Label>Contacts</Form.Label>
                                <Select
                                    options={contactOptions}
                                    isMulti
                                    value={taskData.contacts}
                                    onChange={handleContactChange}
                                    placeholder="Select contacts..."
                                />
                                <div className="d-flex flex-wrap gap-2 mt-2">
                                    {taskData.contacts.map((contact) => (
                                        <Badge key={contact.value} className="p-2">
                                            {contact.label}
                                            <X
                                                size={14}
                                                className="ms-2 cursor-pointer"
                                                onClick={() =>
                                                    handleContactChange(
                                                        taskData.contacts.filter((c) => c.value !== contact.value)
                                                    )
                                                }
                                            />
                                        </Badge>
                                    ))}
                                </div>
                            </Form.Group>

                            {/* Deals Selection */}
                            <Form.Group className="mb-3">
                                <Form.Label>Deals</Form.Label>
                                <Select
                                    options={dealOptions}
                                    isMulti
                                    value={taskData.deals}
                                    onChange={handleDealChange}
                                    placeholder="Select deals..."
                                />
                                <div className="d-flex flex-wrap gap-2 mt-2">
                                    {taskData.deals.map((deal) => (
                                        <Badge key={deal.value} className="p-2">
                                            {deal.label}
                                            <X
                                                size={14}
                                                className="ms-2 cursor-pointer"
                                                onClick={() =>
                                                    handleDealChange(
                                                        taskData.deals.filter((d) => d.value !== deal.value)
                                                    )
                                                }
                                            />
                                        </Badge>
                                    ))}
                                </div>
                            </Form.Group>

                            {/* Leads Selection */}
                            <Form.Group className="mb-3">
                                <Form.Label>Leads</Form.Label>
                                <Select
                                    options={leadOptions}
                                    isMulti
                                    value={taskData.leads}
                                    onChange={handleLeadChange}
                                    placeholder="Select leads..."
                                />
                                <div className="d-flex flex-wrap gap-2 mt-2">
                                    {taskData.leads.map((lead) => (
                                        <Badge key={lead.value} className="p-2">
                                            {lead.label}
                                            <X
                                                size={14}
                                                className="ms-2 cursor-pointer"
                                                onClick={() =>
                                                    handleLeadChange(
                                                        taskData.leads.filter((l) => l.value !== lead.value)
                                                    )
                                                }
                                            />
                                        </Badge>
                                    ))}
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <Form.Group>
                                <Form.Label>Due Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={taskData.due_date}
                                    onChange={(e) => setTaskData({ ...taskData, due_date: e.target.value })}
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group>
                                <Form.Label>Due Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    value={taskData.due_time}
                                    onChange={(e) => setTaskData({ ...taskData, due_time: e.target.value })}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group className="mb-3">
                        <Form.Label>Assigned To</Form.Label>
                        <Form.Select
                            value={taskData.assigned_to}
                            onChange={(e) => setTaskData({ ...taskData, assigned_to: e.target.value })}
                        >
                            <option value="">Select Assignee</option>
                            {users.map(user => (
                                <option key={user._id} value={user.name}>
                                    {user.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                            value={taskData.status}
                            onChange={(e) => setTaskData({ ...taskData, status: e.target.value })}
                        >
                            <option value="Todo">Todo</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Completed">Completed</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                <Button variant="primary" onClick={handleCreateTask}>Create Task</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskModal;