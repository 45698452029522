import React, { useRef, useState, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { EffectCreative, Navigation } from 'swiper/modules';

import { draftedPostsContext } from '../../contexts/context/draftedPostsContext';
import { BiDuplicate, BiEditAlt } from 'react-icons/bi';
import { Button, Modal } from 'react-bootstrap';
import { MdSchedule } from 'react-icons/md';
import EditPost from "./../EditPost/EditPost"
import { postTimeLocale } from '../../Helpers/postTimeLocale';
import { socialAccountContext } from '../../contexts/context/socialAccountContext';

import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

import { FaAngleDown, FaAngleUp, FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube, FaXTwitter, FaLinkedinIn } from "react-icons/fa6";



import moment from 'moment'
import { TbTrash, TbEdit } from 'react-icons/tb';
import useDraftDataActions from "./../../contexts/actions/useDraftDataActions";
import useArchivedDataActions from "./../../contexts/actions/useArchivedDataActions";
import BulkSchedule from '../bulkschedule';

import useSocialPermissions from '../../Components/CustomHooks/useSocialPermissions';
const API_URL = process.env.REACT_APP_API_URL;
const DraftedPosts = ({ accountdata, type }) => {

    const { connectedAccount, connectedAccountdispatch } = useContext(socialAccountContext);

    const { checkForSocialContentPermissoins } = useSocialPermissions();


    const [draftPosts, setSchedulePosts] = React.useState([]);
    const { getdrafteddata } = useDraftDataActions();
    const { draftedPostsData } = useContext(draftedPostsContext);
    const { getarchieveddata } = useArchivedDataActions();
    const [postData, setpostData] = React.useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => {
        // alert("i am closing this");
        setShow(false);
    }
    const handleShow = (ID, data) => {
        setpostData(data)
        setShow(true)
    };

    const [Ar_id, setAr_id] = useState(null);
    const [show02, setShow02] = useState(false);
    const [show04, setShow04] = useState(false);

    const handleClose02 = () => setShow02(false);
    const handleShow02 = (ID, data) => {
        setAr_id(ID);
        setShow02(true)
    };
    const handleClose04 = () => setShow04(false);
    const handleShow04 = (ID, data) => {
        setpostData(data);
        setShow04(true);
    }

    React.useEffect(() => {

        filterScheduledPosts()


    }, [draftedPostsData, , accountdata, type])

    const filterScheduledPosts = () => {
        switch (type) {

            case "ALL":
                if (draftedPostsData.posts) {
                    setSchedulePosts(draftedPostsData.posts);
                }
                break;
            case "FACEBOOK":
                if (draftedPostsData.posts) {
                    const newschedulePosts = draftedPostsData.posts.filter(
                        (post) => post.facebook_pageID === accountdata.facebook_pageID
                    );
                    console.log("new freshbook schedule posts", newschedulePosts);
                    setSchedulePosts(newschedulePosts);
                }
                break;
            case "TWITTER":
                if (draftedPostsData.posts) {
                    const newschedulePosts = draftedPostsData.posts.filter(
                        (post) => post.Twitter_userID === accountdata.Twitter_userID
                    );
                    setSchedulePosts(newschedulePosts);
                }
                break;
            case "INSTAGRAM":
                if (draftedPostsData.posts) {
                    const newschedulePosts = draftedPostsData.posts.filter(
                        (post) => post.instagram_business_account_id === accountdata.instagram_business_account_id
                    );
                    setSchedulePosts(newschedulePosts);
                }
                break;
            case "LINKEDIN":
                if (draftedPostsData.posts) {
                    const newschedulePosts = draftedPostsData.posts.filter(
                        (post) => post.LinkedIn_userID === accountdata.LinkedIn_userID
                    );
                    setSchedulePosts(newschedulePosts);
                }
                break;
            case "YOUTUBE":
                if (draftedPostsData.posts) {
                    const newschedulePosts = draftedPostsData.posts.filter(
                        (post) => post.youtube_userID === accountdata.youtube_userID
                    );
                    setSchedulePosts(newschedulePosts);
                }
            default:
                break;
        }
    };

    const handleAriched = async () => {
        try {
            const response = await fetch(`${API_URL}/socialposts/PostsArchived`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    post_Id: Ar_id,
                    status_type: "ARCHIVED",

                })
            });

            const result = await response.json();
            if (result.status === true) {
                const res = result.data;
                getdrafteddata();
                getarchieveddata()
                handleClose02();
                PNotify.success({
                    title: 'Success',
                    text: 'Updated successfully',
                })
            } else {
                PNotify.error({
                    title: 'Error',
                    text: "Post is not updated",
                })
            }
        } catch (error) {

        }
    }
    return (
        <>
            <Modal show={show04} onHide={handleClose04} centered size="lg" className='Sidebar-Modal-end' >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Duplicate Post</Modal.Title>
                </Modal.Header>
                <BulkSchedule closemodal={handleClose04} scheduleBinData={postData} type="Duplicate" postType="Drafted" method="all" />
            </Modal>
            <Modal show={show} onHide={handleClose} centered size="lg" className='Sidebar-Modal-end' >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Edit Post</Modal.Title>
                </Modal.Header>
                <EditPost handleClose={handleClose} postData={postData} typeStatus="DRAFTED" />
            </Modal>
            {/* Delete Modal Start From Here */}
            <Modal show={show02} onHide={handleClose02} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose02}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={handleAriched}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="post-lists" >

                <div className="all-post-list">
                    {draftPosts && draftPosts.map((item, index) => {

                        const result = postTimeLocale(item.schedule_time);
                        const localDateString = result?.localDateString;
                        const timeZoneAbbreviation = result?.timeZoneAbbreviation;

                        if (!localDateString) {
                            console.error(`Invalid date for item: ${JSON.stringify(item)}`);
                            return null; // Skip rendering this item
                        }

                        const formattedScheduleDate = moment(localDateString).format("MMMM Do YYYY @ h:mma ") + (timeZoneAbbreviation || '');
                        return (

                            <div className="post-box-design" key={index}>

                                <div className="bg-white rounded-lg shadow-sm">
                                    <div className="post-header-details d-flex justify-content-between">
                                        <div className="post-header">
                                            <div className='channel-list-box-icon facebook-profile'>
                                                <img src={
                                                    type === "ALL" ?
                                                        (item.platform === "FACEBOOK" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.facebook_pageID === item.facebook_pageID)?.localProfilePath :
                                                            item.platform === "TWITTER" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.Twitter_userID === item.Twitter_userID)?.localProfilePath :
                                                                item.platform === "INSTAGRAM" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.instagram_business_account_id === item.instagram_business_account_id)?.localProfilePath :
                                                                    item.platform === "LINKEDIN" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.LinkedIn_userID === item.LinkedIn_userID)?.localProfilePath :
                                                                        item.platform === "YOUTUBE" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.youtube_userID === item.youtube_userID)?.localProfilePath :
                                                                            null) :
                                                        type === "FACEBOOK" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                            type === "TWITTER" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                type === "INSTAGRAM" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                    type === "LINKEDIN" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                        type === "YOUTUBE" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                            null
                                                }
                                                    alt="Profile Picture" className="user-profile-img" />

                                                <div className="bdyGBn social-icon-view">
                                                    {item.platform === 'FACEBOOK' && <FaFacebook />}
                                                    {item.platform === 'TWITTER' && <FaXTwitter />}
                                                    {item.platform === 'LINKEDIN' && <FaLinkedinIn />}
                                                    {item.platform === 'INSTAGRAM' && <FaInstagram />}
                                                    {item.platform === 'YOUTUBE' && <FaYoutube />}

                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-lg font-semibold user-name-text">
                                                    {
                                                        type === "ALL" ?
                                                            (item.platform === "FACEBOOK" ? connectedAccount.accounts.find((post) => post.facebook_pageID === item.facebook_pageID)?.facebook_pageName :
                                                                item.platform === "TWITTER" ? connectedAccount.accounts.find((post) => post.Twitter_userID === item.Twitter_userID)?.Twitter_displayName :
                                                                    item.platform === "INSTAGRAM" ? connectedAccount.accounts.find((post) => post.instagram_business_account_id === item.instagram_business_account_id)?.instagram_business_account_name :
                                                                        item.platform === "LINKEDIN" ? connectedAccount.accounts.find((post) => post.LinkedIn_userID === item.LinkedIn_userID)?.LinkedIn_name :
                                                                            item.platform === "YOUTUBE" ? connectedAccount.accounts.find((post) => post.youtube_userID === item.youtube_userID)?.youtube_name :
                                                                                null) :
                                                            type === "FACEBOOK" ? accountdata.facebook_pageName :
                                                                type === "TWITTER" ? accountdata.Twitter_displayName :
                                                                    type === "INSTAGRAM" ? accountdata.instagram_business_account_name :
                                                                        type === "LINKEDIN" ? accountdata.LinkedIn_name :
                                                                            type === "YOUTUBE" ? accountdata.youtube_name :
                                                                                null
                                                    }
                                                </div>
                                                <p className="post-date">Drafted : {formattedScheduleDate}</p>

                                            </div>
                                        </div>

                                        {/* <div className='post-header-btn'>

                                            <button className='btn btn-primary btn-sm post-edit-btn' onClick={() => { handleShow(item._id, item) }}><BiEditAlt /></button>
                                            <button className='btn btn-primary btn-sm post-edit-btn' onClick={() => { handleShow04(item._id, item) }}><BiDuplicate /></button>
                                            <button className='btn btn-primary btn-sm post-edit-btn' onClick={() => { handleShow02(item._id, item) }}><TbTrash /></button>
                                        </div> */}


                                    </div>

                                    <div className="post-body">
                                        <div className="post-body-text">
                                            <p>{item.caption}</p>
                                        </div>
                                        <div className="post-image-grid-custom">
                                            <div className="social-image-grid-custom">
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={10}
                                                    navigation={true}
                                                    modules={[EffectCreative, Navigation]}
                                                    className="mySwiper2"
                                                >{
                                                        item.mediaType == "image" ?
                                                            <>
                                                                {item.images.map((image, index) => (
                                                                    <SwiperSlide key={image.id}>
                                                                        <div className='image-grid-item'>
                                                                            <img src={image.imgUrl.startsWith('https') ? image.imgUrl : API_URL + '/posts/' + image.imgUrl} alt="Post Image" />
                                                                            {/* {index === 3 && images.length > 4 && (
                                                                <div className="view-more">
                                                                    <button>
                                                                        +{images.length - 4}
                                                                    </button>
                                                                </div>
                                                            )} */}
                                                                        </div>
                                                                    </SwiperSlide>
                                                                ))}
                                                            </>

                                                            :
                                                            <>
                                                                {item.videos.map((video, index) => (

                                                                    <SwiperSlide key={video.id}>
                                                                        <div className='video-grid-item'>
                                                                            <video src={video.imgUrl.startsWith('https') ? video.imgUrl : `${API_URL}/posts/${video.imgUrl}`} controls />
                                                                        </div>
                                                                    </SwiperSlide>

                                                                ))}
                                                            </>
                                                    }
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="post-footer-btn">
                                        <div className='mini-page-list-inner-item-content-btn'>
                                            <div className="d-flex new-M">
                                                <button disabled={checkForSocialContentPermissoins() === "view-only"} aria-label="Duplicate" tooltip-position="top" type='button' className="btn duplicate-btn me-3" onClick={() => { handleShow04(item._id, item) }}>
                                                    <BiDuplicate />
                                                </button>
                                                <button disabled={checkForSocialContentPermissoins() === "view-only"} aria-label="Edit" tooltip-position="top" type="button" className="btn btn-edit me-3" onClick={() => { handleShow(item._id, item) }}>
                                                    <TbEdit />
                                                </button>
                                                <button disabled={checkForSocialContentPermissoins() === "view-only"} aria-label='Delete' tooltip-position="top" type="button" className="btn btn-delete" onClick={() => { handleShow02(item._id, item) }}>
                                                    <TbTrash />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {(item.tags && item.tags.length > 0) && (
                                        <div className="brand-Vanity-Tag mt-2">
                                            <p className="d-flex align-items-center">
                                                <h3 className="text-blue-dark me-2">Tags:</h3>
                                                {item.tags.map((tag) => (
                                                    <span style={{
                                                        color: `${tag.tag_color}`,
                                                        backgroundColor: `${tag.tag_color}40`, // Adds transparency to the background
                                                        padding: '4px 8px',
                                                        borderRadius: '25px',
                                                        display: 'inline-block',
                                                        marginRight: '4px',
                                                    }} className="vanity-tag" key={tag}>
                                                        {tag.tag_name}
                                                    </span>
                                                ))}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>



        </>
    )

}

export default DraftedPosts