import React, { useState, useEffect, useContext } from 'react';
import './eventCard.css';
import { getSettings, saveSetting } from '../../BackendApi/Api/Event';
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { EventContext } from '../../EventBuilder/EventsContext';
import { useParams } from 'react-router-dom';

const EventCard = ({ event_id }) => {
    const { formData, setFormData } = useContext(EventContext);
    const {id} = useParams();
console.log("Event ID:", id);
    // ✅ Handles input changes properly
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? e.target.checked : (name === 'capacityType' || name === 'maxAttendees' ? Number(value) : value)
        }));
    };

    useEffect(() => {
        if (id) {
            getSettingData(id);
        }
    }, [id]);
    
    const getSettingData = async (event_id) => {
        try {
            const result = await getSettings({ event_id: event_id });
            console.log("API Response:", result);
    
            if (result?.data?.status) {
                console.log("Setting formData:", result.data.data);
                setFormData(result.data.data);
            } else {
                PNotify.error({
                    title: 'Error',
                    text: 'Failed to fetch settings',
                });
            }
        } catch (error) {
            console.error("API Call Error:", error);
            PNotify.error({
                title: 'Error',
                text: error.message,
            });
        }
    };
    

    const updateEvent = async () => {
        try {
            const result = await saveSetting({ event_id, ...formData });
            if (result.data.status) {
                PNotify.success({
                    title: 'Success',
                    text: 'Event settings updated successfully',
                });
                setFormData(result.data.data);
            } else {
                PNotify.error({
                    title: 'Error',
                    text: result.data.message,
                });
            }
        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: error.message,
            });
        }
    };

    return (
        <div className="event-card">
            <form>
                <label>Display Event Address:</label>
                <select name="showEventAddress" onChange={handleInputChange} value={Number(formData.showEventAddress)}>
                    <option value={0}>Everyone</option>
                    <option value={1}>Only Registered</option>
                </select>

                <label>Type of Event:</label>
                <select name="eventType" onChange={handleInputChange} value={Number(formData.eventType)}>
                    <option value={0}>Free</option>
                    <option value={1}>Paid</option>
                </select>

                <label>Capacity:</label>
                <select name="capacityType" onChange={handleInputChange} value={Number(formData.capacityType)}>
                    <option value={0}>Unlimited</option>
                    <option value={1}>Max Attendees</option>
                </select>

                {formData.capacityType === 1 && (
                    <>
                        <label>Max Attendees:</label>
                        <input
                            type="number"
                            name="maxAttendees"
                            value={formData.maxAttendees}
                            onChange={handleInputChange}
                        />
                        <label>Over Capacity Waitlist?</label>
                        <select name="isWaitlist" onChange={handleInputChange} value={formData.isWaitlist ? "true" : "false"}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </>
                )}

                <label>Display Guest List?</label>
                <select name="showGuestList" onChange={handleInputChange} value={formData.showGuestList ? "true" : "false"}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>

                <label>Display Guest Count?</label>
                <select name="showGuestCount" onChange={handleInputChange} value={formData.showGuestCount ? "true" : "false"}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>

                <label>Display Tickets Left?</label>
                <select name="showTicketsLeft" onChange={handleInputChange} value={formData.showTicketsLeft ? "true" : "false"}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>

                <label>Display Hosts Publicly?</label>
                <select name="showHosts" onChange={handleInputChange} value={formData.showHosts ? "true" : "false"}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>

                <label>Tags</label>
                <select name="statusTag" onChange={handleInputChange} value={Number(formData.statusTag)}>
                    <option value={0}>Upcoming</option>
                    <option value={1}>Sold Out</option>
                    <option value={2}>Limited Seat</option>
                </select>

                <button type="button" className="update-btn" onClick={updateEvent}>
                    Update
                </button>
            </form>
        </div>
    );
};

export default EventCard;
