import React, { useState } from 'react'
import { Tab, Nav } from 'react-bootstrap';
import NotesContent from './notes/NotesContent';
import Activities from './activities/Activities';
import EmailContent from './emails/EmailContent';
import CallContent from './calls/CallContent';
import TaskContent from './tasks/TaskContent';
import MeetingContent from './meetings/MeetingContent';


const CrmActivities = ({ componentType }) => {

    const [activeTab, setActiveTab] = useState('activity');

    const activeTabContemt = (tab) => {

        switch (tab) {
            case 'activity':
                return <Activities componentType={componentType} />;
            case 'notes':
                return <NotesContent componentType={componentType}  />;
            case 'email':
                return <EmailContent componentType={componentType} />;
            case 'calls':
                return <CallContent componentType={componentType} />;
            case 'tasks':
                return <TaskContent componentType={componentType} />;
            // case 'linkedin':
            //     return <NotesContent />;
            case 'meetings':
                return <MeetingContent componentType={componentType} />;
            default:
                return null;
        }

    };

    return (
        <Tab.Container id="activity-tabs" activeKey={activeTab} onSelect={setActiveTab}>
            <Nav variant="tabs" className="mb-4">
                <Nav.Item>
                    <Nav.Link eventKey="activity" className="text-dark">Activity</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="notes" className="text-dark">Notes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="email" className="text-dark">Email</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="calls" className="text-dark">Calls</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tasks" className="text-dark">Tasks</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                    <Nav.Link eventKey="linkedin" className="text-dark">LinkedIn</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                    <Nav.Link eventKey="meetings" className="text-dark">Meetings</Nav.Link>
                </Nav.Item>
            </Nav>

            <div>
                {
                    activeTabContemt(activeTab)
                }
            </div>


        </Tab.Container>
    )
}

export default CrmActivities;