import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FaEdit, FaTrash } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import CreateTag from './CreateTag'
import axios from 'axios'
import * as PNotify from '@pnotify/core'
import '@pnotify/core/dist/PNotify.css'
import '@pnotify/core/dist/BrightTheme.css'
import TagsList from './TagsList'


const API_URL = process.env.REACT_APP_API_URL
const AudienceTags = () => {
    const [showCreateTagModal, setShowCreateTagModal] = useState(false)
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(true)

    // PNotify configuration
    const showNotification = (text, type) => {
        PNotify.alert({
            title: type.charAt(0).toUpperCase() + type.slice(1),
            text: text,
            type: type,
            delay: 3000
        });
    };

    // Fetch tags
    const fetchTags = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await axios.post(`${API_URL}/tags/getusertags`,
                {
                    owner_id: user?.owner_id,
                    type: 'audience'
                })
            setTags(response.data.data)
        } catch (error) {
            showNotification(
                error.response?.data?.message || 'Failed to fetch tags',
                'error'
            )
        } finally {
            setLoading(false)
        }
    }

    // Load tags on component mount
    useEffect(() => {
        fetchTags()
    }, [])

    const handleCloseCreateTagModal = () => setShowCreateTagModal(false)

    // Callback for when a new tag is created
    const handleTagCreated = (newTag) => {
        setTags(prevTags => [...prevTags, newTag])
    }

    // Handle tag deletion
    const handleDeleteTag = async (tagId) => {
        try {
            const token = localStorage.getItem('token')
            await axios.delete(`/api/tags/${tagId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

            showNotification('Tag deleted successfully', 'success')
            setTags(prevTags => prevTags.filter(tag => tag._id !== tagId))
        } catch (error) {
            showNotification(
                error.response?.data?.message || 'Failed to delete tag',
                'error'
            )
        }
    }
    return (
        <>
            <Modal
                show={showCreateTagModal}
                onHide={handleCloseCreateTagModal}
                className="Sidebar-Modal-end"
                backdrop="static"
                keyboard={false}
            >
                <CreateTag
                    onClose={handleCloseCreateTagModal}
                    onTagCreated={handleTagCreated}
                    type="audience"
                />
            </Modal>
            <div className="setting-tab-content-body">
                <div className="tab-content-header-right d-flex justify-content-end">
                    <Button
                        className="btn btn-sm float-right add-user-btn"
                        onClick={() => setShowCreateTagModal(true)}
                    >
                        New Tag
                    </Button>
                </div>
                {loading ? (
                    <div className="text-center p-4">Loading tags...</div>
                ) : tags.length === 0 ? (
                    <div className="text-center p-4">No tags found. Create your first tag!</div>
                ) : (
                    <TagsList tags={tags} handleDeleteTag={handleDeleteTag} />

                )}
            </div>
        </>
    )

}

export default AudienceTags