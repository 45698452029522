import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import *  as PNotify from '@pnotify/core';
import { crmActivitiesContext } from "../../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";

const API_URL = process.env.REACT_APP_API_URL;

const AddNote = ({ show, onHide, onNoteAdded, componentType, id }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const { crmActivitiesDispatch } = useContext(crmActivitiesContext);

    const handleAddNote = async () => {
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const noteContent = draftToHtml(rawContent);
        if (!noteContent.trim()) return;

        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.post(`${API_URL}/crm/add-note`, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                crm_type: componentType,
                owner_id: user._id,
                note: noteContent,
                author: user.name || "User",
            });
            if (response.data.success) {
                crmActivitiesDispatch({ type: "SET_TEMP_FETCH", payload: false });
                // onNoteAdded(response.data.data);
                onHide();
                setEditorState(EditorState.createEmpty());
                PNotify.success({ title: "Success", text: "Note added successfully", delay: 2000 });
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to add note", delay: 3000 });
        }
    };

    return (
        <Modal show={show} onHide={onHide} animation={true} className="Sidebar-Modal-end" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Editor
                    editorState={editorState}
                    wrapperClassName="wysiwyg-wrapper"
                    editorClassName="wysiwyg-editor"
                    onEditorStateChange={setEditorState}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={handleAddNote}>Save Note</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddNote;