import { useState } from 'react';
import Comments from './comments/Comments';
import Associations from './associations/Associations';

const AssociationsPanel = ({ componentType }) => {
  const [activeTab, setActiveTab] = useState('associations');
  const [commentText, setCommentText] = useState('');
  const [associations, setAssociations] = useState({
    companies: [],
    deals: [],
    lists: []
  });

  const [comments, setComments] = useState([]);

  const handleAddComment = () => {
    // Add comment logic here
    if (commentText.trim()) {
      // Logic to add a new comment
      console.log("Adding comment:", commentText);
      // Reset comment text
      setCommentText('');
    }
  };

  return (
    <div className="associations-panel  p-0 border-start h-100">
      {/* Tabs navigation */}
      <div className="tabs-header d-flex border-bottom">
        <div
          className={`tab-item py-2 px-3 flex-grow-1 text-center ${activeTab === 'associations' ? 'fw-bold' : 'text-secondary'}`}
          onClick={() => setActiveTab('associations')}
          style={{ cursor: 'pointer' }}
        >
          Associations
        </div>
        <div
          className={`tab-item py-2 px-3 flex-grow-1 text-center ${activeTab === 'comments' ? 'fw-bold' : 'text-secondary'}`}
          onClick={() => setActiveTab('comments')}
          style={{ cursor: 'pointer' }}
        >
          Comments
        </div>
      </div>

      {/* Associations Tab Content */}
      {activeTab === 'associations' && (
        <Associations componentType={componentType} />
      )}

      {/* Comments Tab Content */}
      {activeTab === 'comments' && (
        <Comments componentType={componentType} />
      )}
    </div>
  );
};

export default AssociationsPanel;