import React from "react";
import { useState, useEffect, useRef } from "react";
import { MdLocationOn } from "react-icons/md";
import { InputGroup, Form } from "react-bootstrap";
import GoogleMaps from "../../EventBuilder/EventsTab/GoogleMap2";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../BackendApi/Api/config";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";

const AddVenue = ({
  handleClose,
  onVenueAdded,
  eventId,
  componentType,
  brandId,
}) => {
  const locationInputRef = useRef(null);
  const autocompleteRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [eventProperties, setEventProperties] = useState({
    eventType: "Public",
    location: "",
    Zipcode: "",
    hostName: "",
    City: "",
    country: "",
    lat: "",
    lng: "",
    venue_name: "",
  });

  useEffect(() => {
    // Check if Google Maps Places API is loaded
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      locationInputRef.current
    ) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        locationInputRef.current,
        { types: ["geocode", "establishment"] }
      );

      // Store reference to autocomplete
      autocompleteRef.current = autocomplete;

      // Fix z-index of autocomplete dropdown
      setTimeout(() => {
        const pacContainers = document.querySelectorAll(".pac-container");
        pacContainers.forEach((container) => {
          container.style.zIndex = "1100"; // Higher than Bootstrap modal z-index
        });
      }, 300);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          // User entered the name of a Place that was not suggested
          alert("No details available for this location");
          return;
        }

        // Extract location details
        const locationDetails = {
          location: place.formatted_address || place.name,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          City: "",
          country: "",
          Zipcode: "",
        };

        // Extract additional address components
        if (place.address_components) {
          place.address_components.forEach((component) => {
            const componentType = component.types[0];

            switch (componentType) {
              case "locality":
                locationDetails.City = component.long_name;
                break;
              case "country":
                locationDetails.country = component.long_name;
                break;
              case "postal_code":
                locationDetails.Zipcode = component.long_name;
                break;
            }
          });
        }

        // Update event properties with location details
        setEventProperties((prevState) => ({
          ...prevState,
          ...locationDetails,
        }));
      });

      return () => {
        // Clean up listener
        if (autocompleteRef.current) {
          window.google.maps.event.clearInstanceListeners(
            autocompleteRef.current
          );
        }
      };
    }
  }, []);

  // Add another useEffect to handle modal open/close
  useEffect(() => {
    // This will run when the component mounts (modal opens)
    const fixZIndex = () => {
      const pacContainers = document.querySelectorAll(".pac-container");
      pacContainers.forEach((container) => {
        container.style.zIndex = "1100"; // Higher than Bootstrap modal z-index
      });
    };

    // Run immediately and set up an interval to keep checking
    // (Google may recreate these elements)
    fixZIndex();
    const intervalId = setInterval(fixZIndex, 500);

    // Clean up on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventProperties((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddVenue = async () => {
    setLoading(true);
    let data = {
      venue_name: eventProperties.venue_name,
      location: eventProperties.location,
      lat: eventProperties.lat,
      lng: eventProperties.lng,
      City: eventProperties.City,
      country: eventProperties.country,
      Zipcode: eventProperties.Zipcode,
    };
    if (componentType === "Event") {
      data.event_id = eventId;
      data.componentType = "Event";
    }
    if (componentType === "Brand") {
      data.brand_id = brandId;
      data.componentType = "Brand";
    }

    console.log("venue data", data);

    if (!eventProperties.venue_name || !eventProperties.location) {
      alert("Please provide venue name and location");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}event/add-venue`, data);
      if (response.data.success) {
        onVenueAdded();
        handleClose();
        setLoading(false);
      }
    } catch (error) {
      console.error("Error adding venue:", error.response.data.message);
      PNotify.error({
        text: error.response.data.message,
        delay: 3000,
      });
      setLoading(false);
    }

    // Implement your venue adding logic here
  };

  return (
    <>
      <div className="form_block_group">
        <Form.Label>Venue Name</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Venue Name"
            aria-label="Venue Name"
            aria-describedby="basic-addon1"
            name="venue_name"
            value={eventProperties.venue_name}
            onChange={handleInputChange}
          />
        </InputGroup>

        <Form.Label>Venue Address</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <MdLocationOn />
          </InputGroup.Text>
          <Form.Control
            ref={locationInputRef}
            type="text"
            placeholder="Location"
            aria-label="Location"
            aria-describedby="basic-addon1"
            name="location"
            value={eventProperties.location}
            onChange={handleInputChange}
          />
        </InputGroup>
        {eventProperties.lat && eventProperties.lng && (
          <div className="mt-3" style={{ width: "100%", height: "250px" }}>
            <GoogleMaps
              center={{
                lat: parseFloat(eventProperties.lat),
                lng: parseFloat(eventProperties.lng),
              }}
              zoom={15}
              markers={[
                {
                  lat: parseFloat(eventProperties.lat),
                  lng: parseFloat(eventProperties.lng),
                  title: eventProperties.location,
                },
              ]}
            />
            <div className="mt-2 text-muted">
              {eventProperties.location}
              {eventProperties.City && `, ${eventProperties.City}`}
              {eventProperties.country && `, ${eventProperties.country}`}
            </div>
          </div>
        )}
      </div>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddVenue}>
          Add Venue
        </Button>
      </Modal.Footer>
    </>
  );
};

export default AddVenue;
