import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import * as XLSX from "xlsx";
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { useHistory } from "react-router-dom";
import axios from "axios";
import AddDealModal from "./add-deal/AddDeal";
import "./dealskaban.css";
import { FaTable, FaColumns } from 'react-icons/fa'; // Import icons for toggle

const API_URL = process.env.REACT_APP_API_URL;

const DealsKanbanPage = () => {
    const [deals, setDeals] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [audienceTags, setAudienceTags] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [filteredDeals, setFilteredDeals] = useState([]);

    const history = useHistory();

    // Define the deal stages for the kanban columns
    const dealStages = [
        "Qualification",
        "Discovery",
        "Proposal",
        "Negotiation",
        "Closed Won",
        "Closed Lost"
    ];

    const formatDeals = (rawDeals) => {
        return rawDeals.map((deal) => ({
            id: deal._id,
            title: deal.title || 'N/A',
            companies: deal.companies || [],
            contacts: deal.contacts || [],
            amount: deal.amount || 0,
            expectedCloseDate: deal.expectedCloseDate || 'N/A',
            dealType: deal.dealType || 'New',
            dealPriority: deal.dealPriority || 'Medium',
            dealOwner: deal?.dealOwner?.fname + " " + deal?.dealOwner?.lname || 'N/A',
            pipeline: deal.pipeline || 'Sales Pipeline',
            dealStage: deal.dealStage || 'Qualification',
            tags: deal.tags || [],
            description: deal.description || 'N/A'
        }));
    };

    const fetchDeals = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-deals`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                const formattedDeals = formatDeals(response.data.data);
                setDeals(formattedDeals);
                setFilteredDeals(formattedDeals);
            }
        } catch (error) {
            console.error("Error fetching deals:", error);
            PNotify.error({
                title: 'Error',
                text: 'Failed to fetch deals',
                delay: 3000
            });
        }
    };

    const fetchCompanies = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-companies`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setCompanies(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    };

    const fetchContacts = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-contacts`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setContacts(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);
        }
    };

    const fetchAudienceTags = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/tags/get-audience-tags`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setAudienceTags(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching audience tags:", error);
        }
    };

    useEffect(() => {
        fetchDeals();
        fetchCompanies();
        fetchContacts();
        fetchAudienceTags();
    }, []);

    // Filter deals based on search input
    useEffect(() => {
        if (searchInput) {
            const lowercasedFilter = searchInput.toLowerCase();
            const filtered = deals.filter(item => {
                return Object.keys(item).some(key => {
                    // Convert the value to string to make sure we can use includes
                    const value = item[key] === null ? '' : item[key].toString();
                    return value.toLowerCase().includes(lowercasedFilter);
                });
            });
            setFilteredDeals(filtered);
        } else {
            setFilteredDeals(deals);
        }
    }, [searchInput, deals]);

    const getDealsAfterAddition = async () => {
        fetchDeals();
    };

    const handleDeleteDeal = async (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this deal?');
        if (!isConfirmed) return;

        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;

            const res = await axios.post(`${API_URL}/crm/delete-deal`, {
                "deal_id": id,
                "owner_id": owner_id
            });

            if (res.data.success) {
                fetchDeals();
                PNotify.success({
                    title: 'Success',
                    text: 'Deal deleted successfully',
                    delay: 3000
                });
            }
        } catch (error) {
            console.error("Error deleting deal:", error);
            PNotify.error({
                title: 'Error',
                text: 'Failed to delete deal',
                delay: 3000
            });
        }
    };

    const updateDealStage = async (dealId, newStage) => {
        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;

            const response = await axios.post(`${API_URL}/crm/update-deal-fields`, {
                "deal_id": dealId,
                "owner_id": owner_id,
                "field": "dealStage",
                "value": newStage
            });

            if (response.data.success) {
                PNotify.success({
                    title: 'Success',
                    text: `Deal moved to ${newStage}`,
                    delay: 2000
                });
                return true;
            }
            return false;
        } catch (error) {
            console.error("Error updating deal stage:", error);
            PNotify.error({
                title: 'Error',
                text: 'Failed to update deal stage',
                delay: 3000
            });
            return false;
        }
    };

    const onDragEnd = async (result) => {
        const { destination, source, draggableId } = result;

        // If dropped outside of a droppable area
        if (!destination) {
            return;
        }

        // If dropped in the same place
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // Get the new stage from the destination droppableId
        const newStage = destination.droppableId;

        // Update the deal in the backend
        const success = await updateDealStage(draggableId, newStage);

        if (success) {
            // Update the deal stage in the local state
            setDeals(prevDeals =>
                prevDeals.map(deal =>
                    deal.id === draggableId
                        ? { ...deal, dealStage: newStage }
                        : deal
                )
            );
        }
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(deals);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Deals");
        XLSX.writeFile(workbook, "deals.xlsx");
    };

    // Format currency
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    };

    // Format date for display
    const formatDate = (dateString) => {
        if (dateString === 'N/A') return 'N/A';
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        } catch (e) {
            return dateString;
        }
    };

    return (
        <div className="deals-kanban-page">

            <div className="view-toggle">
                <button
                    className={`view-toggle-btn active`}
                    onClick={() => history.push('/crm/deals/kanban')}
                    title="Kanban View"
                >
                    <FaColumns />
                </button>
                <button
                    className={`view-toggle-btn`}
                    onClick={() => history.push('/crm/deals/table')}
                    title="Table View"
                >
                    <FaTable />
                </button>
            </div>

            <div className="kanban-controls">
                <div className="search-export">
                    <input
                        type="text"
                        placeholder="Search deals..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="search-input"
                    />
                    <button onClick={exportToExcel} className="export-btn">Export to Excel</button>
                </div>
                <button onClick={() => setShowAddModal(true)} className="add-deal-btn">New Deal</button>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <div className="kanban-board">
                    {dealStages.map((stage) => (
                        <div key={stage} className="kanban-column">
                            <div className="column-header">
                                <h3>{stage}</h3>
                                <span className="deal-count">
                                    {filteredDeals.filter(deal => deal.dealStage === stage).length}
                                </span>
                            </div>
                            <Droppable droppableId={stage}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={`column-content ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                                    >
                                        {filteredDeals
                                            .filter(deal => deal.dealStage === stage)
                                            .map((deal, index) => (
                                                <Draggable key={deal.id} draggableId={deal.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`deal-card ${snapshot.isDragging ? 'dragging' : ''}`}
                                                        >
                                                            <div className="deal-card-header">
                                                                <h4
                                                                    className="deal-title"
                                                                    onClick={() => history.push(`/crm-deal/${deal.id}`)}
                                                                >
                                                                    {deal.title}
                                                                </h4>
                                                                <button
                                                                    className="delete-btn"
                                                                    onClick={() => handleDeleteDeal(deal.id)}
                                                                >
                                                                    ×
                                                                </button>
                                                            </div>
                                                            <div className="deal-info">
                                                                <div className="deal-amount">{formatCurrency(deal.amount)}</div>
                                                                <div className="deal-date">Close: {formatDate(deal.expectedCloseDate)}</div>
                                                                <div className="deal-owner">Owner: {deal.dealOwner}</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    ))}
                </div>
            </DragDropContext>

            <AddDealModal
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                companies={companies}
                contacts={contacts}
                audienceTags={audienceTags}
                getDealsAfterAddition={getDealsAfterAddition}
            />
        </div>
    );
};

export default DealsKanbanPage;