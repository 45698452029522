import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { X } from 'lucide-react';

import AddCompanyModal from "./add-company/AddCompany";
import AddDealModal from "./add-deal/AddDeal";
import AddContactModal from "./add-contact/AddContact";
import AddLeadModal from "./add-lead/AddLead";

const API_URL = process.env.REACT_APP_API_URL;

const Associations = ({ componentType }) => {
    const history = useHistory();
    const { id } = useParams();
    const [audienceTags, setAudienceTags] = useState([]);

    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
    const [showAddDealModal, setShowAddDealModal] = useState(false);
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const [showAddLeadModal, setShowAddLeadModal] = useState(false);

    const [companies, setCompanies] = useState([])
    const [deals, setDeals] = useState([])
    const [contacts, setContacts] = useState([])
    const [leads, setLeads] = useState([])
    const [associations, setAssociations] = useState({
        companies: [],
        deals: [],
        contacts: [],
        leads: []
    });


    const fetchAssociations = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-associations`, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                owner_id,
                crm_type: componentType
            });
            if (response.data.success) {
                const data = response.data.data;
                const associationCompanies = data.filter(item => item.association_type === 'COMPANY');
                const associationLeads = data.filter(item => item.association_type === 'LEAD');
                const associationContacts = data.filter(item => item.association_type === 'CONTACT');
                const associationDeals = data.filter(item => item.association_type === 'DEAL');

                console.log("data associations filtered", associationDeals)
                setAssociations({
                    companies: associationCompanies,
                    leads: associationLeads,
                    contacts: associationContacts,
                    deals: associationDeals
                });
            }
        } catch (error) {
            console.error("Error fetching associations:", error);

        }
    }


    const fetchCompanies = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-companies`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setCompanies(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching companies:", error);

        }
    };

    const fetchContacts = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-contacts`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setContacts(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);

        }
    };


    const fetchDeals = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-deals`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setDeals(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching deals:", error);
        }
    };

    const fetchLeads = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-leads`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setLeads(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching leads:", error);

        }

    };


    const fetchAudienceTags = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/tags/get-audience-tags`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setAudienceTags(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching audience tags:", error);
            // PNotify.error({
            //     title: 'Error',
            //     text: 'Failed to fetch audience tags',
            //     delay: 3000
            // });
        }
    };

    useEffect(() => {
        fetchCompanies();
        fetchContacts();
        fetchDeals();
        fetchLeads();
        fetchAssociations();
        fetchAudienceTags();
    }, []);


    return (
        <>
            <AddCompanyModal
                show={showAddCompanyModal}
                handleClose={() => setShowAddCompanyModal(false)}
                componentType={componentType}
                companies={companies}
                associatedCompanies={associations.companies}
                onAddCompany={() => fetchAssociations()}
                audienceTags={audienceTags}
            />
            <AddDealModal
                show={showAddDealModal}
                handleClose={() => setShowAddDealModal(false)}
                componentType={componentType}
                companies={companies}
                contacts={contacts}
                associatedDeals={associations.deals}
                onAddDeal={() => fetchAssociations()}
                deals={deals}
                audienceTags={audienceTags}
            />
            <AddContactModal
                show={showAddContactModal}
                handleClose={() => setShowAddContactModal(false)}
                componentType={componentType}
                companies={companies}
                contacts={contacts}
                associatedContacts={associations.contacts}
                onAddContact={() => fetchAssociations()}
                audienceTags={audienceTags}
            />
            <AddLeadModal
                show={showAddLeadModal}
                handleClose={() => setShowAddLeadModal(false)}
                componentType={componentType}
                companies={companies}
                leads={leads}
                associatedLeads={associations.leads}
                onAddLead={() => fetchAssociations()}
                audienceTags={audienceTags}
            />
            <div className="p-3">
                {/* Companies */}
                {(componentType === "CONTACT" || componentType === "DEAL") && (
                    <div className="association-section mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="d-flex align-items-center">
                                <span className="me-2">
                                    <i className="bi bi-building"></i>
                                </span>
                                <h6 className="mb-0">Companies</h6>
                            </div>
                            <button onClick={() => setShowAddCompanyModal(true)} className="btn btn-link text-info p-0">
                                + Add
                            </button>
                        </div>

                        {associations.companies.length > 0 ? (
                            associations.companies.map((item, index) => (
                                <div key={index} className="card border-secondary mb-2 p-2" style={{ cursor: 'pointer' }} onClick={() => history.push(`/crm/company/${item.associated_company_id._id}`)}>
                                    <div className="d-flex align-items-center">
                                        <div className="avatar me-2 bg-secondary rounded-circle d-flex justify-content-center align-items-center" style={{ width: '30px', height: '30px' }}>
                                            {item.associated_company_id.name.charAt(0).toUpperCase()}
                                        </div>
                                        <span>{item.associated_company_id.name}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-muted small">No companies associated</p>
                        )}
                    </div>
                )}

                {/* Deals */}
                {(componentType === "COMPANY" || componentType === "CONTACT") && (
                    <div className="association-section mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="d-flex align-items-center">
                                <span className="me-2">
                                    <i className="bi bi-currency-dollar"></i>
                                </span>
                                <h6 className="mb-0">Deals</h6>
                            </div>
                            <button onClick={() => setShowAddDealModal(true)} className="btn btn-link text-info p-0">
                                + Add
                            </button>
                        </div>

                        {associations.deals.length > 0 ? (
                            associations.deals.map((item, index) => (
                                <div key={index} className="card border-secondary mb-2 p-2" style={{ cursor: 'pointer' }} onClick={() => history.push(`/crm/deal/${item.associated_deal_id._id}`)}>
                                    <div className="d-flex align-items-center">
                                        <div className="avatar me-2 bg-secondary rounded-circle d-flex justify-content-center align-items-center" style={{ width: '30px', height: '30px' }}>
                                            {item.associated_deal_id.title.charAt(0).toUpperCase()}
                                        </div>
                                        <span>{item.associated_deal_id.title}</span>
                                        <span className="text-muted align-right">${item.associated_deal_id.amount || '0.00'}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-muted small">No deals associated</p>
                        )}
                    </div>
                )}

                {/* Contacts */}

                {(componentType === "COMPANY" || componentType === "DEAL") && (
                    <div className="association-section mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="d-flex align-items-center">
                                <span className="me-2">
                                    <i className="bi bi-currency-dollar"></i>
                                </span>
                                <h6 className="mb-0">Contacts</h6>
                            </div>
                            <button onClick={() => setShowAddContactModal(true)} className="btn btn-link text-info p-0">
                                + Add
                            </button>
                        </div>

                        {associations.contacts.length > 0 ? (
                            associations.contacts.map((item, index) => (
                                <div key={index} className="card border-secondary mb-2 p-2" style={{ cursor: 'pointer' }} onClick={() => history.push(`/crm/contact/${item.associated_contact_id._id}`)}>
                                    <div className="d-flex align-items-center">
                                        <div className="avatar me-2 bg-secondary rounded-circle d-flex justify-content-center align-items-center" style={{ width: '30px', height: '30px' }}>
                                            {item.associated_contact_id.firstName.charAt(0).toUpperCase()}{item.associated_contact_id.lastName.charAt(0).toUpperCase()}
                                        </div>
                                        <span>{item.associated_contact_id.firstName} {item.associated_contact_id.lastName}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-muted small">No contacts associated</p>
                        )}
                    </div>
                )}

                {/* Leads */}
                {/* <div className="association-section mb-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex align-items-center">
                            <span className="me-2">
                                <i className="bi bi-currency-dollar"></i>
                            </span>
                            <h6 className="mb-0">Leads</h6>
                        </div>
                        <button onClick={() => setShowAddLeadModal(true)} className="btn btn-link text-info p-0">
                            + Add
                        </button>
                    </div>

                    {associations.leads.length > 0 ? (
                        associations.leads.map((item, index) => (
                            <div key={index} className="card border-secondary mb-2 p-2" style={{ cursor: 'pointer' }} onClick={() => history.push(`/crm/lead/${item.associated_lead_id._id}`)}>
                                <div className="d-flex align-items-center">
                                    <div className="avatar me-2 bg-secondary rounded-circle d-flex justify-content-center align-items-center" style={{ width: '30px', height: '30px' }}>
                                        {item.associated_lead_id.firstName.charAt(0).toUpperCase()}{item.associated_lead_id.lastName.charAt(0).toUpperCase()}
                                    </div>
                                    <span>{item.associated_lead_id.firstName} {item.associated_lead_id.lastName}</span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-muted small">No leads associated</p>
                    )}
                </div> */}
            </div>
        </>
    );
};

export default Associations;