import { API_URL } from "../BackendApi/Api/config";
import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

const CustomDropdown = ({ setBtnProperties, jumpToAnchor, myComponents }) => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Find the component that matches jumpToAnchor
    if (jumpToAnchor && myComponents && myComponents.length > 0) {
      // If jumpToAnchor is an object (like when directly passed from state)
      if (typeof jumpToAnchor === "object") {
        setSelectedComponent(jumpToAnchor);
      }
      // If jumpToAnchor is an ID or unique identifier, find the matching component
      else {
        const matchingComponent = myComponents.find(
          (comp) =>
            comp.id === jumpToAnchor ||
            comp.unique_id === jumpToAnchor ||
            JSON.stringify(comp) === jumpToAnchor
        );

        if (matchingComponent) {
          setSelectedComponent(matchingComponent);
        }
      }
    }
  }, [jumpToAnchor, myComponents]);

  const handleSelect = (component) => {
    setSelectedComponent(component);
    setBtnProperties((prev) => ({ ...prev, jumpToAnchor: component }));
    setIsOpen(false);
  };

  return (
    <div style={{ marginTop: 30, position: "relative" }}>
      <div
        style={{ position: "relative", width: 350, border: "1px solid #ccc" }}
      >
        {/* Dropdown Header */}
        <button
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            backgroundColor: "white",
            borderRadius: "8px",
            border: "1px solid #ddd",
            padding: "10px",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            transition: "background-color 0.2s",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {selectedComponent ? (
              <span style={{ color: "#4a5568", fontWeight: 500 }}>
                {selectedComponent.type}
              </span>
            ) : (
              <span style={{ color: "#718096" }}>Jump to Anchor</span>
            )}
          </div>
          {isOpen ? (
            <ChevronUp
              style={{ width: "20px", height: "20px", color: "#718096" }}
            />
          ) : (
            <ChevronDown
              style={{ width: "20px", height: "20px", color: "#718096" }}
            />
          )}
        </button>

        {/* Dropdown List */}
        {isOpen && (
          <div
            style={{
              position: "absolute",
              zIndex: 50,
              width: "100%",
              marginTop: "8px",
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {myComponents && myComponents.length > 0 ? (
              myComponents.map((component, index) => (
                <>
                  {component?.type?.toLowerCase() !== "iframe" && (
                    <div
                      key={index}
                      onClick={() => handleSelect(component)}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "12px 16px",
                        cursor: "pointer",
                        borderBottom:
                          index < myComponents.length - 1
                            ? "1px solid #ddd"
                            : "none",
                        backgroundColor:
                          selectedComponent &&
                          (selectedComponent.id === component.id ||
                            JSON.stringify(selectedComponent) ===
                              JSON.stringify(component))
                            ? "#f0f9ff"
                            : "white",
                        transition: "background-color 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        if (
                          !(
                            selectedComponent &&
                            (selectedComponent.id === component.id ||
                              JSON.stringify(selectedComponent) ===
                                JSON.stringify(component))
                          )
                        ) {
                          e.currentTarget.style.backgroundColor = "#f9f9f9";
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (
                          !(
                            selectedComponent &&
                            (selectedComponent.id === component.id ||
                              JSON.stringify(selectedComponent) ===
                                JSON.stringify(component))
                          )
                        ) {
                          e.currentTarget.style.backgroundColor = "white";
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <span style={{ color: "#4a5568" }}>
                          {component?.type?.toLowerCase()}
                        </span>
                      </div>
                      {component?.properties?.thumbnail && (
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            border: "2px solid #e2e8f0",
                            marginLeft: "16px",
                          }}
                        >
                          <img
                            src={
                              component.properties.thumbnail?.startsWith("http")
                                ? component.properties.thumbnail
                                : `${API_URL}flowpage/${component.properties.thumbnail}`
                            }
                            alt={`${component.type} thumbnail`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              ))
            ) : (
              <div
                style={{
                  padding: "12px 16px",
                  color: "#718096",
                  textAlign: "center",
                }}
              >
                No components available
              </div>
            )}
          </div>
        )}
      </div>
      <label
        htmlFor="exampleFormControlTextarea1"
        style={{
          position: "absolute",
          top: -20,
          left: 0,
          fontSize: "16px",
          marginBottom: 20,
        }}
      >
        Jump to Anchor
      </label>
    </div>
  );
};

export default CustomDropdown;
