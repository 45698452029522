import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col, ListGroup, Badge } from 'react-bootstrap';
import { X } from 'lucide-react';
import axios from 'axios';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { useParams } from 'react-router-dom';
import Select from "react-select";


const API_URL = process.env.REACT_APP_API_URL;

const AddContactModal = ({ show, handleClose, companies, contacts, associatedContacts, componentType, onAddContact, audienceTags }) => {

    const { id } = useParams();

    const [activeTab, setActiveTab] = useState('new');

    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        linkedinUrl: '',
        currentRole: '',
        company: '',
        contactStatus: '',
        contactOwner: '',
        tags: [],
        description: ''
    });

    const [loading, setLoading] = useState(false);
    const [tagInput, setTagInput] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);


    const [contactsList, setContactsList] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);

    useEffect(() => {
        const filteredContacts = contacts.filter(contact => !associatedContacts.some(associated => associated.associated_contact_id._id === contact._id));
        setContactsList(filteredContacts);
    }, [contacts, associatedContacts]);

    const showError = (message) => {
        PNotify.error({
            title: 'Error',
            text: message,
            delay: 3000
        });
    };

    const showSuccess = (message) => {
        PNotify.success({
            title: 'Success',
            text: message,
            delay: 3000
        });
    };

    const validateForm = () => {
        let isValid = true;

        if (!formData.email) {
            showError('Email is required');
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            showError('Please enter a valid email address');
            isValid = false;
        }

        if (!formData.firstName.trim()) {
            showError('First name is required');
            isValid = false;
        }

        if (!formData.lastName.trim()) {
            showError('Last name is required');
            isValid = false;
        }

        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const owner_id = JSON.parse(localStorage.getItem("user"))._id;
        const formDataWithOwner = {
            ...formData,
            tags: selectedTags.map(tag => tag.value),
            owner_id: owner_id,
            method: 'association',
            crm_type: componentType,
            contact_id: id,
            company_id: id,
            lead_id: id,
            deal_id: id
        };

        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/crm/add-contact`, formDataWithOwner);

            if (response.data.success) {
                setFormData({
                    email: '',
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    linkedinUrl: '',
                    currentRole: '',
                    company: '',
                    contactStatus: '',
                    contactOwner: '',
                    tags: [],
                    description: ''
                });

                // showSuccess('Contact added successfully!');
                onAddContact();
                handleClose();
            }
        } catch (error) {
            if (error.response?.data?.message) {
                showError(error.response.data.message);
            } else {
                showError('Error adding contact. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleTagInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = tagInput.trim();
            if (newTag && !formData.tags.includes(newTag)) {
                setFormData(prev => ({
                    ...prev,
                    tags: [...prev.tags, newTag]
                }));
                setTagInput('');
            }
        }
    };

    const removeTag = (tagToRemove) => {
        setFormData(prev => ({
            ...prev,
            tags: prev.tags.filter(tag => tag !== tagToRemove)
        }));
    };

    const handleTagInputChange = (e) => {
        setTagInput(e.target.value);
    };

    const handleContactSelect = (e, contactId) => {
        e.stopPropagation(); // Prevent row click when checkbox is clicked

        if (selectedContacts.includes(contactId)) {
            setSelectedContacts(selectedContacts.filter(id => id !== contactId));
        } else {
            setSelectedContacts([...selectedContacts, contactId]);
        }
    };

    // Handle row click
    const handleRowClick = (contactId) => {
        if (selectedContacts.includes(contactId)) {
            setSelectedContacts(selectedContacts.filter(id => id !== contactId));
        } else {
            setSelectedContacts([...selectedContacts, contactId]);
        }
    };

    const handleAddContacts = async () => {

        if (selectedContacts.length === 0) {
            showError('Please select at least one contact');
            return;
        }

        const owner_id = JSON.parse(localStorage.getItem("user"))._id;
        const data = {
            contacts: selectedContacts,
            owner_id: owner_id,
            crm_type: componentType,
            contact_id: id,
            company_id: id,
            lead_id: id,
            deal_id: id
        };

        try {
            const response = await axios.post(`${API_URL}/crm/add-associated-contact`, data);
            if (response.data.success) {
                onAddContact();
                handleClose();
            }
        } catch (error) {
            console.error("Error adding contacts:", error);
            showError('Failed to add contacts');
        }
    };

    const getInitialStyle = () => {
        return {
            backgroundColor: '#777',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontWeight: 'bold',
            marginRight: '10px'
        };
    };

    const tagsOptions = audienceTags && audienceTags.map((c) => ({
        value: c._id,
        label: `${c.tag_name}`,
    }));

    const handleTagChange = (selected) => {
        setSelectedTags(selected || []);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            animation={true}
            className="Sidebar-Modal-end"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add New Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>



                <div className="tabs-header d-flex border-bottom">
                    <div
                        className={`tab-item py-2 px-3 flex-grow-1 text-center ${activeTab === 'new' ? 'fw-bold' : 'text-secondary'}`}
                        onClick={() => setActiveTab('new')}
                        style={{ cursor: 'pointer' }}
                    >
                        New
                    </div>
                    <div
                        className={`tab-item py-2 px-3 flex-grow-1 text-center ${activeTab === 'existing' ? 'fw-bold' : 'text-secondary'}`}
                        onClick={() => setActiveTab('existing')}
                        style={{ cursor: 'pointer' }}
                    >
                        Existing
                    </div>
                </div>

                {activeTab === 'new' && (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="tel"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>LinkedIn URL</Form.Label>
                            <Form.Control
                                name="linkedinUrl"
                                value={formData.linkedinUrl}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Current Role</Form.Label>
                            <Form.Control
                                type="text"
                                name="currentRole"
                                value={formData.currentRole}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Company</Form.Label>
                            <Form.Select
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                            >
                                <option value="">Select Company</option>
                                {companies.map((company) => (
                                    <option key={company._id} value={company._id}>
                                        {company.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Contact Status</Form.Label>
                            <Form.Select
                                name="contactStatus"
                                value={formData.contactStatus}
                                onChange={handleChange}
                            >
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                                <option value="cold">Cold</option>
                                <option value="new">New</option>
                            </Form.Select>
                        </Form.Group>

                        {/* <Form.Group className="mb-3">
                            <Form.Label>Contact Owner</Form.Label>
                            <Form.Control
                                type="text"
                                name="contactOwner"
                                value={formData.contactOwner}
                                onChange={handleChange}
                            />
                        </Form.Group> */}

                        {/* <Form.Group className="mb-3">
                            <Form.Label>Tags</Form.Label>
                            <div className="border rounded p-2">
                                <div className="d-flex flex-wrap gap-2 mb-2">
                                    {formData.tags.map((tag, index) => (
                                        <span
                                            key={index}
                                            className="badge bg-primary d-flex align-items-center"
                                        >
                                            {tag}
                                            <X
                                                className="ms-1 cursor-pointer"
                                                size={14}
                                                onClick={() => removeTag(tag)}
                                            />
                                        </span>
                                    ))}
                                </div>
                                <Form.Control
                                    type="text"
                                    className="border-0"
                                    value={tagInput}
                                    onChange={handleTagInputChange}
                                    onKeyDown={handleTagInputKeyDown}
                                    placeholder="Type a tag and press Enter or comma to add"
                                />
                            </div>
                        </Form.Group> */}

                        <Form.Group className="mb-3">
                            <Form.Label>Tags</Form.Label>
                            <Select
                                options={tagsOptions}
                                isMulti
                                value={selectedTags}
                                onChange={handleTagChange}
                                placeholder="Select tags..."
                            />
                            <div className="d-flex flex-wrap gap-2 mt-2">
                                {selectedTags.map((tag) => (
                                    <Badge key={tag.value} className="p-2">
                                        {tag.label}
                                        <X
                                            size={14}
                                            className="ms-2 cursor-pointer"
                                            onClick={() =>
                                                handleTagChange(
                                                    selectedTags.filter((c) => c.value !== tag.value)
                                                )
                                            }
                                        />
                                    </Badge>
                                ))}
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <div className="text-end mt-4">
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                className="me-2"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Adding...' : 'Add Contact'}
                            </Button>
                        </div>
                    </Form>
                )}

                {activeTab === 'existing' && (
                    <>
                        <ListGroup className="mb-3" variant="flush">
                            {contactsList.map(contact => (
                                <ListGroup.Item
                                    key={contact._id}
                                    onClick={() => handleRowClick(contact._id)}
                                    className={`d-flex align-items-center ${selectedContacts.includes(contact._id) ? 'bg-primary' : 'bg-dark'} text-white border-secondary`}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        className="me-2"
                                        checked={selectedContacts.includes(contact._id)}
                                        onChange={(e) => handleContactSelect(e, contact._id)}
                                    />
                                    <div style={getInitialStyle()}>
                                        {contact.firstName.charAt(0).toUpperCase()}
                                    </div>
                                    <div>{contact.firstName} {contact.lastName}</div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>

                        <div className="d-flex justify-content-end">
                            <Button
                                variant="primary"
                                size="sm"
                                disabled={selectedContacts.length === 0}
                                onClick={handleAddContacts}
                            >
                                + Add
                            </Button>
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddContactModal;