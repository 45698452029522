import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { Button, Modal } from "react-bootstrap";
import { crmActivitiesContext } from "../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";
import { Badge } from "react-bootstrap";

const API_URL = process.env.REACT_APP_API_URL;


const NotesContent = ({ componentType }) => {
    const { crmActivitiesData } = useContext(crmActivitiesContext);
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [contacts, setContacts] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetchNotes();
        fetchContacts();
    }, [id, crmActivitiesData.tempFetch]);

    const fetchNotes = async () => {
        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;
            const response = await axios.post(`${API_URL}/crm/get-activities`, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                crm_type: componentType,
                owner_id,
                type: "ALL",
            });
            if (response.data.success) {
                setActivities(response.data.data || []);
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to fetch notes", delay: 3000 });
        } finally {
            setLoading(false);
        }
    };

    const fetchContacts = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/crm/get-contacts`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setContacts(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);

        }
    };

    const getPriorityBadgeColor = (priority) => {
        switch (priority.toLowerCase()) {
            case 'high':
                return 'bg-danger';
            case 'medium':
                return 'bg-warning';
            case 'low':
                return 'bg-info';
            default:
                return 'bg-secondary';
        }
    };

    const getStatusBadgeColor = (status) => {
        switch (status.toLowerCase()) {
            case 'todo':
                return 'bg-secondary';
            case 'in progress':
                return 'bg-primary';
            case 'completed':
                return 'bg-success';
            default:
                return 'bg-secondary';
        }
    };

    const renderActivities = (activity) => {

        switch (activity.activity_type) {
            case "NOTE":
                return (
                    <div key={activity._id} className="bg-secondary p-3 mb-2 rounded">
                        <div className="d-flex justify-content-between">
                            <span className="text-info">Note added by {activity?.owner_id?.fname} {activity?.owner_id?.lname}</span>
                            <small className="text-muted">{new Date(activity.activity_created_at).toLocaleTimeString()}</small>
                        </div>
                        <div className="text-white mt-2" dangerouslySetInnerHTML={{ __html: activity.note }} />
                    </div>
                )

            case "CALL":
                return (
                    <div key={activity._id} className="bg-dark text-white p-3 mb-2 rounded">
                        <div className="d-flex justify-content-between">
                            <span className="text-info">Call logged by {activity?.owner_id?.fname} {activity?.owner_id?.lname}</span>
                            <small className="text-muted">
                                {new Date(activity.call_date).toLocaleDateString()} at {activity.call_time}
                            </small>
                        </div>
                        <div className="mt-2">
                            <span className="badge bg-primary me-2">{activity.direction}</span>
                            <span className="badge bg-secondary">{activity.outcome}</span>
                        </div>
                        <div className="mt-2">{activity.call_notes}</div>
                        <div className="mt-2">
                            {activity.contacted.map((contact) => (
                                //filter contacts by contact, contact is the id
                                contacts.filter((c) => c._id === contact).map((filteredContact) => (
                                    <span className="badge bg-success me-2" key={filteredContact._id}>
                                        {filteredContact.firstName} {filteredContact.lastName}
                                    </span>
                                ))
                            ))}
                        </div>
                    </div>
                )

            case "EMAIL":
                return (
                    <div key={activity._id} className="bg-dark text-white p-3 mb-2 rounded">
                        <div className="d-flex justify-content-between">
                            <span className="text-info">
                                Email sent
                                {activity.sent_via_gmail && <Badge bg="info" className="ms-2">Sent via Gmail</Badge>}
                            </span>
                            <small className="text-muted">{new Date(activity.activity_created_at).toLocaleString()}</small>
                        </div>
                        <div>
                            To: <Badge bg="info" className="ms-2">{activity.recipient.map(recipient => recipient).join(", ")}</Badge>
                        </div>
                        <div>
                            From: <Badge bg="info" className="ms-2">{activity?.sender_email}</Badge>
                        </div>
                        <h6 className="mt-2">{activity.subject}</h6>
                        <div className="mt-2" dangerouslySetInnerHTML={{ __html: activity.body }} />
                    </div>
                )

            case "TASK":
                return (
                    <div key={activity._id} className="bg-dark text-white p-3 mb-2 rounded">
                        <span className="text-info">Task added by {activity?.owner_id?.fname} {activity?.owner_id?.lname}</span>

                        <div className="d-flex justify-content-between align-items-center">
                            <h6>{activity.title}</h6>
                            <div>
                                <span className={`badge me-2 ${getPriorityBadgeColor(activity.priority)}`}>
                                    {activity.priority}
                                </span>
                                <span className={`badge ${getStatusBadgeColor(activity.status)}`}>
                                    {activity.status}
                                </span>
                            </div>
                        </div>
                        <p className="mt-2 mb-2">{activity.description}</p>
                        <div className="d-flex justify-content-between text-muted">
                            <small>Assigned to: {activity.assigned_to}</small>
                            <small>Due: {new Date(activity.due_datetime).toLocaleString()}</small>
                        </div>
                    </div>
                )

            case "MEETING":
                return (
                    <div key={activity._id} className="bg-dark text-white p-3 mb-2 rounded">
                        <div className="d-flex justify-content-between">
                            <span className="text-info">Meeting logged by {activity?.author}</span>
                            <small className="text-muted">
                                {new Date(activity.date).toLocaleDateString()} {activity.start_time} - {activity.end_time}
                            </small>
                        </div>
                        <div className="mt-2">
                            <span className="badge bg-primary me-2">{activity.outcome}</span>
                            <span className="badge bg-secondary">
                                {activity.attendees.join(', ')}
                            </span>
                        </div>
                        <div className="mt-2">{activity.notes}</div>
                    </div>
                )

        };

    };



    if (loading) return <div className="text-center text-light">Loading activities...</div>;

    return (
        <div className="p-4 rounded">

            {activities.length > 0 ? (
                activities.map((activity) => (
                    renderActivities(activity)
                ))
            ) : (
                <p className="text-center text-muted">No activities yet.</p>
            )}


        </div>
    );
};

export default NotesContent;