import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import axios from "axios";
import * as PNotify from "@pnotify/core";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/core/dist/Material.css";
import "@pnotify/core/dist/PNotify.css";
import SendEmailModal from "./send-email/SendEmail"; // Import the new component
import { crmActivitiesContext } from "../../../../../contexts/context/crmActivitiesContext";
import { useContext } from "react";

const API_URL = process.env.REACT_APP_API_URL;
// Gmail OAuth configuration
const GMAIL_CLIENT_ID = process.env.REACT_APP_GMAIL_CLIENT_ID;
const GMAIL_REDIRECT_URI = process.env.REACT_APP_GMAIL_REDIRECT_URI;
const GMAIL_SCOPES = [
    'https://www.googleapis.com/auth/gmail.send',
    'https://www.googleapis.com/auth/gmail.readonly'
].join(' ');

const EmailContent = ({ componentType }) => {
    const { crmActivitiesData } = useContext(crmActivitiesContext);
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [gmailConnected, setGmailConnected] = useState(false);
    const [gmailLoading, setGmailLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        fetchEmails();
        checkGmailAuthStatus();
    }, [id, crmActivitiesData.tempFetch]);

    const checkGmailAuthStatus = async () => {
        try {
            setGmailLoading(true);
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.post(`${API_URL}/gmail/check-auth-status`, {
                user_id: user._id
            });
            setGmailConnected(response.data.isConnected);
        } catch (error) {
            console.error("Failed to check Gmail auth status:", error);
        } finally {
            setGmailLoading(false);
        }
    };

    const fetchEmails = async () => {
        try {
            setLoading(true);
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;
            const response = await axios.post(`${API_URL}/crm/get-activities`, {
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id,
                owner_id,
                crm_type: componentType,
                type: "EMAIL",
            });
            if (response.data.success) {
                setEmails(response.data.data);
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to fetch emails", delay: 3000 });
        } finally {
            setLoading(false);
        }
    };

    const connectGmail = () => {
        // Initiate Gmail OAuth flow
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GMAIL_CLIENT_ID}&redirect_uri=${encodeURIComponent(GMAIL_REDIRECT_URI)}&response_type=code&scope=${encodeURIComponent(GMAIL_SCOPES)}&access_type=offline&prompt=consent`;

        // Open the auth window
        const authWindow = window.open(authUrl, '_blank', 'width=500,height=600');

        // Set up a listener for the OAuth callback
        window.addEventListener('message', async (event) => {
            if (event.origin !== window.location.origin) return;

            if (event.data.type === 'GMAIL_AUTH_CALLBACK') {
                authWindow.close();

                try {
                    const user = JSON.parse(localStorage.getItem("user"));
                    const response = await axios.post(`${API_URL}/gmail/complete-auth`, {
                        code: event.data.code,
                        user_id: user._id
                    });

                    if (response.data.success) {
                        setGmailConnected(true);
                        PNotify.success({ title: "Success", text: "Gmail connected successfully", delay: 2000 });
                    }
                } catch (error) {
                    PNotify.error({ title: "Error", text: "Failed to connect Gmail account", delay: 3000 });
                }
            }
        }, false);
    };

    const disconnectGmail = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.post(`${API_URL}/gmail/disconnect`, {
                user_id: user._id
            });

            if (response.data.success) {
                setGmailConnected(false);
                PNotify.success({ title: "Success", text: "Gmail disconnected successfully", delay: 2000 });
            }
        } catch (error) {
            PNotify.error({ title: "Error", text: "Failed to disconnect Gmail account", delay: 3000 });
        }
    };

    const handleEmailSent = (newEmail) => {
        setEmails(prev => ([newEmail, ...prev]));
    };

    return (
        <div className="p-4 rounded">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>Emails</h5>
                <div className="d-flex gap-2">
                    {gmailLoading ? (
                        <Button variant="outline-secondary" disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Checking Gmail status...
                        </Button>
                    ) : gmailConnected ? (
                        <div className="d-flex gap-2 align-items-center">
                            <Badge bg="success">Gmail Connected</Badge>
                            <Button variant="outline-danger" size="sm" onClick={disconnectGmail}>Disconnect</Button>
                        </div>
                    ) : (
                        <Button variant="outline-primary" onClick={connectGmail}>
                            <i className="bi bi-google me-2"></i>
                            Connect Gmail
                        </Button>
                    )}
                    <Button onClick={() => setShowModal(true)}>Send Email</Button>
                </div>
            </div>
            {loading ? (
                <div className="text-center text-light">Loading emails...</div>
            ) : emails.length > 0 ? (
                emails.map(email => (
                    <div key={email.id} className="bg-dark text-white p-3 mb-2 rounded">
                        <div className="d-flex justify-content-between">
                            <span className="text-info">
                                Email sent
                                {email.sent_via_gmail && <Badge bg="info" className="ms-2">Sent via Gmail</Badge>}
                            </span>
                            <small className="text-muted">{new Date(email.activity_created_at).toLocaleString()}</small>
                        </div>
                        <div>
                            To: <Badge bg="info" className="ms-2">{email.recipient.map(recipient => recipient).join(", ")}</Badge>
                        </div>
                        <div>
                            From: <Badge bg="info" className="ms-2">{email?.sender_email}</Badge>
                        </div>
                        <h6 className="mt-2">{email.subject}</h6>
                        <div className="mt-2" dangerouslySetInnerHTML={{ __html: email.body }} />
                    </div>
                ))
            ) : (
                <p className="text-center text-muted">No emails yet.</p>
            )}

            {/* Using the new SendEmailModal component */}
            <SendEmailModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onEmailSent={handleEmailSent}
                id={id}
                componentType={componentType}
                gmailConnected={gmailConnected}
            />
        </div>
    );
};

export default EmailContent;