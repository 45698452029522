import React, { useEffect, useState } from "react";
import { MdTitle } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, useParams, useLocation } from "react-router-dom";
import { useBuilderContext } from "../../../builderContext";
import { useHistory, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SunEditors from "../../../Components/SunEditors";

export function AgentaList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdTitle />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Agenda</h2>
                            <p className="add-block-btn-text-desc">Add a Agenda to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}




export function AgentaAdd() {
    const history = useHistory();
    const { id } = useParams();
    const location = useLocation();
    const { addComponent, editComponent, myComponents } = useBuilderContext();

    const unique_id = new URLSearchParams(location.search).get("unique_id");
    const position = new URLSearchParams(location.search).get("position");

    const [agentaInfo, setAgentaInfo] = useState({
        headding: "Agenta",
        unique_id: unique_id ?? uuidv4(),
        agendaItems: { time: "", description: "" },
    });

    // Handle change in agenda item (time or description)
    const handleAgendaChange = (field, value) => {
        setAgentaInfo((prevState) => ({
            ...prevState,
            agendaItems: {
                ...prevState.agendaItems,
                [field]: value,
            },
        }));
    };

    useEffect(() => {
        if (unique_id) {
            const existingAgenta = myComponents
                .filter((c) => c.type === "AGENTA")
                .map((c) => c.properties)
                .find((c) => c.unique_id === unique_id);

            if (existingAgenta) {
                setAgentaInfo({
                    headding: existingAgenta.headding,
                    unique_id: unique_id,
                    agendaItems: existingAgenta.agendaItems,
                });
            }
        }
    }, [unique_id, myComponents]);

    const saveData = () => {
        if (unique_id) {
            editComponent(position, "AGENTA", agentaInfo, true);
        } else {
            addComponent("AGENTA", agentaInfo, true);
        }
        history.push(`/editEvent/${id}/${unique_id ? "layout" : "addBlock"}`);
    };

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to={`/editEvent/${id}/${unique_id ? "layout" : "addBlock"}`}>
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label
                            htmlFor="headding"
                            className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM"
                        >
                            {unique_id ? "Update" : "Add"} Agenda
                        </label>
                    </div>
                </div>

                <div className="form_box cvtPmc">
                    <div className="form-group did-floating-label-content">
                        <input
                            disabled
                            className="form-control did-floating-input"
                            id="headding"
                            placeholder="Enter Agenda Title"
                            value={agentaInfo.headding } // Use the headding state here
                        />
                        <label
                            htmlFor="headding"
                            className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label"
                        >
                            Agenda Title
                        </label>
                    </div>

                    <div className="agenda-item">
                        <div className="form-group did-floating-label-content">
                            <input
                                className="form-control did-floating-input"
                                type="text"
                                placeholder="Time"
                                value={agentaInfo?.agendaItems?.time}
                                onChange={(e) => handleAgendaChange("time", e.target.value)} // Handle time change
                            />
                            <label
                                htmlFor={`time-`}
                                className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label"
                            >
                                Time
                            </label>
                        </div>

                        <div className="form-group did-floating-label-content">
                            <SunEditors
                                description={agentaInfo?.agendaItems?.description}
                                handleParkingChange={(value) => handleAgendaChange("description", value)} // Handle description change
                            />
                            <label
                                htmlFor={`description-`}
                                className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label"
                            >
                                Description
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={saveData}>
                    {unique_id ? "Update" : "Save"}
                </button>
            </div>
        </>
    );
}

