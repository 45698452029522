import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";


const API_URL = process.env.REACT_APP_API_URL;

const Comments = ({ componentType }) => {

    const { id } = useParams();

    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');

    const fetchComments = async () => {

        const owner_id = JSON.parse(localStorage.getItem("user"))._id;
        try {
            const response = await axios.post(`${API_URL}/crm/get-comments`, {
                crm_type: componentType,
                owner_id: owner_id,
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id
            });
            if (response.data.success) {
                setComments(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchComments();
    }, [])

    const timeAgo = (timestamp) => {
        return moment(timestamp).fromNow();
    };

    const handleAddComment = async () => {
        // Add comment logic here
        if (commentText.trim()) {
            // Logic to add a new comment

            const owner_id = JSON.parse(localStorage.getItem("user"))._id;
            const commentData = {
                comment: commentText,
                owner_id: owner_id,
                crm_type: componentType,
                contact_id: id,
                company_id: id,
                lead_id: id,
                deal_id: id
            }

            try {
                const response = await axios.post(`${API_URL}/crm/add-comment`, commentData);
                if (response.data.success) {
                    fetchComments();
                }
            } catch (error) {
                console.error("Failed to add comment:", error);
            } finally {
                // Reset comment text
                setCommentText('');
            }
        }
    };

    const commenter = JSON.parse(localStorage.getItem("user")).fname[0].toUpperCase() + JSON.parse(localStorage.getItem("user")).lname[0].toUpperCase();
    return (
        <div>
            <div className="p-3">
                <div className="comments-section">
                    <div className="mb-3 d-flex">
                        <div className="avatar me-2 bg-primary rounded-circle d-flex justify-content-center align-items-center" style={{ width: '30px', height: '30px', minWidth: '30px' }}>
                            <span className="text-white">{commenter}</span>
                        </div>
                        <div className="flex-grow-1">
                            <textarea
                                className="form-control bg-dark text-white border-secondary"
                                rows="1"
                                placeholder="Add comment here..."
                                value={commentText}
                                onChange={(e) => setCommentText(e.target.value)}
                            ></textarea>
                            <div className="d-flex justify-content-between mt-2">
                                <button className="btn btn-sm text-white">@</button>
                                <button
                                    className="btn btn-secondary btn-sm"
                                    onClick={handleAddComment}
                                    disabled={!commentText.trim()}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="comments-list">
                        {
                            comments && comments.map((comment, index) => (
                                <div key={index} className="comment-item mb-3 d-flex">
                                    <div className="avatar me-2 bg-primary rounded-circle d-flex justify-content-center align-items-center" style={{ width: '30px', height: '30px', minWidth: '30px' }}>
                                        <span className="text-white">{comment.owner_id.fname[0].toUpperCase()}{comment?.owner_id?.lname[0].toUpperCase()}</span>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <strong className="me-2">{comment.owner_id.fname} {comment?.owner_id?.lname}</strong>
                                            <small className="text-muted">{timeAgo(comment.createdAt)}</small>
                                        </div>
                                        {comment.mentions && (
                                            <div className="text-info">@{comment.mentions}</div>
                                        )}
                                        <p className="mb-0 mt-1">{comment.comment}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Comments;