import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useBuilderContext } from "../../../builderContext";

export function EditContactFrom() {
  const { editComponent } = useBuilderContext();
  const location = useLocation();
  const mydata = location.state?.data;
  const position = location.state?.position;
  const showStatus = location.state?.showStatus;
  const [title, setTitle] = useState("");
  const [addToCrm, setAddToCrm] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (mydata && mydata.title) {
      setTitle(mydata.title);
    }
    if (showStatus) {
      setStatus(showStatus);
    }
  }, [mydata, showStatus]);

  const handleSaveClick = () => {
    editComponent(position, "CONTACTFORM", { title, addToCrm, unique_id: mydata.unique_id }, status);
  };

  return (
    <>
      <div className="add_block">
        <div className="styles__Header-sc-82jpo5-0 cDIdp">
          <div>
            <Link to="/">
              <button type="button" aria-label="Go back to layout view">
                <FaLongArrowAltLeft />
              </button>
            </Link>
            <label
              titleFor=""
              color="grayDarker"
              className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM"
            >
              Edit Title
            </label>
          </div>
        </div>
        <div className="form_box cvtPmc">
          <div className="form-group mb-2">
            <div>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
                placeholder="Enter Title"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-around w-100 align-items-center rounded-2 border p-2 px-4 mb-2"
        style={{ width: "fit-content" }}
      >
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          Add Contact to CRM
        </label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={addToCrm}
            onChange={(e) => setAddToCrm(e.target.checked)}
            id="flexSwitchCheckDefault"
          />
        </div>
      </div>

      <div className="add-block-btn">
        <button className="btn publish-btn" onClick={handleSaveClick}>
          Save
        </button>
      </div>
    </>
  );
}
