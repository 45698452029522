import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { IoIosArrowBack } from "react-icons/io";
import { RiMore2Fill } from "react-icons/ri";
import { FaBolt } from "react-icons/fa";
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { RiEditLine, RiDeleteBin6Line, RiAddLine, RiCheckLine, RiCloseLine } from 'react-icons/ri';

import CrmActivities from '../../components/crm-activities/CrmActivities';
import AssociationsPanel from '../../components/association-panel/AssociationPanel';
import AddNote from '../../components/crm-activities/notes/add-note/AddNote';
import SendEmailModal from '../../components/crm-activities/emails/send-email/SendEmail';
import Select from 'react-select';
import { Badge } from 'react-bootstrap';
import { X } from 'lucide-react';


const API_URL = process.env.REACT_APP_API_URL;

const DealDetails = () => {
    const { id } = useParams();
    const history = useHistory();
    const [dealData, setDealData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [associations, setAssociations] = useState({
        companies: [],
        contacts: [],
        lists: []
    });
    const [comments, setComments] = useState([]);

    const [gmailConnected, setGmailConnected] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);

    useEffect(() => {
        fetchDealDetails();
        checkGmailAuthStatus();
    }, [id]);

    const checkGmailAuthStatus = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.post(`${API_URL}/gmail/check-auth-status`, {
                user_id: user._id
            });
            setGmailConnected(response.data.isConnected);
        } catch (error) {
            console.error("Failed to check Gmail auth status:", error);
        } finally {

        }
    };

    const fetchDealDetails = async () => {
        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;

            const response = await axios.post(`${API_URL}/crm/deal-details`, {
                deal_id: id,
                owner_id
            });

            if (response.data.success) {
                setDealData(response.data.data);
                setAssociations(response.data.associations || {
                    companies: [],
                    contacts: [],
                    lists: []
                });
                setComments(response.data.comments || []);
            }
        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: 'Failed to fetch deal details',
                delay: 3000
            });
        } finally {
            setLoading(false);
        }
    };


    const LeftPanel = () => (
        <div className="col-4 bg-light p-4">
            {/* Header */}
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div onClick={() => history.push('/crm/deals')} className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <IoIosArrowBack />
                    <span className="ms-2">Back</span>
                </div>
                {/* <div className="dropdown">
                    <RiMore2Fill className="cursor-pointer" />
                </div> */}
            </div>

            {/* Deal Card */}
            <div className="mb-4" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
                <div className="d-flex gap-3">
                    <div className="bg-primary rounded-circle d-flex justify-content-center align-items-center text-white"
                        style={{ width: '48px', height: '48px' }}>
                        <span className="fs-4">
                            {dealData?.title?.[0]}
                        </span>
                    </div>
                    <div>
                        <h5 className="mb-1">{dealData?.title}</h5>
                        <div className="text-muted small">${dealData?.amount?.toLocaleString()}</div>
                        <div className="text-muted small">{dealData?.dealStage}</div>
                    </div>
                </div>

                {/* Action Buttons */}
                <div className="d-flex flex-wrap gap-2 mt-3">
                    {/* <button className="btn btn-light border">
                        <span className="d-flex align-items-center">
                            Update Stage <span className="ms-1">▼</span>
                        </span>
                    </button>
                    <button className="btn btn-light border">Email</button>
                    <button className="btn btn-light border">Note</button>
                    <button className="btn btn-light border">Call</button>
                    <button className="btn btn-light border">More</button> */}
                    <button className="btn btn-light border" onClick={() => setShowEmailModal(true)}>Email</button>
                    <button className="btn btn-light border" onClick={() => setShowNoteModal(true)}>Note</button>
                </div>
            </div>

            {/* Run Agents Section */}
            {/* <div className="mb-4" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
                <h6 className="text-muted mb-3">Run Agents</h6>
                <div className="d-flex flex-wrap gap-2">
                    <button className="btn btn-light border">
                        <FaBolt className="text-primary me-2" />
                        Deal Analysis
                    </button>
                    <button className="btn btn-light border">
                        <FaBolt className="text-primary me-2" />
                        Competitor Research
                    </button>
                    <button className="btn btn-light border">
                        <FaBolt className="text-primary me-2" />
                        Market Info
                    </button>
                </div>
            </div> */}

            <EditableDealDetails
                dealData={dealData}
                setDealData={setDealData}
            />

            <div className="mb-4" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
                <h6 className="text-muted mb-3">Last Updated</h6>
                <p>{new Date(dealData.updatedAt).toLocaleString()}</p>
                <h6 className="text-muted mb-3">Created</h6>
                <p>{new Date(dealData.createdAt).toLocaleString()}</p>
            </div>
        </div>
    );

    if (loading) {
        return <div className="text-center p-5">Loading...</div>;
    }

    return (
        <>
            <AddNote
                show={showNoteModal}
                onHide={() => setShowNoteModal(false)}
                componentType="DEAL"
                id={id}
            />
            <SendEmailModal
                show={showEmailModal}
                onHide={() => setShowEmailModal(false)}
                id={id}
                componentType="DEAL"
                gmailConnected={gmailConnected}
            />
            <div className="main-container">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        {/* Left Panel */}
                        <LeftPanel />

                        {/* Center Panel - Activity Tabs */}
                        <div className="col-5 p-4">
                            <CrmActivities componentType="DEAL" />
                        </div>

                        {/* Right Panel - Associations */}
                        <div className="col-3">
                            <AssociationsPanel componentType="DEAL" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const EditableDealDetails = ({ dealData, setDealData }) => {
    const [editing, setEditing] = useState({
        field: null,
        value: null,
        arrayIndex: null
    });
    const [newArrayItem, setNewArrayItem] = useState('');
    const [hoveredItem, setHoveredItem] = useState({ field: null, index: null });
    const [audienceTags, setAudienceTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isEditingTags, setIsEditingTags] = useState(false);

    const dealTypeOptions = [
        { value: "New", label: "New" },
        { value: "Existing", label: "Existing" },
        { value: "Renewal", label: "Renewal" }
    ];

    const priorityOptions = [
        { value: "High", label: "High" },
        { value: "Medium", label: "Medium" },
        { value: "Low", label: "Low" }
    ];

    const stageOptions = [
        { value: "Qualification", label: "Qualification" },
        { value: "Discovery", label: "Discovery" },
        { value: "Proposal", label: "Proposal" },
        { value: "Negotiation", label: "Negotiation" },
        { value: "Closed Won", label: "Closed Won" },
        { value: "Closed Lost", label: "Closed Lost" }
    ];

    const pipelineOptions = [
        { value: "Sales Pipeline", label: "Sales Pipeline" },
        { value: "Marketing Pipeline", label: "Marketing Pipeline" },
        { value: "Partnership Pipeline", label: "Partnership Pipeline" }
    ];

    useEffect(() => {
        fetchAudienceTags();
        // Initialize selected tags if deal already has tags
        if (dealData?.tags && Array.isArray(dealData.tags)) {
            // Handle potential nested array
            const tagArray = Array.isArray(dealData.tags[0]) ? dealData.tags[0] : dealData.tags;
            const initialTags = tagArray.map(tag => {
                if (typeof tag === 'object' && tag._id) {
                    return { value: tag._id, label: tag.tag_name };
                } else {
                    return { value: tag, label: tag };
                }
            });
            setSelectedTags(initialTags);
        }
    }, [dealData]);


    const fetchAudienceTags = async () => {
        const owner_id = JSON.parse(localStorage.getItem("user"))._id;

        try {
            const response = await axios.post(`${API_URL}/tags/get-audience-tags`, {
                "owner_id": owner_id,
            });

            if (response.data.success) {
                setAudienceTags(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching audience tags:", error);
        }
    };

    const handleUpdate = async (field, value, arrayIndex = null) => {
        try {
            const owner_id = JSON.parse(localStorage.getItem("user"))._id;
            const updateData = {
                deal_id: dealData._id,
                owner_id,
                field,
                value
            };

            if (arrayIndex !== null) {
                updateData.arrayIndex = arrayIndex;
            }

            const response = await axios.post(`${API_URL}/crm/update-deal-fields`, updateData);

            if (response.data.success) {
                setDealData(response.data.data);
                PNotify.success({
                    title: 'Success',
                    text: 'Deal updated successfully',
                    delay: 2000
                });
            }
        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: 'Failed to update deal',
                delay: 2000
            });
        }
        setEditing({ field: null, value: null, arrayIndex: null });
        setNewArrayItem('');
    };


    const handleTagChange = (selected) => {
        setSelectedTags(selected || []);
    };

    const handleTagsUpdate = async () => {
        // Extract tag IDs for the update
        const tagValues = selectedTags.map(tag => tag.value);
        await handleUpdate('tags', tagValues);
        setIsEditingTags(false);
    };

    const handleArrayAdd = async (field) => {
        if (!newArrayItem) return;

        const currentArray = dealData[field] || [];
        const updatedArray = [...currentArray, newArrayItem];
        await handleUpdate(field, updatedArray);
    };

    const handleArrayDelete = async (field, index) => {
        const updatedArray = dealData[field].filter((_, i) => i !== index);
        await handleUpdate(field, updatedArray);
    };

    const renderTagsField = (tagsOptions) => {

        return (
            <div className="mb-4">
                <div
                    className="d-flex justify-content-between align-items-center mb-2"
                    onMouseEnter={() => setHoveredItem({ field: 'tags', index: 'header' })}
                    onMouseLeave={() => setHoveredItem({ field: null, index: null })}
                >
                    <h6 className="text-muted mb-0">Tags</h6>
                    {!isEditingTags && hoveredItem.field === 'tags' && hoveredItem.index === 'header' && (
                        <button
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => setIsEditingTags(true)}
                        >
                            <RiEditLine />
                        </button>
                    )}
                </div>

                {isEditingTags ? (
                    <div className="mb-3">
                        <Select
                            options={tagsOptions}
                            isMulti
                            value={selectedTags}
                            onChange={handleTagChange}
                            placeholder="Select tags..."
                        />
                        <div className="d-flex flex-wrap gap-2 mt-2">
                            {selectedTags.map((tag) => (
                                <Badge key={tag.value} className="p-2">
                                    {tag.label}
                                    <X
                                        size={14}
                                        className="ms-2 cursor-pointer"
                                        onClick={() =>
                                            handleTagChange(
                                                selectedTags.filter((c) => c.value !== tag.value)
                                            )
                                        }
                                    />
                                </Badge>
                            ))}
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <button
                                className="btn btn-sm btn-success me-2"
                                onClick={handleTagsUpdate}
                            >
                                <RiCheckLine /> Save
                            </button>
                            <button
                                className="btn btn-sm btn-secondary"
                                onClick={() => setIsEditingTags(false)}
                            >
                                <RiCloseLine /> Cancel
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex flex-wrap gap-2">
                        {dealData.tags && dealData.tags.length > 0 ? (
                            dealData.tags.map((tag, index) => (
                                <Badge key={index} className="p-2 bg-info">
                                    {typeof tag === 'object' ? tag.tag_name : tag}
                                </Badge>
                            ))
                        ) : (
                            <span className="text-muted">No tags</span>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const renderArrayField = (field, label, items = []) => (
        <div className="mb-4">
            <div
                className="d-flex justify-content-between align-items-center mb-2"
                onMouseEnter={() => setHoveredItem({ field, index: 'header' })}
                onMouseLeave={() => setHoveredItem({ field: null, index: null })}
            >
                <h6 className="text-muted mb-0">{label}</h6>
                {(hoveredItem.field === field && hoveredItem.index === 'header') && (
                    <button
                        className="btn btn-sm btn-outline-primary opacity-100"
                        onClick={() => setEditing({ field, value: '', arrayIndex: 'new' })}
                    >
                        <RiAddLine /> Add
                    </button>
                )}
            </div>

            {editing.field === field && editing.arrayIndex === 'new' && (
                <div className="input-group mb-2">
                    <input
                        type="text"
                        className="form-control"
                        value={newArrayItem}
                        onChange={(e) => setNewArrayItem(e.target.value)}
                        placeholder={`Add new ${label.toLowerCase()}`}
                    />
                    <button
                        className="btn btn-success"
                        onClick={() => handleArrayAdd(field)}
                    >
                        <RiCheckLine />
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setEditing({ field: null, value: null, arrayIndex: null })}
                    >
                        <RiCloseLine />
                    </button>
                </div>
            )}

            {items.map((item, index) => (
                <div
                    key={index}
                    className="d-flex align-items-center gap-2 mb-2"
                    onMouseEnter={() => setHoveredItem({ field, index })}
                    onMouseLeave={() => setHoveredItem({ field: null, index: null })}
                >
                    {editing.field === field && editing.arrayIndex === index ? (
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                value={editing.value}
                                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
                            />
                            <button
                                className="btn btn-success"
                                onClick={() => handleUpdate(field, dealData[field].map((i, idx) =>
                                    idx === index ? editing.value : i
                                ))}
                            >
                                <RiCheckLine />
                            </button>
                            <button
                                className="btn btn-secondary"
                                onClick={() => setEditing({ field: null, value: null, arrayIndex: null })}
                            >
                                <RiCloseLine />
                            </button>
                        </div>
                    ) : (
                        <>
                            <span>{item}</span>
                            {hoveredItem.field === field && hoveredItem.index === index && (
                                <div className="btn-group">
                                    <button
                                        className="btn btn-sm btn-outline-secondary"
                                        onClick={() => setEditing({ field, value: item, arrayIndex: index })}
                                    >
                                        <RiEditLine />
                                    </button>
                                    <button
                                        className="btn btn-sm btn-outline-danger"
                                        onClick={() => handleArrayDelete(field, index)}
                                    >
                                        <RiDeleteBin6Line />
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            ))}
        </div>
    );

    const renderDropdownField = (field, label, value, options) => (
        <div
            className="mb-4"
            onMouseEnter={() => setHoveredItem({ field, index: 'simple' })}
            onMouseLeave={() => setHoveredItem({ field: null, index: null })}
        >
            <h6 className="text-muted mb-2">{label}</h6>
            {editing.field === field ? (
                <div className="input-group">
                    <select
                        className="form-select"
                        value={editing.value}
                        onChange={(e) => setEditing({ ...editing, value: e.target.value })}
                    >
                        <option value="">Select {label}</option>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <button
                        className="btn btn-success"
                        onClick={() => handleUpdate(field, editing.value)}
                    >
                        <RiCheckLine />
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setEditing({ field: null, value: null })}
                    >
                        <RiCloseLine />
                    </button>
                </div>
            ) : (
                <div className="d-flex align-items-center gap-2">
                    <p className="text-muted mb-0">
                        {options.find(opt => opt.value === value)?.label || 'Not set'}
                    </p>
                    {hoveredItem.field === field && hoveredItem.index === 'simple' && (
                        <button
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => setEditing({ field, value: value || '' })}
                        >
                            <RiEditLine />
                        </button>
                    )}
                </div>
            )}
        </div>
    );

    const renderSimpleField = (field, label, value, type = 'text') => {
        if (field === 'dealType') {
            return renderDropdownField(field, label, value, dealTypeOptions);
        }
        if (field === 'dealPriority') {
            return renderDropdownField(field, label, value, priorityOptions);
        }
        if (field === 'dealStage') {
            return renderDropdownField(field, label, value, stageOptions);
        }
        if (field === 'pipeline') {
            return renderDropdownField(field, label, value, pipelineOptions);
        }
        if (field === 'dealOwner') {
            return (
                <div className="mb-4">
                    <h6 className="text-muted mb-2">{label}</h6>
                    <div className="d-flex align-items-center gap-2">
                        <p className="text-muted mb-0">
                            {type === 'number' ?
                                `$${parseFloat(value || 0).toLocaleString()}` :
                                (value || 'Not set')}
                        </p>

                    </div>
                </div>
            )
        }

        return (
            <div
                className="mb-4"
                onMouseEnter={() => setHoveredItem({ field, index: 'simple' })}
                onMouseLeave={() => setHoveredItem({ field: null, index: null })}
            >
                <h6 className="text-muted mb-2">{label}</h6>
                {editing.field === field ? (
                    <div className="input-group">
                        {field === 'description' ? (
                            <textarea
                                className="form-control"
                                value={editing.value}
                                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
                                rows={4}
                            />
                        ) : (
                            <input
                                type={type}
                                className="form-control"
                                value={editing.value}
                                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
                            />
                        )}
                        <button
                            className="btn btn-success"
                            onClick={() => handleUpdate(field, editing.value)}
                        >
                            <RiCheckLine />
                        </button>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setEditing({ field: null, value: null })}
                        >
                            <RiCloseLine />
                        </button>
                    </div>
                ) : (
                    <div className="d-flex align-items-center gap-2">
                        <p className="text-muted mb-0">
                            {type === 'number' ?
                                `$${parseFloat(value || 0).toLocaleString()}` :
                                (value || 'Not set')}
                        </p>
                        {hoveredItem.field === field && hoveredItem.index === 'simple' && (
                            <button
                                className="btn btn-sm btn-outline-secondary"
                                onClick={() => setEditing({ field, value: value || '' })}
                            >
                                <RiEditLine />
                            </button>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const tagsOptions = audienceTags && audienceTags.map((c) => ({
        value: c._id,
        label: `${c.tag_name}`,
    }));

    return (
        <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
            {renderSimpleField('dealOwner', 'Deal Owner', dealData?.dealOwner?.fname + ' ' + dealData?.dealOwner?.lname)}
            {renderSimpleField('amount', 'Amount', dealData?.amount, 'number')}
            {renderSimpleField('expectedCloseDate', 'Expected Close Date', dealData?.expectedCloseDate, 'date')}
            {renderSimpleField('dealType', 'Deal Type', dealData?.dealType)}
            {renderSimpleField('dealPriority', 'Priority', dealData?.dealPriority)}
            {renderSimpleField('pipeline', 'Pipeline', dealData?.pipeline)}
            {renderSimpleField('dealStage', 'Deal Stage', dealData?.dealStage)}
            {/* {renderArrayField('tags', 'Tags', Array.isArray(dealData?.tags) ? dealData?.tags : [])} */}
            {renderTagsField(tagsOptions)}

            {renderSimpleField('description', 'Description', dealData?.description)}
        </div>
    );
};

export default DealDetails;