import React, { useState, useContext } from "react";
import Header from "../Components/Header/Header";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Button, Spinner } from "react-bootstrap";
import { Modal, Form } from "react-bootstrap";
import { TbChartBar, TbTrash, TbEdit } from "react-icons/tb";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaSearch } from "react-icons/fa"
import { RiCloseFill } from "react-icons/ri"
import { Link } from "react-router-dom";
import './Campaign.css';
import { getMetaApi } from "../BackendApi/Api/metaApi";
import Power from "../assets/images/power.png";
import Completed from "../assets/images/right.png"
import Scheduled from "../assets/images/calendar.png"
import { BsMegaphone } from "react-icons/bs";
import { SiGoogleads } from "react-icons/si";
import { getSmartlinkApi, getsmartLinksVisits } from "../BackendApi/Api/smartsLinkApi";
import { createCampaignApi, getCampaignApi, updateCampaignApi, deleteCampaignApi, getAllCampaignApi, } from "../BackendApi/Api/campaignApi";
import { getChannelDataApi } from "../BackendApi/Api/channels";
import { useEffect } from "react";
import { nanoid } from 'nanoid';
import { API_URL } from "../BackendApi/Api/config";
import { CampaignContext } from "../campaignContext";
import qrOptiions from "./defaultQR.json"
import { brandSmartCampApi } from "../BackendApi/Api/brandApi";
import Loader2 from "../Components/Loader/Loader2";
import { FormControl } from 'react-bootstrap';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { LuView } from "react-icons/lu";
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import { UserContext } from "../UserContext.js";
import { useHistory } from "react-router-dom";
import { brandSelectionContext } from "../contexts/context/brandSelectionContext.jsx";
import { apiCall } from "../BackendApi/Api/auth.js";
import { tagContext } from "../contexts/context/tagContext.jsx";
import RectangularTag from "../Components/Tags/RectangularTag.jsx";
import Select from "react-select";
import { Badge } from "react-bootstrap";
import { X } from "lucide-react";

const DEFAULT_FRONTHALF = process.env.REACT_APP_DEFAULT_FRONTHALF;






function Campaigns() {

    const history = useHistory();
    const { sidebarBrands } = useContext(brandSelectionContext);
    const userdata = JSON.parse(localStorage.getItem("user"));
    const role = userdata.role;

    const { subscriptionStatus } = useContext(UserContext);

    const { tagData } = useContext(tagContext);

    const currentDate = new Date();

    const [uniqueId, setUniqueId] = useState('');
    const [frontHalf, setFrontHalf] = useState('');

    const defaultqrjson = {
        "width": 300,
        "height": 300,
        "data": frontHalf + "/" + uniqueId + "?method=scan",
        "margin": 0,
        "qrOptions": {
            "typeNumber": "0",
            "mode": "Byte",
            "errorCorrectionLevel": "Q"
        },
        "imageOptions": {
            "hideBackgroundDots": true,
            "imageSize": 0.4,
            "margin": 0
        },
        "dotsOptions": {
            "type": "extra-rounded",
            "color": "#6a1a4c"
        },
        "backgroundOptions": {
            "color": "#ffffff"
        },
        "image": null,
        "dotsOptionsHelper": {
            "colorType": {
                "single": true,
                "gradient": false
            },
            "gradient": {
                "linear": true,
                "radial": false,
                "color1": "#6a1a4c",
                "color2": "#6a1a4c",
                "rotation": "0"
            }
        },
        "cornersSquareOptions": {
            "type": "extra-rounded",
            "color": "#000000"
        },
        "cornersSquareOptionsHelper": {
            "colorType": {
                "single": true,
                "gradient": false
            },
            "gradient": {
                "linear": true,
                "radial": false,
                "color1": "#000000",
                "color2": "#000000",
                "rotation": "0"
            }
        },
        "cornersDotOptions": {
            "type": "",
            "color": "#000000"
        },
        "cornersDotOptionsHelper": {
            "colorType": {
                "single": true,
                "gradient": false
            },
            "gradient": {
                "linear": true,
                "radial": false,
                "color1": "#000000",
                "color2": "#000000",
                "rotation": "0"
            }
        },
        "backgroundOptionsHelper": {
            "colorType": {
                "single": true,
                "gradient": false
            },
            "gradient": {
                "linear": true,
                "radial": false,
                "color1": "#ffffff",
                "color2": "#ffffff",
                "rotation": "0"
            }
        },
        "frameOptions": {
            "type": "qr-box"
        }
    };

    const { individualCampaign, setIndividualCampaign } = useContext(CampaignContext);

    const [brandData, setBrandData] = useState([]);
    const [templateData, setTemplateData] = useState([]);
    const [domainData, setDomainData] = useState([]);

    const [selectedBrandId, setSelectedBrandId] = useState('');

    const [smartlinkdata, setSmartlinkdata] = useState([]);
    const [flexPageData, setFlexPageData] = useState([]);
    const [campaigndata, setCampaigndata] = useState([]);

    const [backhalf, setBackhalf] = useState("");
    const [facebook, setFacebook] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [twitter, setTwitter] = useState("");
    const [pinterest, setPinterest] = useState("");
    const [email, setEmail] = useState("");
    const [short_url, setShort_url] = useState("");
    const [smartlinkId, setSmartlinkId] = useState("");
    const [flexPageId, setFlexPageId] = useState("");

    const [currentStyling, setCurrentStyling] = useState(defaultqrjson);

    const [campaignName, setCampaignName] = useState("");
    const [campaignDescription, setCampaignDescription] = useState("");
    const [campaignPurpose, setCampaignPurpose] = useState('');
    const [customPurpose, setCustomPurpose] = useState('');
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


    const [filteredCampaignData, setFilteredCampaignData] = useState([]);
    const [campaignSearch, setCampaignSearch] = useState("");

    const [editCampaignName, setEditCampaignName] = useState("");
    const [editEndDate, setEditEndDate] = useState("");
    const [editStartDate, setEditStartDate] = useState("");
    const [campaignId, setCampaignId] = useState("");
    const [editCampaignDescription, setEditCampaignDescription] = useState("");
    const [editCampaignPurpose, setEditCampaignPurpose] = useState("");
    const [editCustomPurpose, setEditCustomPurpose] = useState("");
    const [channelData, setChannelData] = useState([]);

    const [smartlinkChannelsData, setSmartlinkChannelsData] = useState([]);
    const [brandChannelsData, setBrandChannelsData] = useState([]);

    const [titleC, setTitleC] = useState("");
    const [descriptionC, setDescriptionC] = useState("");
    const [channel, setChannel] = useState([]);

    const [qrDesign, setQrDesign] = useState({});

    const [domainName, setDomainName] = useState("");
    const [smartlinkType, setSmartlinkType] = useState(0);

    const [Templatedataoption, setTemplatedataoption] = useState([]);

    const [longUrl, setLongUrl] = useState('');
    const [title, setTitle] = useState('Enter Title');
    const [description, setDescription] = useState('');
    const [favicon, setFavicon] = useState('');
    const [UrlType, setUrlType] = useState("simple");
    const [templateid, setTemplateId] = useState('');
    // const [smartlinkdata, setSmartlinkdata] = useState('');
    const [Loading, setLoading] = useState(false);
    const [meteLoading, setMetaLoading] = useState(false);

    const [smartlinkStyling, setSmartlinkStyling] = useState('');
    const [smartlinkOwner_id, setSmartlinkOwner_id] = useState('');
    const [brandOwnerId, setBrandsOwner_id] = useState('');

    const [editEndDate2, setEditEndDate2] = useState("");
    const [editStartDate2, setEditStartDate2] = useState("");

    const [filteredSmartlinkData, setFilteredSmartlinkData] = useState([]);
    const [smartlinkSearch, setSmartlinkSearch] = useState("");

    // const [domainData, setDomainData] = useState([]);
    const [templateImage, settemplateImage] = useState("");



    const [tags, setTags] = useState([]);

    const [BrandId, setBrandId] = useState("");


    const [selectedTag, setSelectedTag] = useState("");

    const [selectedBrandOwnerTags, setSelectedBrandOwnerTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);



    useEffect(() => {

        if (tagData.tags && tagData.tags.length > 0) {

            if (sidebarBrands.selected !== "all") {

                const brandOwnerId = sidebarBrands.brands.find((brand) => brand._id == sidebarBrands.selected).owner_id;
                console.log("de silva wala brandOwnerId: ", brandOwnerId);

                const filteredTags = tagData.tags.filter((tag) => tag.owner_id === brandOwnerId);

                setTags(filteredTags);

            }
            else {

                setTags(tagData.tags);
            }

        }

    }, [sidebarBrands, tagData]);

    useEffect(() => {

        console.log("tagData.tags: ", smartlinkType);

        if (tagData.tags.length > 0) {

            if (smartlinkType == "0") {

                if (smartlinkId != "") {

                    const brandOwnerId = smartlinkdata.find((smart) => smart._id == smartlinkId).owner_id;

                    const filteredTags = tagData.tags.filter((tag) => tag.owner_id === brandOwnerId);
                    setSelectedTag("");

                    setSelectedBrandOwnerTags(filteredTags);

                }
                else {
                    setSelectedBrandOwnerTags([]);

                }

            }
            else if (smartlinkType == "2") {

                if (flexPageId != "") {

                    const brandOwnerId = flexPageData.find((smart) => smart._id == flexPageId).owner_id;

                    const filteredTags = tagData.tags.filter((tag) => tag.owner_id === brandOwnerId);
                    setSelectedTag("");

                    setSelectedBrandOwnerTags(filteredTags);
                }
                else {
                    setSelectedBrandOwnerTags([]);
                }

            }
            else if (smartlinkType == "1") {

                console.log("selectedBrandId:sss ", selectedBrandId);

                console.log("brandData sidebr: ", sidebarBrands);


                if (selectedBrandId != "") {



                    const brandOwnerId = brandData.find((brand) => brand.brand_id == selectedBrandId).owner_id;

                    const filteredTags = tagData.tags.filter((tag) => tag.owner_id === brandOwnerId);
                    setSelectedTag("");

                    setSelectedBrandOwnerTags(filteredTags);
                }
                else {
                    setSelectedBrandOwnerTags([]);
                }

            }

        }

    }, [smartlinkType, smartlinkId, tagData, selectedBrandId, flexPageId]);


    const generateUniqueId = () => {
        const id = nanoid(8);
        setUniqueId(id);
    };

    const validateUrl = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    };

    const getMetaData = () => {
        setMetaLoading(true)
        const data = {
            url: longUrl
        }

        if (!validateUrl(longUrl)) {
            // URL is invalid, handle error
            PNotify.error({
                title: 'Error',
                text: 'Please enter a valid URL',
                delay: 1000,
                modules: {
                    Desktop: {
                        desktop: true
                    }
                }
            });
            setMetaLoading(false)
        } else {
            getMetaApi(data).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === true) {
                        const title = res.data.data.title;
                        const description = res.data.data.description;
                        const icon = res.data.data.icon;

                        setTitle(title);
                        setDescription(description);
                        setFavicon(icon);
                        setMetaLoading(false)

                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            text: 'Data not found',
                            modules: {
                                Desktop: {
                                    desktop: true
                                }
                            }
                        });
                        setMetaLoading(false)
                    }

                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                    });
                }
            }).catch((err) => {
                console.log("err", err);
            }).finally((ee) => {
                setMetaLoading(false)
            });
        }
    }

    const getBrandData = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        setLoading(true);
        const data = {
            owner_id: user.owner_id,
        }
        brandSmartCampApi(data).then((res) => {
            if (res.code === 200) {

                if (sidebarBrands.selected === "all") {

                    setBrandData(res.data.data);

                    if (res.data.data.length > 0) {
                        const templateData = res.data.data[0].template;
                        const domainData = res.data.data[0].domain;

                        setTemplateData(templateData);
                        setDomainData(domainData);

                        const defaulttem = templateData[0];
                        setTemplateId(defaulttem._id);
                        setSmartlinkStyling(defaulttem.styling);

                    }

                }
                else {

                    let daa = res.data.data.filter((brand) => brand.brand_id === sidebarBrands.selected);
                    setBrandData(daa);
                    if (daa.length > 0) {
                        const templateData = daa[0].template;
                        const domainData = daa[0].domain;

                        setTemplateData(templateData);
                        setDomainData(domainData);

                        const defaulttem = templateData[0];
                        setTemplateId(defaulttem._id);
                        setSmartlinkStyling(defaulttem.styling);

                    }

                }

            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong',
                });

            }
        }).catch((err) => {
            console.log("err", err);

        }).finally(() => {
            setLoading(false);
        }
        );
    }

    useEffect
        (() => {
            getBrandData();
        }, [])






    // const [individualCampaign, setIndividualCampaign] = useState({});

    const updateChannelData = (index) => {

        // update cahnnel data with status false of this index
        const newChannelData = [...channelData];
        newChannelData[index].status = !newChannelData[index].status;
        setChannelData(newChannelData);

    }


    const getChannelData = () => {
        const user = JSON.parse(localStorage.getItem('user'));

        setLoading(true);

        const data = {
            owner_id: user.owner_id,
            created_by: user._id,
            role: user.role
        }

        getChannelDataApi(data).then((res) => {

            const newChannelData = res.data.data.map((value) => {
                return {

                    _id: value._id,
                    channel_icon: value?.channel_icon,
                    status: false,
                    utm_name: value.channel_name,
                    channel_name: value.channel_name,
                    channel_category: value.channel_category

                }
            })
            setChannelData(newChannelData);

        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        }
        );
    }


    const handleCampaignSearch = (e) => {
        const searchValue = e.target.value;
        setCampaignSearch(searchValue);
        const newFilter = campaigndata.filter((value) => {
            return value.campaign_name.toLowerCase().includes(campaignSearch.toLowerCase());
        });

        if (searchValue === "" || searchValue.trim() === "") {
            setFilteredCampaignData(campaigndata);
        }
        else {
            setFilteredCampaignData(newFilter);
        }
    }

    const handleCampaignFilter = (selectedFilter) => {
        // Filter the campaign data based on the selected filter
        const filteredData = campaigndata.filter((campaign) => {
            // const currentDate = new Date();
            const startDate = new Date(campaign.start_date);
            const endDate = new Date(campaign.end_date);

            switch (selectedFilter) {
                case 'Active':
                    return currentDate > startDate && currentDate < endDate;
                case 'Scheduled':
                    return startDate > currentDate && endDate > currentDate;
                case 'Completed':
                    return endDate < currentDate;
                case 'All':
                    return true;
                default:
                    return false; // Default to exclude if no match
            }
        });

        setFilteredCampaignData(filteredData);

        // Now, you can use the filteredData as needed or update your component state with it.
    }

    const handleClearFilter = () => {
        setCampaignSearch("");
        setFilteredCampaignData(campaigndata);
    }

    const handleTagFilter = (tag) => {

        if (tag === "All") {
            setFilteredCampaignData(campaigndata);
        }
        else {
            const filteredData = campaigndata.filter((campaign) => campaign?.tags?.map(tag => tag._id).includes(tag));
            setFilteredCampaignData(filteredData);
        }

    }



    const handleChange = (event, type) => {
        setBackhalf(event.target.value);
        const selectedOption = event.target.options[event.target.selectedIndex];
        setShort_url(selectedOption.getAttribute('short_url'));
        const smartid = selectedOption.getAttribute('smartid');

        if (type === "smartlink") {
            setSmartlinkId(smartid);
        }
        if (type === "flexpage") {
            setFlexPageId(smartid);
        }
        const design = selectedOption.getAttribute('design');
        setQrDesign(design);
        const domain_name = selectedOption.getAttribute('domain_n');
        setDomainName(domain_name);
        const l_url = selectedOption.getAttribute('L_url');
        setLongUrl(l_url);

        const channels = selectedOption.getAttribute('channels');
        const smartlinkOwner_ids = selectedOption.getAttribute('smartlinkOwner_ids');
        setSmartlinkOwner_id(smartlinkOwner_ids)

        const channelsArray = JSON.parse(channels); // Parse the channels


        const newChannelData = channelsArray
            .filter((value) => value.isActive) // Filter channels where isActive is true
            .map((value) => {
                return {
                    _id: value._id,
                    channel_icon: value?.channel_icon,
                    status: false,
                    utm_name: value.channel_name,
                    channel_name: value.channel_name,
                    channel_category: value.channel_category,
                    url: value.url
                };
            });

        setChannelData(newChannelData);
        setSmartlinkChannelsData(newChannelData);



    };

    const [saveCamLoading, setSaveCamLoading] = useState(false)
    const handleCreateCampaign = (e) => {
        e.preventDefault();
        setSaveCamLoading(true);

        const shortUrl = "bxr.gg/";
        const backhalf = uniqueId;
        const full_short_url = frontHalf + "/" + backhalf;
        const styling = currentStyling;
        //replace the data of current styling with the full_short_url

        styling.data = full_short_url + "?method=scan";

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const created_by = user._id;
        const owner_id = user.owner_id;

        let selectedChannelData
        // 0 for FlexLinks 2 for FlexPage ,1 for New Url 
        if (smartlinkType === 0 || smartlinkType === 2) {

            selectedChannelData = smartlinkChannelsData.filter((value) => {
                return value.status === true;
            });

        }

        if (smartlinkType === 1) {// 1 for New Url

            selectedChannelData = brandChannelsData.filter((value) => {
                return value.status === true;
            });


        }

        let formData;
        if (smartlinkType === 0 || smartlinkType === 2) {/// 0 for FlexLinks 2 for FlexPage
            formData = {
                smartlinkType: smartlinkType,
                created_by: created_by,
                owner_id: smartlinkOwner_id,
                campaign_name: campaignName,
                campaign_description: campaignDescription,
                campaign_purpose: campaignPurpose,
                custom_purpose: customPurpose,
                start_date: startDate,
                end_date: endDate,
                campaign_type: "custom",
                channels: selectedChannelData,
                smartlink_id: smartlinkType === 0 ? smartlinkId : flexPageId,
                qr_design: JSON.stringify(qrOptiions),
                domain_name: domainName,
                destination_url: longUrl,
                creator_name: user?.fname + " " + user?.lname,
                creator_image: user?.profile_image,
                tag_id: selectedTag == "" ? undefined : selectedTag,
                tags: selectedTags.map((tag) => tag.value),
            }
        }

        if (smartlinkType === 1) {// 1 for New Url

            const userdata = localStorage.getItem("user");
            const user = JSON.parse(userdata);

            formData = {
                smartlinkType: smartlinkType,
                created_by: created_by,
                owner_id: brandOwnerId,
                campaign_name: campaignName,
                campaign_description: campaignDescription,
                campaign_purpose: campaignPurpose,
                custom_purpose: customPurpose,
                start_date: startDate,
                end_date: endDate,
                campaign_type: "custom",
                channels: selectedChannelData,
                qr_design: JSON.stringify(qrOptiions),
                domain_name: domainName,
                destination_url: longUrl,
                creator_name: user?.fname + " " + user?.lname,
                creator_image: user?.profile_image,
                tag_id: selectedTag == "" ? undefined : selectedTag,
                tags: selectedTags.map((tag) => tag.value),

                smartlinkData: {
                    full_short_url: full_short_url,
                    domain_name: frontHalf,
                    template_image: backhalf + ".png",
                    template_id: templateid,
                    url_type: UrlType,
                    backhalf: backhalf,
                    smartlinkStyling: smartlinkStyling,
                    styling: JSON.stringify(styling),
                    long_url: longUrl,
                    title: title,
                    description: description,
                    created_by: created_by,
                    owner_id: owner_id,
                    favicon: favicon,
                    type: "0",
                    creation_method: "campaign",
                    creator_image: user?.profile_image,
                    creator_name: user?.fname + " " + user?.lname,
                    brand_id: selectedBrandId,
                    destinationType: "url"

                }
            }
        }

        if ((subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
            || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && campaigndata.length >= 1) {
            PNotify.error({
                title: 'Error',
                text: 'Please upgrade your plan to continue',
            });
            setSaveCamLoading(false)
            return false;
        }
        else if (campaignName === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please Enter Campaign Name',
                delay: 1000,
            });
            setSaveCamLoading(false)
        }
        else if (startDate === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please Enter Start Date',
                delay: 1000,
            });
            setSaveCamLoading(false)
        }
        else if (endDate === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please Enter End Date',
                delay: 1000,
            });
            setSaveCamLoading(false)
        } else if (campaignPurpose === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please Enter Campaign Purpose',
                delay: 1000,
            });
            setSaveCamLoading(false)
        } else if (campaignPurpose === "Other" && customPurpose === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please Specify Other Purpose',
                delay: 1000,
            });
            setSaveCamLoading(false)

        } else if (selectedChannelData == null || selectedChannelData.length == 0 || selectedChannelData == "" || selectedChannelData == undefined) {
            PNotify.error({
                title: 'Error',
                text: 'Please Select atlist one Channel',
                delay: 1000,
            });
            setSaveCamLoading(false)
        }
        else {
            setSaveCamLoading(true)
            createCampaignApi(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === true) {
                        PNotify.success({
                            title: 'Success',
                            text: 'Campaign Created Successfully',
                            delay: 1000,
                        });
                        getcampaigndata();
                        handleClose03();
                        setSaveCamLoading(false);
                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            text: 'Something went wrong',
                        });
                        setSaveCamLoading(false);
                    }
                } else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                    });
                    setSaveCamLoading(false);
                }
            }).catch((err) => {
                PNotify.error({
                    title: 'Error',
                    text: err,
                });
                setSaveCamLoading(false);
            }).finally((err) => {
                setSaveCamLoading(false)
            });
        }


    }

    const getsmartlinkdata = async () => {
        setLoading(true);
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const created_by = user._id;
        const owner_id = user.owner_id;
        const role = user.role;

        const data = {
            id: created_by,
        }

        const response = await fetch(`${API_URL}smartlink/getallsmartlinksforcampaign`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        const result = await response.json();
        if (result.status) {
            if (sidebarBrands.selected === "all") {

                setSmartlinkdata(result.data);
            } else {

                const newdata = result.data.filter((value) => {
                    return value.brand_id === sidebarBrands.selected
                })
                setSmartlinkdata(newdata);
            }
        }


    }

    const getFlexPageData = async () => {

        setLoading(true);
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const created_by = user._id;
        const owner_id = user.owner_id;
        const role = user.role;

        const data = {
            id: created_by,
        }

        const response = await fetch(`${API_URL}smartlink/getallflexpagesforcampaign`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        const result = await response.json();
        if (result.status) {
            if (sidebarBrands.selected === "all") {

                setFlexPageData(result.data);
            } else {

                const newdata = result.data.filter((value) => {
                    return value.brand_id === sidebarBrands.selected
                })
                setFlexPageData(newdata);
            }
        }


    }


    const getcampaigndata = () => {
        setLoading(true);
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const created_by = user._id;
        const owner_id = user.owner_id;
        const role = user.role;

        const data = {
            created_by: created_by,
            owner_id: owner_id,
            role: role
        }

        getAllCampaignApi(data).then((res) => {

            if (res.code === 200) {
                if (res.data.status === true) {
                    const ApiData = res.data.data;
                    console.log("ApiData", ApiData)
                    if (sidebarBrands.selected == "all") {
                        setCampaigndata(ApiData);
                        setFilteredCampaignData(ApiData);
                    } else {
                        const newdata = ApiData.filter((value) => {
                            return value.brand_id === sidebarBrands.selected
                        })
                        setCampaigndata(newdata);
                        setFilteredCampaignData(newdata);
                    }
                    // setCampaigndata(ApiData);
                    // setFilteredCampaignData(ApiData);
                    let campaignData = ApiData.find((value) => {
                        return value.campaign_id === campaignId
                    })
                    setTitleC(campaignData?.campaign_name);
                    setDescriptionC(campaignData?.campaign_description);
                    console.log("api", campaignData);
                    setLoading(false);
                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Data not found',
                        delay: 1000,
                    });
                    setLoading(false);
                }
            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong',
                    delay: 1000,
                });
                setLoading(false);
            }
        }).catch((err) => {
            PNotify.error({
                title: 'Error',
                text: err,
            })
            setLoading(false);

        }).finally(() => {
            setLoading(false);
        }
        );
    }

    const handleEditCampaign = () => {
        setSaveCamLoading(true)
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const data = {
            id: campaignId,
            campaign_name: titleC,
            start_date: editStartDate,
            end_date: editEndDate,
            campaign_description: descriptionC,
            campaign_purpose: editCampaignPurpose,
            custom_purpose: editCustomPurpose,
            updator_name: user.fname + " " + user.lname,
            updator_image: user.profile_image,
        }

        updateCampaignApi(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    PNotify.success({
                        title: 'Success',
                        text: 'Campaign Updated Successfully',
                        delay: 1000,
                    });
                    getcampaigndata();
                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                        delay: 1000,
                    });
                }

            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong',
                    delay: 1000,
                });
            }
        }).catch((err) => {
            console.log("err", err);
        }).finally((err) => {
            setSaveCamLoading(false)
        });
    }

    const handleDeleteCampaign = () => {
        setLoading(true);
        const data = {
            id: campaignId
        }

        deleteCampaignApi(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    PNotify.success({
                        title: 'Success',
                        text: 'Campaign Deleted Successfully',
                        delay: 1000,
                    });
                    getcampaigndata();
                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                        delay: 1000,
                    });
                }
            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong',
                    delay: 1000,
                });
            }
        }).catch((err) => {
            console.log("err", err);
        });
    }

    const showUrl = () => {
        document.querySelector('.description-url').style.display = 'block';
        document.querySelector('.campaign-description').style.display = 'none';
        document.querySelector('.description-url2').style.display = 'block';
        document.querySelector('.flexpage-select').style.display = 'none';

    }

    const showCampaign = () => {
        document.querySelector('.description-url').style.display = 'none';
        document.querySelector('.campaign-description').style.display = 'block';
        document.querySelector('.description-url2').style.display = 'none';
        document.querySelector('.flexpage-select').style.display = 'none';

    }

    const showCampaignFlexpage = () => {
        document.querySelector('.description-url').style.display = 'none';
        document.querySelector('.campaign-description').style.display = 'none';
        document.querySelector('.description-url2').style.display = 'none';
        document.querySelector('.flexpage-select').style.display = 'block';

    }

    useEffect(() => {
        getBrandData();
        getsmartlinkdata();
        getcampaigndata();
        getFlexPageData();
        // getChannelData();
        // getClicksVisites();

    }, []);


    const handleBrandSelect = (e) => {
        const selectedOption = e.target.selectedOptions[0];
        const templateData = selectedOption.getAttribute('templateData');
        const domainData = selectedOption.getAttribute('domainData');
        const brandOwnerIds = selectedOption.getAttribute('brandOwnerId');

        // Parse the domainData from the selected option
        const domain = JSON.parse(domainData);

        // Get domain URLs from the .env variable
        const domainUrls = process.env.REACT_APP_DOMAIN_URLS.split(',');

        // Create an array from the domain URLs
        const newArray = domainUrls.map((url) => ({ domain_name: url }));

        // Combine the new array with the existing domain data
        const finalArray = [...newArray, ...domain];

        console.log("domain", domain);
        setDomainData(finalArray);
        setFrontHalf(finalArray[0]?.domain_name); // Use optional chaining for safety
        setDomainName(finalArray[0]?.domain_name); // Use optional chaining for safety

        const template = JSON.parse(templateData);
        setTemplateData(template);
        setTemplateId(template[0]._id);
        setSmartlinkStyling(JSON.parse(template[0].styling));
        setBrandsOwner_id(brandOwnerIds);
        const channels = selectedOption.getAttribute('channels');

        const idea = selectedOption.getAttribute('value');
        setSelectedBrandId(idea);

        console.log("channels", channels);

        const channelsArray = JSON.parse(channels); // Parse the channels
        console.log("channelsArray", channelsArray);

        const newChannelData = channelsArray
            .filter((value) => value.isActive) // Filter channels where isActive is true
            .map((value) => {
                return {
                    _id: value._id,
                    channel_icon: value?.channel_icon,
                    status: false,
                    utm_name: value.channel_name,
                    channel_name: value.channel_name,
                    channel_category: value.channel_category,
                    url: value.url
                };
            });

        setChannelData(newChannelData);
        setBrandChannelsData(newChannelData);
    };


    const handleDomainSelect = (e) => {
        setFrontHalf(e.target.value);
        setDomainName(e.target.value);
    }

    const handletemplateSelect = (e) => {
        setTemplateId(e.target.value);
        setSmartlinkStyling(e.target.selectedOptions[0].getAttribute('Styling'));
    }





    const handleSelectChange = (e) => {
        const selectedPurpose = e.target.value;
        setCampaignPurpose(selectedPurpose);

        // If "Other" is selected, show the input field

    };


    const handleCustomPurposeChange = (e) => {
        setCustomPurpose(e.target.value);
    };

    const handleEditSelectChange = (e) => {
        const selectedEditPurpose = e.target.value;
        setEditCampaignPurpose(selectedEditPurpose);

        // If "Other" is selected, show the input field

    };

    const handleEditCustomPurposeChange = (e) => {
        setEditCustomPurpose(e.target.value);
    }


    const [show02, setShow02] = useState(false);

    const handleClose02 = () => setShow02(false);
    const handleShow02 = () => setShow02(true);


    const [show03, setShow03] = useState(false);

    const handleClose03 = () => {
        setShow03(false);
        setSmartlinkId('');
        setSelectedBrandId('');

        setSmartlinkChannelsData([]);
        setBrandChannelsData([]);
        setSmartlinkType(0);

    }
    const handleShow03 = () => setShow03(true);

    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [show06, setShow06] = useState(false);

    const handleClose06 = () => setShow06(false);
    const handleShow06 = () => setShow06(true);

    const tagsOptions = selectedBrandOwnerTags && selectedBrandOwnerTags.map((c) => ({
        value: c._id,
        label: `${c.tag_name}`,
    }));

    const handleTagChange = (selected) => {
        setSelectedTags(selected || []);
    };



    return (
        <>
            {/* Delete Modal Start From Here */}
            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={() => {
                        handleClose04();
                        handleDeleteCampaign();
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Modal End Here */}

            <Modal show={show06} onHide={handleClose06} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Subscription not active !</Modal.Title>
                </Modal.Header>

                {role == "0" ? (<>
                    <Modal.Body>Please Upgrade to  an active plan to continue using this feature</Modal.Body>
                    <Modal.Footer>
                        <Button className='delete-Cancel-bttn' onClick={() => { history.push("/setting/price") }}>
                            Upgrade
                        </Button>
                    </Modal.Footer>
                </>) : (
                    <Modal.Body>Please contact ypur admin and ask them to upgrade to  an active plan.</Modal.Body>
                )}
            </Modal>

            <Modal show={show03} onHide={handleClose03} className="Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><BsMegaphone />Add New Campaign</Modal.Title>
                    {/* <Modal.Title>Create New SmartLink</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className=''>
                        <div className='col-md-12'>
                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="FlexLink"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            onClick={() => { showCampaign(); setSmartlinkType(0); setSelectedTag(''); setSelectedTags([]); }}
                                            defaultChecked // Set the first option as default
                                        />
                                        <Form.Check
                                            inline
                                            label="FlexPage"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-3`}
                                            onClick={() => { showCampaignFlexpage(); setSmartlinkType(2); setSelectedTag(''); setSelectedTags([]); }}
                                        // defaultChecked // Set the first option as default
                                        />
                                        <Form.Check
                                            inline
                                            label="New url"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            onClick={() => { showUrl(); setSmartlinkType(1); generateUniqueId(); setSelectedTag(''); setSelectedTags([]); }}
                                        />
                                    </div>
                                ))}
                            </Form>

                            <Form.Group className="did-floating-label-content campaign-description" controlId="formBasicEmail"
                                style={{ display: 'block' }}>
                                <Form.Select
                                    className="did-floating-select"
                                    aria-label="Default select example"
                                    onChange={
                                        (event) => {
                                            handleChange(event, "smartlink");
                                        }

                                    } >
                                    <option disabled selected> Please Select a FlexLink</option>
                                    {
                                        smartlinkdata && smartlinkdata.map((item) => {
                                            return (
                                                <option
                                                    smartid={item._id}
                                                    short_url={item.full_short_url}
                                                    design={item.styling}
                                                    domain_n={item.domain_name}
                                                    value={item.backhalf}
                                                    smartlinkOwner_ids={item.owner_id}
                                                    L_url={item.long_url}
                                                    channels={JSON.stringify(item.channels)}                                                >
                                                    {item.backhalf}-{item.title}
                                                    <div>
                                                        <img src={item.favicon} className="smartlink-img" />
                                                    </div>
                                                </option>
                                            )
                                        }
                                        )
                                    }
                                </Form.Select>
                                <Form.Label className="did-floating-label">Select FlexLink</Form.Label>
                            </Form.Group>



                            <Form.Group className="did-floating-label-content flexpage-select" controlId="formBasicEmail"
                                style={{ display: 'none' }}>
                                <Form.Select
                                    className="did-floating-select"
                                    aria-label="Default select example"
                                    onChange={
                                        (event) => {
                                            handleChange(event, "flexpage");
                                        }
                                    }
                                >
                                    <option disabled selected> Please Select a FlexPage</option>
                                    {
                                        flexPageData && flexPageData.map((item) => {
                                            return (
                                                <option
                                                    smartid={item._id}
                                                    short_url={item.full_short_url}
                                                    design={item.styling}
                                                    domain_n={item.domain_name}
                                                    value={item.backhalf}
                                                    smartlinkOwner_ids={item.owner_id}
                                                    L_url={item.long_url}
                                                    channels={JSON.stringify(item.channels)}                                                >
                                                    {item.backhalf}-{item.title}
                                                    <div>
                                                        <img src={item.favicon} className="smartlink-img" />
                                                    </div>
                                                </option>
                                            )
                                        }
                                        )
                                    }
                                </Form.Select>
                                <Form.Label className="did-floating-label">Select FlexPage</Form.Label>
                            </Form.Group>

                            <Form.Group className="did-floating-label-content" controlId="formBasicName">
                                <Form.Control className="did-floating-input" type="text" placeholder="" value={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
                                <Form.Label className="did-floating-label">New Campaign Name</Form.Label>
                            </Form.Group>


                            <Form.Group controlId="formBasicPurpose" className="did-floating-label-content">
                                <Form.Label>What are you trying to drive or grow?</Form.Label>
                                <Form.Control as="select" value={campaignPurpose} onChange={handleSelectChange}>
                                    <option selected disabled>Select a Purpose</option>
                                    <option value="Page Views"> Page Views </option>
                                    <option value="Product Sales">Product Sales</option>
                                    <option value="Affiliate Referrals">Affiliate Referrals</option>
                                    <option value="Newsletter Subscribers">Newsletter Subscribers</option>
                                    <option value="SaaS Subscribers">SaaS Subscribers</option>
                                    <option value="Software Downloads">Software Downloads</option>
                                    <option value="Brand Awareness">Brand Awareness</option>
                                    <option value="Event Ticket Sales">Event Ticket Sales</option>
                                    <option value="Other">Other</option>
                                </Form.Control>
                            </Form.Group>

                            {campaignPurpose === 'Other' && (
                                <Form.Group controlId="formCustomPurpose" className="did-floating-label-content">
                                    <Form.Label >Please specify other puropse</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Custom Purpose"
                                        value={customPurpose}
                                        onChange={handleCustomPurposeChange}
                                        className="did-floating-input"
                                    />
                                </Form.Group>
                            )}


                            <Form.Group className="did-floating-label-content" controlId="formBasicCAMPAIGN">
                                <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={campaignDescription} onChange={
                                    (e) => {
                                        setCampaignDescription(e.target.value);
                                    }
                                } placeholder="" />
                                <Form.Label className="did-floating-label">Campaign description</Form.Label>
                            </Form.Group>

                            {/* <div className="did-floating-label-content">
                                <select

                                    className="did-floating-select"
                                    // onclick={handelTemplate}
                                    onChange={
                                        (e) => {
                                            setSelectedTag(e.target.value);
                                        }
                                    }
                                >
                                    <option value="">No Tag</option>

                                    {selectedBrandOwnerTags && selectedBrandOwnerTags.map((item) => {

                                        return (
                                            <option value={item._id} >{item.tag_name}</option>
                                        )
                                    })
                                    }
                                </select>
                                <label className="did-floating-label">Select Tag</label>
                            </div> */}

                            <Form.Group className="mb-3">
                                <Form.Label>Tags</Form.Label>
                                <Select
                                    options={tagsOptions}
                                    isMulti
                                    value={selectedTags}
                                    onChange={handleTagChange}
                                    placeholder="Select tags..."
                                />
                                <div className="d-flex flex-wrap gap-2 mt-2">
                                    {selectedTags.map((tag) => (
                                        <Badge key={tag.value} className="p-2">
                                            {tag.label}
                                            <X
                                                size={14}
                                                className="ms-2 cursor-pointer"
                                                onClick={() =>
                                                    handleTagChange(
                                                        selectedTags.filter((c) => c.value !== tag.value)
                                                    )
                                                }
                                            />
                                        </Badge>
                                    ))}
                                </div>
                            </Form.Group>

                            <hr />
                            <div className="description-url" style={{ display: 'none' }} >
                                <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                    <div className="d-flex">
                                        <Form.Control className="did-floating-input" type="text" value={longUrl} onChange={
                                            (e) => {
                                                setLongUrl(e.target.value);
                                            }
                                        } placeholder="Enter URL Here" />
                                        <Form.Label className="did-floating-label">Destination / Long URL</Form.Label>

                                        <button className="btn btn-primary form-inline-btn" onClick={getMetaData}>{meteLoading ? <Spinner /> : "Get Meta"}</button>
                                    </div>
                                </Form.Group>
                                <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                    <Form.Control className="did-floating-input" type="text" value={title} onChange={
                                        (e) => {
                                            setTitle(e.target.value);
                                        }
                                    } placeholder="" />
                                    <Form.Label className="did-floating-label">Title</Form.Label>
                                </Form.Group>
                                <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                    <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={description} onChange={
                                        (e) => {
                                            setDescription(e.target.value);
                                        }
                                    }
                                        placeholder="" />
                                    <Form.Label className="did-floating-label">Description</Form.Label>
                                </Form.Group>
                                <Form.Group className="did-floating-label-content image-preview-edit" controlId="formBasicEmail">
                                    <Form.Label >Favicon</Form.Label>
                                    {/* <div className="default-favicon">
                                        <img src={require('../assets/images/logo/popuplogo.webp')} alt="" className="img-fluid" />
                                    </div> */}
                                    <img src={favicon} alt="" className="img-fluid" />
                                </Form.Group>
                            </div>
                            <div className="d-flex">
                                <div className="col-md-6 me-2">
                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                        <Form.Control className="did-floating-input" type="date" placeholder="Enter Campaign Name" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                        <Form.Label className="did-floating-label">Start Date</Form.Label>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                        <Form.Control className="did-floating-input" type="date" placeholder="Enter Campaign Name" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                        <Form.Label className="did-floating-label">End Date</Form.Label>
                                    </Form.Group>
                                </div>
                            </div>
                            <hr />
                            <div className="description-url2" style={{ display: 'none' }}>
                                <div className="d-flex">
                                    <div className="col-md-6 me-2">
                                        <Form.Group className="did-floating-label-content" controlId="formBasic">
                                            <Form.Select className="did-floating-select" aria-label="Default select example" onChange={handleBrandSelect}>
                                                <option disabled selected> Please Select a Brand</option>

                                                {brandData && brandData.map((item) => {
                                                    return (
                                                        <option
                                                            value={item.brand_id}
                                                            templateData={JSON.stringify(item.template)}
                                                            domainData={JSON.stringify(item.domain)}
                                                            channels={JSON.stringify(item.channels)}
                                                            brandOwnerId={item.owner_id}
                                                        >{item.brand_name}</option>
                                                    )
                                                })}

                                                {/* <option>Select a Brand</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option> */}
                                            </Form.Select>
                                            <Form.Label className="did-floating-label">Select Brand</Form.Label>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="did-floating-label-content" controlId="formBasic">
                                            <Form.Select className="did-floating-select" aria-label="Default select example" onChange={handleDomainSelect}>
                                                <option disabled selected> Please Select a Domain</option>
                                                {domainData && domainData.map((item) => {
                                                    return (
                                                        <option value={item.domain_name} >{item.domain_name}</option>
                                                    )
                                                })
                                                }
                                            </Form.Select>
                                            <Form.Label className="did-floating-label">Custom Domain</Form.Label>
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Group className="" controlId="formBasicCHANNELA">
                                    <Form.Group className="did-floating-label-content" controlId="formBasic">
                                        <Form.Select className="did-floating-select" aria-label="Default select example"
                                            onChange={handletemplateSelect}>
                                            <option disabled selected> Please Select a Template</option>
                                            {templateData && templateData.map((item) => {
                                                return (
                                                    <option value={item._id} Styling={item.styling} >{item.template_name}</option>
                                                )
                                            })
                                            }
                                        </Form.Select>
                                        <Form.Label className="did-floating-label">Template</Form.Label>
                                    </Form.Group>
                                </Form.Group>
                            </div>
                            <Form.Group className="" controlId="formBasicCHANNELA">
                                <Form.Label>Channels</Form.Label>
                            </Form.Group>
                            {/* 
                            <div className="text-center create-text">
                                Please Create a Channel
                            </div> */}

                            {
                                (smartlinkType == 0 &&
                                    <>

                                        {

                                            smartlinkId == "" ?
                                                <>
                                                    <div>please select a FlexLink</div>

                                                </> :

                                                <>

                                                    {
                                                        (smartlinkChannelsData && smartlinkChannelsData.length == 0) ? <div>The brand for this smartlink doesn't have any active channels</div> :
                                                            <>

                                                                <div className="channel-item mb-3">
                                                                    <div className="channel_img_list">
                                                                        {smartlinkChannelsData && smartlinkChannelsData.map((item, index) => {
                                                                            return (
                                                                                <div className="channel-icon">
                                                                                    <button className="channel-icon-btn"

                                                                                        onClick={() => {

                                                                                            const newChannelData = [...smartlinkChannelsData];
                                                                                            newChannelData[index].status = !newChannelData[index].status;
                                                                                            setSmartlinkChannelsData(newChannelData);

                                                                                        }}
                                                                                        style={{
                                                                                            backgroundColor: item.status ? "black" : "white",
                                                                                            color: item.status ? "white" : "black"
                                                                                        }}
                                                                                    >
                                                                                        <img src={API_URL + `channels/` + item?.channel_icon} alt="" />
                                                                                        <div className="channel-name">
                                                                                            {item.channel_name}
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>



                                                            </>


                                                    }


                                                </>

                                        }




                                    </>)

                            }


                            {
                                (smartlinkType == 2 &&
                                    <>

                                        {

                                            smartlinkId == "" ?
                                                <>
                                                    <div>please select a FlexLink</div>

                                                </> :

                                                <>

                                                    {
                                                        (smartlinkChannelsData && smartlinkChannelsData.length == 0) ? <div>The brand for this smartlink doesn't have any active channels</div> :
                                                            <>

                                                                <div className="channel-item mb-3">
                                                                    <div className="channel_img_list">
                                                                        {smartlinkChannelsData && smartlinkChannelsData.map((item, index) => {
                                                                            return (
                                                                                <div className="channel-icon">
                                                                                    <button className="channel-icon-btn"

                                                                                        onClick={() => {

                                                                                            const newChannelData = [...smartlinkChannelsData];
                                                                                            newChannelData[index].status = !newChannelData[index].status;
                                                                                            setSmartlinkChannelsData(newChannelData);

                                                                                        }}
                                                                                        style={{
                                                                                            backgroundColor: item.status ? "black" : "white",
                                                                                            color: item.status ? "white" : "black"
                                                                                        }}
                                                                                    >
                                                                                        <img src={API_URL + `channels/` + item?.channel_icon} alt="" />
                                                                                        <div className="channel-name">
                                                                                            {item.channel_name}
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>



                                                            </>


                                                    }


                                                </>

                                        }




                                    </>)

                            }

                            {
                                (smartlinkType == 1 &&
                                    <>

                                        {

                                            selectedBrandId == "" ?
                                                <>
                                                    <div>please select a brand</div>

                                                </> :

                                                <>

                                                    {
                                                        (brandChannelsData && brandChannelsData.length == 0) ? <div>The brand doesn't have any active channels</div> :
                                                            <>

                                                                <div className="channel-item mb-3">
                                                                    <div className="channel_img_list">
                                                                        {brandChannelsData && brandChannelsData.map((item, index) => {
                                                                            return (
                                                                                <div className="channel-icon">
                                                                                    <button className="channel-icon-btn"

                                                                                        onClick={() => {

                                                                                            const newChannelData = [...brandChannelsData];
                                                                                            newChannelData[index].status = !newChannelData[index].status;
                                                                                            setBrandChannelsData(newChannelData);

                                                                                        }}
                                                                                        style={{
                                                                                            backgroundColor: item.status ? "black" : "white",
                                                                                            color: item.status ? "white" : "black"
                                                                                        }}
                                                                                    >
                                                                                        <img src={API_URL + `channels/` + item?.channel_icon} alt="" />
                                                                                        <div className="channel-name">
                                                                                            {item.channel_name}
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>



                                                            </>


                                                    }


                                                </>

                                        }




                                    </>)

                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {saveCamLoading ? <SpinnerLoader /> :
                        <button disabled={(subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                            || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && campaigndata.length >= 1 ? true : false}
                            className="Active-bttn btn" onClick={handleCreateCampaign} >Create Campaign </button>}
                </Modal.Footer>
            </Modal>

            <Modal show={show02} onHide={handleClose02} className="Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbEdit />Edit Campaign</Modal.Title>
                    {/* <Modal.Title>Create New SmartLink</Modal.Title> */}


                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className=''>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control disabled={currentDate >= editStartDate2 && currentDate <= editEndDate2} className="did-floating-input" type="text" placeholder="" value={titleC} onChange={(e) => setTitleC(e.target.value)} />
                                <Form.Label className="did-floating-label">New Campaign Name</Form.Label>
                            </Form.Group>
                            <Form.Group className="did-floating-label-content" controlId="formBasicCAMPAIGN">
                                <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={descriptionC} onChange={
                                    (e) => {
                                        setDescriptionC(e.target.value);
                                    }
                                } placeholder="" />
                                <Form.Label className="did-floating-label">Campaign description</Form.Label>
                            </Form.Group>

                            <Form.Group className="did-floating-label-content" controlId="formBasicPurpose">
                                <Form.Control as="select" value={editCampaignPurpose} onChange={handleEditSelectChange} selected={editCampaignPurpose} className="did-floating-select">
                                    <option value="Page Views">Page Views</option>
                                    <option value="Product Sales">Product Sales</option>
                                    <option value="Affiliate Referrals">Affiliate Referrals</option>
                                    <option value="Newsletter Subscribers">Newsletter Subscribers</option>
                                    <option value="SaaS Subscribers">SaaS Subscribers</option>
                                    <option value="Software Downloads">Software Downloads</option>
                                    <option value="Brand Awareness">Brand Awareness</option>
                                    <option value="Event Ticket Sales">Event Ticket Sales</option>
                                    <option value="Other">Other</option>
                                </Form.Control>
                                <Form.Label className="did-floating-label">What's this Campaign's Primary Purpose?</Form.Label>
                            </Form.Group>

                            {editCampaignPurpose === 'Other' && (
                                <Form.Group controlId="formCustomPurpose" className="did-floating-label-content" >
                                    <Form.Control
                                        type="text"
                                        placeholder="Custom Purpose"
                                        value={editCustomPurpose}
                                        onChange={handleEditCustomPurposeChange}
                                        className="did-floating-input"
                                    />
                                    <Form.Label className="did-floating-label">Please specify other puropse</Form.Label>
                                </Form.Group>
                            )}

                            <div className="d-flex">
                                <div className="col-md-6 me-2">
                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                        <Form.Control disabled={currentDate > editStartDate2} className="did-floating-input" type="date" placeholder="" value={editStartDate} onChange={(e) => setEditStartDate(e.target.value)} />
                                        <Form.Label className="did-floating-label">Start Date</Form.Label>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                        <Form.Control className="did-floating-input" type="date" placeholder="" value={editEndDate} onChange={(e) => setEditEndDate(e.target.value)} />
                                        <Form.Label className="did-floating-label">End Date</Form.Label>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {saveCamLoading ? <SpinnerLoader /> :
                        <button className="Active-bttn btn" onClick={() => {
                            handleClose02();
                            handleEditCampaign();
                        }} >Update Campaign </button>}
                </Modal.Footer>
            </Modal>

            <Header />

            <div className="main-container cam-paigns">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        {Loading && <Loader2 />}

                        <div className="main-page-body-content-body-container">
                            <div className="table-card-header add-btn d-flex">
                                <h5 className="main-card-title"><BsMegaphone className="header-icon" />CAMPAIGNS</h5>
                                <div className="card-content-header-right">
                                    <Button className="btn btn-sm float-right add-user-btn"
                                        onClick={
                                            () => {

                                                if ((subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                                                    || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && campaigndata.length >= 1) {
                                                    handleShow06();
                                                }
                                                else {
                                                    handleShow03();
                                                }
                                            }

                                        } > <BsMegaphone />New Campaigns</Button>
                                </div>
                            </div>
                            <div className="wrap-campion">
                                <form role="search" className="search-form form">
                                    <label>
                                        <span className="screen-reader-text">Search for...</span>
                                        <input type="search" className="search-field" placeholder="Search..." value={campaignSearch} onChange={handleCampaignSearch} />
                                    </label>
                                    <button class="search-submit button">
                                        <FaSearch />
                                    </button>
                                </form>
                                <div className="XZYPL">
                                    <Dropdown className="campaign-dropdown" onSelect={handleCampaignFilter}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Filter Campaigns
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="All">All</Dropdown.Item>
                                            <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
                                            <Dropdown.Item eventKey="Scheduled">Scheduled</Dropdown.Item>
                                            <Dropdown.Item eventKey="Completed">Completed</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className="clear-btn">
                                        <button class="close-btn-cmp" onClick={handleClearFilter}>
                                            <RiCloseFill />
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <Dropdown onSelect={handleTagFilter}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Tags Filter
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="All">All</Dropdown.Item>
                                            {tags && tags.map((item) => (
                                                <Dropdown.Item eventKey={item._id}>{item.tag_name}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="table-card-body-inner mobile-view-hide">
                                <div className="Domain-table">
                                    <ul>
                                        {filteredCampaignData && filteredCampaignData.map((campaign, index) => {
                                            const scans = campaign.channels.map((i) => i?.scans || 0); // Use 0 as default if scans is undefined
                                            const clicks = campaign.channels.map((i) => i?.clicks || 0); // Use 0 as default if clicks is undefined
                                            const totalScans = scans.reduce((acc, curr) => acc + curr, 0);
                                            console.log("campaign", campaign);
                                            const totalClicks = clicks.reduce((acc, curr) => acc + curr, 0);
                                            const createdOnTime = new Date(campaign?.created_on).toLocaleTimeString("en-US", { minute: "2-digit", hour: "2-digit", });
                                            const updatedOnTime = new Date(campaign?.updated_on).toLocaleTimeString("en-US", { minute: "2-digit", hour: "2-digit", });
                                            var channels = campaign.channels;
                                            const startDate = new Date(campaign.start_date);
                                            const endDate = new Date(campaign.end_date);

                                            let color;
                                            let backgroundColor;



                                            switch (campaign.campaign_purpose) {
                                                case "Page Views":
                                                    color = "#00DFF7";
                                                    backgroundColor = "#D6F2F5";
                                                    break;
                                                case "Product Sales":
                                                    color = "#3b5999"
                                                    backgroundColor = "#acc7ff";
                                                    break;
                                                case "Affiliate Referrals":
                                                    color = "#E5B700";
                                                    backgroundColor = "#EEE4BC";
                                                    break;
                                                case "Newsletter Subscribers":
                                                    color = "#EB01FA";
                                                    backgroundColor = "#F5D1F7";
                                                    break;
                                                case "SaaS Subscribers":
                                                    color = "#f5771f";
                                                    backgroundColor = "#ffd4ba";
                                                    break;
                                                case "Software Downloads":
                                                    color = "#00F6B0";
                                                    backgroundColor = "#C8F6E9";
                                                    break;
                                                case "Brand Awareness":
                                                    color = "#d68500";
                                                    backgroundColor = "#ffedca";
                                                    break;
                                                case "Other":
                                                    color = "#29a71a";
                                                    backgroundColor = "#b3ffaa";
                                                    break;
                                                case "Event Ticket Sales":
                                                    color = "#FF5733";
                                                    backgroundColor = "#FFD1C1";
                                                    break;
                                                default:
                                                    color = "#29a71a";
                                                    backgroundColor = "#b3ffaa";
                                            }

                                            const tag_id = campaign?.tag_id;

                                            return (
                                                <li>
                                                    <div className="Smartlink01" style={{ position: "relative" }}>
                                                        {/* {tag_id &&
                                                            <RectangularTag tag_id={tag_id} />
                                                        } */}
                                                        <div className="SmartLinks-table-view">
                                                            <div className="d-flex align-items-center">
                                                                <div className="views02">
                                                                    <div className="campaign-table-image">
                                                                        {currentDate >= startDate && currentDate <= endDate ? (
                                                                            <img src={Power} alt="" className="img-fluid" />
                                                                        ) : startDate > currentDate && endDate > currentDate ? (

                                                                            <img src={Scheduled} alt="" className="img-fluid" />
                                                                        ) : endDate < currentDate && startDate < currentDate ? (

                                                                            <img src={Completed} alt="" className="img-fluid" />
                                                                        ) : null}
                                                                    </div>
                                                                    <h5>{totalClicks + totalScans} <br /><span> Visits</span></h5>
                                                                    <div>
                                                                        <h6 className="ps-0">{totalClicks} Clicks</h6>
                                                                        <h6 className="ps-0">{totalScans} Scans</h6>
                                                                    </div>
                                                                    <div className="new-design-campain">
                                                                        <h3>{campaign.campaign_name}</h3>


                                                                        <div className="vanity-tag d-flex smartlink-tag">
                                                                            {campaign.campaign_purpose === 'Other' ? (
                                                                                <h4 style={{ color: color, backgroundColor: backgroundColor }}>{campaign.custom_purpose}</h4>) : (<h4 style={{ color: color, backgroundColor: backgroundColor }}>{campaign.campaign_purpose}</h4>
                                                                            )}
                                                                        </div>
                                                                        <div className="inner-icon-list">
                                                                            {channels && channels.map((channe) => {
                                                                                return (

                                                                                    <img src={process.env.REACT_APP_API_URL + "/channels/" + channe?.channel_icon} />

                                                                                )
                                                                            })}
                                                                        </div>
                                                                        {(campaign.tags && campaign.tags.length > 0) && (
                                                                            <div className="brand-Vanity-Tag mt-2">
                                                                                <p className="d-flex align-items-center">
                                                                                    <h3 className="text-blue-dark me-2">Tags:</h3>
                                                                                    {campaign.tags.map((tag) => (
                                                                                        <span style={{
                                                                                            color: `${tag.tag_color}`,
                                                                                            backgroundColor: `${tag.tag_color}40`, // Adds transparency to the background
                                                                                            padding: '4px 8px',
                                                                                            borderRadius: '25px',
                                                                                            display: 'inline-block',
                                                                                            marginRight: '4px',
                                                                                        }} className="vanity-tag" key={tag}>
                                                                                            {tag.tag_name}
                                                                                        </span>
                                                                                    ))}
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {/* <div className="SmartLink-list">

                                                                        <div className="campaign-table-image hide-desktop">
                                                                            <img src={Power} alt="" className="img-fluid" />
                                                                        </div>

                                                                        <div className="Domain-table-content">
                                                                            <h5>{campaign.short_url}</h5>
                                                                            <div className="d-col">

                                                                                <div className="">
                                                                                    <h5>Start: {(new Date(campaign.start_date)).toLocaleDateString("en-US", {
                                                                                        timeZone: "UTC",
                                                                                    })}</h5>
                                                                                    <h5>End: {(new Date(campaign.end_date)).toLocaleDateString("en-US", {
                                                                                        timeZone: "UTC",
                                                                                    })}</h5>

                                                                                </div>
                                                                            </div>
                                                                            <div className="VanityVisits">
                                                                                <h4>101 visits</h4>
                                                                                <div className="d-flex">
                                                                                    <p>56 Clicks</p>
                                                                                    <p>45 Scans</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div> */}


                                                            </div>
                                                        </div>

                                                        <div className="Domain-table-action">
                                                            <div key={index}>
                                                                {/* <div className="details-box-smartlink">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="profile-user-image">
                                                                            {campaign?.creator_name && (
                                                                                <img
                                                                                    src={API_URL + `profile/` + campaign.creator_image}
                                                                                    alt="Creator Image"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="user-details">
                                                                            <h6>{campaign?.creator_name}</h6>
                                                                            <div className="d-flex align-items-center">
                                                                                <span>
                                                                                    {campaign?.creator_name
                                                                                        ? `Created: ${new Date(campaign?.created_on).toLocaleDateString("en-US", {
                                                                                            year: "numeric",
                                                                                            month: "numeric",
                                                                                            day: "2-digit",

                                                                                        })} ${createdOnTime}`
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                <div className="details-box-smartlink">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="profile-user-image">
                                                                            {campaign?.updator_name && (
                                                                                <img
                                                                                    src={API_URL + `profile/` + campaign?.updator_image}
                                                                                    alt="Creator Image"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="user-details">
                                                                            <h6>{campaign?.updator_name}</h6>
                                                                            <div className="d-flex align-items-center">
                                                                                <span>
                                                                                    {campaign?.updator_name
                                                                                        ? `Updated : ${new Date(campaign?.updated_on).toLocaleDateString("en-US", {
                                                                                            year: "numeric",
                                                                                            month: "numeric",
                                                                                            day: "2-digit",
                                                                                        })} ${updatedOnTime}`
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=" ">

                                                                <div className="d-flex justify-content-end">
                                                                    {/* { (currentDate < startDate) &&( */}
                                                                    <Link to={`/viewcampaign/${campaign.campaign_id}`}>
                                                                        <button type="button" className="btn btn-layout-view me-3">
                                                                            <LuView />
                                                                        </button>
                                                                    </Link>

                                                                    <button disabled={(currentDate < startDate) && (currentDate < endDate)} type="button" className="btn btn-chart me-3">
                                                                        <Link to={`/campaign/metrics/${campaign.campaign_id}`}>
                                                                            <TbChartBar />
                                                                        </Link>
                                                                    </button>
                                                                    <button type="button" disabled={(currentDate > startDate) && (currentDate > endDate)} className="btn btn-edit me-3" onClick={(e) => {
                                                                        console.log(campaign);
                                                                        handleShow02();
                                                                        setEditCampaignName(campaign.campaign_name);
                                                                        setCampaignDescription(campaign.campaign_description);
                                                                        setEditCampaignPurpose(campaign?.campaign_purpose);
                                                                        setEditCustomPurpose(campaign?.custom_purpose);
                                                                        setTitleC(campaign?.campaign_name);
                                                                        setDescriptionC(campaign?.campaign_description);
                                                                        setEditEndDate2(endDate);
                                                                        setEditStartDate2(startDate);
                                                                        setEditEndDate((campaign.end_date).split("T")[0]);
                                                                        setEditStartDate((campaign.start_date).split("T")[0]);
                                                                        setCampaignId(campaign.campaign_id);
                                                                    }}>
                                                                        <TbEdit />
                                                                    </button>
                                                                    {/* )} */}
                                                                    <button type="button" className="btn btn-delete" onClick={() => {
                                                                        handleShow04();
                                                                        setCampaignId(campaign.campaign_id)
                                                                    }}>
                                                                        <TbTrash />
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="smartlink-mobile-show">
                                    {filteredCampaignData && filteredCampaignData.map((campaign, index) => {
                                        const scans = campaign.channels.map((i) => i?.scans || 0); // Use 0 as default if scans is undefined
                                        const clicks = campaign.channels.map((i) => i?.clicks || 0); // Use 0 as default if clicks is undefined
                                        const totalScans = scans.reduce((acc, curr) => acc + curr, 0);
                                        const totalClicks = clicks.reduce((acc, curr) => acc + curr, 0);
                                        console.log("Total Scans:", totalScans);
                                        console.log("Total Clicks:", campaign.description);
                                        var channels = campaign.channels;
                                        console.log("this is my channels", channels[0])
                                        // const currentDate = new Date();
                                        const startDate = new Date(campaign.start_date);
                                        const endDate = new Date(campaign.end_date);

                                        const campaignTags = campaign.tags;
                                        console.log("this is my campaign tags", campaignTags)

                                        return (
                                            <div className="smartlink-view-card">

                                                <div className="smartlink-view-card-body">
                                                    <div className="smartlink-view-card-body-inner">

                                                        <div className="brand-link campagin-link">
                                                            <div className="campaign-table-image">
                                                                {currentDate >= startDate && currentDate <= endDate ? (
                                                                    <img src={Power} alt="" className="img-fluid" />
                                                                ) : startDate > currentDate && endDate > currentDate ? (

                                                                    <img src={Scheduled} alt="" className="img-fluid" />
                                                                ) : endDate < currentDate && startDate < currentDate ? (

                                                                    <img src={Completed} alt="" className="img-fluid" />
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        <div className="new-design-campain campagin-name-view">
                                                            <h3>{campaign.campaign_name}</h3>
                                                            {campaign.campaign_purpose === 'Other' ? (
                                                                <p>{campaign.custom_purpose}</p>) : (<p>{campaign.campaign_purpose}</p>
                                                            )}
                                                        </div>

                                                        <div className="d-flex align-items-center justify-content-between text-center mb-3">
                                                            <h5>{totalClicks + totalScans} <br /><span> Visits</span></h5>
                                                            <h6>{totalClicks} <br /> Clicks</h6>
                                                            <h6>{totalScans} <br /> Scans</h6>
                                                        </div>

                                                        <div className="inner-icon-list">
                                                            {channels && channels.map((channe) => {
                                                                return (

                                                                    <img src={process.env.REACT_APP_API_URL + "/channels/" + channe?.channel_icon} />

                                                                )
                                                            })}
                                                        </div>

                                                        {(campaign.tags && campaign.tags.length > 0) && (
                                                            <div className="brand-Vanity-Tag mt-2">
                                                                <p className="d-flex align-items-center">
                                                                    <h3 className="text-blue-dark me-2">Tags:</h3>
                                                                    {campaign.tags.map((tag) => (
                                                                        <span style={{ backgroundColor: `${tag.tag_color}` }} className="vanity-tag" key={tag}>
                                                                            {tag.tag_name}
                                                                        </span>
                                                                    ))}
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="smartlink-btn-list">
                                                            <div className="Domain-table-action campaign-action">
                                                                <div className="button-group1 ">
                                                                    <div className="d-flex">
                                                                        <Link to={`/campaign/metrics/${campaign.campaign_id}`}>
                                                                            <button disabled={(currentDate < startDate) && (currentDate < endDate)} type="button" className="btn btn-chart me-3">
                                                                                <TbChartBar />
                                                                            </button>
                                                                        </Link>
                                                                        <Link to={`/viewcampaign/${campaign.campaign_id}`}>
                                                                            <button type="button" className="btn btn-layout me-3">
                                                                                <MdOutlineRemoveRedEye />
                                                                            </button>
                                                                        </Link>
                                                                        <button type="button" disabled={(currentDate > startDate) && (currentDate > endDate)} className="btn btn-edit me-3" onClick={() => {
                                                                            console.log(campaign);
                                                                            handleShow02();
                                                                            setEditCampaignName(campaign?.campaign_name);
                                                                            setCampaignDescription(campaign?.campaign_description);
                                                                            setEditCampaignPurpose(campaign?.campaign_purpose);
                                                                            setEditCustomPurpose(campaign?.custom_purpose);
                                                                            setTitleC(campaign?.campaign_name);
                                                                            setDescriptionC(campaign?.campaign_description);
                                                                            setEditEndDate2(endDate);
                                                                            setEditStartDate2(startDate);




                                                                            setEditEndDate((campaign.end_date).split("T")[0]);
                                                                            setEditStartDate((campaign.start_date).split("T")[0]);
                                                                            setCampaignId(campaign.campaign_id);

                                                                        }}>
                                                                            <TbEdit />
                                                                        </button>
                                                                        {/* )} */}
                                                                        <button type="button" className="btn btn-delete" onClick={() => {
                                                                            handleShow04();
                                                                            setCampaignId(campaign.campaign_id)
                                                                        }}>
                                                                            <TbTrash />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Campaigns;