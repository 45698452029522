import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { API_URL } from "../../BackendApi/Api/config";
import { useBuilderContext } from "../../builderContext";
import Loader2 from "../../Components/Loader/Loader2";

// Import your custom components here
import BuilderHeader from "../../PageBuilder/Components/TemplateView/BuilderHeader";
import BuilderTitle from "./flexComponents/BuilderTitle";
import BuilderIcon from "./flexComponents/BuilderIcon";
import BuilderButton from "./flexComponents/BuilderButton";
import BuilderText from "./flexComponents/BuilderText";
import ImageText from "./flexComponents/ImageText";
import YoutubeLink from "./flexComponents/YoutubeLink";
import Spotify from "./flexComponents/Spotify";
import ImageGrid from "./flexComponents/ImageGrid";
import Testimonials from "./flexComponents/Testimonials";
import ContactUs from "./flexComponents/ContactUs";
import Event from "./flexComponents/Event";
import YoutubeIframe from "./flexComponents/YoutubeIframe";
import { useParams } from "react-router-dom";
import { AiOutlineMore } from "react-icons/ai";
import SocialSharePage from "../../Components/share/SocialShare";
import {
  ProfessionalMotionBackground,
  AdvancedMotionGraphicsBackgroundspam,
  ParticleContainer,
  AdvancedMotionGraphicsBackground,
  AnimatedParticles,
  AnimatedParticles2,
  AnimatedParticles3,
  CelebrationParty,
  StarfallAnimation,
  TailStarsAnimation,
  ThunderStorm,
  GlitterBackground,
  DynamicBackground,
  CosmicContainer,
  AnimatedBackground,
  GalaxySpiral,
  RainEffect,
} from "../../EventBuilder/Components/demo";
import EventParking from "./flexComponents/EventParking";
import EventAgenta from "./flexComponents/EventAgenta";
import EventsImagesList from "./flexComponents/EventsImagesList";
import EventsGalleryList from "./flexComponents/EventsGalleryList";
import ContactForm from "./flexComponents/ContactForms";

import EventPage from "./EventPage";
import Flexpages2 from "./Flexpages2";
import NotFoundpage from "./NotFoundpage";

import WebPage from "../../views/web-builder-public/MainBuilder";
import { WebBuilderContext } from "../../contexts/context/webBuilderContext";

function BuilderLeft() {
  const { webBuilderDispatch } = React.useContext(WebBuilderContext);

  let {
    setMinipage,
    color,
    setColor,
    buttonColor,
    setButtonColor,
    fontFamily,
    setFontFamily,
    setSmartlink_url,
    setQrImage,
    myComponents,
    backgroundType,
    setBackgroundType,
    BackgroundColor,
    setBackgroundColor,
    backgroundColor2,
    setBackgroundColor2,
    colorPosition,
    setColorPosition,
    title,
    setTitle,
    setDescription,
    description,
    setShowPoweredBy,
    showPoweredBy,
    brand_name,
    setBrand_name,
    brand_img,
    setBrand_img,
    brandsocials,
    setBrandsocials,
  } = useBuilderContext();

  const [loading, setLoading] = useState(true);
  const [smartlink_id, setSmartlink_id] = useState("");
  const [tracking_id, setTracking_id] = useState("");
  const [og_image, setOg_image] = useState("");
  const [show404, setShow404] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const { username } = useParams();
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const apiCallMade = useRef(false);

  const [componentToRender, setComponentToRender] = useState(null);
  const [venue, setVenue] = useState(null);

  const backgroundRef = useRef({});

  useEffect(() => {
    if (!apiCallMade.current) {
      fetchPageData();
      apiCallMade.current = true;
    }
  }, [username]);
  const url = new URL(window.location.href);

  const fetchPageData = async () => {
    try {
      console.log("Fetching data for username:", username);
      const url = new URL(window.location.href);
      const hostname = url.hostname;

      let subdomain =
        hostname.split(".")[1] === "localhost" ||
        hostname.split(".")[1] === "withastral"
          ? hostname.split(".")[0]
          : null;

      console.log("subdomain", subdomain);

      const headers = subdomain
        ? { Subdomain: subdomain, type: "flexPage" }
        : { type: "flexPage" };

      // Extract query parameters
      const queryParams = url.searchParams.toString();
      const apiUrlWithParams = queryParams
        ? `${API_URL}${username}?${queryParams}`
        : `${API_URL}${username}`;

      const response = await axios.get(apiUrlWithParams, { headers });
      if (response.status === 200 && response.data.status) {
        if (response.data.data.type == "WebBuilder") {
          setComponentToRender("WebBuilder");

          console.log(
            "blocks data incoming",
            response.data.data.webBuilder.blocks
          );

          webBuilderDispatch({
            type: "SET_NAVBAR",
            payload: response.data.data.webBuilder.navbar,
          });

          webBuilderDispatch({
            type: "SET_BLOCKS",
            payload: response.data.data.webBuilder.blocks,
          });

          setLoading(false);
          setInitialLoadComplete(true);

          // Set backgroundType after initial load
          if (initialLoad) {
            setInitialLoad(false); // Update flag to prevent re-triggering
          }
        } else {
          setComponentToRender("NotWebBuilder");

          const resData = response.data.data;

          console.log("the event res data", resData);
          setTracking_id(resData.tracking_id);
          setVenue(resData?.event_venue);
          setMinipage(resData.page_json);
          setColor(resData.color);
          setButtonColor(resData.buttonColor);
          setFontFamily(resData.fontFamily);
          setSmartlink_id(resData.smartlink_id);
          setBackgroundColor(resData.background_color1);
          setBackgroundColor2(resData.background_color2);
          setColorPosition(resData.background_Position);
          setTitle(resData.title);
          setDescription(resData.description);
          setOg_image(resData.og_image);
          setBackgroundType(resData.background_type);
          setBrand_name(resData.brand_name);
          setBrand_img(resData.logoLight);
          setBrandsocials(resData.brandsocials);

          if (
            "showPoweredBy" in resData &&
            resData.showPoweredBy !== undefined
          ) {
            setShowPoweredBy(resData.showPoweredBy);
          }
          setLoading(false);
          setInitialLoadComplete(true);

          // Set backgroundType after initial load
          if (initialLoad) {
            setBackgroundType(resData.background_type);
            setInitialLoad(false); // Update flag to prevent re-triggering
          }
        }
      } else {
        setLoading(false);
        setShow404(true);
      }
    } catch (err) {
      console.error("Error fetching page data:", err);
      setLoading(false);
      setShow404(true);
    }
  };

  const title1 = "Sample Digital Card";
  const description1 = "This is a sample digital card description.";
  const og_image1 =
    "https://th.bing.com/th/id/OIP.sqQnyn3zwW_Zh0U6amTuawHaLH?rs=1&pid=ImgDetMain";
  const generateMetaTags = () => {
    const metaTags = [
      { name: "title", content: `Digital Card Of ${title1}` },
      { name: "description", content: description1 },
      { property: "og:title", content: `Digital Card Of ${title1}` },
      { property: "og:description", content: description1 },
      { property: "og:url", content: og_image1 }, //`${API_URL}ogimages/${og_image}`
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@astral" },
      { name: "twitter:title", content: title1 },
      { name: "twitter:description", content: description1 },
    ];

    if (og_image) {
      metaTags.push({ property: "og:image", content: og_image1 }); //`${API_URL}ogimages/${og_image}`
      metaTags.push({ name: "twitter:image", content: og_image1 }); //`${API_URL}ogimages/${og_image}`
    }

    return metaTags;
  };

  const directionMap = {
    topbottom: "to bottom",
    bottomtop: "to top",
    rightleft: "to left",
    leftright: "to right",
  };

  // Default to 'to right' if colorPosition doesn't match any key
  const resolvedDirection = directionMap[colorPosition] || "to right";
  const backgroundStyle = useMemo(() => {
    if (backgroundType === "1") {
      return { backgroundColor: BackgroundColor }; // Changed from background to backgroundColor
    } else if (backgroundType === "2") {
      return {
        backgroundImage: `linear-gradient( ${resolvedDirection},${BackgroundColor}, ${backgroundColor2})`,
      };
    } else {
      return {
        //  backgroundImage: ``
      }; // Changed from background to backgroundColor
    }
  }, [backgroundType, BackgroundColor, backgroundColor2, colorPosition]);
  useEffect(() => {
    const handleInitialAnchor = () => {
      const hash = window.location.hash;
      if (hash) {
        const targetId = hash.replace("#", "");
        const element = document.getElementById(targetId);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
      }
    };

    handleInitialAnchor();
  }, []);

  let checkbgTranslate = [
    "IntenseFireworks",
    "CelebrationFireworks",
    "StarfallAnimation",
    "CelebrationParty",
    "TailStarsAnimation",
    "ParticleContainer",
    "AdvancedMotionGraphicsBackgroundspam",
    "GlitterBackground",
    "DynamicBackground",
    "CosmicContainer",
    "AnimatedBackground",
    "AdvancedMotionGraphicsBackground",
    "ProfessionalMotionBackground",
    "GalaxySpiral",
  ];

  console.log("checkbgTranslate", BackgroundColor);
  const renderAnimations = () => {
    switch (BackgroundColor) {
      case "ProfessionalMotionBackground":
        return <ProfessionalMotionBackground animation={true} />;
      case "GalaxySpiral":
        return <GalaxySpiral animation={true} />;
      case "ParticleContainer":
        return <ParticleContainer animation={true} />;
      case "AdvancedMotionGraphicsBackgroundspam":
        return <AdvancedMotionGraphicsBackgroundspam animation={true} />;
      case "GlitterBackground":
        return <GlitterBackground animation={true} />;
      case "DynamicBackground":
        return <DynamicBackground animation={true} />;
      case "CosmicContainer":
        return <CosmicContainer animation={true} />;

      case "TailStarsAnimation":
        return <TailStarsAnimation animation={true} />;
      case "RainEffect":
        return <RainEffect animation={true} />;
      case "AnimatedBackground":
        return <AnimatedBackground animation={true} />;
      case "AdvancedMotionGraphicsBackground":
        return (
          <AdvancedMotionGraphicsBackground
            animation={true}
            particleCount={75}
            backgroundColor="#0f172a"
            colorPalette={["#3b82f6", "#10b981", "#f43f5e"]}
          />
        );
      default:
        return <GalaxySpiral animation={true} />;
    }
  };
  let newValidate = checkbgTranslate.includes(BackgroundColor);
  const renderComponent = (component, index, newValidate) => {
    let unique_id;
    if(!component.showStatus) {
      return null;
    }
    if (component.type === "TESTIMONIAL") {
      unique_id = component.properties[0]?.unique_id;
    } else {
      unique_id = component.properties?.unique_id;
    }
    let types = component.type === "EVENT" ? true : false;

    // Common props for all components
    const commonProps = {
      key: index,
      id: unique_id || `component-${index}`,
      className: "component-wrapper",
      newValidate: newValidate,
    };

    switch (component.type) {
      case "HEADER":
        return <BuilderHeader type={newValidate} data={component.properties} />;
      case "TITLE":
        return <BuilderTitle {...commonProps} data={component.properties} />;
      case "ICONS":
        return (
          <BuilderIcon
            unique_id={unique_id}
            {...commonProps}
            data={component.properties}
          />
        );
      case "BUTTON":
        return (
          <BuilderButton
            smartlink_id={smartlink_id}
            {...commonProps}
            data={component.properties}
            buttonColor={buttonColor}
          />
        );
      case "TEXT":
        return <BuilderText {...commonProps} data={component.properties} />;
      case "IMAGETEXT":
        return (
          <ImageText
            smartlink_id={smartlink_id}
            {...commonProps}
            data={component.properties}
          />
        );
      case "YOUTUBELINK":
        return (
          <YoutubeLink
            smartlink_id={smartlink_id}
            {...commonProps}
            selectedbtn={component.selectedValue}
            data={component.properties}
          />
        );
      case "SPOTIFY":
        return <Spotify {...commonProps} data={component.properties} />;
      case "IMAGEGRID":
        return (
          <ImageGrid
            smartlink_id={smartlink_id}
            {...commonProps}
            data={component.properties}
          />
        );
      case "YOUTUBEIFRAME":
        return (
          <YoutubeIframe
            smartlink_id={smartlink_id}
            {...commonProps}
            selectedbtn={component.selectedValue}
            data={component.properties}
          />
        );
      case "EVENT":
        return (
          <Event
            brand_img={brand_img}
            brand_name={brand_name}
            types={newValidate}
            {...commonProps}
            smartlink_id={smartlink_id}
            data={component.properties}
            venue={venue}
          />
        );

      case "PARKING":
        return (
          <EventParking
            types={newValidate}
            {...commonProps}
            smartlink_id={smartlink_id}
            data={component.properties}
          />
        );

      case "AGENTA":
        return (
          <EventAgenta
            types={newValidate}
            {...commonProps}
            smartlink_id={smartlink_id}
            data={component.properties}
          />
        );

      case "IMAGES":
        return (
          <EventsImagesList
            types={newValidate}
            {...commonProps}
            smartlink_id={smartlink_id}
            data={component.properties}
          />
        );

      case "GALLERY":
        return (
          <EventsGalleryList
            types={newValidate}
            {...commonProps}
            smartlink_id={smartlink_id}
            data={component.properties}
          />
        );

      case "CONTACTUS":
        return <ContactUs {...commonProps} data={component.properties} />;
      case "TESTIMONIAL":
        return (
          <Testimonials
            unique_id={unique_id}
            {...commonProps}
            data={component.properties}
          />
        );
      case "CONTACTFORM":
        return (
          <ContactForm
            unique_id={unique_id}
            {...commonProps}
            data={component.properties}
          />
        );
      default:
        return null;
    }
  };

  const renderMainComponent = () => {
    const validationState = show404 ? null : newValidate;

    switch (validationState) {
      case true:
        return (
          <EventPage
            brandsocials={brandsocials}
            brand_img={brand_img}
            brand_name={brand_name}
            myComponents={myComponents}
            renderAnimations={renderAnimations}
            url={url}
            smartlink_id={smartlink_id}
            renderComponent={renderComponent}
            venue={venue}
          />
        );
      case false:
        return (
          <Flexpages2
            backgroundStyle={backgroundStyle}
            url={url}
            smartlink_id={smartlink_id}
            renderComponent={renderComponent}
          />
        );

      default:
        return <NotFoundpage />;
    }
  };

  switch (componentToRender) {
    case "WebBuilder":
      return <WebPage />;
    case "NotWebBuilder":
      return (
        <>
          <Helmet>
            {generateMetaTags().map((tag, index) => (
              <meta key={index} {...tag} />
            ))}
          </Helmet>

          {loading ? <Loader2 /> : renderMainComponent()}
        </>
      );
    default:
      return (
        <>
          <Helmet>
            {generateMetaTags().map((tag, index) => (
              <meta key={index} {...tag} />
            ))}
          </Helmet>

          {loading ? <Loader2 /> : renderMainComponent()}
        </>
      );
  }
}

export default BuilderLeft;
