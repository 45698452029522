import React from "react";
import { useContext, useState } from "react";
import "./CreateSmartlink.css";
import { BsFillImageFill } from 'react-icons/bs';
import { IoArrowBackCircleOutline, IoCode } from 'react-icons/io5';
import { FaApple, FaGlobeAmericas, } from 'react-icons/fa';
import { MdLink, MdAndroid, MdLocalOffer, MdLockOutline, MdOutlineCalendarToday, MdOutlineLocalOffer, MdQrCode } from 'react-icons/md';
import { TbLink, TbWebhook, TbBrandApple, TbHandClick } from "react-icons/tb";
import { GiTargeting } from "react-icons/gi"
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import SmartLinkEditing from "../SmartLinkTabs/SmartLinkEditing";
import Qrtemplates from "../SmartLinkTabs/Qrtemplates";
import IosTargeting from "../SmartLinkTabs/IosTargeting";
import TrackingCode from "../SmartLinkTabs/TrackingCode";
import Schedule from "../SmartLinkTabs/Schedule";
import UTMsParameters from "../SmartLinkTabs/UTMsParameters";
import GeoTargeting from "../SmartLinkTabs/GeoTargeting";
import AndroidTargeting from "../SmartLinkTabs/AndroidTargeting";
import Redirection from "../SmartLinkTabs/Redirection";
import PasswordProtection from "../SmartLinkTabs/PasswordProtection";
import Header from "../Header/Header";
import Accordion from 'react-bootstrap/Accordion';
import LinkCloaking from "../SmartLinkTabs/LinkCloaking";
import CallToAction from "../SmartLinkTabs/CallToAction";
import { smartlinkdataApi } from "../../BackendApi/Api/smartsLinkApi";
import { SmartLinkContext } from "./SmartLinkcontext";
import { getscheduleapi } from "../../BackendApi/Api/smartsLinkApi";
import { getlinkcloakingApi } from "../../BackendApi/Api/smartsLinkApi";
import { getpassworddataApi } from "../../BackendApi/Api/smartsLinkApi";
import { gettrackingdataApi } from "../../BackendApi/Api/smartsLinkApi";
import { sessionContext } from "../../sessionContext";
import WebAnalytics from "../SmartLinkTabs/WebAnalytics";
import { API_URL } from "../../BackendApi/Api/config";

import { useBuilderContext } from "../../builderContext";
import ResolveDestination from "./ResolveDestination";




function CreateSmartlink() {

    const { sessionName, setSessionName, sessionDescription, setSessionDescription, SessionData, setSessionData } = useContext(sessionContext);

    const { company_slug, setCompain_slug } = useBuilderContext();

    const [smartlinkeditdata, setSmartlinkEditData, schedulesdata, setSchedulesData,latest, setLatest, linkcloakingdata, setLinkCloakingData,  passwordprotection, setPasswordProtection, trackingdata, setTrackingData, qrdata, setQrData] = React.useContext(SmartLinkContext);

    const [flexPages, setFlexPages] = useState([]);


    const navigate = (direction) => {
        window.history.go(direction);
    }

    //get id from url
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);

    const [smartlinkdata, setSmartlinkdata] = React.useState([]);

    const userdata = localStorage.getItem("user");
    const user = JSON.parse(userdata);
    const getdatasmartlink = () => {
        const data = {
            "id": id
        }

        smartlinkdataApi(data).then((res) => {
            if (res.data.status === true) {
                const Apidata = res.data.data.data;

                const flexpages = res.data.data.flexpages

                setFlexPages(flexpages)


                console.log("this is my api data 0000000000000000000000000000000000000=============", Apidata);
                setSmartlinkdata(Apidata);
                setCompain_slug(Apidata.full_short_url)
                setSmartlinkEditData(Apidata);
                // setLatest(false)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    React.useEffect(() => {
        getdatasmartlink();

    }, [latest])
    let goes = async () => {
        console.log("latest", latest)

    }
    goes()
    // const getscheduledata = () => {
    //     const data = {
    //         "id": id
    //     }
    //     getscheduleapi(data).then((res) => {
    //         if (res.data.status === true) {
    //             const Apidata = res.data.data;
    //             setSchedulesData(Apidata);
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }

    const getlinkcloakingdata = () => {
        const data = {
            "id": id
        }
        getlinkcloakingApi(data).then((res) => {
            if (res.data.status === true) {
                const Apidata = res.data.data;
                setLinkCloakingData(Apidata);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    // const getpassworddata = () => {
    //     const data = {
    //         "id": id
    //     }

    //     getpassworddataApi(data).then((res) => {
    //         if (res.data.status === true) {
    //             const Apidata = res.data.data;
    //             setPasswordProtection(Apidata);
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }

    const gettrackingdata = () => {
        const data = {
            "id": id
        }

        gettrackingdataApi(data).then((res) => {
            if (res.data.status === true) {
                const Apidata = res.data.data;
                setTrackingData(Apidata);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const updateqrdata = () => {
        setQrData(smartlinkdata.styling);
    }

    const createdOnTime = new Date(smartlinkdata.created_on).toLocaleTimeString();
    const formattedTime = createdOnTime.slice(0, 5) + ' ' + createdOnTime.slice(-2);
    const updatedOnTime = new Date(smartlinkdata.updated_on).toLocaleTimeString();
    const formattedUpdatedTime = updatedOnTime.slice(0, 5) + ' ' + updatedOnTime.slice(-2);
    const truncatedUrl = smartlinkdata.long_url?.length > 100
    ? `${smartlinkdata.long_url.substring(0, 120)}...`
    : smartlinkdata.long_url;
    return (
        <>
            <Header />

            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <div className="tab-content-body">
                                <div className="col-md-12">
                                    <div className="Back-btn d-flex justify-content-between">

                                        <div className="d-flex align-items-center  ">
                                            <button className="Back-btn-icon" onClick={() => navigate(-1)}><IoArrowBackCircleOutline /></button>
                                            <div className="Back-btn-text">
                                                <h5>{smartlinkdata.full_short_url}</h5>
                                                {ResolveDestination(smartlinkdata)}
                                            </div>
                                        </div>

                                        <div >
                                            <div className="details-box-smartlink">
                                                <div className="d-flex align-items-center">
                                                    <div className="profile-user-image">
                                                        {smartlinkdata.creator_name && (
                                                            <img
                                                                src={API_URL + `profile/` + smartlinkdata.creator_image}
                                                                alt="Creator Image"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="user-details">
                                                        <h6>{smartlinkdata.creator_name}</h6>
                                                        <div className="d-flex align-items-center">
                                                            <span>
                                                                {smartlinkdata.creator_name
                                                                    ? `Created: ${new Date(smartlinkdata.created_on).toLocaleDateString()} ${formattedTime}`
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="details-box-smartlink">
                                                <div className="d-flex align-items-center">
                                                    <div className="profile-user-image">
                                                        {smartlinkdata.updator_name && (
                                                            <img
                                                                src={API_URL + `profile/` + smartlinkdata.updator_image}
                                                                alt="Creator Image"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="user-details">
                                                        <h6>{smartlinkdata.updator_name}</h6>
                                                        <div className="d-flex align-items-center">
                                                            <span>
                                                                {smartlinkdata.updator_name
                                                                    ? `Updated : ${new Date(smartlinkdata.updated_on).toLocaleDateString()} ${formattedUpdatedTime}`
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></div>
                                    </div>
                                </div>


                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row className="mobile-view-hide">
                                        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-12">
                                            <div className="tab-card">
                                                <div className="tab-card-body">
                                                    <div className="tab-content">
                                                        <Nav variant="pills" className="flex-column">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="first" className="technician-tab-link"> <div className="user-profile-box"><MdLink /></div> <span className="tab-user-name" >Flexlink Editing</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="second" onClick={updateqrdata} className="technician-tab-link"> <div className="user-profile-box"><MdQrCode /></div> <span className="tab-user-name" >QR Designer</span></Nav.Link>
                                                            </Nav.Item>
                                                            {/* <Nav.Item>
                                                                    <Nav.Link eventKey="third" className="technician-tab-link"> <div className="user-profile-box"><FaApple /></div> <span className="tab-user-name" >iOS Targeting</span></Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="fourth" className="technician-tab-link"> <div className="user-profile-box"><MdAndroid /></div> <span className="tab-user-name" >Android Targeting</span></Nav.Link>
                                                                </Nav.Item> */}
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="fifth" className="technician-tab-link"> <div className="user-profile-box"><MdLocalOffer /></div> <span className="tab-user-name" >UTM & Custom Parameters </span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="sixth" className="technician-tab-link"> <div className="user-profile-box"><GiTargeting /></div> <span className="tab-user-name" >Targeting</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="seventh" className="technician-tab-link"> <div className="user-profile-box"><MdOutlineCalendarToday /></div> <span className="tab-user-name" >Scheduled Waypoint</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="ninth" className="technician-tab-link"> <div className="user-profile-box"><MdLockOutline /></div> <span className="tab-user-name" >Password Waypoint</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="eleventh" className="technician-tab-link"> <div className="user-profile-box"><BsFillImageFill /></div> <span className="tab-user-name" >Content Waypoint</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="twelth" className="technician-tab-link"> <div className="user-profile-box"><TbHandClick /></div> <span className="tab-user-name" >Call To Action</span></Nav.Link>
                                                            </Nav.Item>
                                                            {/* <Nav.Item>
                                                                    <Nav.Link eventKey="eighth" onClick={getlinkcloakingdata} className="technician-tab-link"> <div className="user-profile-box"><TbLink /></div> <span className="tab-user-name" >Link Cloaking</span></Nav.Link>
                                                                </Nav.Item> */}
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="tenth" onClick={gettrackingdata} className="technician-tab-link"> <div className="user-profile-box"><IoCode /></div> <span className="tab-user-name" >Header & Tracking Codes</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="towth" className="technician-tab-link"> <div className="user-profile-box"><TbWebhook /></div> <span className="tab-user-name" >Webhook</span></Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-8 col-12">
                                            <div className="tab-content Smart-Link-Editing">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><MdLink /></div>Flexlink Editing</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            {/* <SmartLinkEditing smartlinkdata={smartlinkdata}/> */}
                                                            <SmartLinkEditing flexPages={flexPages}/>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><MdQrCode /></div>QR Designer </h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <Qrtemplates pageType="template" />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="third">
                                                        <div className="tab-content-header add-btn d-flex">
                                                            <h5 className="tab-content-title ps-2" ><div className="tab-content-icon"><FaApple /></div>iOS Targeting</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <IosTargeting />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fourth">
                                                        <div className="tab-content-header add-btn d-flex">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><MdAndroid /></div>Android Targeting</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <AndroidTargeting />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fifth">
                                                        <div className="tab-content-header add-btn d-flex">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><MdLocalOffer /></div>UTM & Custom Parameters</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <UTMsParameters />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="sixth">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><GiTargeting /></div>Targeting</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <GeoTargeting />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="seventh">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><MdOutlineCalendarToday /></div>Scheduled Waypoint</h5>
                                                            <div className="tab-content-text ps-2">
                                                                if users visit the smart link out side of the schedule they will redirected to the 404 page
                                                            </div>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <div className="table-card-body-inner">
                                                                <Schedule />
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="eighth">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><TbLink /></div>Link Cloaking</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <LinkCloaking />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="twelth">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><TbHandClick /></div>Call To Action</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <CallToAction />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="ninth">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><MdLockOutline /></div>Password Waypoint</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <PasswordProtection />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="tenth">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><IoCode /></div>Header & Tracking Codes</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <TrackingCode />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="eleventh">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><BsFillImageFill /></div>Content Waypoint</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <Redirection />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="towth">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><TbWebhook /></div>Webhook</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <WebAnalytics />
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </div>
                                        </div>
                                    </Row>
                                </Tab.Container>
                            </div>
                            <div className="row pd-20">
                                <div className="mobile-view-show">
                                    <div className="accordion-list">
                                        <Accordion defaultActiveKey="0">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdLink /></div> <span className="tab-user-name" >Flexlink Editing</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <SmartLinkEditing />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdQrCode /></div> <span className="tab-user-name" >QR Designer</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <Qrtemplates pageType="template" />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><TbBrandApple /></div> <span className="tab-user-name" >iOS Targeting</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <IosTargeting />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdAndroid /></div> <span className="tab-user-name" >Android Targeting</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <AndroidTargeting />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdOutlineLocalOffer /></div> <span className="tab-user-name" >UTM & Custom Parameters</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <UTMsParameters />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><FaGlobeAmericas /></div> <span className="tab-user-name" >Targeting</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <GeoTargeting />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdOutlineCalendarToday /></div> <span className="tab-user-name" >Scheduled Waypoint</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        {/* <div className="tab-content-header">
                                                                <div className="tab-content-text">
                                                                    if users visit the smart link out side of the schedule they will redirected to the 404 page
                                                                </div>
                                                            </div> */}
                                                        <div className="tab-content-body">
                                                            <div className="table-card-body-inner">
                                                                <Schedule />
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="7">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><TbLink /></div> <span className="tab-user-name" >Link Cloaking</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <div className="table-card-body-inner">
                                                                <LinkCloaking />
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="12">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><TbHandClick /></div> <span className="tab-user-name" >Call To Action</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <div className="table-card-body-inner">
                                                                <CallToAction />
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="8">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdLockOutline /></div> <span className="tab-user-name" > Password Waypoint</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <PasswordProtection />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="9">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><IoCode /></div> <span className="tab-user-name" >Header & Tracking Codes</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <TrackingCode />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="10">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><BsFillImageFill /></div> <span className="tab-user-name" >Content Waypoint</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <Redirection />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateSmartlink;