import React, { useRef, useState, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { EffectCreative, Navigation } from 'swiper/modules';

import { completedPostsContext } from '../../contexts/context/completedPostsContext';

import { postTimeLocale } from '../../Helpers/postTimeLocale';
import { FaRegThumbsUp, FaRegComment, FaShareAlt } from "react-icons/fa"
import moment from 'moment'
import PostMetrics from './postMetrics';

import { socialAccountContext } from '../../contexts/context/socialAccountContext';

import { FaAngleDown, FaAngleUp, FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube, FaXTwitter, FaLinkedinIn } from "react-icons/fa6";




const API_URL = process.env.REACT_APP_API_URL;
const CompletedPosts = ({ accountdata, type }) => {

    const { connectedAccount, connectedAccountdispatch } = useContext(socialAccountContext);

    const [CompletedPosts, setCompletedPosts] = React.useState([]);

    const { completedPostsData } = useContext(completedPostsContext);

    React.useEffect(() => {

        filterCompletedPosts()

        console.log("completed posts statae changes", completedPostsData.posts)



    }, [completedPostsData, accountdata, type])

    const filterCompletedPosts = () => {

        console.log('the type is', type)
        switch (type) {
            case "ALL":
                if (completedPostsData.posts) {
                    setCompletedPosts(completedPostsData.posts);
                }
                break;
            case "FACEBOOK":
                if (completedPostsData.posts) {
                    console.log("wohoo i am here")
                    const newschedulePosts = completedPostsData.posts.filter(
                        (post) => post.facebook_pageID === accountdata.facebook_pageID
                    );
                    console.log("new freshbook schedule posts", newschedulePosts);
                    setCompletedPosts(newschedulePosts);

                }
                break;
            case "TWITTER":
                if (completedPostsData.posts) {
                    const newschedulePosts = completedPostsData.posts.filter(
                        (post) => post.Twitter_userID === accountdata.Twitter_userID
                    );
                    console.log("completedPostsData", completedPostsData)
                    setCompletedPosts(newschedulePosts);
                }
                break;
            case "INSTAGRAM":
                if (completedPostsData.posts) {
                    const newschedulePosts = completedPostsData.posts.filter(
                        (post) => post.instagram_business_account_id === accountdata.instagram_business_account_id
                    );

                    setCompletedPosts(newschedulePosts);
                }
                break;
            case "LINKEDIN":
                if (completedPostsData.posts) {
                    const newschedulePosts = completedPostsData.posts.filter(
                        (post) => post.LinkedIn_userID === accountdata.LinkedIn_userID
                    );
                    setCompletedPosts(newschedulePosts);
                }
                break;
            case "YOUTUBE":
                if (completedPostsData.posts) {
                    const newschedulePosts = completedPostsData.posts.filter(
                        (post) => post.youtube_userID === accountdata.youtube_userID
                    );
                    setCompletedPosts(newschedulePosts);
                }
            default:
                break;
        }
    };

    return (
        <>

            <div className="post-lists" >

                <div className="all-post-list">
                    {CompletedPosts && CompletedPosts.map((item, index) => {

                        const result = postTimeLocale(item?.posted_time || item.schedule_time);
                        const localDateString = result?.localDateString;
                        const timeZoneAbbreviation = result?.timeZoneAbbreviation;

                        if (!localDateString) {
                            console.error(`Invalid date for item: ${JSON.stringify(item)}`);
                            return null; // Skip rendering this item
                        }

                        const formattedScheduleDate = moment(localDateString).format("MMMM Do YYYY @ h:mma ") + (timeZoneAbbreviation || '');
                        return (

                            <div className="post-box-design" key={index}>

                                <div className="bg-white rounded-lg shadow-sm">
                                    <div className="post-header-details d-flex justify-content-between">
                                        <div className="post-header">
                                            <div className='channel-list-box-icon facebook-profile'>
                                                <img src={
                                                    type === "ALL" ?
                                                        (item.platform === "FACEBOOK" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.facebook_pageID === item.facebook_pageID)?.localProfilePath :
                                                            item.platform === "TWITTER" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.Twitter_userID === item.Twitter_userID)?.localProfilePath :
                                                                item.platform === "INSTAGRAM" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.instagram_business_account_id === item.instagram_business_account_id)?.localProfilePath :
                                                                    item.platform === "LINKEDIN" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.LinkedIn_userID === item.LinkedIn_userID)?.localProfilePath :
                                                                        item.platform === "YOUTUBE" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.youtube_userID === item.youtube_userID)?.localProfilePath :
                                                                            null) :
                                                        type === "FACEBOOK" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                            type === "TWITTER" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                type === "INSTAGRAM" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                    type === "LINKEDIN" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                        type === "YOUTUBE" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                            null
                                                }
                                                    alt="Profile Picture" className="user-profile-img" />

                                                <div className="bdyGBn social-icon-view">
                                                    {item.platform === 'FACEBOOK' && <FaFacebook />}
                                                    {item.platform === 'TWITTER' && <FaXTwitter />}
                                                    {item.platform === 'LINKEDIN' && <FaLinkedinIn />}
                                                    {item.platform === 'INSTAGRAM' && <FaInstagram />}
                                                    {item.platform === 'YOUTUBE' && <FaYoutube />}

                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-lg font-semibold user-name-text">
                                                    {
                                                        type === "ALL" ?
                                                            (item.platform === "FACEBOOK" ? connectedAccount.accounts.find((post) => post.facebook_pageID === item.facebook_pageID)?.facebook_pageName :
                                                                item.platform === "TWITTER" ? connectedAccount.accounts.find((post) => post.Twitter_userID === item.Twitter_userID)?.Twitter_displayName :
                                                                    item.platform === "INSTAGRAM" ? connectedAccount.accounts.find((post) => post.instagram_business_account_id === item.instagram_business_account_id)?.instagram_business_account_name :
                                                                        item.platform === "LINKEDIN" ? connectedAccount.accounts.find((post) => post.LinkedIn_userID === item.LinkedIn_userID)?.LinkedIn_name :
                                                                            item.platform === "YOUTUBE" ? connectedAccount.accounts.find((post) => post.youtube_userID === item.youtube_userID)?.youtube_name :
                                                                                null) :
                                                            type === "FACEBOOK" ? accountdata.facebook_pageName :
                                                                type === "TWITTER" ? accountdata.Twitter_displayName :
                                                                    type === "INSTAGRAM" ? accountdata.instagram_business_account_name :
                                                                        type === "LINKEDIN" ? accountdata.LinkedIn_name :
                                                                            type === "YOUTUBE" ? accountdata.youtube_name :
                                                                                null
                                                    }
                                                </div>
                                                <p className="post-date">Posted : {formattedScheduleDate}</p>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="post-body">
                                        <div className="post-body-text">
                                            <p>{item.caption}</p>
                                        </div>
                                        <div className="post-image-grid-custom">
                                            <div className="social-image-grid-custom">
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={10}
                                                    navigation={true}
                                                    modules={[EffectCreative, Navigation]}
                                                    className="mySwiper2"
                                                >{
                                                        item.mediaType == "image" ?
                                                            <>
                                                                {item.images.map((image, index) => (
                                                                    <SwiperSlide key={image.id}>
                                                                        <div className='image-grid-item'>
                                                                            <img src={image.imgUrl.startsWith('https') ? image.imgUrl : API_URL + '/posts/' + image.imgUrl} alt="Post Image" />
                                                                            {/* {index === 3 && images.length > 4 && (
                                                                <div className="view-more">
                                                                    <button>
                                                                        +{images.length - 4}
                                                                    </button>
                                                                </div>
                                                            )} */}
                                                                        </div>
                                                                    </SwiperSlide>
                                                                ))}
                                                            </>

                                                            :
                                                            <>
                                                                {item.videos.map((video, index) => (

                                                                    <SwiperSlide key={video.id}>
                                                                        <div className='video-grid-item'>
                                                                            <video src={video.imgUrl.startsWith('https') ? video.imgUrl : `${API_URL}/posts/${video.imgUrl}`} controls />
                                                                        </div>
                                                                    </SwiperSlide>

                                                                ))}
                                                            </>
                                                    }
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>

                                    {(item.tags && item.tags.length > 0) && (
                                        <div className="brand-Vanity-Tag mt-2">
                                            <p className="d-flex align-items-center">
                                                <h3 className="text-blue-dark me-2">Tags:</h3>
                                                {item.tags.map((tag) => (
                                                    <span style={{
                                                        color: `${tag.tag_color}`,
                                                        backgroundColor: `${tag.tag_color}40`, // Adds transparency to the background
                                                        padding: '4px 8px',
                                                        borderRadius: '25px',
                                                        display: 'inline-block',
                                                        marginRight: '4px',
                                                    }} className="vanity-tag" key={tag}>
                                                        {tag.tag_name}
                                                    </span>
                                                ))}
                                            </p>
                                        </div>
                                    )}
                                    {/* 
                                    <div className='post-footer'>
                                        <div className='post-footer-inner'>
                                            <div className='post-likes'>
                                                <FaRegThumbsUp />
                                                <p>0</p>
                                            </div>
                                            <div className='post-comments'>
                                                <FaRegComment />
                                                <p>0</p>
                                            </div>
                                            <div className='post-shares'>
                                                <FaShareAlt />
                                                <p>0</p>
                                            </div>
                                        </div>
                                    </div> */}

                                    <PostMetrics item={item} type={type} user_id={accountdata} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )

}

export default CompletedPosts